import { useSMSIntegrationData } from './smsHooks'
import {
  IntegrationMappingStatus,
  IntegrationMappingType,
  useGetIntegrationStatus,
} from 'src/api'

interface UseAngiLeadsIntegrationDataResponse {
  isEnabled: boolean
}

interface UseAngiLeadsIntegrationDataParams {
  locationId: number
}

export const useAngiLeadsIntegrationData = ({
  locationId,
}: UseAngiLeadsIntegrationDataParams): UseAngiLeadsIntegrationDataResponse => {
  const { isEnabled: isSmsEnabled } = useSMSIntegrationData({ locationId })
  const { data: integrationStatus } = useGetIntegrationStatus({
    locationId,
    type: IntegrationMappingType.ANGI_LEADS,
  })

  return {
    isEnabled:
      isSmsEnabled &&
      integrationStatus?.status === IntegrationMappingStatus.READY,
  }
}
