import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'

import { useUpdateContact, useUpdateContactChannel } from 'src/api'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import {
  formatPhoneNumber,
  isPhoneNumberValid,
  unFormatPhoneNumber,
} from 'src/utils'

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const contactFormSchema = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    phone: yup
      .string()
      .test('Is valid phone', 'Please enter a valid phone number', (value) => {
        if (!value) {
          return true
        }

        return isPhoneNumberValid(value || '')
      }),
    email: yup.string().email('Please check your email address and try again.'),
  })
  .required()

export type ContactFormType = 'name' | 'email' | 'phone'

interface ContactForm {
  type: ContactFormType
  locationId: number
  contactId: number
  channelId: number
  hasWrittenName: boolean
  firstName: string
  lastName: string
  phone: string
  email: string
  baseDataCy: string
}

const generateDataCy = (baseDataCy: string, label: string) =>
  `${baseDataCy}-form-${label}`

export const ContactInfoSectionForm: React.FC<ContactForm> = ({
  type,
  locationId,
  contactId,
  hasWrittenName,
  firstName,
  lastName,
  phone,
  email,
  channelId,
  baseDataCy,
}) => {
  const theme = useTheme()
  const { closeModal } = useModalNotificationsContext()
  const { mutateAsync: updateContact } = useUpdateContact(locationId, contactId)
  const { mutateAsync: updateChannel } = useUpdateContactChannel(
    locationId,
    contactId
  )

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting, dirtyFields },
    watch,
    register,
    setValue,
  } = useForm<ContactForm>({
    reValidateMode: 'onBlur',
    resolver: yupResolver(contactFormSchema),
    defaultValues: {
      firstName: hasWrittenName ? firstName : '',
      lastName: hasWrittenName ? lastName : '',
      phone,
      email,
    },
  })

  const formWatcher = watch()

  const onSubmit = handleSubmit(async (data) => {
    if (
      (dirtyFields.firstName && data.firstName?.length === 0) ||
      (dirtyFields.lastName && data.lastName?.length === 0) ||
      (dirtyFields.email && data.email?.length === 0) ||
      (dirtyFields.phone && data.phone?.length === 0)
    ) {
      toast.error('Empty contact information is not allowed')

      return
    }

    try {
      let updated: string | undefined

      if (dirtyFields.firstName || dirtyFields.lastName) {
        updated = 'name'

        await updateContact({
          firstName: data.firstName,
          lastName: data.lastName,
        })
      }

      if (dirtyFields.email || dirtyFields.phone) {
        await updateChannel({
          channelId,
          emailAddress: data.email,
          phoneNumber: data.phone ? unFormatPhoneNumber(data.phone) : undefined,
        })

        updated = 'phone|email'
      }

      if (!!updated) {
        toast.success('Contact updated successfully')
        closeModal()
      }
    } catch (error) {
      toast.error('There was an error trying to update the customer.')
    }
  })

  useEffect(() => {
    const { phone: formPhone } = formWatcher

    if (formPhone?.length === 10) {
      setValue('phone', formatPhoneNumber(formPhone))
    }
  }, [formWatcher, setValue])

  return (
    <>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        {type === 'name' && (
          <>
            <Input
              label="First Name"
              autoComplete="firstName"
              data-cy={generateDataCy(baseDataCy, 'firstName')}
              errors={formErrors}
              {...register('firstName')}
            />
            <Input
              label="Last Name"
              autoComplete="lastName"
              data-cy={generateDataCy(baseDataCy, 'lastName')}
              errors={formErrors}
              {...register('lastName')}
            />
          </>
        )}
        {type === 'phone' && (
          <Input
            label="Phone"
            autoComplete="phone"
            data-cy={generateDataCy(baseDataCy, 'phone')}
            errors={formErrors}
            {...register('phone')}
          />
        )}
        {type === 'email' && (
          <Input
            label="Email"
            autoComplete="email"
            data-cy={generateDataCy(baseDataCy, 'email')}
            errors={formErrors}
            {...register('email')}
          />
        )}
        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            baseDataAttribute={generateDataCy(baseDataCy, 'cancel')}
            style={{ marginRight: theme.space(6) }}
            disabled={isSubmitting}
            onClick={closeModal}
          />

          <Button
            action="primary"
            type="submit"
            baseDataAttribute={generateDataCy(baseDataCy, 'merge-save')}
            label={'Save'}
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </form>
    </>
  )
}

interface UseContactFormParams {
  baseDataAttribute: string
  locationId: number
  contactId: number
  channelId: number
  firstName: string
  lastName: string
  hasWrittenName: boolean
  phone: string
  email: string
}

const useContactForm = ({
  contactId,
  email,
  firstName,
  hasWrittenName,
  lastName,
  locationId,
  phone,
  baseDataAttribute,
  channelId,
}: UseContactFormParams) => {
  const theme = useTheme()
  const { showModal } = useModalNotificationsContext()

  const openContactForm = useCallback(
    (type: ContactFormType) =>
      showModal({
        title: 'Contact Info',
        height: 'auto',
        dataCy: 'mh-contact-details-contact-info',
        width: theme.space(110),
        hideActionButtons: true,
        customBody: (
          <ContactInfoSectionForm
            key={[locationId, contactId, channelId].join('-')}
            baseDataCy={baseDataAttribute}
            type={type}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            email={email}
            hasWrittenName={hasWrittenName}
            locationId={locationId}
            contactId={contactId}
            channelId={channelId}
          />
        ),
      }),
    [
      showModal,
      theme,
      baseDataAttribute,
      firstName,
      lastName,
      phone,
      email,
      hasWrittenName,
      locationId,
      contactId,
      channelId,
    ]
  )

  return { openContactForm }
}

export default useContactForm
