import { toast } from 'react-toastify'

import { IntegrationMappingErrorType, IntegrationMappingType } from 'src/api'
import IntegrationFailureNotification from 'src/components/MessagingHub/IntegrationFailureNotification'

export const useShowIntegrationFailureNotification = () => {
  return ({
    errorType,
    integrationType,
  }: {
    errorType: IntegrationMappingErrorType
    integrationType: IntegrationMappingType
  }) => {
    toast(
      <IntegrationFailureNotification
        errorType={errorType}
        integrationType={integrationType}
      />,
      {
        position: 'top-center',
        autoClose: false,
        closeOnClick: true,
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
      }
    )
  }
}
