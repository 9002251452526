import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface PatchLocationUserEmailOrPhoneMutationParams {
  id: number
  canLogin?: boolean
  shouldReceiveLrSummaries?: boolean
  shouldReceiveUpdates?: boolean
}

type ItemType = 'email' | 'phone'

export const usePatchLocationUserEmailOrPhone = (
  locationId: number,
  userId: number,
  itemType: ItemType
) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: PatchLocationUserEmailOrPhoneMutationParams) => {
      const client = await getClient()

      if (itemType === 'phone') {
        await client.patch(
          `/locations/${locationId}/users/${userId}/phone-numbers/${params.id}`,
          params
        )
      }

      if (itemType === 'email') {
        await client.patch(
          `/locations/${locationId}/users/${userId}/email-addresses/${params.id}`,
          params
        )
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
