import React from 'react'
import styled from 'styled-components'

import { PaymentMethod, useGetLocationPaymentMethods } from 'src/api'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { ContentContainer } from 'src/containers/Settings/Location/Users/styled'
import { useLocationContext } from 'src/contexts/LocationContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import {
  AmexIcon,
  BankGenericIcon,
  CardGenericIcon,
  DiscoverIcon,
  MasterCardIcon,
  VisaIcon,
} from 'src/stories/assets'
import { Body } from 'src/stories/typography'

const getCardIcon = (paymentMethod: PaymentMethod): JSX.Element => {
  if (paymentMethod.bankRoutingNumber) {
    return <BankGenericIcon />
  }

  switch (paymentMethod.cardType) {
    case 'Visa':
      return <VisaIcon />
    case 'American Express':
      return <AmexIcon />
    case 'MasterCard':
      return <MasterCardIcon />
    case 'Discover':
      return <DiscoverIcon />
    default:
      return <CardGenericIcon />
  }
}

const FlexContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const IconContainer = styled.div(({ theme }) => ({
  marginRight: theme.space(2.5),
}))

const CardDetailsContainer = styled.div({
  flexGrow: 1,
})

const PaymentMethods: React.FC = () => {
  const { locationId } = useLocationContext()
  const { data: paymentMethods, isLoading } =
    useGetLocationPaymentMethods(locationId)
  const baseDataAttribute = 'settings-payment-methods'

  return (
    <PageLayout
      title="Payment methods"
      subtitle=""
      baseDataAttribute={baseDataAttribute}
    >
      <ContentContainer>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {!!paymentMethods?.data &&
              paymentMethods.data.map((paymentMethod, i) => {
                let exp = ''

                if (paymentMethod.expMonth && paymentMethod.expYear) {
                  const formattedMonth = paymentMethod.expMonth
                    .toString()
                    .padStart(2, '0')
                  const formattedYear = paymentMethod.expYear
                    .toString()
                    .slice(-2)

                  exp = `${formattedMonth}/${formattedYear}`
                }

                return (
                  <PageSectionContainer
                    key={i}
                    title={paymentMethod.cardType ? 'Card' : 'Bank account'}
                    subtitle=""
                    variant="inverted"
                    isRounded
                  >
                    <FlexContainer>
                      <IconContainer>
                        {getCardIcon(paymentMethod)}
                      </IconContainer>
                      <CardDetailsContainer>
                        {paymentMethod.cardType && (
                          <Body fontWeight="bolder">
                            {paymentMethod.cardType}
                          </Body>
                        )}

                        {paymentMethod.lastFour && exp && (
                          <Body>
                            Card ending in {`****${paymentMethod.lastFour}`}{' '}
                            Exp: {exp}
                          </Body>
                        )}

                        {paymentMethod.bankRoutingNumber &&
                          paymentMethod.lastFour && (
                            <Body>
                              Bank Account ending in{' '}
                              {`****${paymentMethod.lastFour}`}
                            </Body>
                          )}
                      </CardDetailsContainer>
                    </FlexContainer>
                  </PageSectionContainer>
                )
              })}
          </>
        )}
      </ContentContainer>
    </PageLayout>
  )
}

export default PaymentMethods
