import styled from 'styled-components'

import Input from 'src/stories/Input'
import { DownloadIcon } from 'src/stories/assets'

export const TableContainer = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

export const TableWrapper = styled.div({
  width: '100%',
  overflowX: 'auto',
})

export const BottomSpace = styled.div(({ theme }) => ({
  height: theme.space(3),
}))

export const AutomatedCampaignsPillContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

export const CheckboxContainer = styled.div(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const HeaderContentContainer = styled.div<{ clickable: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
  })
)

export const FooterContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(3)}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  gap: theme.space(2),
  background: theme.colors.base_5,
  borderTop: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  fontSize: '1.4rem',
}))

export const PillContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.space(1),
}))

export const StyledAnchor = styled.a(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  color: theme.colors.base_60,
  cursor: 'pointer',
  textWrap: 'nowrap',
}))

export const StyledCheckbox = styled(Input)(({ theme }) => ({
  height: theme.space(5),
  width: theme.space(5),
  marginBottom: '0px',
}))

export const StyledZeroStateTextContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

export const StyledDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
  marginRight: theme.space(2),
}))
