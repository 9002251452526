import { toast } from 'react-toastify'

import {
  ForwardingPhoneNumber,
  useGetLocationFeatureFlag,
  useGetPhoneNumberForwardingStatus,
  useToggleLocationForwardingPhoneNumber,
} from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import Constants from 'src/lib/Constants'

interface UseLocationForwardingPhoneNumberOptions {
  canDoToggle: () => Promise<boolean>
  forwardingPhoneNumber?: ForwardingPhoneNumber
}

export const useLocationForwardingPhoneNumber = ({
  canDoToggle,
  forwardingPhoneNumber,
}: UseLocationForwardingPhoneNumberOptions) => {
  const { locationId, activeLocation } = useLocationContext()
  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })
  const isTextingEnabled = textingEnabledFeatureFlag.value === 1

  const { data: forwardingStatus } = useGetPhoneNumberForwardingStatus({
    locationId,
  })

  const {
    mutateAsync: toggleLocationForwardingPhoneNumber,
    isPending: isToggling,
  } = useToggleLocationForwardingPhoneNumber(locationId)

  const enableForwardingPhoneNumber =
    isTextingEnabled && !!activeLocation.primaryRentedPhoneNumberId
  const isTogglingDisabled = !enableForwardingPhoneNumber || isToggling
  const isLrEnabled = !forwardingStatus?.forward

  const onToggle = async () => {
    if (
      !enableForwardingPhoneNumber ||
      !activeLocation.primaryRentedPhoneNumberId // adding type check to avoid undefined validation below
    ) {
      toast.error('Cannot toggle Live Receptionist, contact support')

      return
    }

    const isValid = await canDoToggle()

    if (!isValid) {
      return
    }

    if (!forwardingPhoneNumber?.id) {
      toast.info('Please save a fallback number')

      return
    }

    const forward = !forwardingStatus?.forward

    await toggleLocationForwardingPhoneNumber({
      phoneNumberId: activeLocation.primaryRentedPhoneNumberId,
      forward,
      forwardingPhoneNumberId: forward ? forwardingPhoneNumber.id : undefined,
    })

    toast.success(
      `Live Receptionist was ${!forward ? 'enabled' : 'disabled'} successfully`
    )
  }

  return {
    isLrEnabled,
    isTogglingDisabled,
    isTextingEnabled,
    onToggle,
  }
}
