import styled from 'styled-components'

export const DetailsLabel = styled.div(({ theme }) => ({
  fontWeight: 500,
  fontSize: '1.3rem',
  width: '100%',
  color: theme.colors.base_100,
  margin: 0,
  marginTop: theme.space(3),
}))

export const DetailsText = styled(DetailsLabel)(({ theme }) => ({
  fontWeight: 400,
  color: theme.colors.base_50,
  marginTop: theme.space(1),
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.space(2),
}))
