import { useTheme } from 'styled-components'

import UpsellModalImage from 'src/assets/upsell-modal.png'
import UpsellModal from 'src/components/MessagingHub/UpsellModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'

export const useShowUpsellModal = () => {
  const { showModal } = useModalNotificationsContext()
  const theme = useTheme()

  return () => {
    showModal({
      title: 'Upgrade to Signpost software plan',
      dataCy: 'mh-upsell-modal',
      width: theme.space(240),
      modalActionsOptions: {
        callToAction: {
          label: 'Schedule a call',
          onClick: () =>
            window.location.assign(Constants.RedirectUrls.scheduleACall),
        },
      },
      customRightComponent: (
        <img
          data-cy="mh-upsell-modal-image"
          src={UpsellModalImage}
          alt="Upsell Modal"
          style={{ height: theme.space(129) }}
        />
      ),
      customBody: <UpsellModal />,
    })
  }
}
