import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row as ReactRow,
  SortingState,
  Table as ReactTable,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { format, parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { Contact, useGetContacts } from 'src/api'
import { useGetContactsCsv } from 'src/api/hooks/queries/useGetContactsCsvQuery'
import Toolbar from 'src/components/Contacts/Toolbar'
import {
  CheckboxContainer,
  FooterContainer,
  HeaderContentContainer,
  StyledAnchor,
  StyledCheckbox,
  StyledDownloadIcon,
  StyledZeroStateTextContainer,
  TableContainer,
  TableWrapper,
} from 'src/components/Contacts/styled'
import { useTableSearchParams } from 'src/hooks/useTableSearchParams'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Table, TBody, Td, Th, THead, Tr } from 'src/stories/table'
import TableSortIcon from 'src/stories/table/Table-SortIcon'
import { TablePagination } from 'src/stories/table/TablePagination'
import { Body } from 'src/stories/typography'
import { downloadStringAsFile, formatPhoneNumber } from 'src/utils'

const DEFAULT_TAKE = 50
const FULL_SIZE = 1000 //symbolic number size for 100% width

interface Props {
  showContact: (contactId: number) => void
}

const ContactsTable: React.FC<Props> = ({ showContact }) => {
  const theme = useTheme()
  const { isMediumScreen } = useScreenSizes()

  // Route and search parameters
  const {
    setSearchParams,
    locationId,
    pagination,
    search: searchQueryParam,
    sort: sortQueryParam,
  } = useTableSearchParams(DEFAULT_TAKE)

  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip / pagination.take,
      pageSize: pagination.take,
    }),
    [pagination.skip, pagination.take]
  )

  const tableSorting = useMemo<SortingState>(
    () =>
      sortQueryParam
        ? [
            {
              id: sortQueryParam.field ?? '',
              desc: sortQueryParam.direction === 'DESC',
            },
          ]
        : [],
    [sortQueryParam]
  )

  const { data: contactsData, isLoading } = useGetContacts({
    locationId,
    pagination,
    search: searchQueryParam,
    sort: sortQueryParam,
  })
  const { refetch, isFetching: isFetchingCsv } = useGetContactsCsv({
    locationId,
    search: searchQueryParam,
    sort: sortQueryParam,
  })

  const totalItems = contactsData?.total || 0
  const take = pagination.take ?? 1
  const pageCount = Math.ceil(totalItems / take)

  const columnHelper = createColumnHelper<Contact>()

  const handleSearch = (searchValue: string) => {
    table.resetPagination(true)

    if (searchValue.length) {
      setSearchParams((current) => {
        current.set('search', searchValue)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('search')

        return current
      })
    }
  }

  const handleSort = (sort: Updater<SortingState>) => {
    const sortUpdate = functionalUpdate(sort, tableSorting)

    table.resetPagination(true)

    if (sortUpdate.length) {
      const { id, desc } = sortUpdate[0]

      setSearchParams((current) => {
        current.set('direction', desc ? 'DESC' : 'ASC')
        current.set('field', id)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('direction')
        current.delete('field')

        return current
      })
    }
  }

  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set('take', _pageSize.toString())
      current.set('skip', (_pageSize * _pageIndex).toString())

      return current
    })
  }

  const handleDownload = async () => {
    const { data: csv } = await refetch()

    if (csv) {
      downloadStringAsFile(
        `contacts_${format(new Date(), 'yyyy-MM-dd')}.csv`,
        csv,
        'text/csv'
      )
    }
  }

  const emptyPlaceholder = '-'

  const checkboxCell = columnHelper.display({
    id: 'select',
    size: 40,
    header: ({ table }: { table: ReactTable<Contact> }) => (
      <CheckboxContainer>
        <StyledCheckbox
          type="checkbox"
          name="checkbox"
          checked={table.getIsAllRowsSelected()}
          verticallySpaced={false}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      </CheckboxContainer>
    ),
    cell: ({ row }: { row: ReactRow<Contact> }) => (
      <CheckboxContainer>
        <StyledCheckbox
          type="checkbox"
          name="checkbox"
          checked={row.getIsSelected()}
          verticallySpaced={false}
          onChange={row.getToggleSelectedHandler()}
        />
      </CheckboxContainer>
    ),
  })

  const columnsMediumScreen = [
    checkboxCell,
    columnHelper.accessor(
      (row) =>
        [row.firstName, row.lastName].filter(Boolean).join(' ') ||
        emptyPlaceholder,
      {
        id: 'firstName',
        header: 'name',
        size: 180,
        cell: ({
          getValue,
          row: {
            original: { id: contactId },
          },
        }) => (
          <>
            <Button
              label={getValue()}
              asLink
              displayAsText
              style={{ color: theme.colors.base_60 }}
              onClick={() => {
                showContact(contactId)
              }}
            />
          </>
        ),
      }
    ),
    columnHelper.accessor('emailAddress', {
      header: 'email',
      cell: (info) => info.getValue() || emptyPlaceholder,
      size: 180,
    }),
    columnHelper.accessor('phoneNumber', {
      header: 'phone',
      cell: ({
        getValue,
        row: {
          original: { id: contactId },
        },
      }) => {
        const phone = getValue()

        return phone ? (
          <StyledAnchor href={`contacts/${contactId}`}>
            <Body>{phone ? formatPhoneNumber(phone) : emptyPlaceholder}</Body>
          </StyledAnchor>
        ) : (
          emptyPlaceholder
        )
      },
      size: 180,
    }),
    columnHelper.accessor('addedAt', {
      header: 'date added',
      enableSorting: false,
      size: 60,
      cell: ({ row }: { row: ReactRow<Contact> }) => {
        const { original } = row

        return format(parseISO(original.addedAt), 'MM/dd/yyyy')
      },
    }),
  ]

  const columns = [
    checkboxCell,
    columnHelper.accessor(
      (row) => {
        const name =
          [row.firstName, row.lastName].filter(Boolean).join(' ') || null

        return name ?? row.phoneNumber ?? row.emailAddress ?? emptyPlaceholder
      },
      {
        id: 'firstName',
        header: 'Contact',
        size: FULL_SIZE,
        cell: ({
          getValue,
          row: {
            original: { id: contactId },
          },
        }) => (
          <StyledAnchor href={`contacts/${contactId}`}>
            {getValue()}
          </StyledAnchor>
        ),
      }
    ),
  ]

  const table = useReactTable({
    columns: isMediumScreen ? columnsMediumScreen : columns,
    data: contactsData?.data || [],
    debugTable: process.env.REACT_APP_ENVIRONMENT !== 'prod',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    onSortingChange: handleSort,
    pageCount,
    state: {
      pagination: tablePagination,
      sorting: tableSorting,
    },
  })

  return (
    <TableContainer>
      <Toolbar search={searchQueryParam} onSearch={handleSearch} />
      <TableWrapper>
        <Table style={{ tableLayout: isMediumScreen ? 'auto' : 'fixed' }}>
          <THead>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{
                      width:
                        header.getContext().column.getSize() === FULL_SIZE
                          ? '100%'
                          : header.getContext().column.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <HeaderContentContainer
                        clickable={header.column.getCanSort()}
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <TableSortIcon isSorted={header.column.getIsSorted()} />
                      </HeaderContentContainer>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  {isLoading ? (
                    <LoadingSpinner logoHeight={theme.space(15)} />
                  ) : (
                    <StyledZeroStateTextContainer>
                      No results
                    </StyledZeroStateTextContainer>
                  )}
                </Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </TableWrapper>
      <FooterContainer>
        <Button
          type="button"
          label="Download contacts as CSV file"
          icon={StyledDownloadIcon}
          asLink
          maxWidth={theme.space(65)}
          style={{ ...(isFetchingCsv ? { cursor: 'progress' } : {}) }}
          onClick={handleDownload}
          disabled={isFetchingCsv}
        />
        <TablePagination table={table} />
      </FooterContainer>
    </TableContainer>
  )
}

export default ContactsTable
