import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import { Heading, Subtitle } from 'src/stories/typography'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledTitle = styled(Heading)(({ theme }) => ({
  color: theme.colors.primary_1,
  margin: `${theme.space(25)} 0 0 0`,
}))

const StyledSubtitle = styled(Subtitle)(({ theme }) => ({
  color: theme.colors.base_50,
  marginTop: 0,
}))

interface Props {
  errorCode: string
  logout: () => void
  login: () => void
}

const Auth0Error: React.FC<Props> = ({ errorCode, logout, login }) => {
  const [, setSearchParams] = useSearchParams()
  const theme = useTheme()
  const [hasPopupPermission, setHasPopupPermission] = useState<boolean | null>(
    null
  )

  useEffect(() => {
    setSearchParams({})

    if ('permissions' in navigator) {
      navigator.permissions
        .query({ name: 'notifications' as PermissionName })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            setHasPopupPermission(true)
          } else {
            setHasPopupPermission(false)
          }
        })
        .catch((error) => {
          setHasPopupPermission(false)
        })
    } else {
      setHasPopupPermission(false)
    }
  }, [setSearchParams])

  const isPopUpClose = errorCode === 'Popup closed'
  const errorTitle = hasPopupPermission
    ? 'Something went wrong'
    : 'Click to login'

  return (
    <Container>
      <StyledTitle size="extraLarge">{errorTitle}</StyledTitle>

      {isPopUpClose && (
        <>
          <StyledSubtitle size="medium">
            You have closed the popup window
          </StyledSubtitle>
          <Button
            type="button"
            label="Login"
            style={{ maxWidth: theme.space(25) }}
            onClick={login}
          />
        </>
      )}

      {!hasPopupPermission && !isPopUpClose && (
        <>
          <StyledSubtitle size="medium">
            Please enable popups for this site
          </StyledSubtitle>
          <Button
            type="button"
            label="Login"
            style={{ maxWidth: theme.space(25) }}
            onClick={login}
          />
        </>
      )}

      {!isPopUpClose && hasPopupPermission && (
        <>
          <StyledSubtitle size="medium">
            Please contact support and provide the following error code:
          </StyledSubtitle>
          <pre>{errorCode}</pre>
          <Button
            type="button"
            label="Logout"
            style={{ maxWidth: theme.space(25) }}
            onClick={logout}
          />
        </>
      )}
    </Container>
  )
}

export default Auth0Error
