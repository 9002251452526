import { MutationObserverResult } from '@tanstack/react-query'

import { UpdateLocationMutationParams } from 'src/api'

export interface MutationBusinessProps<
  T = Omit<UpdateLocationMutationParams, 'locationId'>,
  TNewVal = Partial<T>
> extends Pick<MutationObserverResult, 'isPending'> {
  update: (newValue: TNewVal) => Promise<void>
}

export enum SmsRegistrationWizardSteps {
  OVERVIEW = 0,
  BUSINESS_INFO = 1,
  PRIMARY_CONTACT_INFO = 2,
  REVIEW_AND_SUBMIT = 3,
  FINISH = 4,
}
