import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateUserEmailAddressParams {
  locationId: number
  userId: number
  emailAddress: string
  canLogin: boolean
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
}

export const useCreateLocationUserEmailAddress = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateUserEmailAddressParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${params.locationId}/users/${params.userId}/email-addresses`,
        {
          emailAddress: params.emailAddress,
          canLogin: params.canLogin,
          shouldReceiveLrSummaries: params.shouldReceiveLrSummaries,
          shouldReceiveUpdates: params.shouldReceiveUpdates,
        }
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
