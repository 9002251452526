import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'

import { LocationsResource } from 'src/client'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import logger from 'src/utils/logger'

const StyledInformationText = styled.p(({ theme }) => ({
  fontSize: '1.4rem',
  marginTop: 0,
  marginBottom: theme.space(6),
}))

const StyledButtonsContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const formSchema = yup
  .object({
    name: yup
      .string()
      .required('List name is required')
      .test(
        'Is valid name',
        'This is a reserved segment name Facebook Leads',
        (value) => value !== Constants.facebookReservedSegmentName
      ),
  })
  .required()

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  baseDataAttribute: string
  locationId: number
  onSubmitCallback?: (listId: number) => Promise<void> | void
}

const AddContactListForm: React.FC<Props> = ({
  baseDataAttribute,
  locationId,
  onSubmitCallback,
}) => {
  const theme = useTheme()
  const { closeModal } = useModalNotificationsContext()

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { id } = await LocationsResource.addSegment({
        locationId,
        name: data.name,
      })

      if (onSubmitCallback) await onSubmitCallback(id)

      closeModal()
    } catch (error) {
      logger.error('MH - Error creating segment', { error })
      toast.error('There was an error trying to create this segment.')
    }
  })

  return (
    <>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <StyledInformationText>
          What do you want to call your list?
        </StyledInformationText>
        <Input
          label="List name"
          autoComplete="name"
          data-cy={`${baseDataAttribute}-form-new-segment-input`}
          disabled={isSubmitting}
          errors={formErrors}
          {...register('name')}
        />

        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            baseDataAttribute={`${baseDataAttribute}-form-new-segment-cancel`}
            style={{ marginRight: theme.space(6) }}
            disabled={isSubmitting}
            onClick={closeModal}
          />

          <Button
            action="primary"
            type="submit"
            baseDataAttribute={`${baseDataAttribute}-form-new-segment-submit`}
            label="Create List"
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </form>
    </>
  )
}

export default AddContactListForm
