import React from 'react'
import styled from 'styled-components'

import { CheckmarkIcon, CrossIcon } from './assets'

export interface ToggleProps
  extends Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'type' | 'className' | 'role' | 'style'
  > {
  dataCy: string
}

const StyledToggleContainer = styled.label(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  width: theme.space(12),
  minWidth: theme.space(12),
  height: theme.space(6),
  borderRadius: '24px',
}))

const StyledToggleInput = styled.input(() => ({
  display: 'none',
}))

const StyledToggleTrack = styled.span<
  Pick<ToggleProps, 'checked' | 'disabled'>
>(({ theme, checked, disabled }) => ({
  position: 'absolute',
  cursor: disabled ? 'auto' : 'pointer',
  color: checked ? theme.colors.primary_2 : theme.colors.base_10,
  backgroundColor: 'currentColor',
  borderRadius: 'inherit',
  boxShadow: `inset 0 0 0 1px ${
    checked ? 'currentColor' : theme.colors.base_20
  }`,
  inset: 0,
  transition: 'color 0.3s ease',
  opacity: disabled ? 0.5 : 1,
  '&::before': {
    position: 'absolute',
    content: '""',
    width: theme.space(6),
    height: theme.space(6),
    borderRadius: '50%',
    backgroundColor: theme.colors.base_0,
    boxShadow: `inset 0 0 0 1px ${
      checked ? 'currentColor' : theme.colors.base_20
    }`,
    transform: `translateX(${checked ? theme.space(6) : '0px'})`,
    transition: 'transform 0.3s ease',
  },
}))

const StyledCheckmarkIcon = styled(CheckmarkIcon)<
  Pick<ToggleProps, 'disabled'>
>(({ theme, disabled }) => ({
  position: 'absolute',
  left: theme.space(2),
  pointerEvents: 'none',
  top: '50%',
  transform: 'translateY(-50%)',
  opacity: disabled ? 0.5 : 1,
  stroke: theme.colors.base_0,
}))

const StyledCrossIcon = styled(CrossIcon)<Pick<ToggleProps, 'disabled'>>(
  ({ theme, disabled }) => ({
    position: 'absolute',
    left: 'auto',
    right: theme.space(2),
    pointerEvents: 'none',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: disabled ? 0.5 : 1,
  })
)

const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
  const { checked, disabled, dataCy, onChange } = props

  const containerProps: Record<string, string> = {}

  if (!disabled) {
    containerProps['data-dd'] = dataCy
  }

  return (
    <StyledToggleContainer data-cy={dataCy} {...containerProps}>
      <StyledToggleInput
        type="checkbox"
        role="switch"
        data-cy={dataCy + '-input'}
        onChange={onChange || (() => null)}
        {...props}
        ref={ref}
      />
      <StyledToggleTrack
        data-cy={dataCy + '-track'}
        checked={checked}
        disabled={disabled}
      />
      <StyledCheckmarkIcon
        data-cy={dataCy + '-checkmark'}
        disabled={disabled}
      />
      <StyledCrossIcon data-cy={dataCy + '-cross'} disabled={disabled} />
    </StyledToggleContainer>
  )
})

Toggle.displayName = 'Toggle'

export default Toggle
