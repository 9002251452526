import React from 'react'
import styled from 'styled-components'

import SettingsStatusAttributes from './StatusAttributes'
import SettingsTagsAttributes from './TagAttributes'
import PageLayout from 'src/components/WafLayout/PageLayout'

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(8),
}))

const LocationMessagingHubSettings: React.FC = () => {
  const baseDataAttribute = 'settings-location-mh'

  return (
    <PageLayout title="Messaging Hub" baseDataAttribute={baseDataAttribute}>
      <SettingsStatusAttributes baseDataAttribute={baseDataAttribute} />
      <StyledSeparator />
      <SettingsTagsAttributes baseDataAttribute={baseDataAttribute} />
    </PageLayout>
  )
}

export default LocationMessagingHubSettings
