import { useMutation } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface StartContactUploadParams {
  locationId: number
  fileName: string
  firstNameField: string
  lastNameField: string
  phoneNumberField: string
  emailAddressField: string
}

export const useStartContactUpload = () => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: StartContactUploadParams) => {
      return getClient()
        .then((client) =>
          client.put<{ signedUrl: string }>(
            `/locations/${params.locationId}/contacts/upload`,
            {
              fileName: params.fileName,
              firstNameField: params.firstNameField,
              lastNameField: params.lastNameField,
              phoneNumberField: params.phoneNumberField,
              emailAddressField: params.emailAddressField,
            }
          )
        )
        .then((r) => r.data)
    },
  })
}
