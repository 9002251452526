import { addMethod, string as yupString, BaseSchema } from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'

import { isUsStateValid, isZipCodeValid } from 'src/utils'

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends BaseSchema<TType, TContext, TOut> {
    phoneNumber(message?: string): StringSchema<TType, TContext>
    usState(message?: string): StringSchema<TType, TContext>
    zipCode(message?: string): StringSchema<TType, TContext>
  }
}

addMethod(yupString, 'phoneNumber', function stateValidation(message?: string) {
  return this.test(
    'validPhoneNumber',
    message || 'Please enter a valid 10-digit phone number',
    (value: string | undefined) => {
      const hasJust10digits = /^\d{10}$/

      return !value || hasJust10digits.test(value)
    }
  )
})

addMethod(yupString, 'usState', function stateValidation(message?: string) {
  return this.test(
    'validUSState',
    message || 'Please insert a valid state',
    (value: string | undefined) => !value || isUsStateValid(value)
  )
})

addMethod(yupString, 'zipCode', function zipCodeValidation(message?: string) {
  return this.test(
    'validZipCode',
    message || 'Please insert a valid zip code',
    (value: string | undefined) => !value || isZipCodeValid(value)
  )
})
