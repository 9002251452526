import styled from 'styled-components'

export const StyledForm = styled.form(({ theme }) => ({
  maxWidth: theme.space(132),
}))

export const StyledTextarea = styled.textarea<{
  height?: number
  error?: boolean
}>(({ theme, height, error }) => ({
  width: '100%',
  minHeight: theme.space(height ? height : 50),
  marginTop: theme.space(1),
  padding: `${theme.space(2)} ${theme.space(3)}`,
  backgroundColor: theme.colors.base_0,
  color: error ? theme.colors.accent_2 : theme.colors.base_100,
  border: `1px solid ${error ? theme.colors.accent_2 : theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  fontSize: '1.6rem',
  fontFamily: 'Roboto',

  ':disabled': {
    cursor: 'not-allowed',
  },

  '&:hover': {
    borderColor: error ? theme.colors.accent_2 : theme.colors.base_50,
  },

  '&:focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${
      error ? theme.colors.accent_2 : theme.colors.primary_1
    }`,
  },
}))
