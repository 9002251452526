import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Conversation } from 'src/api/types'

export interface GetContactConversationsParams {
  locationId: number
  contactId?: number
}

const useConversationsFetch = () => {
  const { getClient } = useApiClient()

  return async ({ locationId, contactId }: GetContactConversationsParams) => {
    if (!contactId) return []

    const client = await getClient()

    const response = await client.get<Conversation[]>(
      `/locations/${locationId}/contacts/${contactId}/conversations`
    )

    return response.data
  }
}

export const useGetContactConversations = (
  { locationId, contactId }: GetContactConversationsParams,
  enabled = true
) => {
  const fetchConversations = useConversationsFetch()

  return useQuery({
    queryKey: [
      'contact-conversations',
      {
        locationId,
        contactId,
      },
    ] as const,
    queryFn: () => fetchConversations({ locationId, contactId }),
    enabled,
  })
}

export const useGetContactConversationsFetch = (locationId: number) => {
  const queryClient = useQueryClient()
  const fetchConversations = useConversationsFetch()

  return (contactId: number) =>
    queryClient.fetchQuery({
      queryKey: ['contact-conversations', { locationId, contactId }],
      queryFn: () => fetchConversations({ locationId, contactId }),
    })
}
