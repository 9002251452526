import { useState } from 'react'
import styled from 'styled-components'

import Select from 'src/stories/Select'

const FlexParent = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.space(2.5),
}))

const FlexItem = styled.div<{ flex: number }>(({ flex }) => ({
  flex,
  fontSize: '1.6rem',
}))

const StatusFilter: React.FC = () => {
  type SelectedStatusType = 'subscribed' | 'opted in'

  const [selectedStatus, setSelectedStatus] =
    useState<SelectedStatusType>('subscribed')

  return (
    <FlexParent>
      <FlexItem flex={3}>Show me the contacts who</FlexItem>
      <FlexItem flex={3}>
        <Select
          options={[
            { label: 'are', value: 'are' },
            { label: 'are not', value: 'are not' },
          ]}
        />
      </FlexItem>
      <FlexItem flex={3}>
        <Select
          initialValue={selectedStatus}
          options={[
            { label: 'subscribed', value: 'subscribed' },
            { label: 'opted in', value: 'opted in' },
          ]}
          onChange={(_selectedStatus: SelectedStatusType) =>
            setSelectedStatus(_selectedStatus)
          }
        />
      </FlexItem>
      <FlexItem flex={3}>
        {selectedStatus === 'subscribed'
          ? 'to marketing emails'
          : 'to text marketing'}
      </FlexItem>
    </FlexParent>
  )
}

const LifecycleFilter: React.FC = () => {
  return (
    <FlexParent>
      <FlexItem flex={4}>Show me contacts who are</FlexItem>
      <FlexItem flex={4}>
        <Select
          options={[
            { label: 'Prospects', value: 'prospects' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Unhappy', value: 'unhappy' },
            { label: 'Unknown', value: 'unknown' },
          ]}
        />
      </FlexItem>
      <FlexItem flex={4}></FlexItem>
    </FlexParent>
  )
}

const EstimatesFilter: React.FC = () => {
  return (
    <FlexParent>
      <FlexItem flex={6}>Show me contacts who have an estimate in the</FlexItem>
      <FlexItem flex={4}>
        <Select
          options={[
            { label: 'Draft', value: 'draft' },
            { label: 'Sent', value: 'sent' },
            { label: 'Approved', value: 'approved' },
            { label: 'Declined', value: 'declined' },
          ]}
        />
      </FlexItem>
      <FlexItem flex={2}>state</FlexItem>
    </FlexParent>
  )
}

const JobsFilter: React.FC = () => {
  return (
    <FlexParent>
      <FlexItem flex={6}>Show me contacts who have a job in the</FlexItem>
      <FlexItem flex={4}>
        <Select
          options={[
            { label: 'Draft', value: 'draft' },
            { label: 'Sent', value: 'sent' },
            { label: 'Approved', value: 'approved' },
            { label: 'Declined', value: 'declined' },
          ]}
        />
      </FlexItem>
      <FlexItem flex={2}>state</FlexItem>
    </FlexParent>
  )
}

export const renderFilter = (selected: string) => {
  switch (selected) {
    case 'status':
      return <StatusFilter />
    case 'lifecycle':
      return <LifecycleFilter />
    case 'estimates':
      return <EstimatesFilter />
    case 'jobs':
      return <JobsFilter />
  }
}
