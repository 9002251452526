import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import styled from 'styled-components'

import {
  BlockAllowListItem,
  DEFAULT_TAKE,
  SpamAction,
  useGetLocationBlockAllowList,
} from 'src/api'
import { useTableSearchParams } from 'src/hooks/useTableSearchParams'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { Table, TBody, Td, TFoot, Th, THead, Tr } from 'src/stories/table'
import { TablePagination } from 'src/stories/table/TablePagination'
import { Heading } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

interface Props {
  action: SpamAction
  title: string
}

const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
  marginBottom: theme.space(8),
}))

const StyledZeroStateTextContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.space(5),
}))

const BlockOrAllowTable: React.FC<Props> = ({ action, title }) => {
  const prefix = `${action.toLowerCase()}_`
  const {
    pagination,
    sort: sortQueryParam,
    locationId,
    setSearchParams,
  } = useTableSearchParams(DEFAULT_TAKE, prefix)
  const { data, isLoading } = useGetLocationBlockAllowList({
    locationId,
    action,
    pagination,
  })
  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip / pagination.take,
      pageSize: pagination.take,
    }),
    [pagination.skip, pagination.take]
  )
  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set(`${prefix}take`, _pageSize.toString())
      current.set(`${prefix}skip`, (_pageSize * _pageIndex).toString())

      return current
    })
  }
  const columnHelper = createColumnHelper<BlockAllowListItem>()
  const columns = [
    columnHelper.accessor('phoneNumber', {
      header: 'Phone Number',
      cell: (info) => formatPhoneNumber(info.getValue()),
    }),
    columnHelper.accessor('action', {
      header: 'Status',
      cell: (info) => info.getValue(),
    }),
  ]
  const table = useReactTable({
    columns,
    data: data?.pages[0]?.data || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    pageCount: Math.ceil((data?.pages[0]?.total || 0) / (pagination.take || 1)),
    state: {
      pagination: tablePagination,
      sorting: sortQueryParam
        ? [
            {
              id: sortQueryParam.field ?? '',
              desc: sortQueryParam.direction === 'DESC',
            },
          ]
        : [],
    },
  })

  return (
    <ContentContainer>
      <Heading>{title}</Heading>
      <Table rounded>
        <THead>
          {table.getHeaderGroups().map((group) => (
            <Tr backgroundColor="base_10" key={group.id}>
              {group.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length}>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <StyledZeroStateTextContainer>
                    No results
                  </StyledZeroStateTextContainer>
                )}
              </Td>
            </Tr>
          )}
        </TBody>
        <TFoot>
          <Tr>
            <Td colSpan={columns.length - 1} />
            <Td>
              <TablePagination table={table} />
            </Td>
          </Tr>
        </TFoot>
      </Table>
    </ContentContainer>
  )
}

export default BlockOrAllowTable
