import { Auth0Provider } from '@auth0/auth0-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'

import Vendors from 'src/containers/Vendors'
import { AccountContextProvider } from 'src/contexts/AccountContext'
import { AuthContextProvider } from 'src/contexts/AuthContext'
import ErrorBoundary from 'src/contexts/ErrorBoundary'
import { ModalNotificationsContextProvider } from 'src/contexts/ModalNotificationsContext'
import Routes from 'src/routes'
import UserlessRoutes from 'src/routes/UserlessRoutes'
import theme from 'src/stories/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const REDIRECT_URL = window.location.origin
const audienceDomain = (process.env.REACT_APP_AUTH0_AUDIENCE ||
  process.env.REACT_APP_BACKEND_HOST) as string

const App: React.FC = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: REDIRECT_URL,
        audience: `https://${audienceDomain}`,
      }}
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ErrorBoundary>
              <AuthContextProvider>
                <AccountContextProvider>
                  <ModalNotificationsContextProvider>
                    <Vendors>
                      <Routes />
                      <ToastContainer
                        autoClose={5000}
                        hideProgressBar
                        rtl={false}
                        bodyStyle={{ fontSize: '1.3rem' }}
                      />
                    </Vendors>
                  </ModalNotificationsContextProvider>
                </AccountContextProvider>
              </AuthContextProvider>

              <UserlessRoutes />
            </ErrorBoundary>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Auth0Provider>
  )
}

export default App
