import React, { memo } from 'react'
import styled from 'styled-components'

import { TypedConversationEvent } from 'src/api'
import { Body } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const Link = styled.a(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary_2,
  margin: 0,
  display: 'inline',
}))

interface Props {
  message: TypedConversationEvent<'LR_SUMMARY_EVENT'>
}

const LiveReceptionistMessageContent: React.FC<Props> = memo(({ message }) => {
  const messageDisplayFields = message.details?.displayFieldsJson || []

  const displayFields = messageDisplayFields.map((field, id) => {
    const strValue = (field.value as string | undefined) ?? ''
    let value = (
      <Body as="p" color="dark">
        {strValue}
      </Body>
    )

    if (field.label === 'Phone') {
      value = (
        <Link href={`tel:${strValue}`}>{formatPhoneNumber(strValue)}</Link>
      )
    } else if (field.label === 'email') {
      value = <Link href={`mailto:${strValue}`}>{strValue}</Link>
    }

    return (
      <div key={id}>
        <Body as="p" color="darker" fontWeight="bold">
          {field.label as string}:{' '}
        </Body>
        {value}
      </div>
    )
  })

  return (
    <Container>
      <Section>{displayFields}</Section>
    </Container>
  )
})

export default LiveReceptionistMessageContent
