import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { DefaultTheme } from 'styled-components'

import TrialUpgradeModal from 'src/components/TrialUpgrade/Modal'
import Modal, { ModalActionsOptions } from 'src/stories/Modal'

export interface UpdateModalParams {
  title?: string
  customBody?: ReactElement
  customRightComponent?: ReactElement
  dataCy?: string
  maxSpacedModal?: boolean
  headerColor?: keyof DefaultTheme['colors']
  headerTitleColor?: keyof DefaultTheme['colors']
  headerTitleFontSize?: string
  headerTitleFontWeight?: number
  headerShadow?: boolean
  reducedPadding?: boolean
  height?: string
  width?: string
  canClose?: boolean
  hideActionButtons?: boolean
  modalActionsOptions?: ModalActionsOptions
}

interface ModalNotificationsContextProps {
  showModal: (params: UpdateModalParams) => void
  showTrialUpgradeModal: (params: UpdateModalParams) => void
  closeModal: () => void
}

// Context designed for all types of notifications that render over the content
// of the app and are accessible from anywhere in the app (modals, toasts, notifications…)
/* This context provides a Singleton modal instance, on the assumption
 * that there will only be a single modal displayed at any time, and that the modals look
 * essentially the same in each spot, with only minor differences in content & styling.
 *
 * In the future we might add additional varieties of modals to this context, but only
 * if we identify a use case for a substantially different pattern.
 *
 * The general expected usage is:
 *   (1) Update the modal (making it look exactly like you want)
 *   (2) show it
 *   (3) (sometimes) update it again while it's open
 *   (4) close it
 *
 * Note: As of June 3 2022 the first step requires actively clearing any "left-over" content
 * from prior uses of the modal during a session. We might want to update the showModal()
 * behavior so that it always returns the modal to a default state, and then sets the
 * content to match the new showModal() inputs.
 */

const ModalNotificationsContext = createContext<ModalNotificationsContextProps>(
  {} as ModalNotificationsContextProps
)

export const ModalNotificationsContextProvider: React.FCWithChildren = ({
  children,
}) => {
  // Modal states
  const { pathname } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalActionsOptions, setModalActionsOptions] =
    useState<ModalActionsOptions>()
  const [isTrialUpgradeModal, setIsTrialUpgradeModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [rightComponent, setRightComponent] = useState<ReactElement>()
  const [maxSpacedModal, setMaxSpacedModal] = useState(false)
  const [hideActionButtons, setHideActionButtons] = useState(false)
  const [modalDataCy, setModalDataCy] = useState('')
  const [modalHeaderColor, setModalHeaderColor] = useState<
    keyof DefaultTheme['colors'] | undefined
  >()
  const [modalHeaderTitleColor, setModalHeaderTitleColor] = useState<
    keyof DefaultTheme['colors'] | undefined
  >()
  const [modalHeaderFontSize, setModalHeaderFontSize] = useState('')
  const [modalHeaderFontWeight, setModalHeaderFontWeight] = useState(400)
  const [modalHeaderShadow, setModalHeaderShadow] = useState(false)
  const [modalReducedPadding, setModalReducedPadding] = useState(false)
  const [modalHeight, setModalHeight] = useState('')
  const [modalWidth, setModalWidth] = useState('')
  const [modalCanClose, setCanCloseModal] = useState(true)

  useEffect(() => {
    if (isModalOpen) {
      setIsModalOpen(false)
    }
    // When the route changes and the modal isOpen, we should close it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleSetupModal = useCallback(
    ({
      title,
      customBody,
      customRightComponent,
      dataCy,
      maxSpacedModal: setupMaxSpacedModal,
      headerColor,
      headerTitleColor,
      headerTitleFontSize,
      headerTitleFontWeight,
      headerShadow,
      reducedPadding,
      height,
      width,
      canClose,
      hideActionButtons: setupHideActionButtons,
      modalActionsOptions: setupModalActionsOptions,
    }: UpdateModalParams) => {
      setModalTitle(title ?? '')
      setModalBody(customBody ?? <></>)
      setRightComponent(customRightComponent)
      setModalDataCy(dataCy ?? '')
      setModalHeight(height ?? '')
      setModalWidth(width ?? '')
      setModalHeaderColor(headerColor)
      setModalHeaderTitleColor(headerTitleColor)
      setModalHeaderFontSize(headerTitleFontSize ?? '1.6rem')
      setModalHeaderFontWeight(headerTitleFontWeight ?? 400)
      setModalHeaderShadow(!!headerShadow)
      setModalReducedPadding(reducedPadding ?? false)
      setCanCloseModal(canClose ?? true)
      setMaxSpacedModal(setupMaxSpacedModal ?? false)
      setHideActionButtons(setupHideActionButtons ?? false)
      setModalActionsOptions(setupModalActionsOptions)
    },
    []
  )

  const handleShowModal = useCallback(
    (params?: UpdateModalParams) => {
      if (params) {
        handleSetupModal(params)
      } else {
        setCanCloseModal(true)
      }
      setIsTrialUpgradeModal(false)
      setIsModalOpen(true)
    },
    [handleSetupModal]
  )

  const handleShowTrialUpgradeModal = (params?: Partial<UpdateModalParams>) => {
    setCanCloseModal(Boolean(params?.canClose))

    setIsTrialUpgradeModal(true)
    setIsModalOpen(true)
  }

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const contextValue = {
    showModal: handleShowModal,
    showTrialUpgradeModal: handleShowTrialUpgradeModal,
    closeModal: handleCloseModal,
  }

  return (
    <ModalNotificationsContext.Provider value={contextValue}>
      {children}

      {isTrialUpgradeModal && isModalOpen && (
        <TrialUpgradeModal
          modalCanClose={modalCanClose}
          handleClose={() => setIsModalOpen(false)}
        />
      )}

      {!isTrialUpgradeModal && isModalOpen && (
        <Modal
          height={modalHeight}
          width={modalWidth}
          modalTitle={modalTitle}
          modalDataCy={modalDataCy}
          maxSpacedModal={maxSpacedModal}
          modalCanClose={modalCanClose}
          headerColor={modalHeaderColor}
          headerTitleColor={modalHeaderTitleColor}
          headerTitleFontSize={modalHeaderFontSize}
          headerTitleFontWeight={modalHeaderFontWeight}
          headerShadow={modalHeaderShadow}
          reducedPadding={modalReducedPadding}
          handleClose={handleCloseModal}
          hideActions={hideActionButtons}
          modalActionsOptions={modalActionsOptions}
          rightComponent={rightComponent}
        >
          {modalBody}
        </Modal>
      )}
    </ModalNotificationsContext.Provider>
  )
}

const useModalNotificationsContext = () => useContext(ModalNotificationsContext)

export default useModalNotificationsContext
