import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

import Constants from 'src/lib/Constants'

export const useApiClient = () => {
  const { getAccessTokenSilently } = useAuth0()

  const client = axios.create({
    baseURL: `https://${process.env.REACT_APP_BACKEND_HOST!}`,
  })

  return {
    getClient: async () => {
      const token = await getAccessTokenSilently()

      client.interceptors.request.use((request) => {
        request.headers.set(Constants.HeaderKeys.bearer, `Bearer ${token}`)

        return request
      })

      return client
    },
  }
}
