import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { IntegrationMappingType, IntegrationStatus } from 'src/api/types'

export interface GetIntegrationStatusParams {
  locationId: number
  type: IntegrationMappingType
}

export const useGetIntegrationStatus = ({
  locationId,
  type,
}: GetIntegrationStatusParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'integration-status',
      {
        locationId,
        type,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<IntegrationStatus>(
            `/locations/${params.locationId}/integrations/${params.type}/status`
          )
        )
        .then((r) => r.data)
    },
  })
}
