import React from 'react'
import styled, { CSSObject, useTheme } from 'styled-components'

import { Button, ButtonProps } from 'src/stories/Button'

enum Variant {
  Default = 'default',
  Primary = 'primary',
  Ghost = 'ghost',
}

interface VariantProps {
  background: string
  padding: string
  border: string
}

const StyledContainer = styled.section<{
  variant: VariantProps
  disabled?: boolean
}>(({ theme, variant, disabled }) => ({
  background: variant.background,
  border: variant.border,
  borderRadius: theme.constants.largeBorderRadius,
  padding: variant.padding,
  filter: disabled ? 'opacity(70%)' : 'none',
}))

const StyledTitle = styled.h2(({ theme }) => ({
  margin: theme.space(0),
  marginBottom: theme.space(2),
  fontSize: '2rem',
  lineHeight: '3.2rem',
}))

const StyledSubtitle = styled.p(({ theme }) => ({
  margin: theme.space(0),
  marginBottom: theme.space(2),
  fontSize: '1.6rem',
  color: theme.colors.base_70,
}))

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(3),
}))

const StyledChildren = styled.div``

export const SectionAction: React.FC<ButtonProps> = ({
  baseDataAttribute = 'section-container',
  style,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Button
      displayAsText
      size="large"
      style={{
        float: 'right',
        paddingLeft: theme.space(2),
        paddingBottom: theme.space(1),
        ...style,
      }}
      baseDataAttribute={baseDataAttribute + '-action'}
      {...props}
    />
  )
}

export interface SectionContainerProps extends React.PropsWithChildren {
  /**
   * The principal section title
   */
  title?: string | JSX.Element
  /**
   * The section subtitle
   */
  subtitle?: string | JSX.Element
  /**
   * The variant section style
   * Can be default, primary or ghost
   */
  variant?: `${Variant}`
  /**
   * Visual filter to show as disabled
   * but still need validation to internal components to prevent user of editing the component
   */
  disabled?: boolean
  /**
   * Action button element, you can use SectionAction default component importing from this component
   */
  action?: JSX.Element
  /**
   * childrenStyle is CSSObject value to add style to the children
   */
  childrenStyle?: CSSObject
  style?: CSSObject
  /**
   * Base Data attribute.
   * Will append `-container`, `-header`, `-title`, and `subtitle` to containing elements
   */
  baseDataAttribute?: string
}

/**
 * Section container to separate different contents
 */
const SectionContainer: React.FCWithChildren<SectionContainerProps> = ({
  title,
  subtitle,
  action,
  variant = Variant.Default,
  disabled,
  childrenStyle,
  style,
  baseDataAttribute = 'section-container',
  children,
}) => {
  const theme = useTheme()

  const variantConfig: Record<Variant, VariantProps> = {
    [Variant.Default]: {
      background: theme.colors.base_3,
      padding: `${theme.space(6)} ${theme.space(8)}`,
      border: `1px solid ${theme.colors.base_20}`,
    },
    [Variant.Primary]: {
      background: theme.colors.base_0,
      padding: `${theme.space(6)} ${theme.space(5)}`,
      border: `1px solid ${theme.colors.base_20}`,
    },
    [Variant.Ghost]: {
      background: 'transparent',
      padding: '0',
      border: 'none',
    },
  }

  return (
    <StyledContainer
      data-cy={baseDataAttribute + '-container'}
      variant={variantConfig[variant]}
      disabled={disabled}
      style={style}
    >
      {action}
      {title && (
        <StyledTitle data-cy={`${baseDataAttribute}-title`}>
          {title}
        </StyledTitle>
      )}
      {subtitle && (
        <StyledSubtitle data-cy={`${baseDataAttribute}-subtitle`}>
          {subtitle}
        </StyledSubtitle>
      )}
      {(title || subtitle) && <StyledSeparator />}
      <StyledChildren style={childrenStyle}>{children}</StyledChildren>
    </StyledContainer>
  )
}

export default SectionContainer
