import { RefObject, useEffect } from 'react'

interface UseClickOutsideProps {
  ref: RefObject<HTMLDivElement>
  onClickOutside: () => void
}

export const useClickOutside = ({
  ref,
  onClickOutside,
}: UseClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node | null)) {
        onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])

  return
}
