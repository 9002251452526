// import ReactGA from 'react-ga4'
import React, { useEffect } from 'react'

import { awsRum } from './rum'
import useAuthContext from 'src/contexts/AuthContext'

const Vendors: React.FCWithChildren = ({ children }) => {
  const { user } = useAuthContext()

  useEffect(() => {
    awsRum?.addSessionAttributes({
      userId: user.id,
      userRoles: user.roles.join(','),
    })
    // TODO: Uncomment after having a new Google Analytics.
    //
    // const getGaUserRole = () => {
    //   let userRole = ''
    //   if (user.attachedAgencyIds && user.attachedAgencyIds.length > 0) {
    //     userRole = 'AGENCY'
    //   } else if (
    //     user.attachedMerchantIds &&
    //     user.attachedMerchantIds.length > 0
    //   ) {
    //     userRole = 'MERCHANT'
    //   } else if (user.roles && user.roles.length > 0) {
    //     if (user.roles.includes('ROLE_ACCOUNT_MANAGER')) {
    //       userRole = 'AM'
    //     }
    //     if (
    //       user.roles.includes('ROLE_ADMIN') ||
    //       user.roles.includes('ROLE_DEV')
    //     ) {
    //       userRole = 'ADMIN'
    //     }
    //   }
    //   return userRole
    // }
    // ReactGA.initialize(
    //   process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID as string,
    //   {
    //     gtagOptions: {
    //       userId: user.id,
    //       dimension1: user.id,
    //       dimension2: getGaUserRole(),
    //     },
    //   }
    // )
  }, [user])

  return <>{children}</>
}

export default Vendors
