import { useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'

import NotFound from 'src/components/NotFound'
import useAuthContext from 'src/contexts/AuthContext'
import { isInternalUser } from 'src/utils'
import { RouteParams, RouteParamsKeys } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

interface PrivateRouteProps {
  validateRouteParams?: RouteParamsKeys[]
  allowedParams: RouteParams[]
  allowLoggedInUser?: boolean
}

export const PrivateRoute: React.FCWithChildren<PrivateRouteProps> = ({
  validateRouteParams = [],
  allowedParams = [],
  allowLoggedInUser,
  children,
}) => {
  const { user, handleLogout } = useAuthContext()
  const params = useParams()

  useEffect(() => {
    if (!user) {
      logger.debug('Not logged in, handling logout')
      handleLogout()
    }
  }, [handleLogout, user])

  const hasRouteParam = allowedParams.some((allowedParam) =>
    validateRouteParams.every(
      /**
       * Ignoring this TS warning because allowedParams will be any key from RouteParams,
       * which, for now, is LocationRouteParams or UserRouteParams.
       * Since the string will have values that will no overlap or be key of the interface
       * TS reports the warning. This will not be an issue since the props are related to each
       * other.
       */
      (routeParam) =>
        // @ts-expect-error See description above
        allowedParam[routeParam] &&
        // @ts-expect-error See description above
        allowedParam[routeParam] === params[routeParam]
    )
  )

  if (!user) return <Navigate to="/login" />

  if (isInternalUser(user) || hasRouteParam || allowLoggedInUser) {
    return <>{children}</>
  }

  return <NotFound />
}
