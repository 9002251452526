import { useCallback, useRef, useState } from 'react'

const useBaseDebouncer = <CB extends CallableFunction>(
  callback: CB,
  timeout?: number
): [CB, boolean] => {
  const debouncer = useRef<NodeJS.Timeout | null>(null)
  const [isPending, setIsPending] = useState(false)
  const timer = timeout || 400

  const returnedCallback = useCallback(
    (...params: unknown[]) => {
      if (debouncer.current) {
        clearTimeout(debouncer.current)
      }

      setIsPending(true)

      debouncer.current = setTimeout(() => {
        callback(...params)
        setIsPending(false)
      }, timer)
    },
    [timer, callback]
  )

  return [returnedCallback as unknown as CB, isPending]
}

export const useDebouncerState = <CB extends CallableFunction>(
  callback: CB,
  timeout?: number
): [CB, boolean] => useBaseDebouncer(callback, timeout)

export const useDebouncer = <CB extends CallableFunction>(
  callback: CB,
  timeout?: number
): CB => {
  const [returnedCallback] = useBaseDebouncer(callback, timeout)

  return returnedCallback
}
