import { AxiosInstance } from 'axios'

import { Segment, SegmentsRequests } from 'src/client/interfaces/Segments'
import Resource from 'src/client/resource'

const actions = (client: AxiosInstance): SegmentsRequests => {
  const get = async (locationId: number): Promise<Segment[]> =>
    client.get(`/v3/locations/${locationId}/segments`)

  return { get }
}

export default Resource(actions)
