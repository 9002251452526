import React, { MouseEventHandler } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

interface ContainerProps {
  $backgroundColor?: keyof DefaultTheme['colors']
  $borderColor?: keyof DefaultTheme['colors']
  width?: string
  maxWidth?: string
  paddingXUnits: number
  borderRadius: string
}

const Container = styled.div<ContainerProps>(
  ({
    theme,
    $backgroundColor,
    width,
    maxWidth,
    paddingXUnits,
    borderRadius,
  }) => ({
    backgroundColor: $backgroundColor && theme.colors[$backgroundColor],
    height: theme.space(6),
    width: width ? width : 'auto',
    maxWidth: maxWidth ? maxWidth : 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: [theme.space(0.5), theme.space(paddingXUnits)].join(' '),
    borderRadius,
    border: `1px solid ${theme.colors.base_10}`,
  })
)

type TextAlignTypes = 'center' | 'left' | 'right'

const Text = styled.span<{
  color?: keyof DefaultTheme['colors']
  textAlign: TextAlignTypes
  fontSize?: string
}>(({ color, textAlign, theme, fontSize }) => ({
  color: color ? theme.colors[color] : undefined,
  margin: 0,
  textAlign,
  width: '100%',
  fontSize,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

const Dot = styled.div<{ $color?: keyof DefaultTheme['colors'] }>(
  ({ theme, $color }) => ({
    width: theme.space(1),
    height: theme.space(1),
    backgroundColor: $color && theme.colors[$color],
    borderRadius: theme.constants.borderRadius,
    marginRight: theme.space(1),
  })
)

export interface PillProps {
  backgroundColor?: keyof DefaultTheme['colors']
  borderColor?: keyof DefaultTheme['colors']
  color?: keyof DefaultTheme['colors']
  dataCy?: string
  dotColor?: keyof DefaultTheme['colors']
  hideDot?: boolean
  text: string | JSX.Element
  paddingXUnits?: number
  borderRadius?: string
  textAlign?: TextAlignTypes
  maxWidth?: string
  width?: string
  fontSize?: string
  onClick?: MouseEventHandler<HTMLElement>
}

const Pill: React.FC<PillProps> = ({
  backgroundColor = 'base_3',
  borderColor = 'base_10',
  color,
  dataCy = 'pill',
  dotColor = 'primary_2',
  hideDot = false,
  text,
  textAlign = 'left',
  paddingXUnits = 4,
  borderRadius,
  maxWidth,
  width,
  fontSize,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <Container
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      data-cy={dataCy}
      width={width}
      maxWidth={maxWidth}
      onClick={onClick}
      paddingXUnits={paddingXUnits}
      borderRadius={borderRadius || theme.constants.largeBorderRadius}
    >
      {!hideDot && <Dot data-cy={dataCy + '-dot'} $color={dotColor} />}
      <Text
        color={color}
        textAlign={textAlign}
        data-cy={dataCy + '-text'}
        fontSize={fontSize}
      >
        {text}
      </Text>
    </Container>
  )
}

export default Pill
