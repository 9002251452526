import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface DeleteUserListParams {
  listId: number
  userId: number
}

export const useDeleteUserList = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: DeleteUserListParams) => {
      const client = await getClient()

      await client.delete(
        `locations/${locationId}/lists/${params.listId}/users/${params.userId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
