import { useEffect, useMemo, useState } from 'react'

import { Location } from 'src/api'
import useAccountContext from 'src/contexts/AccountContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import { formatAddress } from 'src/utils'

interface LocationSelectorItem {
  name: string
  locationAddresses: Location[]
}

const mapMultiLocations = (locations: Location[]) =>
  locations.reduce<LocationSelectorItem[]>((a, c) => {
    const location = a.find((l) => l.name === c.name)

    if (location) {
      location.locationAddresses.push(c)
    } else {
      const l: LocationSelectorItem = {
        name: c.name,
        locationAddresses: [c],
      }

      a.push(l)
    }

    return a
  }, [])

export const useMultiLocationHeaderHooks = (isInternalUser = false) => {
  const [title, setTitle] = useState('All Locations Overview')
  const [dimmedLabel, setDimmedLabel] = useState('')
  const [label, setLabel] = useState('Go to a Location')
  const { locationId } = useLocationContext()
  const { locations: accountLocations } = useAccountContext()
  const locations = useMemo<LocationSelectorItem[]>(
    () => mapMultiLocations(accountLocations),
    [accountLocations]
  )

  useEffect(() => {
    if (locationId && accountLocations) {
      const selectedLocation = accountLocations.find((l) => l.id === locationId)
      let _title = selectedLocation?.name ?? 'All Locations Overview'
      let _dimmedLabel = ''
      let _label = 'Go to a Location'

      if (selectedLocation) {
        _title = formatAddress(
          selectedLocation.addressStreet1,
          selectedLocation.addressStreet2 ?? '',
          selectedLocation.addressCity,
          selectedLocation.addressState,
          selectedLocation.addressZipCode
        )

        _dimmedLabel = 'Current Location:'
        _label = selectedLocation.name
      }

      setLabel(_label)
      setDimmedLabel(_dimmedLabel)
      setTitle(_title)
    }
  }, [locationId, accountLocations, isInternalUser])

  return { title, dimmedLabel, label, locations }
}
