import styled from 'styled-components'

interface TableProps {
  rounded?: boolean | { noFooter: boolean }
}

export const Table = styled.table<TableProps>(({ theme, rounded }) => ({
  fontSize: '1.4rem',
  width: '100%',
  backgroundColor: theme.colors.base_0,
  ...(rounded
    ? {
        borderCollapse: 'separate',
        borderSpacing: 0,
        'tr th': {
          borderTop: `1px solid ${theme.colors.base_20}`,
        },
        'tr th,tr td': {
          borderBottom: `1px solid ${theme.colors.base_20}`,
        },
        'tr th:last-child,tr td:last-child': {
          borderRight: `1px solid ${theme.colors.base_20}`,
        },
        'tr th:first-child,tr td:first-child': {
          borderLeft: `1px solid ${theme.colors.base_20}`,
        },
        'tr:first-child th:first-child': {
          borderTopLeftRadius: theme.constants.borderRadius,
        },
        'tr:first-child th:last-child': {
          borderTopRightRadius: theme.constants.borderRadius,
        },
        ...(rounded === true || !rounded.noFooter
          ? {
              'tfoot tr:last-child td:first-child': {
                borderBottomLeftRadius: theme.constants.borderRadius,
              },
              'tfoot tr:last-child td:last-child': {
                borderBottomRightRadius: theme.constants.borderRadius,
              },
            }
          : {
              'tr:last-child td:first-child': {
                borderBottomLeftRadius: theme.constants.borderRadius,
              },
              'tr:last-child td:last-child': {
                borderBottomRightRadius: theme.constants.borderRadius,
              },
            }),
      }
    : {
        borderCollapse: 'collapse',
        border: `1px solid ${theme.colors.base_20}`,
        tr: {
          borderBottom: `1px solid ${theme.colors.base_20}`,
        },
      }),
}))
