import { AxiosInstance } from 'axios'

import { CustomizeIntantResponseRequests } from 'src/client/interfaces/Integrations'
import Resource from 'src/client/resource'
import Constants from 'src/lib/Constants'

const actions = (client: AxiosInstance): CustomizeIntantResponseRequests => ({
  checkFacebookPageToken: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'FacebookIntegration',
      payload: {
        locationId: params.locationId,
        taskType: 'checkPageToken',
        uid: params.userId,
      },
    })
  },
  connectGoogleAccount: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'GoogleMessagesIntegration',
      payload: {
        details: {
          locationId: params.locationId,
          email: params.email,
          fullName: params.fullName,
          googlePlaceId: params.googlePlaceId,
          website: params.website,
        },
        taskType: 'connect',
        uid: params.userId,
      },
    })
  },
  connectIntegration: (params) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Locations.Integrations',
      method: 'connect',
      params: {
        integration: params.integration,
        locationId: params.locationId,
        syncConfig: params.syncConfig,
        status: params.status,
      },
    })
  },
  disconnectIntegration(params) {
    return this.connectIntegration({ ...params, syncConfig: {} })
  },
  enableThumbtackMessaging: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'ThumbtackIntegration',
      payload: {
        taskType: 'enableMessaging',
        details: {
          locationId: params.locationId,
          accessToken: params.accessToken,
          refreshToken: params.refreshToken,
        },
      },
    })
  },
  handleThumbtackCode: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'ThumbtackIntegration',
      payload: {
        taskType: 'exchangeAccessToken',
        details: {
          code: params.code,
        },
      },
    })
  },
  saveFacebookToken: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'FacebookIntegration',
      payload: {
        locationId: params.locationId,
        taskType: 'saveToken',
        userToken: params.userToken,
      },
    })
  },
  sendAngiLeadsConnectEmail: (params) => {
    return client.post(Constants.Backend.Endpoints.LAMBDA_PROXY, {
      name: 'IntegrationsHelper',
      payload: {
        locationId: params.locationId,
        task: 'sendAngiLeadsConnectEmail',
        params: {
          accountNumber: params.accountNumber,
          companyName: params.companyName,
          email: params.email,
          firstName: params.firstName,
          lastName: params.lastName,
        },
      },
    })
  },
})

export default Resource(actions)
