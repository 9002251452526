import { useEffect } from 'react'
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import {
  attachRedirectionableParams,
  generateUserDefaultRedirect,
} from './utils'
import { Location, AuthorizedUser } from 'src/api'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { getLoginRedirect } from 'src/lib/Constants'
import { generateLocationUrl, generateUserUrl, isInternalUser } from 'src/utils'
import logger from 'src/utils/logger'

export const handleRedirection = ({
  urlSearchParams,
  userLocations,
  authorizedUser,
  navigate,
}: {
  urlSearchParams: URLSearchParams
  userLocations: Location[]
  authorizedUser: AuthorizedUser
  navigate: NavigateFunction
}) => {
  const logRedirectionEvent = (message: string, ...rest: unknown[]) =>
    logger.debug(`handleRedirection - ${message}`, { ...rest })

  let { path: redirectionPath } = generateUserDefaultRedirect(
    authorizedUser,
    userLocations
  )
  let redirectionUrl: URL | undefined = undefined
  const redirectInfo = getLoginRedirect(urlSearchParams.get('page')!)

  logRedirectionEvent('urlSearchParams', {
    searchParams: Array.from(urlSearchParams.entries()),
  })
  logRedirectionEvent('redirectInfo', { redirectInfo })

  if (urlSearchParams.has('redirect')) {
    redirectionUrl = new URL(urlSearchParams.get('redirect')!)

    logRedirectionEvent(
      'urlSearchParams has redirect, setting redirection.url to',
      redirectionUrl.toString()
    )
  } else if (redirectInfo && !isInternalUser(authorizedUser)) {
    if (redirectInfo.type === 'Location') {
      redirectionPath = generateLocationUrl(
        userLocations[0].id,
        redirectInfo.path
      )
      logRedirectionEvent(
        'redirectInfo.type is Location, setting redirection.url to',
        redirectionPath
      )
    } else if (redirectInfo.type === 'User') {
      logRedirectionEvent(
        'redirectInfo.type is User, setting redirection.url to',
        redirectionPath
      )
      redirectionPath = generateUserUrl(authorizedUser.id, redirectInfo.path)
    } else {
      throw new Error(
        `redirectInfo.type is of unknown type: ${redirectInfo.type}`
      )
    }
  }

  redirectionUrl = attachRedirectionableParams(
    redirectionUrl || new URL(redirectionPath, origin),
    urlSearchParams
  )

  // if (userRequiresTosAgreement(userLocations, authorizedUser)) {
  //   logRedirectionEvent('Changing url to /terms')
  //   const termsUrl = getTOsRedirectUrl(redirection.url)

  //   redirection.url = termsUrl
  // }

  logRedirectionEvent('About to navigate to', redirectionUrl.toString())
  navigate(
    {
      pathname: redirectionUrl.pathname,
      search: redirectionUrl.search,
      hash: redirectionUrl.hash,
    },
    { replace: true }
  )
}

const Login: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const [urlSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    handleRedirection({
      urlSearchParams,
      userLocations: locations,
      authorizedUser: user,
      navigate,
    })
  }, [locations, user, urlSearchParams, navigate])

  return null
}

export default Login
