import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface UpsertInstantResponseConfigParams {
  responderId: number
  locationId: number
  template?: string
  isEnabled?: boolean
  sendVCard?: boolean
}

export const useUpsertInstantResponseConfig = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpsertInstantResponseConfigParams) => {
      return getClient().then((client) =>
        client.patch<void>(
          `/locations/${params.locationId}/instant-response-configs/responder/${params.responderId}`,
          {
            isEnabled: params.isEnabled,
            template: params.template,
            sendVCard: params.sendVCard,
          }
        )
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['instant-response-configs', { locationId }],
      })
    },
  })
}
