import React, { ReactElement, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { ArrowDownIcon } from './assets'

export type ExpandableContentCustomTrigger = (
  toggle: () => void,
  expanded?: boolean
) => ReactElement

export interface ExpandableContentProps {
  triggerText?: string
  organizeAs?: 'column' | 'row'
  enableScroll?: boolean
  removeScroll?: boolean
  initiallyExpanded?: boolean
  customTrigger?: ExpandableContentCustomTrigger
  children: React.ReactNode | string
}

const Content = styled.div<{
  expanded: boolean
  $flexDirection?: 'column' | 'row'
  $enableScroll?: boolean
  $removeScroll?: boolean
}>(({ expanded, $flexDirection, $enableScroll, $removeScroll }) => {
  return {
    ...($removeScroll ? {} : { overflow: $enableScroll ? 'scroll' : 'hidden' }),
    display: expanded ? 'flex' : 'none',
    ...(expanded
      ? {
          flexDirection: $flexDirection || 'row',
        }
      : {}),
  }
})

const Trigger = styled.div(({ theme }) => ({
  cursor: 'pointer',
  fontSize: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.space(5)} 0`,
}))

const ExpandableContent: React.FC<ExpandableContentProps> = ({
  triggerText,
  customTrigger,
  organizeAs,
  enableScroll,
  removeScroll,
  initiallyExpanded,
  children,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded ?? false)
  const toggle = () => setExpanded((ex) => !ex)
  const theme = useTheme()

  return (
    <>
      {customTrigger ? (
        customTrigger(toggle, expanded)
      ) : (
        <Trigger onClick={toggle}>
          {triggerText} {<ArrowDownIcon stroke={theme.colors.base_50} />}
        </Trigger>
      )}

      <Content
        expanded={expanded}
        $flexDirection={organizeAs}
        $enableScroll={enableScroll}
        $removeScroll={removeScroll}
      >
        {children}
      </Content>
    </>
  )
}

export default ExpandableContent
