import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface FeatureFlag {
  name: string
  value: number
}

export const useGetLocationFeatureFlag = ({
  locationId,
  featureFlagName,
  defaultValue,
}: {
  locationId: number
  featureFlagName: string
  defaultValue: number
}) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'location-feature-flag',
      { locationId, featureFlagName },
    ] as const,
    initialData: {
      name: featureFlagName,
      value: defaultValue,
    },
    queryFn: async ({
      queryKey: [, { locationId: lid, featureFlagName: ffn }],
    }) =>
      getClient()
        .then((client) =>
          client.get<FeatureFlag>(
            `/locations/${lid}/feature-flags/${ffn}?defaultValue=${ffn}`
          )
        )
        .then((r) => r.data),
  })
}
