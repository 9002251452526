import React from 'react'

import EditTeamMemberNotesForm from './EditTeamMemberNotesForm'
import { Body } from 'src/stories/typography'

const EMPTY_STATE_TEXT =
  'Write a helpful note about this contact that only you will see.'

interface Props {
  locationId: number
  userId: number
  notes?: string
  isEditing?: boolean
  onCompleted?: () => void
}

const LocationUserDetailPanelItemNotes: React.FC<Props> = ({
  locationId,
  userId,
  notes,
  isEditing,
  onCompleted,
}) => {
  if (isEditing) {
    return (
      <EditTeamMemberNotesForm
        locationId={locationId}
        userId={userId}
        userNotes={notes}
        onCompleted={onCompleted}
      />
    )
  }

  return (
    <Body as="p" color={Boolean(notes) ? 'dark' : 'lighter'}>
      {Boolean(notes) ? notes : EMPTY_STATE_TEXT}
    </Body>
  )
}

export default LocationUserDetailPanelItemNotes
