import React from 'react'
import styled from 'styled-components'

import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body } from 'src/stories/typography'

const Wrapper = styled.div<{ $isDesktop: boolean }>(
  ({ theme, $isDesktop }) => ({
    display: 'flex',
    flexDirection: $isDesktop ? 'row' : 'column',
    gap: theme.space(2),
  })
)

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
}))

const Link = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  textDecoration: 'none',
}))

interface SharedProps {
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
  value: string
}

interface EmailProps extends SharedProps {
  itemType: 'email'
  canLogin: boolean
  isVerified?: boolean
}

interface PhoneProps extends SharedProps {
  itemType: 'phone'
  type: string
  carrier?: string
}

type Props = EmailProps | PhoneProps

const LocationUserDetailItem: React.FC<Props> = ({
  shouldReceiveLrSummaries,
  shouldReceiveUpdates,
  value,
  ...props
}) => {
  const { isMediumScreen } = useScreenSizes()
  const flags = [
    shouldReceiveUpdates && 'receives Signpost updates',
    shouldReceiveLrSummaries && 'receives Call center updates',
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Wrapper $isDesktop={isMediumScreen}>
      <Container>
        <Body as="span">
          <Link
            href={
              props.itemType === 'email' ? `mailto:${value}` : `tel:${value}`
            }
          >
            {value}
          </Link>
        </Body>
        {props.itemType === 'email' && props.canLogin && (
          <Body color="lighter" ellipsis>
            {props.isVerified ? 'Verified' : 'Not verified'}
          </Body>
        )}
      </Container>
      {props.itemType === 'email' && props.canLogin && (
        <Body color="lighter" ellipsis>
          can log in
        </Body>
      )}
      {props.itemType === 'phone' && (
        <Body color="lighter" ellipsis>
          {props.type}
          {props.carrier && ` (${props.carrier})`}
        </Body>
      )}
      <Body color="lighter">{flags}</Body>
    </Wrapper>
  )
}

export default LocationUserDetailItem
