import { useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import ConversationListItemOptionsDropdown from './ConversationListItemOptionsDropdown'
import {
  ConversationListItemContactTagContainer,
  ConversationListItemContainer,
  ConversationListItemHeadingContainer,
  ConversationListItemHeadingNameContainer,
  ConversationListItemOptionsContainer,
  ConversationListItemStatusContainer,
} from './styled'
import {
  Conversation,
  isConversationEvent,
  useBulkUpdateConversation,
  useGetContact,
  useGetContactTagAttributes,
  useGetConversationStatusAttributes,
  useUpdateConversationStatus,
} from 'src/api'
import ContactTag from 'src/components/MessagingHub/ContactDetailsPane/ContactTag'
import { useFormattedTimestamp } from 'src/components/MessagingHub/ConversationsPane/hooks'
import {
  MessageIcon,
  useGetMessageIcon,
} from 'src/components/MessagingHub/hooks'
import { showNotificationOnMhActions } from 'src/components/MessagingHub/utils'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { getConversationItemPreviewErrorText } from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import Dropdown, { closeDropdownEvent } from 'src/stories/Dropdown'
import Input from 'src/stories/Input'
import StatusIconComponent from 'src/stories/StatusIcon'
import { UnreadIcon, ThreeDotsIcon } from 'src/stories/assets'
import { Body } from 'src/stories/typography'

export interface ConversationListItemProps {
  conversationItem: Conversation
  selected: boolean
  index?: number
  isEditDisabled: boolean
  onClick: () => void
  editMode?: boolean
  toggleSelectConversation: (conversationId: number, checked: boolean) => void
  checked: boolean
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({
  conversationItem,
  selected,
  index,
  isEditDisabled,
  onClick,
  editMode,
  toggleSelectConversation,
  checked,
}) => {
  const dropdownRef = useRef<HTMLSpanElement>(null)
  const { locationId } = useLocationContext()
  const { refetch } = useConversationsListContext()
  const { mutateAsync: onBulkUpdateConversation } =
    useBulkUpdateConversation(locationId)
  const { mutateAsync: onUpdateConversationStatus } =
    useUpdateConversationStatus(locationId)
  const { data: conversationStatusAttributes = [] } =
    useGetConversationStatusAttributes({ locationId })
  const theme = useTheme()
  const time = useFormattedTimestamp(
    new Date(conversationItem.mostRecentEvent.timestamp)
  )
  const { data: contactTagAttributes = [] } = useGetContactTagAttributes({
    locationId,
  })
  const { data: contact } = useGetContact({
    locationId,
    contactId: conversationItem.contactId,
  })

  const navigate = useNavigate()

  const { mostRecentEvent } = conversationItem
  let customIcon: MessageIcon = mostRecentEvent.type

  if (conversationItem.isSpam) {
    customIcon = 'Spam'
  } else if (mostRecentEvent.isInstantResponse) {
    customIcon = 'Signpost'
  }

  const Icon = useGetMessageIcon(customIcon)

  const unread = !conversationItem.mostRecentEvent.isRead

  const nameTitle = conversationItem.contactDisplayName

  const preview = isConversationEvent(
    conversationItem.mostRecentEvent,
    'PHONE_CALL_EVENT'
  )
    ? 'Outbound call'
    : conversationItem.mostRecentEvent.message

  const previewError = getConversationItemPreviewErrorText(conversationItem)

  const conversationStatus = conversationStatusAttributes.find(
    (s) => s.id === conversationItem.statusId
  )
  const selectedTag = useMemo(
    () =>
      contactTagAttributes.find((attr) => attr.id === contact?.tagIds[0])
        ?.label,
    [contactTagAttributes, contact]
  )

  return (
    <ConversationListItemContainer
      $editMode={editMode}
      $active={selected}
      data-cy={`${unread ? 'unread-' : ''}conversation-item-${index ?? ''}`}
      onClick={onClick}
    >
      {editMode && (
        <Input
          type="checkbox"
          data-cy={`mh-conversations-pane-conversation-item-${
            index ?? ''
          }-checkbox`}
          name="checkbox"
          disabled={isEditDisabled}
          containerStyle={{
            margin: 0,
            width: theme.space(5),
            height: theme.space(5),
            marginLeft: theme.space(4),
            gridRow: 'span 4 / span 4',
          }}
          onChange={(e) => {
            toggleSelectConversation(conversationItem.id, e.target.checked)
          }}
          checked={checked}
        />
      )}
      <ConversationListItemStatusContainer
        isVisible={unread || !!conversationStatus}
      >
        {unread ? (
          <UnreadIcon />
        ) : conversationStatus ? (
          <StatusIconComponent $hexColor={conversationStatus.color} />
        ) : null}
      </ConversationListItemStatusContainer>
      <ConversationListItemHeadingContainer>
        {Icon}
        <ConversationListItemHeadingNameContainer
          data-cy={`mh-conversations-pane-conversation-item-${
            index ?? ''
          }-preview-name`}
          ellipsis
          color="darker"
          fontWeight={unread ? 'bolder' : 'bold'}
          size="large"
        >
          {nameTitle}
        </ConversationListItemHeadingNameContainer>
        <Body
          data-cy={`mh-conversations-pane-conversation-item-${
            index ?? ''
          }-preview-time`}
          size="small"
          color="lighter"
          fontWeight="bold"
        >
          {time}
        </Body>
      </ConversationListItemHeadingContainer>
      <ConversationListItemOptionsContainer>
        {!isEditDisabled && (
          <>
            <span
              ref={dropdownRef}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <ThreeDotsIcon
                height={theme.space(5)}
                width={theme.space(5)}
                fill={theme.colors.base_20}
              />
            </span>
            <Dropdown anchor={dropdownRef} hideArrow>
              <ConversationListItemOptionsDropdown
                isArchived={conversationItem.isArchived}
                onArchiveClicked={() => {
                  void onBulkUpdateConversation({
                    locationId,
                    conversationId: [conversationItem.id],
                    isArchived: !conversationItem.isArchived,
                  }).then((result) => {
                    showNotificationOnMhActions(
                      'archived',
                      result.conversations,
                      result.conversationsUpdated
                    )

                    dropdownRef.current?.dispatchEvent(closeDropdownEvent)
                  })
                }}
                isSpam={conversationItem.isSpam}
                onMarkAsSpamClicked={() => {
                  void onBulkUpdateConversation({
                    locationId,
                    conversationId: [conversationItem.id],
                    isSpam: !conversationItem.isSpam,
                  }).then((result) => {
                    showNotificationOnMhActions(
                      conversationItem.isSpam
                        ? 'marked as not spam'
                        : 'marked as spam',
                      result.conversations,
                      result.conversationsUpdated
                    )

                    dropdownRef.current?.dispatchEvent(closeDropdownEvent)
                  })
                }}
                onEditStatusesClicked={() => {
                  navigate(`/${locationId}/settings/messaging-hub`)

                  dropdownRef.current?.dispatchEvent(closeDropdownEvent)
                }}
                isRead={conversationItem.mostRecentEvent.isRead}
                onMarkReadClicked={() => {
                  void onBulkUpdateConversation({
                    locationId,
                    conversationId: [conversationItem.id],
                    conversationEventId: [conversationItem.mostRecentEvent.id],
                    isRead: !conversationItem.mostRecentEvent.isRead,
                  }).then((result) => {
                    showNotificationOnMhActions(
                      'marked as read',
                      result.conversations,
                      result.conversationsUpdated
                    )

                    dropdownRef.current?.dispatchEvent(closeDropdownEvent)
                  })
                }}
                conversationStatusAttributes={conversationStatusAttributes}
                onStatusAttributeClicked={async (statusId) => {
                  await onUpdateConversationStatus({
                    conversationId: conversationItem.id,
                    statusId,
                  })

                  dropdownRef.current?.dispatchEvent(closeDropdownEvent)

                  await refetch()
                }}
              />
            </Dropdown>
          </>
        )}
      </ConversationListItemOptionsContainer>
      {preview && (
        <Body
          data-cy={`mh-conversations-pane-conversation-item-${
            index ?? ''
          }-preview-message`}
          ellipsis
          color="darker"
          fontWeight={unread ? 'bold' : 'medium'}
        >
          {preview}
        </Body>
      )}
      {previewError && (
        <Body
          data-cy={`mh-conversations-pane-conversation-item-${
            index ?? ''
          }-preview-error-message`}
          ellipsis
          color="darker"
          fontWeight={unread ? 'bolder' : 'bold'}
          customColor="accent_2"
          size="small"
        >
          {previewError}
        </Body>
      )}
      {selectedTag && (
        <ConversationListItemContactTagContainer>
          <ContactTag isSelected label={selectedTag} />
        </ConversationListItemContactTagContainer>
      )}
      {mostRecentEvent.facebookPageName && mostRecentEvent.facebookPageId && (
        <Body
          data-cy={`mh-conversations-pane-conversation-item-facebook-page-name-${
            index ?? ''
          }-preview`}
          size="small"
          color="lighter"
          ellipsis
        >
          Page:{' '}
          <Body
            as="a"
            customColor="primary_2"
            size="small"
            {...{
              href: `https://facebook.com/${mostRecentEvent.facebookPageId}`,
              target: '_blank',
            }}
          >
            {mostRecentEvent.facebookPageName}
          </Body>
        </Body>
      )}
    </ConversationListItemContainer>
  )
}

export default ConversationListItem
