import { createContext, useContext, useMemo } from 'react'

import { ConversationEvent, useGetConversationEvents } from 'src/api'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'

export interface ConversationMessagesContextType {
  messages: ConversationEvent[]
  hasMoreMessages: boolean
  isLoading: boolean
  getMore: () => Promise<void>
  refetch: () => Promise<void>
}

const ConversationMessagesContext =
  createContext<ConversationMessagesContextType>({
    messages: [],
    isLoading: false,
    hasMoreMessages: false,
    getMore: () => Promise.resolve(),
    refetch: () => Promise.resolve(),
  })

export const ConversationMessagesContextProvider: React.FCWithChildren = ({
  children,
}) => {
  const { locationId } = useLocationContext()
  const { openConversationId } = useConversationsListContext()

  const {
    data: conversationEventsQuery,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationEvents({
    locationId,
    conversationId: openConversationId,
  })

  const messages = useMemo(
    () =>
      conversationEventsQuery?.pages.reduce<ConversationEvent[]>(
        (acc, page) => [...acc, ...page.data],
        []
      ) ?? [],
    [conversationEventsQuery?.pages]
  )

  return (
    <ConversationMessagesContext.Provider
      value={{
        messages,
        isLoading,
        hasMoreMessages: hasNextPage,
        getMore: async () => {
          await fetchNextPage()
        },
        refetch: async () => {
          await refetch()
        },
      }}
    >
      {children}
    </ConversationMessagesContext.Provider>
  )
}

export const useConversationMessagesContext = () =>
  useContext(ConversationMessagesContext)

export default ConversationMessagesContextProvider
