import {
  InsightsWidgetContainer,
  StyledWidgetDivider,
  StyledWidgetHeader,
  StyledWidgetSubtitle,
  StyledWidgetTitle,
} from './styled'

interface Props {
  baseDataAttribute: string
  title: string
  headerElement?: JSX.Element
  isHeaderElementNextToTitle?: boolean
  subtitle?: string | JSX.Element
  addDivider?: boolean
}

export const InsightsWidget: React.FCWithChildren<Props> = ({
  baseDataAttribute,
  title,
  headerElement,
  isHeaderElementNextToTitle = false,
  subtitle,
  addDivider,
  children,
}) => {
  return (
    <InsightsWidgetContainer data-cy={baseDataAttribute}>
      <StyledWidgetHeader
        data-cy={baseDataAttribute + '-header'}
        isElementNextToTitle={isHeaderElementNextToTitle}
      >
        <StyledWidgetTitle
          data-cy={baseDataAttribute + '-header-label'}
          hasElementNext={isHeaderElementNextToTitle}
        >
          {title}
        </StyledWidgetTitle>
        {headerElement && (
          <div data-cy={baseDataAttribute + '-header-element'}>
            {headerElement}
          </div>
        )}
      </StyledWidgetHeader>
      {subtitle &&
        (typeof subtitle === 'string' ? (
          <StyledWidgetSubtitle data-cy={baseDataAttribute + '-subtitle'}>
            {subtitle}
          </StyledWidgetSubtitle>
        ) : (
          subtitle
        ))}
      {addDivider && <StyledWidgetDivider />}
      <div data-cy={baseDataAttribute + '-content'}>{children}</div>
    </InsightsWidgetContainer>
  )
}
