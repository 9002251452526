import { AxiosInstance } from 'axios'
import { format } from 'date-fns'

import { downloadStringAsFile } from './../../utils/index'
import {
  ContactsListParameters,
  ContactsListDownloadParameters,
  ContactsV3Requests,
  ContactV3Response,
  UpdateContactSubscriptionParameters,
} from 'src/client/interfaces/ContactsV3'
import { Segment } from 'src/client/interfaces/Segments'
import Resource from 'src/client/resource'
import Constants from 'src/lib/Constants'

const actions = (client: AxiosInstance): ContactsV3Requests => {
  /**
   * Download contacts as a CSV
   * @param parameters ContactsListDownloadParameters
   * @param parameters.locationId The location ID to download the list of contacts for
   * @param parameters.search A contact name, phone, or email to query
   * @param parameters.segmentId A segment/list to filter by
   * @param parameters.sort.direction The direction to sort, ASC or DESC
   * @param parameters.sort.field The field to sort by
   * @returns void - A file is downloaded by the browser
   */
  const downloadContactsList = async ({
    locationId,
    search,
    segmentId,
    sort,
  }: ContactsListDownloadParameters) => {
    let params: {
      export?: boolean
      search?: string
      segmentId?: number
      direction?: string
      field?: string
    } = { export: true, search, segmentId }

    if (sort) {
      const { direction, field } = sort

      params = { ...params, direction, field }
    }

    const data: string = await client.get(
      `/v3/locations/${locationId}/contacts`,
      { params }
    )

    downloadStringAsFile(
      `customers_${format(new Date(), 'yyyy-MM-dd')}.csv`,
      data,
      'text/csv'
    )
  }

  /**
   * DO NOT USE this function as it is likely to change
   * It is being used for prototyping.
   * @param parameters ContactsListParameters
   * @param parameters.locationId The location ID to gather the list of contacts for
   * @param parameters.pagination.take Pagination - How many records to return
   * @param parameters.pagination.skip Pagination - How many records to skip
   * @param parameters.search A contact name, phone, or email to query
   * @param parameters.segmentId A segment/list to filter by
   * @param parameters.sort.direction The direction to sort, ASC or DESC
   * @param parameters.sort.field The field to sort by
   * @returns A list of contacts with segment/list info
   */
  const getContactsList = ({
    locationId,
    pagination: { take, skip },
    search,
    segmentId,
    sort,
  }: ContactsListParameters): Promise<ContactV3Response> => {
    let params: {
      take: number
      skip: number
      search?: string
      segmentId?: number
      direction?: string
      field?: string
    } = { take: take ?? 0, skip: skip ?? 0 }

    if (search) {
      params = { search, ...params }
    }

    if (segmentId) {
      params = { segmentId, ...params }
    }

    if (sort) {
      const { direction, field } = sort

      params = { direction, field, ...params }
    }

    return client.get(`/v3/locations/${locationId}/contacts`, { params })
  }

  const updateContactSubscription = ({
    customerId,
    subscribed,
  }: UpdateContactSubscriptionParameters): Promise<boolean> => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Customers',
      method: 'updateSubscription',
      params: {
        customerId,
        subscription: 'email_remarketing',
        onBehalfOf: 'merchant',
        subscribed,
      },
    })
  }

  /**
   * DO NOT USE this function as it is likely to change
   * It is being used for prototyping.
   * @param locationId The location ID to gather the list of segments for
   * @returns A list of segments
   */
  const getSegmentsList = (locationId: number): Promise<Segment[]> =>
    client.get(`/v3/locations/${locationId}/segments`)

  return {
    downloadContactsList,
    getContactsList,
    getSegmentsList,
    updateContactSubscription,
  }
}

export default Resource(actions)
