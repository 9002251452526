import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface CreateLocationVCardMutationParams {
  locationId: number
  firstName: string
  lastName: string
  emailAddress: string
  addressStreet1: string
  addressStreet2?: string
  addressCity: string
  addressState: string
  addressZipCode: string
}

export const useCreateLocationVCard = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateLocationVCardMutationParams) => {
      return getClient()
        .then((client) =>
          client.post<void>(`/locations/${params.locationId}/vcards`, {
            firstName: params.firstName,
            lastName: params.lastName,
            emailAddress: params.emailAddress,
            addressStreet1: params.addressStreet1,
            addressStreet2: params.addressStreet2,
            addressCity: params.addressCity,
            addressState: params.addressState,
            addressZipCode: params.addressZipCode,
          })
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['locations', { locationId }],
      })
    },
  })
}
