import { AwsRum, AwsRumConfig, PartialDomEventPluginConfig } from 'aws-rum-web'
import { PartialHttpPluginConfig } from 'aws-rum-web/dist/cjs/plugins/utils/http-utils'

export let awsRum: AwsRum | undefined

try {
  const applicationId = process.env.REACT_APP_CW_RUM_APP_ID
  const applicationVersion = process.env.REACT_APP_RUNNING_VERSION
  const applicationRegion = process.env.REACT_APP_CW_RUM_APP_REGION
  const guestRoleArn = process.env.REACT_APP_CW_RUM_GUEST_ROLE_ARN
  const identityPoolId = process.env.REACT_APP_CW_RUM_IDENTITY_POOL_ID
  const endpoint = process.env.REACT_APP_CW_RUM_ENDPOINT

  if (
    applicationId &&
    applicationVersion &&
    applicationRegion &&
    guestRoleArn &&
    identityPoolId &&
    endpoint
  ) {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn,
      identityPoolId,
      endpoint,
      telemetries: [
        'performance',
        'errors',
        [
          'http',
          {
            addXRayTraceIdHeader: false,
          } as PartialHttpPluginConfig,
        ],
        [
          'interaction',
          {
            events: [
              { event: 'click', element: document as unknown as HTMLElement },
            ],
            interactionId: (event) => {
              const eventPath = event.composedPath() as Element[]

              for (const element of eventPath) {
                if (element.hasAttribute) {
                  if (element.hasAttribute('data-cy')) {
                    return element.getAttribute('data-cy') as string
                  } else if (element.hasAttribute('data-dd')) {
                    return element.getAttribute('data-dd') as string
                  }
                }
              }

              return ''
            },
          } as PartialDomEventPluginConfig,
        ],
      ],
      allowCookies: true,
      enableXRay: true,
    }

    awsRum = new AwsRum(
      applicationId,
      applicationVersion,
      applicationRegion,
      config
    )
  }
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
