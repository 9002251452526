import styled from 'styled-components'

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}))

export const FormContainer = styled.form(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.space(2),
  width: '100%',
}))
