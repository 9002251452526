import { AxiosInstance } from 'axios'

import { CommsRequests } from 'src/client/interfaces/Comms'
import Resource from 'src/client/resource'
import Constants from 'src/lib/Constants'

const actions = (client: AxiosInstance): CommsRequests => ({
  find: ({ fingerprint }) => {
    return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
      module: 'Comms',
      method: 'find',
      params: {
        fingerprint,
      },
    })
  },
})

export default Resource(actions)
