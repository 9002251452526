import React from 'react'
import styled from 'styled-components'

import { StyledNavigationItem } from 'src/stories/Dropdown'
import { Body } from 'src/stories/typography'
import { generateLocationUrl } from 'src/utils'

const StyledLocationStyledNavigationItem = styled(StyledNavigationItem)(
  ({ theme }) => ({
    padding: [
      theme.space(3), // Top
      theme.space(5), // Right
      theme.space(3), // Bottom
      theme.space(6), // Left
    ].join(' '),
  })
)

const StyledLocationInfoWrapper = styled.div(({ theme }) => ({
  maxWidth: theme.space(45),
  paddingLeft: theme.space(2),
}))

const StyledP = styled.p({
  margin: '0',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  fontSize: '1.4rem',
})

const StyledLocationAddress = styled(StyledP)(({ theme }) => ({
  WebkitLineClamp: 2,
  color: theme.colors.base_30,
}))

interface LocationNavigationItemProps {
  isSelected?: boolean
  displayName: string
  locationId: number
  address: string
  dataCy?: string
}

const LocationNavigationItem: React.FC<LocationNavigationItemProps> = ({
  isSelected = false,
  address,
  displayName,
  locationId,
  dataCy,
}) => {
  return (
    <StyledLocationStyledNavigationItem
      data-cy={dataCy}
      to={generateLocationUrl(locationId, '/messaging-hub')}
    >
      <StyledLocationInfoWrapper>
        <Body
          fontWeight={isSelected ? 'bolder' : 'medium'}
          color={isSelected ? 'medium' : 'light'}
          ellipsis
        >
          {displayName}
        </Body>
        <StyledLocationAddress>{address}</StyledLocationAddress>
      </StyledLocationInfoWrapper>
    </StyledLocationStyledNavigationItem>
  )
}

export default LocationNavigationItem
