import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'

import { ConversationStatusAttributes } from 'src/api'
import StatusIconComponent from 'src/stories/StatusIcon'
import {
  ArchiveIcon,
  OpenMessageIcon,
  SpamIcon,
  CloseMessageIcon,
  StatusTagIcon,
} from 'src/stories/assets'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  padding: `${theme.space(1.5)} 0`,
}))

const RowContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(4),
  padding: `${theme.space(2)} ${theme.space(3)}`,
  paddingRight: theme.space(15),
  alignItems: 'center',

  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.colors.primary_1_10,
  },
}))

interface Props {
  isArchived: boolean
  onArchiveClicked: () => void
  isSpam: boolean
  onMarkAsSpamClicked: () => void
  onEditStatusesClicked: () => void
  isRead: boolean
  onMarkReadClicked: () => void
  conversationStatusAttributes: ConversationStatusAttributes[]
  onStatusAttributeClicked: (id: number) => void
}

const ConversationListItemOptionsDropdown: React.FC<Props> = ({
  isArchived,
  onArchiveClicked,
  isSpam,
  onMarkAsSpamClicked,
  onEditStatusesClicked,
  isRead,
  onMarkReadClicked,
  conversationStatusAttributes,
  onStatusAttributeClicked,
}) => {
  const theme = useTheme()
  const items = useMemo(
    () => [
      {
        icon: isRead ? CloseMessageIcon : OpenMessageIcon,
        content: `Mark as${isRead ? ' un' : ' '}read`,
        onClick: onMarkReadClicked,
      },
      {
        icon: ArchiveIcon,
        content: isArchived ? 'Unarchive' : 'Archive',
        onClick: onArchiveClicked,
      },
      {
        icon: SpamIcon,
        content: `Mark as${isSpam ? ' not ' : ' '}spam`,
        onClick: onMarkAsSpamClicked,
      },
    ],
    [
      isArchived,
      onArchiveClicked,
      isSpam,
      onMarkAsSpamClicked,
      isRead,
      onMarkReadClicked,
    ]
  )

  const afterLabelItems = useMemo(
    () => [
      {
        icon: StatusTagIcon,
        content: `Edit Statuses`,
        onClick: onEditStatusesClicked,
      },
    ],
    [onEditStatusesClicked]
  )

  return (
    <Container>
      {items.map((item) => (
        <RowContainer
          key={item.content}
          onClick={(e) => {
            e.stopPropagation()

            item.onClick()
          }}
        >
          <item.icon fill={theme.colors.base_100} />
          <Body color="dark">{item.content}</Body>
        </RowContainer>
      ))}
      <hr />
      {conversationStatusAttributes.map((item) => (
        <RowContainer
          key={item.id}
          onClick={(e) => {
            e.stopPropagation()

            onStatusAttributeClicked(item.id)
          }}
        >
          <StatusIconComponent
            $hexColor={item.color}
            style={{ width: theme.space(5) }}
          />
          <Body color="dark">{item.label}</Body>
        </RowContainer>
      ))}

      {afterLabelItems.map((item) => (
        <RowContainer
          key={item.content}
          onClick={(e) => {
            e.stopPropagation()

            item.onClick()
          }}
        >
          <item.icon fill={theme.colors.base_100} />
          <Body color="dark">{item.content}</Body>
        </RowContainer>
      ))}
    </Container>
  )
}

export default ConversationListItemOptionsDropdown
