import React, { memo } from 'react'
import { useTheme } from 'styled-components'

interface Props {
  url: string
  title: string
  dataCy?: string
}

const YoutubeIframe: React.FC<Props> = memo(({ title, url, dataCy }) => {
  const theme = useTheme()

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%',
        borderRadius: theme.constants.borderRadius,
      }}
    >
      <iframe
        src={url}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          border: 0,
          width: '100%',
          height: '100%',
        }}
        data-cy={dataCy}
      ></iframe>
    </div>
  )
})

export default YoutubeIframe
