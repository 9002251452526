import { AxiosInstance } from 'axios'

import { CustomFeaturePackagesRequests } from 'src/client/interfaces/FeaturePackages'
import Resource from 'src/client/resource'

const actions = (client: AxiosInstance): CustomFeaturePackagesRequests => ({
  findLocationFeaturePackages: ({ locationId }) => {
    return client.get(`/v3/locations/${locationId}/feature-packages`)
  },
})

export default Resource(actions)
