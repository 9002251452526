import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { ConversationStatusAttributes } from 'src/api/types'

export interface GetConversationStatusAttributesParams {
  locationId: number
}

export const useGetConversationStatusAttributes = ({
  locationId,
}: GetConversationStatusAttributesParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'conversation-status-attributes',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<ConversationStatusAttributes[]>(
            `/locations/${params.locationId}/conversations/status-attributes`
          )
        )
        .then((r) => r.data)
    },
  })
}
