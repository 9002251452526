import React, { MouseEventHandler } from 'react'
import styled, { useTheme } from 'styled-components'

import { CloseIcon as TimesIcon } from './assets'
import { ReactComponent as CompanyLogo } from 'src/stories/assets/company-header-logo.svg'

export interface SidedrawerProps {
  open: boolean
  onClose: MouseEventHandler<SVGSVGElement>
  children: React.ReactNode
  showLogo?: boolean
  paddingTop?: number
}

const SidedrawerContainer = styled.div<Partial<SidedrawerProps>>(
  ({ open, theme, paddingTop }) => ({
    height: '100%',
    width: theme.space(80),
    maxWidth: theme.space(80),
    position: 'fixed',
    zIndex: theme.zIndexes.sidedrawer,
    top: 0,
    left: 0,
    backgroundColor: theme.colors.base_0,
    overflowX: 'hidden',
    transform: open ? 'translateX(0)' : 'translateX(-100%)',
    transition: 'transform 0.3s ease-out',
    paddingTop: theme.space(paddingTop ?? 0),
  })
)

const StyledTimesIconOpen = styled(TimesIcon)(({ theme }) => ({
  transform: 'translateX(0)',
  transition: 'transform 0.3s ease-out',
  zIndex: theme.zIndexes.sidedrawer + 1,
  cursor: 'pointer',
  marginTop: theme.space(4),
}))

const StyledTimesIconClosed = styled(TimesIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.space(9),
  left: theme.space(88),
  transform: 'translateX(-3000%)',
  transition: 'transform 0.3s ease-out',
  zIndex: theme.zIndexes.sidedrawer,
  cursor: 'pointer',
}))

const StyledHeaderLogoContainer = styled.div(({ theme }) => ({
  margin: `0 ${theme.space(4)} ${theme.space(8)} ${theme.space(4)}`,
  width: 'auto',
  height: theme.space(19),
  display: 'flex',
  justifyContent: 'space-between',
}))

const Sidedrawer: React.FC<SidedrawerProps> = ({
  open,
  onClose,
  showLogo = true,
  children,
}) => {
  const theme = useTheme()

  return (
    <>
      <SidedrawerContainer open={open} paddingTop={10}>
        {showLogo && (
          <StyledHeaderLogoContainer>
            <CompanyLogo
              fill={theme.colors.primary_1}
              style={{ width: theme.space(50) }}
            />
            {open ? (
              <StyledTimesIconOpen
                id="open-close-iconxd"
                fill={theme.colors.base_50}
                onClick={onClose}
              />
            ) : (
              <StyledTimesIconClosed fill={theme.colors.base_50} />
            )}
          </StyledHeaderLogoContainer>
        )}
        {children}
      </SidedrawerContainer>
    </>
  )
}

export default Sidedrawer
