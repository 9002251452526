import { intervalToDuration, subMonths } from 'date-fns'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { InsightsContextInterface } from './types'
import { LrSummary, useGetLrInsightsSummary } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'

const zeroStateLiveReceptionistReporting: LrSummary = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  numOfCalls: 0,
  numOfSolicitorCalls: 0,
  estimatedMinutesSavedFromSolicitorCalls: 0,
  planUsageSeconds: 0,
  avgDurationSeconds: 0,
  estimatedMinutesSavedPerSolicitorCall: 0,
  callNumbersByWrapupCode: {
    callBackRequest: 0,
    scheduling: 0,
    solicitor: 0,
    billing: 0,
    quote: 0,
    complaint: 0,
    other: 0,
  },
  callsByWeekday: {
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  },
}

const InsightsContext = createContext<InsightsContextInterface>({
  // Live Receptionist Data
  shouldShowLiveReceptionist: false,
  isLiveReceptionistLoading: false,
  liveReceptionistReportingData: {
    startDate: '',
    endDate: '',
    numOfCalls: 0,
    numOfSolicitorCalls: 0,
    estimatedMinutesSavedFromSolicitorCalls: 0,
    planUsageSeconds: 0,
    avgDurationSeconds: 0,
    estimatedMinutesSavedPerSolicitorCall: 0,
    callNumbersByWrapupCode: {
      callBackRequest: 0,
      scheduling: 0,
      solicitor: 0,
      billing: 0,
      quote: 0,
      complaint: 0,
      other: 0,
    },
    callsByWeekday: {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    },
  },
  isMhDataLoading: false,
  mhAvgResponseTime: { days: 0, hours: 0, minutes: 0 },
})

export const InsightsContextProvider: React.FCWithChildren = ({ children }) => {
  const { locationId } = useLocationContext()
  const today = useMemo(() => new Date(), [])
  const oneMonthAgo = useMemo(() => subMonths(new Date(), 1), [])

  const {
    isLoading: isLiveReceptionistLoading,
    data: liveReceptionistReportingData = zeroStateLiveReceptionistReporting,
  } = useGetLrInsightsSummary({
    locationId,
    startDate: oneMonthAgo,
    endDate: today,
  })

  // Live Receptionist Data
  const shouldShowLiveReceptionist = useMemo(() => true, [])

  // MH Data
  const [isMhDataLoading, setIsMhDataLoading] = useState(false)
  const [mhAvgResponseTime, setMhAvgResponseTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  const processMessagingHubData = useCallback(() => {
    setIsMhDataLoading(true)
    const avgResponseTimeInSeconds = 100000

    const { days, hours, minutes } = intervalToDuration({
      start: 0,
      end: avgResponseTimeInSeconds ? avgResponseTimeInSeconds * 1000 : 0,
    })

    setMhAvgResponseTime({ days: days!, hours: hours!, minutes: minutes! })
    setIsMhDataLoading(false)
  }, [])

  useEffect(() => {
    const fetchAndProcessMessagingHubData = () => {
      processMessagingHubData()
    }

    void fetchAndProcessMessagingHubData()
  }, [processMessagingHubData])

  let contextValues: InsightsContextInterface = {
    // Live Receptionist Data
    shouldShowLiveReceptionist,
    isLiveReceptionistLoading,
    liveReceptionistReportingData,
    // MH Data
    isMhDataLoading,
    mhAvgResponseTime,
  }

  return (
    <InsightsContext.Provider value={contextValues}>
      {children}
    </InsightsContext.Provider>
  )
}

const useInsightsContext = () => useContext(InsightsContext)

export default useInsightsContext
