import styled from 'styled-components'

import {
  BodyProps,
  HeadingProps,
  SubtitleProps,
  TextProps,
  BaseProps,
} from './Typography.types'

const BaseStyled = styled.p<BaseProps>(({ textAlign = 'left', ellipsis }) => {
  const base = {
    textAlign,
    margin: 0,
  }

  if (ellipsis)
    return {
      ...base,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }

  return { ...base }
})

export const TextStyled = styled(BaseStyled)<TextProps>(
  ({
    theme,
    fontSize = theme.space(3),
    fontWeight,
    textTransform,
    customColor,
    color = 'medium',
  }) => ({
    fontSize,
    fontWeight: fontWeight ? theme.typography.weights[fontWeight] : undefined,
    textTransform,
    color: customColor
      ? theme.colors[customColor]
      : theme.typography.colors[color],
  })
)

export const HeadingStyled = styled(BaseStyled)<HeadingProps>(
  ({ theme, size = 'medium', customColor }) => ({
    ...theme.typography.heading[size],
    color: customColor
      ? theme.colors[customColor]
      : theme.typography.heading[size].color,
  })
)

export const SubtitleStyled = styled(BaseStyled)<SubtitleProps>(
  ({ theme, size = 'medium', customColor }) => ({
    ...theme.typography.subtitle[size],
    color: customColor
      ? theme.colors[customColor]
      : theme.typography.heading[size].color,
  })
)

export const BodyStyled = styled(BaseStyled)<BodyProps>(
  ({
    theme,
    size = 'medium',
    fontWeight,
    textTransform,
    fontStyle,
    color,
    customColor,
  }) => ({
    ...theme.typography.body[size],
    fontWeight: fontWeight ? theme.typography.weights[fontWeight] : undefined,
    textTransform,
    fontStyle,
    color: customColor
      ? theme.colors[customColor]
      : color
      ? theme.typography.colors[color]
      : undefined,
  })
)
