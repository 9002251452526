import { yupResolver } from '@hookform/resolvers/yup'
import { isAxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { useCreateLocationUserEmailAddress } from 'src/api'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import logger from 'src/utils/logger'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
}))

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))

const formSchema = yup.object({
  email: yup.string().email().required('Email address is required'),
  canLogin: yup.boolean().optional().default(false),
  shouldReceiveLrSummaries: yup.boolean().optional().default(false),
  shouldReceiveUpdates: yup.boolean().optional().default(false),
})

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onComplete: () => void
  dataCy?: string
  locationId: number
  userId: number
}

const AddEmailToTeamMemberModal: React.FC<Props> = ({
  onComplete,
  dataCy,
  locationId,
  userId,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { mutateAsync: createUserEmail } =
    useCreateLocationUserEmailAddress(locationId)
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(
    async ({
      email,
      canLogin,
      shouldReceiveLrSummaries,
      shouldReceiveUpdates,
    }) => {
      try {
        await createUserEmail({
          locationId,
          userId,
          emailAddress: email,
          canLogin,
          shouldReceiveLrSummaries,
          shouldReceiveUpdates,
        })

        onComplete()
      } catch (error: unknown) {
        let errorMessage =
          'There was an error trying to create this email address.'

        if (isAxiosError(error) && error.response?.status === 409) {
          errorMessage += ' Please contact support.'
        }

        logger.error('Failed to create email address', { error })
        toast.error(errorMessage)
      }
    }
  )

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Form id={dataCy} onSubmit={onSubmit}>
      <Input
        type="email"
        label="Email:"
        {...register('email')}
        {...baseInputProps}
      />
      <Input
        type="checkbox"
        label="Email can log in?"
        {...register('canLogin')}
        {...baseInputProps}
      />
      <Input
        type="checkbox"
        label="Receive signpost updates?"
        {...register('shouldReceiveLrSummaries')}
        {...baseInputProps}
      />
      <Input
        type="checkbox"
        label="Receive call center updates?"
        {...register('shouldReceiveUpdates')}
        {...baseInputProps}
      />
      <StyledButtonsContainer>
        <Button
          action="secondary"
          type="button"
          label="Cancel"
          disabled={isSubmitting}
          onClick={closeModal}
        />

        <Button
          action="primary"
          type="submit"
          label="Add"
          disabled={isSubmitting}
        />
      </StyledButtonsContainer>
    </Form>
  )
}

export default AddEmailToTeamMemberModal
