import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { LocationList } from 'src/api/types'

export const useGetLocationLists = (locationId: number) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['location-lists', { locationId }] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      getClient()
        .then((client) =>
          client.get<LocationList[]>(`/locations/${lid}/lists/list/USER`)
        )
        .then((r) =>
          r.data.reduce<Record<number, LocationList>>(
            (acc, list) => ({ ...acc, [list.id]: list }),
            {}
          )
        ),
  })
}
