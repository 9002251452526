import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { LocationUser } from 'src/api/types'

export const useGetLocationUsers = (locationId: number) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['location-users', { locationId }] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      getClient()
        .then((client) => client.get<LocationUser[]>(`/locations/${lid}/users`))
        .then((r) => r.data),
  })
}
