import styled, { useTheme } from 'styled-components'

import Tooltip, { TooltipProps } from 'src/stories/Tooltip'
import { QuestionIcon } from 'src/stories/assets'

export const HelperTooltipWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

export const StyledContainer = styled.span(({ theme }) => ({
  marginLeft: theme.space(1),
  lineHeight: theme.space(0),
}))

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.base_50,
}))

interface HelperTooltipProps {
  children: TooltipProps['text']
  style?: React.CSSProperties
}

const HelperTooltip: React.FC<HelperTooltipProps> = ({ children, style }) => {
  const theme = useTheme()

  return (
    <StyledContainer>
      <Tooltip
        text={children}
        fontSize="1.4rem"
        fontWeight={400}
        color={theme.colors.base_60}
        widthUnits={63}
        textStyle={{
          textAlign: 'left',
          paddingLeft: theme.space(4),
          paddingRight: theme.space(4),
          lineHeight: '2rem',
        }}
        left={theme.space(2)}
        style={style}
      >
        <StyledQuestionIcon />
      </Tooltip>
    </StyledContainer>
  )
}

export default HelperTooltip
