import React, { ReactElement } from 'react'
import { Icons } from 'react-toastify'
import styled from 'styled-components'

import BannerNotification from './BannerNotification'
import { IntegrationMappingErrorType, IntegrationMappingType } from 'src/api'
import Constants from 'src/lib/Constants'
import { formatPhoneNumber } from 'src/utils'

const Icon = Icons.error

const Link = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  textDecoration: 'none',
}))

const contactSupportTeam: ReactElement = (
  <>
    Please contact our team to resolve at your earliest convenience:{' '}
    <Link href={`mailto:${Constants.Branding.companySupportEmail}`}>
      {Constants.Branding.companySupportEmail}
    </Link>{' '}
    |{' '}
    <Link href={`tel:${Constants.Branding.companyPhone}`}>
      {formatPhoneNumber(Constants.Branding.companyPhone)}
    </Link>
  </>
)

const TITLE_TEXT: Record<string, string> = {
  [IntegrationMappingErrorType.ANGI_LEADS_ACCOUNT_NUMBER]:
    'Angi Leads Number is incorrect',
  [IntegrationMappingErrorType.TWILIO_CAMPAIGN_ERROR]: 'Campaign Error',
  [IntegrationMappingErrorType.TWILIO_EIN_MISMATCH]: 'SMS EIN Mismatch',
  [IntegrationMappingErrorType.TWILIO_FALLBACK_ERROR]: 'Campaign Error',
}

const SUBTITLE_TEXT: Record<string, string | ReactElement> = {
  [IntegrationMappingErrorType.ANGI_LEADS_ACCOUNT_NUMBER]:
    'Please verify the Angi Leads account number and try again.',
  [IntegrationMappingErrorType.TWILIO_CAMPAIGN_ERROR]: contactSupportTeam,
  [IntegrationMappingErrorType.TWILIO_EIN_MISMATCH]:
    'Please verify your business EIN and try again.',
  [IntegrationMappingErrorType.TWILIO_FALLBACK_ERROR]: contactSupportTeam,
}

const IntegrationFailureNotification: React.FC<{
  errorType: IntegrationMappingErrorType
  integrationType: IntegrationMappingType
}> = ({ errorType, integrationType }) => {
  return (
    <BannerNotification
      type="error"
      icon={<Icon theme="colored" type="error" />}
      title={TITLE_TEXT[errorType] || errorType}
      message={SUBTITLE_TEXT[errorType] || contactSupportTeam}
    />
  )
}

export default IntegrationFailureNotification
