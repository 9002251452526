import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { PhoneNumberSmsRegistration } from 'src/api/types'

export const useGetLatestSmsRegistration = ({
  locationId,
}: {
  locationId: number
}) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'latest-sms-registration',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      getClient()
        .then((client) =>
          client.get<PhoneNumberSmsRegistration>(
            `/locations/${lid}/sms-registrations/latest`
          )
        )
        .then((r) => r.data),
  })
}
