import { InfiniteData } from '@tanstack/react-query'

import { Contact, Conversation, Paginated } from 'src/api'

const getConversationContact = (conversation: Conversation): Contact => {
  const { contactId, contactPhoneNumber, contactDisplayName } = conversation

  return {
    id: contactId,
    firstName: contactDisplayName,
    lastName: '',
    phoneNumber: contactPhoneNumber,
    channels: [],
    addedAt: '',
    tagIds: [],
  } as Contact
}

export const getConversationsContacts = (
  conversationsData: InfiniteData<Paginated<Conversation>, unknown> | undefined
): Contact[] => {
  return (
    conversationsData?.pages.flatMap((page) =>
      page.data.map((conversation) => getConversationContact(conversation))
    ) || []
  )
}
