import { SortDirection } from '@tanstack/react-table'
import React from 'react'
import styled from 'styled-components'

import { ArrowIcon } from 'src/stories/assets'

interface Props {
  isSorted: false | SortDirection
}

const StyledArrowIcon = styled(ArrowIcon)(({ theme }) => ({
  marginLeft: theme.space(2),
  marginTop: theme.space(-1),
}))

const TableSortIcon: React.FC<Props> = ({ isSorted }) => {
  if (isSorted === 'asc') {
    return (
      <StyledArrowIcon
        style={{
          transform: 'rotate(90deg)',
        }}
      />
    )
  } else if (isSorted === 'desc') {
    return <StyledArrowIcon style={{ transform: 'rotate(270deg)' }} />
  }

  return null
}

export default TableSortIcon
