import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import {
  LocationBusinessType,
  SmsRegistrationBusinessIndustry,
  SmsRegistrationSoleProprietorshipBusinessIndustry,
  SmsRegistrationRepresentativePosition,
} from 'src/api/types'

export interface CreateSmsRegistrationParams {
  locationId: number
  businessType: LocationBusinessType
  businessIdentifier?: string
  phoneNumber: string
  websiteUrl?: string
  representativeFirstName: string
  representativeLastName: string
  representativeTitle?: string
  representativePosition?: SmsRegistrationRepresentativePosition
  representativePhoneNumber: string
  businessName: string
  businessIndustry:
    | SmsRegistrationBusinessIndustry
    | SmsRegistrationSoleProprietorshipBusinessIndustry
  representativeEmailAddress: string
  addressStreet1: string
  addressStreet2?: string
  addressCity: string
  addressState: string
  addressPostalCode: string
}

export const useCreateSmsRegistration = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      locationId: lId,
      ...rest
    }: CreateSmsRegistrationParams) => {
      const client = await getClient()

      await client.post(`/locations/${lId}/sms-registrations`, rest)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['locations', { locationId }],
      })
    },
  })
}
