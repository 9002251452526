import React from 'react'
import styled, { keyframes } from 'styled-components'

import { CompanyLoadingIcon } from './assets'
import theme from './theme'

const blueColor = ({
  $initialColor,
  $finalColor,
}: LoadingSpinnerProps) => keyframes`
  from {
    fill: ${$initialColor};
  }

  60% {
    fill: ${$finalColor};
    fill-opacity: 100%;
  }

  to {
    fill ${$initialColor};
  }
`

const LoadingSpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCompanyLoading = styled(CompanyLoadingIcon)<LoadingSpinnerProps>`
  animation: ${(props) => blueColor(props)} 2s ease-in infinite;
  height: ${(props) => (props.height ? props.height : 'unset')};
`

export interface LoadingSpinnerProps {
  $initialColor: string
  $finalColor: string
  logoHeight?: string
  logoWidth?: string
}

const LoadingSpinner: React.FC<Partial<LoadingSpinnerProps>> = ({
  $initialColor = theme.colors.base_10,
  $finalColor = theme.colors.primary_2,
  logoHeight,
  logoWidth,
}) => {
  return (
    <LoadingSpinnerContainer data-cy="loading-spinner-container">
      <StyledCompanyLoading
        data-cy="loading-spinner"
        $initialColor={$initialColor}
        $finalColor={$finalColor}
        height={logoHeight}
      />
    </LoadingSpinnerContainer>
  )
}

export default LoadingSpinner
