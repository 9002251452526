import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ViewContact from './ViewContact'
import { BottomSpace } from 'src/components/Contacts/styled'
import WafLayout from 'src/components/WafLayout'
import Table from 'src/containers/Contacts/ContactsTable'
// import ContactsHeader from 'src/containers/Contacts/Header'

interface Props {
  isMultiLocation: boolean
}

const Contacts: React.FC<Props> = ({ isMultiLocation }) => {
  const { search } = useLocation()
  const [selectedContactId, setSelectedContactId] = useState<
    number | undefined
  >(() => {
    const searchParam = new URLSearchParams(search).get('contact')

    if (searchParam && !isNaN(+searchParam)) {
      return +searchParam
    }
  })
  const [contactName, setContactName] = useState<string>()

  return (
    <WafLayout
      // layoutTitle={<ContactsHeader />}
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
      }}
      heading={{
        openSidedrawerButtonLabel: '',
        sidedrawerTitle: (selectedContactId && contactName) || 'Contact',
        sidedrawerContent: (
          <>
            {selectedContactId && (
              <ViewContact
                contactId={selectedContactId}
                onContactName={(name: string) => {
                  setContactName(name)
                }}
              />
            )}
          </>
        ),
        titleSlot: 'Contacts',
        closeSidedrawerButtonOnClick: () => {
          setContactName(undefined)
          setSelectedContactId(undefined)
        },
      }}
      isSidedrawerOpen={!!selectedContactId}
    >
      <Table
        showContact={(contactId: number) => {
          setContactName(undefined)
          setSelectedContactId(contactId)
        }}
      />
      <BottomSpace />
    </WafLayout>
  )
}

export default Contacts
