import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { usePatchLocationUser } from 'src/api'
import {
  StyledForm,
  StyledTextarea,
} from 'src/components/Settings/common/layout'
import { Button } from 'src/stories/Button'
import logger from 'src/utils/logger'

const StyledButtonsContainer = styled.div(({ theme }) => ({
  float: 'right',
}))

const editTeamMembeNotesFormSchema = yup.object({
  notes: yup.string().default(''),
})

type EditTeamMembeNotesFormSchema = yup.InferType<
  typeof editTeamMembeNotesFormSchema
>

interface EditTeamMembeNotesFormProps {
  locationId: number
  userId: number
  userNotes?: string
  onCompleted?: () => void
}

const EditTeamMembeNotesForm: React.FC<EditTeamMembeNotesFormProps> = ({
  locationId,
  userNotes,
  userId,
  onCompleted,
}) => {
  const { mutateAsync: patchLocationUser } = usePatchLocationUser(
    locationId,
    userId
  )
  const {
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<EditTeamMembeNotesFormSchema>({
    resolver: yupResolver(editTeamMembeNotesFormSchema),
    defaultValues: {
      notes: userNotes ?? '',
    },
  })

  const onSubmit = handleSubmit(async ({ notes }) => {
    try {
      await patchLocationUser({ notes })
      onCompleted?.()
    } catch (error) {
      logger.error('Failed to update user notes', { error })
      toast.error('There was an error trying to update user notes.')
    }
  })

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledTextarea
        height={25}
        disabled={isSubmitting}
        placeholder="Write your notes here..."
        {...register('notes')}
      />
      <StyledButtonsContainer>
        <Button
          action="primary"
          type="submit"
          label="save"
          disabled={isSubmitting}
        />
      </StyledButtonsContainer>
    </StyledForm>
  )
}

export default EditTeamMembeNotesForm
