import styled from 'styled-components'

export const ContentHeaderContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_0,
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: '4px 4px 0px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.space(4)} ${theme.space(6)}`,
}))

export const ContentHeaderTitle = styled.p(({ theme }) => ({
  margin: 0,
  fontSize: '1.8rem',
}))

export const ContentBodyContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_0,
  borderRight: `1px solid ${theme.colors.base_20}`,
  borderLeft: `1px solid ${theme.colors.base_20}`,
}))

export const ContentBodySection = styled.div<{
  $isSmallDesktop?: boolean
}>(({ theme, $isSmallDesktop }) => ({
  borderBottom: `1px solid ${theme.colors.base_20}`,
  padding: `${$isSmallDesktop ? theme.space(8) : theme.space(2)}`,
  backgroundColor: theme.colors.base_10,
}))

export const ContentFooterContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_0,
  padding: `${theme.space(4)} ${theme.space(6)}`,
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: '0px 0px 4px 4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ContentFooterToggle = styled.div(({ theme }) => ({
  gap: theme.space(3),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}))

export const ContentFooterLabel = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.colors.primary_2,
  fontSize: '1.2rem',
  alignSelf: 'center',
}))
