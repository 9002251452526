import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import NewConversationModal from 'src/components/MessagingHub/ConversationsPane/Modals/NewConversationModal'
import { getTCPAModalParams } from 'src/components/MessagingHub/TCPAModal'
import { hasAcceptedTCPA } from 'src/components/MessagingHub/utils'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Tooltip from 'src/stories/Tooltip'
import { ComposeIcon, MobilePhoneIcon, QuestionIcon } from 'src/stories/assets'
import { Body, Heading } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

interface StyledContainerProps {
  isMediumDesktop?: boolean
}

const StyledContainer = styled.div<StyledContainerProps>(
  ({ isMediumDesktop, theme }) => ({
    display: 'flex',
    alignItems: 'center',

    gap: theme.space(3),
  })
)

interface CommonProps {
  isDesktop: boolean
}

const StyledForwardingPhoneContainer = styled.div<CommonProps>(
  ({ isDesktop, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    gap: theme.space(2),
    alignItems: 'center',
  })
)

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  marginRight: theme.space(5),
  cursor: 'pointer',
}))

interface ConversationHeadingProps {
  handleTabConversationChange: HandleTabConversationChangeFn
  isOpenConversation: boolean
}

const ConversationsActionsHeading: React.FC<ConversationHeadingProps> = ({
  handleTabConversationChange,
  isOpenConversation,
}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const theme = useTheme()
  const { refetchUser } = useAuthContext()
  const { legacyUser } = useAccountContext()
  const { showModal, closeModal } = useModalNotificationsContext()
  const { locationId, activeLocation } = useLocationContext()

  const { isDesktop, isMediumDesktop } = useMhContext()

  const [locationHasForwardingPhone, forwardingPhone] = useMemo(() => {
    return activeLocation.primaryRentedPhoneNumberId &&
      activeLocation.primaryRentedPhoneNumber
      ? [true, formatPhoneNumber(activeLocation.primaryRentedPhoneNumber)]
      : [false, '...processing']
  }, [activeLocation])

  const handleRenderModal = useCallback(() => {
    // TODO: Re implement when we decide what to do for TCPA
    const preventTcpaValidation = true

    if (preventTcpaValidation || hasAcceptedTCPA(legacyUser)) {
      showModal({
        title: 'New Message',
        dataCy: 'mh-new-conversation-modal',
        hideActionButtons: true,
        customBody: (
          <NewConversationModal
            handleTabConversationChange={handleTabConversationChange}
            locationId={locationId}
          />
        ),
      })
    } else {
      showModal(
        getTCPAModalParams(() => {
          void refetchUser()
          closeModal()
        }, legacyUser.id)
      )
    }
  }, [
    locationId,
    legacyUser,
    showModal,
    closeModal,
    handleTabConversationChange,
    refetchUser,
  ])

  useEffect(() => {
    if (!!new URLSearchParams(search).get('openConversationModal')) {
      const newUrl = new URLSearchParams(search)

      newUrl.delete('openConversationModal')

      navigate(`?${newUrl.toString()}`)
      void handleRenderModal()
    }
  }, [search, handleRenderModal, navigate])

  const showPhoneNumber = !isOpenConversation || isMediumDesktop

  return (
    <StyledContainer isMediumDesktop={isMediumDesktop}>
      <Heading as="h1" size="large">
        Inbox
      </Heading>
      {showPhoneNumber && (
        <StyledForwardingPhoneContainer
          isDesktop={isDesktop}
          data-cy="mh-forwarding-number"
        >
          <MobilePhoneIcon fill={theme.colors.base_50} />

          <Body as="p" size="medium">
            {forwardingPhone}
          </Body>
          {!locationHasForwardingPhone && (
            <Tooltip
              text={`Your customer-facing number is being set up. Once it's enabled you can start using the Messaging Hub and Instant Responses.\n\nIf you don't see it yet, please refresh the page in a few minutes.`}
              left={theme.space(0.5 * -1)}
              fontSize="1.2rem"
              color={theme.colors.base_60}
              textStyle={{ whiteSpace: 'pre-wrap' }}
              style={{ marginLeft: theme.space(2) }}
              fontWeight={400}
              widthUnits={60}
              position="bottom"
            >
              <StyledQuestionIcon fill={theme.colors.base_50} />
            </Tooltip>
          )}
        </StyledForwardingPhoneContainer>
      )}
      {locationHasForwardingPhone && (
        <Button
          action="primary"
          label="New"
          baseDataAttribute="new-conversation"
          onClick={handleRenderModal}
          icon={ComposeIcon}
        />
      )}
    </StyledContainer>
  )
}

export default ConversationsActionsHeading
