import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
  justifyContent: 'flex-start',
}))

interface SectionItem {
  label: string
  value?: string | number
  alwaysShow: boolean
}

export interface Section {
  label: string
  items: SectionItem[]
}

interface Props {
  section: Section
}

const AngiLeadsMessageContentSection: React.FC<Props> = ({
  section: { items, label },
}) => {
  const [showAll, setShowAll] = useState(false)

  return (
    <Container>
      <Body textTransform="uppercase" color="light">
        {label}
      </Body>
      {items.map((item) =>
        showAll || item.alwaysShow ? (
          <div key={item.label}>
            <Body as="p" color="darker" fontWeight="bold">
              {item.label}
            </Body>
            <Body as="p">{item.value}</Body>
          </div>
        ) : null
      )}
      <Button
        label={showAll ? 'Hide Details' : 'Show Details'}
        asLink
        displayAsText
        style={{ width: 'unset', justifyContent: 'flex-start' }}
        onClick={() => setShowAll((s) => !s)}
      />
    </Container>
  )
}

export default AngiLeadsMessageContentSection
