import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import {
  UserPhoneCarrier,
  UserPhoneNumberType,
  useCreateLocationUserPhoneNumber,
} from 'src/api'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import PhoneNumberInput from 'src/stories/PhoneNumberInput'
import Select from 'src/stories/Select'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import logger from 'src/utils/logger'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
}))

const StyledColumnsContainer = styled.div<{ columns: number }>(
  ({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
    columnGap: theme.space(3),
    rowGap: theme.space(6),
  })
)

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))

const formSchema = yup.object({
  phoneNumber: yup.string().phoneNumber().required('Phone number is required'),
  extension: yup.string().optional(),
  shouldReceiveLrSummaries: yup.boolean().optional().default(false),
  shouldReceiveUpdates: yup.boolean().optional().default(false),
  type: yup
    .mixed<UserPhoneNumberType>()
    .oneOf(Object.values(UserPhoneNumberType))
    .required('Phone type is required'),
  carrierSelector: yup
    .mixed<UserPhoneCarrier>()
    .oneOf(Object.values(UserPhoneCarrier), 'Invalid carrier')
    .when('type', {
      is: UserPhoneNumberType.MOBILE,
      then: yup.string().required('Carrier is required'),
    }),
  carrier: yup.string().when('carrierSelector', {
    is: UserPhoneCarrier.OTHER,
    then: yup.string().required('Carrier is required'),
  }),
})

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onComplete: () => void
  dataCy?: string
  locationId: number
  userId: number
}

const AddPhoneToTeamMemberModal: React.FC<Props> = ({
  onComplete,
  dataCy,
  locationId,
  userId,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const { mutateAsync: createUserPhoneNumber } =
    useCreateLocationUserPhoneNumber(locationId)
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    register,
    watch,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const carrierSelectorValue = watch('carrierSelector')
  const phoneType = watch('type')
  const showCarrierInput =
    carrierSelectorValue === UserPhoneCarrier.OTHER &&
    phoneType === UserPhoneNumberType.MOBILE

  const onSubmit = handleSubmit(
    async ({
      phoneNumber,
      extension,
      type,
      carrierSelector,
      carrier,
      shouldReceiveLrSummaries,
      shouldReceiveUpdates,
    }) => {
      const extensionParsed = extension ? parseInt(extension) : undefined
      let carrierParsed =
        carrierSelector === UserPhoneCarrier.OTHER ? carrier : carrierSelector

      if (phoneType !== UserPhoneNumberType.MOBILE) {
        carrierParsed = undefined
      }

      try {
        await createUserPhoneNumber({
          locationId,
          userId,
          phoneNumber,
          extension: extensionParsed,
          type,
          carrier: carrierParsed,
          shouldReceiveLrSummaries,
          shouldReceiveUpdates,
        })

        onComplete()
      } catch (error) {
        logger.error('Failed to create phone number', { error })
        toast.error('There was an error trying to create this phone number.')
      }
    }
  )

  const phoneTypeOptions = [
    {
      value: UserPhoneNumberType.OFFICE,
      label: 'Office',
    },
    {
      value: UserPhoneNumberType.MOBILE,
      label: 'Mobile',
    },
    {
      value: UserPhoneNumberType.HOME,
      label: 'Home',
    },
  ]

  const carrierOptions = Object.keys(UserPhoneCarrier).map((key) => {
    return {
      value: UserPhoneCarrier[key as keyof typeof UserPhoneCarrier],
      label: UserPhoneCarrier[key as keyof typeof UserPhoneCarrier],
    }
  })

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Form id={dataCy} onSubmit={onSubmit}>
      <StyledColumnsContainer columns={isDesktop ? 4 : 1}>
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <PhoneNumberInput
              label="Phone:"
              {...field}
              {...baseInputProps}
              containerStyle={{ gridColumn: 'span 3 / span 3' }}
            />
          )}
        />
        <Input
          type="number"
          label="Ext:"
          {...register('extension', {
            pattern: {
              value: /^[0-9]*$/,
              message: 'Invalid extension',
            },
          })}
          {...baseInputProps}
          min={0}
        />
      </StyledColumnsContainer>
      <Input
        type="checkbox"
        label="Receive signpost updates?"
        {...register('shouldReceiveLrSummaries')}
        {...baseInputProps}
      />
      <Input
        type="checkbox"
        label="Receive call center updates?"
        {...register('shouldReceiveUpdates')}
        {...baseInputProps}
      />
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <Select
            label="Phone Type:"
            options={phoneTypeOptions}
            placeholder="Please Select"
            errorText={errors.type?.message}
            onChange={(value) => {
              field.onChange(value)
              field.onBlur()
            }}
            initialValue={field.value}
          />
        )}
      />
      {phoneType === UserPhoneNumberType.MOBILE && (
        <Controller
          control={control}
          name="carrierSelector"
          render={({ field }) => (
            <Select
              label="Carrier:"
              options={carrierOptions}
              placeholder="Please Select"
              openedOptionsDirection="up"
              errorText={errors.carrierSelector?.message}
              onChange={(value) => {
                field.onChange(value)
                field.onBlur()
              }}
              initialValue={field.value}
            />
          )}
        />
      )}
      {showCarrierInput && (
        <Input label="Carrier:" {...register('carrier')} {...baseInputProps} />
      )}

      <StyledButtonsContainer>
        <Button
          action="secondary"
          type="button"
          label="Cancel"
          disabled={isSubmitting}
          onClick={closeModal}
        />

        <Button
          action="primary"
          type="submit"
          label="Add"
          disabled={isSubmitting}
        />
      </StyledButtonsContainer>
    </Form>
  )
}

export default AddPhoneToTeamMemberModal
