import React from 'react'
import styled, { useTheme } from 'styled-components'

import Tooltip from 'src/stories/Tooltip'

interface Props {
  tooltipMessage?: string
}

const TooltipLogo = styled.div(({ theme }) => ({
  height: theme.space(5),
  width: theme.space(5),
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  fontSize: '1.4rem',
  border: `2px solid ${theme.colors.base_30}`,
  color: theme.colors.base_30,
}))

const QuestionTooltip: React.FC<Props> = ({ tooltipMessage }) => {
  const theme = useTheme()

  if (!tooltipMessage) return null

  return (
    <Tooltip
      widthUnits={60}
      fontSize="1.3rem"
      color={theme.colors.base_80}
      text={tooltipMessage}
      fontWeight={400}
    >
      <TooltipLogo>?</TooltipLogo>
    </Tooltip>
  )
}

export default QuestionTooltip
