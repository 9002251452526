import pino from 'pino'

import { UnknownObject } from './interfaces'

const pinoLogger = pino({
  level: 'debug',
  browser: {
    asObject: false,
  },
  transport: {
    targets: [],
  },
})

const logger = {
  debug: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.debug(optionalParams, message)
      : pinoLogger.debug(message)
  },

  info: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.info(optionalParams, message)
      : pinoLogger.info(message)
  },

  error: (message: string, optionalParams?: UnknownObject) => {
    optionalParams
      ? pinoLogger.error(optionalParams, message)
      : pinoLogger.error(message)
  },
}

export default logger
