import styled from 'styled-components'

export const THead = styled.thead(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.2rem',
  textTransform: 'uppercase',
  'tr th': {
    backgroundColor: theme.colors.base_10,
  },
}))
