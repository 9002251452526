import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Contact } from 'src/api/types'

export interface GetContactParams {
  locationId: number
  contactId?: number | string
}

export const useGetContact = ({ locationId, contactId }: GetContactParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'contact',
      {
        locationId,
        contactId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<Contact>(
            `/locations/${params.locationId}/contacts/${params.contactId!}`
          )
        )
        .then((r) => r.data)
    },
    enabled: !!(
      locationId &&
      contactId &&
      !isNaN(+contactId) &&
      +contactId > 0
    ),
  })
}
