import styled from 'styled-components'

const StyledNotFoundContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledTitle = styled.h1(({ theme }) => ({
  color: theme.colors.primary_1,
  margin: `${theme.space(25)} 0 0 0`,
}))

const StyledSubtitle = styled.h3(({ theme }) => ({
  color: theme.colors.base_50,
  marginTop: 0,
  fontWeight: 'normal',
  fontSize: '2rem',
}))

const NotFound: React.FC = () => {
  return (
    <StyledNotFoundContainer>
      <StyledTitle>error: 404</StyledTitle>
      <StyledSubtitle>Whoops, this page doesn&apos;t exist.</StyledSubtitle>
    </StyledNotFoundContainer>
  )
}

export default NotFound
