import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { useTheme } from 'styled-components'

import { Conversation } from 'src/api'
import ConversationListItem from 'src/components/MessagingHub/ConversationsPane/ConversationListItem'
import { StyledContentContainer } from 'src/components/MessagingHub/styled'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import useMhContext from 'src/contexts/MhContext'
import { isEditableConversationItem } from 'src/contexts/MhContext/utils'
import { Col } from 'src/stories/Layout'
import LoadingSpinner from 'src/stories/LoadingSpinner'

export const StyledConversationsContainer = styled(Col)((props) => ({
  overflowY: 'scroll',
}))

export type SelectableConversationsList = Conversation & {
  selected: boolean
}

interface Props {
  handleTabConversationChange: HandleTabConversationChangeFn
  editMode: boolean
  toggleConversation: (conversationId: number, checked: boolean) => void
  selectableConversationsList: SelectableConversationsList[]
}

const ConversationList: React.FC<Props> = ({
  handleTabConversationChange,
  selectableConversationsList,
  toggleConversation,
  editMode,
}) => {
  const theme = useTheme()
  const {
    isDesktop,
    isZeroState,
    openConversationId,
    conversationsListIsLoading,
    isMediumDesktop,
    hasMoreConversations,
    getMoreConversations,
  } = useMhContext()

  return (
    <StyledContentContainer
      data-cy="conversations-list"
      height={`calc(100% - ${theme.space(isDesktop ? 31 : 30)})`}
      $border="right"
      $isMediumDesktop={isMediumDesktop}
    >
      <StyledConversationsContainer unspaced id="scrollableConversationsList">
        {conversationsListIsLoading && <LoadingSpinner />}
        {!conversationsListIsLoading && (
          <InfiniteScroll
            dataLength={selectableConversationsList.length}
            next={getMoreConversations}
            style={{
              overflow: 'hidden',
            }}
            hasMore={hasMoreConversations}
            loader={
              <div>
                <LoadingSpinner />
              </div>
            }
            scrollableTarget="scrollableConversationsList"
          >
            {selectableConversationsList.map((c, index) => (
              <ConversationListItem
                key={`conversation-item-${c.id}`}
                selected={c.id === openConversationId}
                conversationItem={c}
                index={index}
                checked={c.selected}
                toggleSelectConversation={toggleConversation}
                isEditDisabled={!isEditableConversationItem(c)}
                onClick={() =>
                  handleTabConversationChange({
                    mode: 'conversation',
                    conversationId: c.id,
                    conversationEventId: c.mostRecentEvent.id,
                    doNavigate:
                      !isZeroState &&
                      c.id !== DEFAULT_ZERO_STATE_CONVERSATION_ID,
                  })
                }
                editMode={editMode}
              />
            ))}
          </InfiniteScroll>
        )}
      </StyledConversationsContainer>
    </StyledContentContainer>
  )
}

export default ConversationList
