import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'

import {
  useGetConversationStatusAttributes,
  useUpdateConversationStatusAttributes,
} from 'src/api'
import AttributesAction from 'src/containers/Settings/Location/MessagingHub/AttributesAction'
import { useLocationContext } from 'src/contexts/LocationContext'
import Input from 'src/stories/Input'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import StatusIcon from 'src/stories/StatusIcon'
import { Body } from 'src/stories/typography'

const ItemsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

const ItemContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

const formSchema = yup
  .object({
    items: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          label: yup.string().required('Label is required'),
          color: yup.string().required('Color is required'),
        })
      )
      .required(),
  })

  .required()

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  baseDataAttribute: string
}

const SettingsStatusAttributes: React.FC<Props> = ({ baseDataAttribute }) => {
  const dataAttribute = `${baseDataAttribute}-status-attributes`
  const { locationId } = useLocationContext()
  const theme = useTheme()
  const [isEditing, setIsEditing] = useState(false)
  const { data: conversationStatusAttributes = [], refetch } =
    useGetConversationStatusAttributes({ locationId })
  const { mutateAsync: updateConversationStatusAttribute } =
    useUpdateConversationStatusAttributes(locationId, true)

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    values: {
      items: conversationStatusAttributes,
    },
    resetOptions: { keepDefaultValues: true },
  })

  const onSubmit = handleSubmit(async ({ items }) => {
    await Promise.allSettled(
      items.map((item) =>
        updateConversationStatusAttribute({
          statusId: item.id,
          color: item.color,
          label: item.label,
        })
      )
    )

    await refetch()

    toast.success('Conversation statuses updated successfully')
    setIsEditing(false)
  })

  return (
    <PageSectionContainer
      title="Conversation Statuses"
      titleProps={{
        as: 'h3',
        size: 'medium',
      }}
      variant="inverted"
      isRounded
      action={AttributesAction}
      actionProps={{
        baseDataAttribute: dataAttribute,
        isEditing,
        onClick: () => setIsEditing((v) => !v),
      }}
      preventHiding
      childrenStyle={{
        gap: theme.space(3),
      }}
    >
      <Body>
        Create your message labels organize your conversations, track progress,
        highlight important conversations, and much more.
      </Body>

      <ItemsContainer>
        <form onSubmit={onSubmit} id={dataAttribute + '-form'}>
          {conversationStatusAttributes.map((statusAttribute, idx) => (
            <ItemContainer key={statusAttribute.id}>
              <StatusIcon
                $hexColor={statusAttribute.color}
                style={{ marginTop: theme.space(1.5), height: theme.space(8) }}
              />
              <Input
                autoComplete="name"
                data-cy={`${dataAttribute}-form-${idx}-input`}
                disabled={isSubmitting || !isEditing}
                errors={formErrors}
                height={theme.space(6)}
                style={{ marginTop: 0 }}
                containerStyle={{ minWidth: '33%' }}
                {...register(`items.${idx}.label`)}
              />
            </ItemContainer>
          ))}
        </form>
      </ItemsContainer>
    </PageSectionContainer>
  )
}

export default SettingsStatusAttributes
