import { forwardRef, SVGProps } from 'react'
import styled from 'styled-components'

import { DefaultChevronIcon } from 'src/stories/assets'

export const LeftChevronIcon = styled(DefaultChevronIcon)(({ theme }) => ({
  width: theme.space(4),
}))

export const RightChevronIcon = styled(LeftChevronIcon)(({ theme }) => ({
  transform: 'rotate(180deg)',
}))

export const UpChevronIcon = styled(LeftChevronIcon)(({ theme }) => ({
  transform: 'rotate(90deg)',
}))

export const DownChevronIcon = styled(LeftChevronIcon)(({ theme }) => ({
  transform: 'rotate(270deg)',
}))

export interface ChevronIconProps extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  direction?: 'left' | 'right' | 'up' | 'down'
}

export const ChevronIcon = forwardRef<SVGSVGElement, ChevronIconProps>(
  ({ direction, ...props }, ref) => {
    switch (direction) {
      case 'right':
        return <RightChevronIcon ref={ref} {...props} />
      case 'up':
        return <UpChevronIcon ref={ref} {...props} />
      case 'down':
        return <DownChevronIcon ref={ref} {...props} />
      default:
        return <LeftChevronIcon ref={ref} {...props} />
    }
  }
)
