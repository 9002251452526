import { createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useAuthContext from './AuthContext'
import { BillingStatus, Location, useGetLocation } from 'src/api'
import { useGetIntegrationFailures } from 'src/api/hooks/queries/useGetIntegrationFailures'
import { LocationInterface } from 'src/client/interfaces/Common'
import { LocationV3Interface } from 'src/client/interfaces/LocationsV3'
import { useShowDisabledAccountModal } from 'src/components/MessagingHub/DisabledAccountModal/hooks'
import { useShowIntegrationFailureNotification } from 'src/components/MessagingHub/IntegrationFailureNotification/hooks'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { isInternalUser } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'

interface LocationContextInterface {
  locationId: number
  /** @deprecated currently set to 0 */
  merchantId: number
  /** @deprecated currently set to null */
  legacyActiveLocation: LocationInterface
  /** @deprecated currently set to null */
  legacyActiveLocationV3: LocationV3Interface
  activeLocation: Location
}

export const LocationContext = createContext<LocationContextInterface>(
  {} as LocationContextInterface
)

export const useLocationContext = () => useContext(LocationContext)

export const LocationContextProvider: React.FCWithChildren = ({ children }) => {
  const params = useParams<UseLocationRouteParams>()

  const locationId =
    params.locationId && !isNaN(+params.locationId)
      ? +params.locationId
      : undefined

  // Since it is a hook it has to come before any return statement.
  // Using non-null operator since the hook itself is disabled if locationId is not defined
  const { data: activeLocation, isLoading } = useGetLocation({
    locationId: locationId!,
  })
  const { data: integrationFailures } = useGetIntegrationFailures({
    locationId: locationId!,
  })
  const showDisabledAccountModal = useShowDisabledAccountModal()
  const showIntegrationFailureNotification =
    useShowIntegrationFailureNotification()
  const { user } = useAuthContext()

  useEffect(() => {
    if (
      !isInternalUser(user) &&
      activeLocation?.billingStatus &&
      (activeLocation.billingStatus === BillingStatus.SUSPENDED ||
        activeLocation.billingStatus === BillingStatus.CANCELLED)
    ) {
      showDisabledAccountModal({
        status: activeLocation.billingStatus,
        closingDate: activeLocation.closingDate,
      })
    }
  }, [activeLocation, showDisabledAccountModal, user])

  useEffect(() => {
    if (integrationFailures?.data.length) {
      integrationFailures.data.forEach(({ errorType, integrationType }) => {
        showIntegrationFailureNotification({
          errorType,
          integrationType,
        })
      })
    }
  }, [integrationFailures]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!locationId || !activeLocation) {
    return null
  }

  return (
    <LocationContext.Provider
      value={{
        locationId,
        merchantId: 0,
        legacyActiveLocation: null!,
        legacyActiveLocationV3: null!,
        activeLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
