import styled, { DefaultTheme } from 'styled-components'

interface TdProps {
  padding?: number | string
  color?: keyof DefaultTheme['colors']
  textAlign?: 'left' | 'right'
}

export const Td = styled.td<TdProps>(
  ({ theme, padding = 2, color = 'base_60', textAlign = 'left' }) => ({
    padding: typeof padding === 'number' ? theme.space(padding) : padding,
    color: color ? theme.colors[color] : undefined,
    textAlign,
  })
)
