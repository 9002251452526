import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface UpdateLocationForwardingPhoneNumberParams {
  forwardingPhoneNumberId: number
  phoneNumber: string
}

export const useUpdateLocationForwardingPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateLocationForwardingPhoneNumberParams) => {
      await getClient().then((client) =>
        client.patch(
          `/locations/${locationId}/forwarding-phone-numbers/${params.forwardingPhoneNumberId}`,
          {
            phoneNumber: params.phoneNumber,
          }
        )
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-forwarding-phone-number', { locationId }],
      })
    },
  })
}
