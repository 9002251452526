import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface DeleteLocationUserEmailAddressMutationParams {
  userId: number
  emailId: number
}

export const useDeleteLocationUserEmailAddress = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: DeleteLocationUserEmailAddressMutationParams
    ) => {
      const client = await getClient()

      await client.delete(
        `/locations/${locationId}/users/${params.userId}/email-addresses/${params.emailId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
