import styled, { useTheme } from 'styled-components'

import { StyledNavigationItem } from 'src/stories/Dropdown'
import { LocationIcon } from 'src/stories/assets'

const StyledLocationIcon = styled(LocationIcon)(({ theme }) => ({
  cursor: 'pointer',
  height: '1.7rem',
  marginRight: theme.space(3),
}))

const ReturnToMultiLocationView = () => {
  const theme = useTheme()

  return (
    <StyledNavigationItem to={'/accounts'}>
      <StyledLocationIcon fill={theme.colors.base_50} />
      Location Overview
    </StyledNavigationItem>
  )
}

export default ReturnToMultiLocationView
