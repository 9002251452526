import { useMutation } from '@tanstack/react-query'
import { AxiosHeaders } from 'axios'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { ConversationEvent, ConversationEventTypeEnum } from 'src/api/types'

interface CreateConversationEventParams {
  locationId: number
  conversationId: number
  message: string
  channelId: number
  type: ConversationEventTypeEnum
  sendVCard: boolean
  mediaUrls?: string[]
}

export const useCreateConversationEvent = (fetchOnCreate = false) => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: CreateConversationEventParams) => {
      const client = await getClient()

      const { headers } = await client.post(
        `/locations/${params.locationId}/conversations/${params.conversationId}/events`,
        {
          channelId: params.channelId,
          message: params.message,
          type: params.type,
          sendVCard: params.sendVCard,
          mediaUrls: params.mediaUrls,
        }
      )

      if (headers instanceof AxiosHeaders && headers.has('location')) {
        const location = headers['location'] as string

        const id = location.split('/').pop()

        if (id && fetchOnCreate) {
          const { data } = await client.get<ConversationEvent>(
            `/locations/${params.locationId}/conversations/${params.conversationId}/events/${id}`
          )

          return data
        }
      }
    },
  })
}
