import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NavLink } from 'react-router-dom'
import styled, { CSSObject } from 'styled-components'

import { useClickOutside } from 'src/hooks/useClickOutside'

interface StyledDropdownProps {
  left: number
  top: number
  isOpen: boolean
}
const StyledDropdown = styled.div<StyledDropdownProps>(
  ({ left, top, isOpen, theme }) => ({
    position: 'absolute',
    left,
    top,
    backgroundColor: theme.colors.base_0,
    border: `1px solid ${theme.colors.base_20}`,
    boxShadow: `${theme.colors.base_20} 0px 0px 5px 0px`,
    borderRadius: theme.constants.largeBorderRadius,
    visibility: isOpen ? 'visible' : 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndexes.dropdown,
  })
)

const StyledDropdownArrow = styled.div(({ theme }) => ({
  position: 'absolute',
  height: theme.space(2),
  width: theme.space(4),
  top: `calc(0% - ${theme.space(2)})`,
  left: `calc(100% - ${theme.space(4)} - ${theme.space(2)})`,
  backgroundColor: theme.colors.base_0,
  border: `1px solid ${theme.colors.base_20}`,
  borderBottom: 'none',
  boxShadow: `${theme.colors.base_20} 0px 0px 5px 0px`,
  clipPath: 'polygon(0% 100%, 50% 0%, 100% 100%)',
}))

export const StyledNavigationItem = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.space(4)} ${theme.space(5)}`,
  color: theme.colors.base_50,
  fontSize: '1.5rem',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base_10,
  },
}))

export const StyledItem = styled.div(({ theme }) => ({
  padding: `${theme.space(4)} ${theme.space(5)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: theme.colors.base_50,
  fontSize: '1.5rem',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base_10,
  },
}))

export const DropdownItem = styled.div(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(4)}`,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  color: theme.colors.base_100,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.colors.base_3,
  },
}))

export const DropdownItemsContainer = styled.div(({ theme }) => ({
  padding: `${theme.space(1)} 0`,

  [`${DropdownItem}:not(:last-child)`]: {
    borderBottom: `1px solid ${theme.colors.base_10}`,
  },
}))

export interface DropdownProps {
  /**
   * HTML Button element to where the Dropdown will be anchored to
   */
  anchor: RefObject<
    | HTMLButtonElement
    | HTMLImageElement
    | HTMLInputElement
    | HTMLDivElement
    | HTMLSpanElement
  >
  /**
   * Defaults to false. Hides the arrow at the top of the dropdown
   */
  hideArrow?: boolean
  /**
   * Defaults to center. Determines how the dropdown is going to be aligned, in relation
   * to the referenced component it's anchored to
   */
  alignment?: 'center' | 'left' | 'right'
  /**
   * Additional styles for the Dropdown container
   */
  style?: CSSObject
  shouldShowDropdown?: boolean
  scrollableContainerQuerySelector?: string
}

// This 10 represents 10 pixels that works as margin between the search box and the
// suggestions dropdown
const DEFAULT_SEPARATOR_PX = 10

const Dropdown: React.FCWithChildren<DropdownProps> = ({
  children,
  anchor,
  hideArrow = false,
  alignment = 'center',
  style,
  shouldShowDropdown = true,
  scrollableContainerQuerySelector,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  useClickOutside({ ref: dropdownRef, onClickOutside: () => setIsOpen(false) })

  const toggleIsOpen = useCallback(() => setIsOpen((v) => !v), [])

  useEffect(() => {
    if (anchor.current && dropdownRef.current) {
      anchor.current.addEventListener('click', toggleIsOpen, { once: true })
      anchor.current.addEventListener('closeDropdown', () => setIsOpen(false), {
        once: true,
      })

      const {
        top: anchorTop,
        height: anchorHeight,
        left: anchorLeft,
        width: anchorWidth,
      } = anchor.current.getBoundingClientRect()
      const dropDownContentHeight = dropdownRef.current.clientHeight
      const dropDownContentWidth = dropdownRef.current.clientWidth

      let newLeft = 0

      if (alignment === 'left') newLeft = anchorLeft
      if (alignment === 'right')
        newLeft = anchorLeft + anchorWidth - dropdownRef.current.offsetWidth
      if (alignment === 'center') newLeft = anchorLeft + anchorWidth / 2 + 14

      if (dropdownRef.current && alignment === 'center') {
        newLeft -= dropDownContentWidth
      }

      let newTop = anchorTop + anchorHeight + DEFAULT_SEPARATOR_PX

      const shouldOpenUp = newTop + dropDownContentHeight > window.innerHeight

      if (shouldOpenUp) {
        newTop =
          anchorTop -
          anchorHeight -
          DEFAULT_SEPARATOR_PX -
          dropDownContentHeight
      }

      if (scrollableContainerQuerySelector) {
        const scrollableContainer = document.querySelector(
          scrollableContainerQuerySelector
        )

        if (scrollableContainer) {
          newTop -= scrollableContainer.scrollTop
        }
      }

      setTop(newTop)
      setLeft(newLeft)
    }
  }, [
    anchor,
    isOpen,
    dropdownRef,
    alignment,
    toggleIsOpen,
    scrollableContainerQuerySelector,
  ])

  return (
    <StyledDropdown
      ref={dropdownRef}
      left={left}
      top={top}
      isOpen={shouldShowDropdown && isOpen}
      style={style}
    >
      {!hideArrow && <StyledDropdownArrow />}
      {children}
    </StyledDropdown>
  )
}

export const closeDropdownEvent = new Event('closeDropdown', {
  bubbles: true,
})

export default Dropdown
