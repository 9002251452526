import { useGetLocationFeatureFlag } from 'src/api'
import Constants from 'src/lib/Constants'

interface UseSmsIntegrationDataResponse {
  isEnabled: boolean
}

interface UseSMSIntegrationDataParams {
  locationId: number
}

export const useSMSIntegrationData = ({
  locationId,
}: UseSMSIntegrationDataParams): UseSmsIntegrationDataResponse => {
  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })

  return {
    isEnabled: textingEnabledFeatureFlag.value === 1,
  }
}
