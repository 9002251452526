import React, { memo } from 'react'

import {
  ConversationEventDirection,
  ConversationEventTypeEnum,
  TypedConversationEvent,
  isConversationEvent,
  useGetConversationEvent,
} from 'src/api'
import MessageBubble from 'src/components/MessagingHub/MessagesPane/MessageBubble'
import PhoneCallRecordingPlayer from 'src/components/MessagingHub/MessagesPane/PhoneCallRecordingPlayer'
import { useLocationContext } from 'src/contexts/LocationContext'

interface Props {
  message:
    | TypedConversationEvent<'LR_SUMMARY_EVENT'>
    | TypedConversationEvent<'LR_VOICEMAIL_EVENT'>
  contactName?: string
  conversationId: number
  baseDataAttribute: string
}

const LiveReceptionistMessageRecording: React.FC<Props> = memo(
  ({ message, conversationId, baseDataAttribute }) => {
    const { locationId } = useLocationContext()

    const { data } = useGetConversationEvent({
      locationId,
      conversationId,
      conversationEventId: message.id,
      enabled: !!message.details?.recordings.length,
    })

    // const recordingExpDate = data?.liveReceptionistData?.recordingDeletedAt
    //   ? formatRelative(
    //       new Date(data?.liveReceptionistData?.recordingDeletedAt),
    //       new Date()
    //     )
    //   : ''

    const recordings = (
      (data &&
        ((isConversationEvent(
          data,
          ConversationEventTypeEnum.LR_SUMMARY_EVENT
        ) &&
          data.details?.recordings) ||
          (isConversationEvent(
            data,
            ConversationEventTypeEnum.LR_VOICEMAIL_EVENT
          ) &&
            data.details?.recordings))) ||
      []
    ).filter((recording) => recording.url)

    const timestamp = (
      message.timestamp ? new Date(message.timestamp) : new Date()
    ).toISOString()

    return (
      <>
        {recordings.map((recording, idx) => (
          <MessageBubble
            key={`${baseDataAttribute}-recording-${idx}`}
            date={timestamp}
            contactName={''}
            sourceType={ConversationEventDirection.INCOMING}
            isEmptyBackground={false}
            dataCy={`${baseDataAttribute}-recording-${idx}`}
            isSimplified
          >
            <PhoneCallRecordingPlayer
              recordingUrl={recording.url}
              // recordingDeletedDate={recordingExpDate}
            />
          </MessageBubble>
        ))}
      </>
    )
  }
)

export default LiveReceptionistMessageRecording
