import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { LocationPhoneNumber } from 'src/api/types'

export const useGetLocationPhoneNumbers = (locationId: number) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['location-phone-numbers', { locationId }] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      getClient()
        .then((client) =>
          client.get<LocationPhoneNumber[]>(`/locations/${lid}/phone-numbers`)
        )
        .then((r) => r.data),
  })
}
