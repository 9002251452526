import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { ForwardingPhoneNumber, Paginated } from 'src/api/types'

export interface GetLocationForwardingPhoneNumberParams {
  locationId: number
}

export const useGetLocationForwardingPhoneNumber = ({
  locationId,
}: GetLocationForwardingPhoneNumberParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'location-forwarding-phone-number',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      const client = await getClient()

      const { data } = await client.get<Paginated<ForwardingPhoneNumber>>(
        `/locations/${params.locationId}/forwarding-phone-numbers`
      )
      const item = data.data[0]

      return item || null
    },
  })
}
