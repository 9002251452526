import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateContactTagAttributesParams {
  tagId: number
  label: string
}

export const useUpdateContactTagAttributes = (
  locationId: number,
  preventQueryInvalidation = false
) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateContactTagAttributesParams) => {
      const client = await getClient()

      await client.put(
        `/locations/${locationId}/contacts/tag-attributes/${params.tagId}`,
        {
          label: params.label,
        }
      )
    },
    onSuccess: async () => {
      if (!preventQueryInvalidation) {
        await queryClient.invalidateQueries({
          queryKey: ['contact-tag-attributes', { locationId }],
        })
      }
    },
  })
}
