import { useSearchParams } from 'react-router-dom'

import { ListResourceParams, SortDirection } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'

export const useTableSearchParams = (
  defaultTake = 50,
  prefix = ''
): ListResourceParams & {
  searchParams: ReturnType<typeof useSearchParams>[0]
  setSearchParams: ReturnType<typeof useSearchParams>[1]
} => {
  const { locationId } = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const direction = searchParams.has(`${prefix}direction`)
    ? (searchParams.get(`${prefix}direction`) as SortDirection)
    : undefined
  const field = searchParams.get(`${prefix}field`) || undefined
  const search = searchParams.get(`${prefix}search`) || undefined
  const take = searchParams.has(`${prefix}take`)
    ? +(searchParams.get(`${prefix}take`) as string)
    : defaultTake
  const skip = searchParams.has(`${prefix}skip`)
    ? +(searchParams.get(`${prefix}skip`) as string)
    : 0

  return {
    searchParams,
    setSearchParams,
    locationId,
    pagination: { take, skip },
    search,
    sort:
      direction && field
        ? {
            direction,
            field,
          }
        : undefined,
  }
}
