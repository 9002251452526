import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

import AddEmailToTeamMemberModal from './components/AddEmailToTeamMemberModal'
import AddTeamMemberToListModal from './components/AddTeamMemberToListModal'
import LocationUserDetailItem from './components/LocationUserDetailItem'
import LocationUserDetailsPane from './components/LocationUserDetailsPane'
import LocationUserItemActions from './components/LocationUserItemActions'
import LocationUserItemNotes from './components/LocationUserItemNotes'
import { ContentContainer } from './styled'
import {
  LocationUser,
  useGetLocationUsers,
  useDeleteLocationUser,
} from 'src/api'
import { useGetLocationLists } from 'src/api/hooks/queries/useGetLocationListsQuery'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import WafLayout, { WafLayoutProps } from 'src/components/WafLayout'
import PageLayout from 'src/components/WafLayout/PageLayout'
import AddPhoneToTeamMemberModal from 'src/containers/Settings/Location/Users/components/AddPhoneToTeamMemberModal'
import AddTeamMemberModal from 'src/containers/Settings/Location/Users/components/AddTeamMemberModal'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useDebouncer } from 'src/hooks/useDebounce'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import { formatPhoneNumber, formatPhoneType } from 'src/utils'
import logger from 'src/utils/logger'

interface Props {
  layoutProps: Omit<WafLayoutProps, 'children'>
}

export const LocationUsers: React.FC<Props> = ({ layoutProps }) => {
  const baseDataAttribute = 'settings-location-users'
  const theme = useTheme()
  const [activeLocationUser, setActiveLocationUser] = useState<LocationUser>()
  const { locationId } = useLocationContext()
  const { data: locationUsers = [], isLoading } =
    useGetLocationUsers(locationId)
  const { data: locationLists = [] } = useGetLocationLists(locationId)
  const { showModal, closeModal } = useModalNotificationsContext()
  const { mutateAsync: deleteUser } = useDeleteLocationUser(locationId)

  const handleUserDeletion = useDebouncer(async (userId: number) => {
    try {
      await deleteUser(userId)
      closeModal()
    } catch (error) {
      logger.error('Error deleting email', { error })
      toast.error('Error deleting email')
    }
  }, 400)

  useEffect(() => {
    if (activeLocationUser) {
      setActiveLocationUser(
        locationUsers.find((user) => user.id === activeLocationUser.id)
      )
    }
  }, [locationUsers, activeLocationUser])

  return (
    <WafLayout
      {...layoutProps}
      isSidedrawerOpen={!!activeLocationUser}
      heading={{
        onlySidedrawer: true,
        titleSlot: '',
        openSidedrawerButtonLabel: '',
        sidedrawerContent: activeLocationUser ? (
          <LocationUserDetailsPane
            activeLocationUser={activeLocationUser}
            locationLists={locationLists}
            onPhoneAdd={() => {
              showModal({
                title: 'Add phone',
                dataCy: `settings-location-users-add-phone-${activeLocationUser.id}`,
                hideActionButtons: true,
                customBody: (
                  <AddPhoneToTeamMemberModal
                    onComplete={() => {
                      closeModal()
                    }}
                    dataCy={`settings-location-users-add-phone-${activeLocationUser.id}`}
                    locationId={locationId}
                    userId={activeLocationUser.id}
                  />
                ),
              })
            }}
            onEmailAdd={() => {
              showModal({
                title: 'Add email',
                dataCy: `settings-location-users-add-email-${activeLocationUser.id}`,
                hideActionButtons: true,
                customBody: (
                  <AddEmailToTeamMemberModal
                    onComplete={() => {
                      closeModal()
                    }}
                    dataCy={`settings-location-users-add-email-${activeLocationUser.id}`}
                    locationId={locationId}
                    userId={activeLocationUser.id}
                  />
                ),
              })
            }}
            onListAdd={() => {
              showModal({
                title: 'Add list',
                dataCy: `settings-location-users-add-list-${activeLocationUser.id}`,
                hideActionButtons: true,
                customBody: (
                  <AddTeamMemberToListModal
                    onComplete={() => {
                      closeModal()
                    }}
                    dataCy={`settings-location-users-add-list-${activeLocationUser.id}`}
                    locationId={locationId}
                    userId={activeLocationUser.id}
                  />
                ),
              })
            }}
          />
        ) : (
          <></>
        ),
        sidedrawerTitle: `${activeLocationUser?.firstName ?? ''} ${
          activeLocationUser?.lastName ?? ''
        }`.trim(),
        closeSidedrawerButtonOnClick: () => setActiveLocationUser(undefined),
      }}
    >
      <PageLayout
        title="My Team"
        subtitle="View and update your employees and internal users"
        baseDataAttribute={baseDataAttribute}
      >
        <ContentContainer>
          {isLoading ? (
            <LoadingSpinner logoHeight={theme.space(15)} />
          ) : (
            <>
              {locationUsers.map((user, idx) => (
                <PageSectionContainer
                  key={user.id}
                  variant="inverted"
                  isRounded
                  title={`${user.firstName} ${user.lastName}`.trim()}
                  titleProps={{
                    as: 'h3',
                    size: 'medium',
                  }}
                  action={LocationUserItemActions}
                  preventHiding
                  actionProps={{
                    baseDataAttribute: `${baseDataAttribute}-user-${idx}`,
                    onClick: () =>
                      setActiveLocationUser((u) => {
                        if (u?.id === user.id) {
                          return undefined
                        }

                        return user
                      }),
                    onClickDelete: () => {
                      showModal({
                        title: 'Delete User',
                        dataCy: `${baseDataAttribute}-delete-user-${idx}`,
                        headerColor: 'accent_2',
                        height: 'auto',
                        modalActionsOptions: {
                          callToAction: {
                            label: 'Delete',
                            action: 'danger',
                            onClick: () => handleUserDeletion(user.id),
                          },
                        },
                        customBody: (
                          <ConfirmationModal message="Are you sure you want to delete this user? This action cannot be undone." />
                        ),
                      })
                    },
                  }}
                >
                  {user.emailAddresses.map((email) => (
                    <LocationUserDetailItem
                      key={email.id}
                      itemType="email"
                      value={email.emailAddress}
                      canLogin={email.canLogin}
                      shouldReceiveLrSummaries={email.shouldReceiveLrSummaries}
                      shouldReceiveUpdates={email.shouldReceiveUpdates}
                      isVerified={email.isVerified}
                    />
                  ))}
                  {user.phoneNumbers.map((phone) => (
                    <LocationUserDetailItem
                      key={phone.id}
                      itemType="phone"
                      value={formatPhoneNumber(phone.phoneNumber)}
                      type={formatPhoneType(phone.type)}
                      carrier={phone.carrier}
                      shouldReceiveLrSummaries={phone.shouldReceiveLrSummaries}
                      shouldReceiveUpdates={phone.shouldReceiveUpdates}
                    />
                  ))}

                  {user.lists?.length > 0 && (
                    <LocationUserItemNotes
                      title="Lists"
                      notes={user.lists
                        .map((list) => locationLists[list.listId]?.name)
                        .join(', ')}
                    />
                  )}
                  <LocationUserItemNotes
                    title="Notes"
                    notes={user.notes ?? ''}
                  />
                </PageSectionContainer>
              ))}
              <PageSectionContainer
                action={false}
                variant="inverted"
                isRounded
                title={
                  <Button
                    baseDataAttribute={`${baseDataAttribute}-add-new-user`}
                    displayAsText
                    label="Add new team member"
                    style={{ height: theme.space(8) }}
                    onClick={() => {
                      showModal({
                        title: 'Add new team member',
                        dataCy: `${baseDataAttribute}-add-new-user`,
                        hideActionButtons: true,
                        customBody: (
                          <AddTeamMemberModal
                            onComplete={() => {
                              closeModal()
                            }}
                            dataCy={`${baseDataAttribute}-add-new-user`}
                            locationId={locationId}
                          />
                        ),
                      })
                    }}
                  />
                }
              />
            </>
          )}

          {/* TODO: remove commented out code, can be used are reference */}
          {/* <CreateUserForm
          baseDataAttribute={baseDataAttribute}
          onSubmit={async (data) => {
            await mutateAsync({
              locationId,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber,
              emailAddress: data.emailAddress,
              isReadonly: data.isReadonly,
            })
            await refetch()
          }}
        />
        <Table data-cy={baseDataAttribute + '-table'} rounded>
          <THead data-cy={baseDataAttribute + '-table-header'}>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{ width: header.getContext().column.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <HeaderContentContainer
                        clickable={header.column.getCanSort()}
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <TableSortIcon isSorted={header.column.getIsSorted()} />
                      </HeaderContentContainer>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody data-cy={baseDataAttribute + '-table-body'}>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id} padding={4}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  {isLoading ? (
                    <LoadingSpinner logoHeight={theme.space(15)} />
                  ) : (
                    <StyledZeroStateTextContainer>
                      No results
                    </StyledZeroStateTextContainer>
                  )}
                </Td>
              </Tr>
            )}
          </TBody>
        </Table> */}
        </ContentContainer>
      </PageLayout>
    </WafLayout>
  )
}
