import { Table as ReactTable } from '@tanstack/react-table'
import React, { ReactElement } from 'react'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'
import Select from 'src/stories/Select'
import { ArrowIcon } from 'src/stories/assets'
import { Body } from 'src/stories/typography'

export interface PaginationProps<T> {
  includeFirstLastSelectors?: boolean
  table: ReactTable<T>
}

const PaginationContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: theme.space(3),
}))

const PaginationButton = styled(Button)(({ theme, disabled }) => ({}))

const FirstAndLastSelectorContainer = styled.span({
  display: 'inline-flex',
  flexDirection: 'row',
})

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

export const TablePagination = <T extends object>({
  includeFirstLastSelectors = false,
  table,
}: PaginationProps<T>): ReactElement => {
  const theme = useTheme()
  const options = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  const currentIdx = table.getState().pagination.pageIndex
  const currentPageSize = table.getState().pagination.pageSize
  const initialRange = currentIdx * currentPageSize + 1
  const finalRange = initialRange - 1 + table.getRowModel().rows.length
  const canNextPage = table.getCanNextPage()
  const canPreviousPage = table.getCanPreviousPage()

  return (
    <PaginationContainer>
      <Body>Show</Body>
      <Select
        options={options}
        height={theme.space(8)}
        containerStyle={{ flexGrow: 0 }}
        openedOptionsDirection="up"
        initialValue={table.getState().pagination.pageSize}
        onChange={(size) => table.setPageSize(size)}
      />
      <Body>
        {initialRange} - {finalRange}
      </Body>
      <ButtonsContainer>
        {includeFirstLastSelectors && (
          <PaginationButton
            action="secondary"
            label={
              <FirstAndLastSelectorContainer>
                <ArrowIcon />
                <ArrowIcon />
              </FirstAndLastSelectorContainer>
            }
            disabled={!canPreviousPage}
            onClick={() => table.setPageIndex(0)}
          />
        )}
        <PaginationButton
          outline
          action="secondary"
          label={
            <ArrowIcon
              style={{
                fill: !canPreviousPage ? theme.colors.base_50 : undefined,
              }}
            />
          }
          disabled={!canPreviousPage}
          onClick={() => table.previousPage()}
        />
        <PaginationButton
          outline
          action="secondary"
          label={
            <ArrowIcon
              style={{
                transform: 'rotate(180deg)',
                fill: !canNextPage ? theme.colors.base_50 : undefined,
              }}
            />
          }
          disabled={!canNextPage}
          onClick={() => table.nextPage()}
        />
        {includeFirstLastSelectors && (
          <PaginationButton
            action="secondary"
            label={
              <FirstAndLastSelectorContainer>
                <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
              </FirstAndLastSelectorContainer>
            }
            disabled={!canNextPage}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          />
        )}
      </ButtonsContainer>
    </PaginationContainer>
  )
}
