import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import { StyledContent } from 'src/components/Settings/Business/styled'
import {
  StyledForm,
  StyledTextarea,
} from 'src/components/Settings/common/layout'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'

export const StyledLabel = styled.label((props) => ({
  fontSize: '1.4rem',
  fontWeight: 'bold',
}))

const descriptionFormSchema = yup.object({
  description: yup.string().default(''),
})

type DescriptionFormSchema = yup.InferType<typeof descriptionFormSchema>

interface DescriptionFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const DescriptionForm: React.FC<DescriptionFormProps> = ({
  baseDataAttribute,
  isPending,
  update,
  onSubmitFinished,
}) => {
  const { activeLocation } = useLocationContext()
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<DescriptionFormSchema>({
    resolver: yupResolver(descriptionFormSchema),
    defaultValues: {
      description: activeLocation.description,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    await update(data)
    onSubmitFinished()
  })

  const disableForm = isSubmitting || isPending

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-description`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <div>
          <StyledLabel>Business description:</StyledLabel>
          <StyledTextarea
            height={50}
            disabled={disableForm}
            {...register('description')}
          />
        </div>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-description`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default DescriptionForm
