import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Location } from 'src/api/types'

export const useGetLocation = ({
  locationId,
  enabled = true,
}: {
  locationId: number
  enabled?: boolean
}) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'locations',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) =>
      getClient()
        .then((client) => client.get<Location>(`/locations/${lid}`))
        .then((r) => r.data),
    enabled: enabled && !!locationId && !isNaN(locationId),
  })
}
