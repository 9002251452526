import React from 'react'
import styled from 'styled-components'

import CheckmarkIcon from 'src/stories/assets/checkmark.svg'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))

const Title = styled.h2(({ theme }) => ({
  fontWeight: 500,
  fontSize: '2.4rem',
  color: theme.colors.base_100,
}))

const Subtitle = styled.p(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1.6rem',
  color: theme.colors.base_50,
  margin: 0,
}))

const ListText = styled(Subtitle)(({ theme }) => ({
  color: theme.colors.base_60,
}))

const BoldListText = styled.span(({ theme }) => ({
  color: theme.colors.base_100,
}))

const UnorderedList = styled.ul(({ theme }) => ({
  padding: 0,
  gap: theme.space(4),
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.space(6),
  marginBottom: theme.space(9),
}))

const ListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  backgroundImage: `url(${CheckmarkIcon})`,
  backgroundRepeat: 'no-repeat',
  margin: 0,
  paddingLeft: theme.space(7),
  verticalAlign: 'middle',
}))

const listItems = [
  [
    'Instantly respond',
    'to everyone who reached out on top social media sites and lead sources',
  ],
  [
    'Use one central inbox',
    'to save hours each day instead of searching for messages',
  ],
  [
    'Generate reviews',
    'to get found and chosen on 40+ top review sites with our email and text message review campaigns',
  ],
  [
    'Win repeat business',
    'by staying top of mind. Consistently engage your customer base with promotional campaigns',
  ],
]

const UpsellModal: React.FC = () => {
  return (
    <Container>
      <Title>Turn your leads into customers</Title>
      <Subtitle>
        All the tools you need to beat the competition - all in one place.
        <br />
        Schedule a call with our team to learn more.
      </Subtitle>
      <UnorderedList>
        {listItems.map(([boldText, text]) => (
          <ListItem key={boldText}>
            <ListText>
              <BoldListText>{boldText}</BoldListText> {text}
            </ListText>
          </ListItem>
        ))}
      </UnorderedList>
    </Container>
  )
}

export default UpsellModal
