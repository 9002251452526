import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import Constants from 'src/lib/Constants'
import { getCookie } from 'src/utils'
import { UnknownObject } from 'src/utils/interfaces'

const client = axios.create({
  baseURL: `https://${process.env.REACT_APP_BACKEND_HOST!}`,
})

export const axiosRequestInterceptor = (
  request: InternalAxiosRequestConfig
) => {
  const bearer = getCookie(`BEARER-${process.env.REACT_APP_ENVIRONMENT!}`)

  request.headers.set(Constants.HeaderKeys.bearer, bearer)

  return request
}

client.interceptors.request.use(axiosRequestInterceptor)

client.interceptors.response.use(
  (response) => {
    return response.data as AxiosResponse<unknown | UnknownObject>
  },
  async (error: unknown) => {
    // No 401 redirections at this levels. It's handled by the AuthContext
    return Promise.reject((error as AxiosError).response)
  }
)

export default client
