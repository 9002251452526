import React, { ReactElement, useEffect, useRef } from 'react'
import styled from 'styled-components'

import MessageHeader from 'src/components/MessagingHub/MessagesPane/ZeroState/MessageHeader'
import YoutubeIframe from 'src/components/YoutubeIframe'
import Constants from 'src/lib/Constants'
import { Col, Row } from 'src/stories/Layout'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

interface StyledContainerInterface {
  $isDesktop: boolean
  $isMediumDesktop: boolean
}
const StyledContainer = styled(Row)<StyledContainerInterface>(
  ({ theme, $isDesktop, $isMediumDesktop }) => ({
    flexGrow: 1,
    margin: `${$isMediumDesktop ? theme.space(5) : 0} 0`,
    padding: `0 ${theme.space($isDesktop ? 20 : 10)}`,
    height: '100%',
    overflowY: 'scroll',
  })
)

const StyledContent = styled(Col)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: theme.space(130),
  margin: `${theme.space(5)} auto`,
  marginBottom: theme.space(10),
}))

const StyledBubble = styled.div(({ theme }) => ({
  marginTop: theme.space(2),
  padding: `${theme.space(3)} ${theme.space(4)}`,
  border: `1px solid ${theme.colors.base_40}`,
  borderRadius: '8px',
  backgroundColor: theme.colors.base_5,
  color: theme.colors.base_40,
  fontSize: '1.3rem',
}))

const StyledAnchor = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
}))

interface ZeroStateMessage {
  id: string
  shouldDisplay: () => boolean
  insertScrollerRefAfter?: boolean
  video?: {
    title: string
    url: string
    dataCy?: string
  }
  text?: ReactElement
}

interface ZeroStateProps {
  greeting: string
}

export const TextAnchor = (text: string, url: string) => (
  <StyledAnchor href={url} target="_blank" rel="noreferrer noopener">
    {text}
  </StyledAnchor>
)

const ZeroState: React.FC<ZeroStateProps> = ({ greeting }) => {
  const scrollerRef = useRef<HTMLDivElement>(null)
  const { isLargeScreen: isLargeDesktop, isMediumScreen: isMediumDesktop } =
    useScreenSizes()

  const messages: ZeroStateMessage[] = [
    {
      id: 'lr-welcome-message',
      shouldDisplay: () => true,
      insertScrollerRefAfter: false,
      text: (
        <>
          {greeting}Welcome to your {Constants.Branding.companyName} inbox where
          you can view your call summaries. If you have selected that you want
          your call summaries via text and email you will still receive them
          that way. But, having one dedicated inbox to view them can help cut
          down on the chaos of those busy, jumbled inboxes. In addition you can
          use this app to view your invoices, manage your method of payment, and
          see important data about the calls we are answering for you.
        </>
      ),
    },
    {
      id: 'lr-welcome-video',
      shouldDisplay: () => true,
      insertScrollerRefAfter: false,
      video: {
        url: Constants.Branding.VideoLinks.welcomeAccount,
        title: 'Get the most out of your Live Receptionist account',
      },
    },
  ]

  const filteredMessages = messages.filter((message) => message.shouldDisplay())

  useEffect(() => {
    scrollerRef.current?.scrollIntoView()
  }, [])

  return (
    <StyledContainer
      direction="column"
      $isDesktop={isLargeDesktop}
      $isMediumDesktop={isMediumDesktop}
    >
      <StyledContent>
        {filteredMessages.map((message) => {
          return (
            <React.Fragment key={message.id}>
              <MessageHeader />
              {message.video && (
                <YoutubeIframe
                  url={message.video.url}
                  title={message.video.url}
                />
              )}
              {message.text && (
                <StyledBubble>
                  <p>{message.text}</p>
                </StyledBubble>
              )}
            </React.Fragment>
          )
        })}
      </StyledContent>
    </StyledContainer>
  )
}

export default ZeroState
