import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Location } from 'src/api/types'

export const useGetUserLocations = (enabled = true) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['user-locations'],
    queryFn: async () =>
      getClient()
        .then((client) => client.get<Location[]>('/locations/me'))
        .then((r) => r.data),
    enabled,
  })
}
