import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateUserListParams {
  listId: number
  userId: number
}

export const useCreateUserList = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateUserListParams) => {
      const client = await getClient()

      await client.put(
        `locations/${locationId}/lists/${params.listId}/users/${params.userId}`,
        {}
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
