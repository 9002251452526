import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { useCreateLocationList } from 'src/api/hooks/mutations/useCreateLocationListMutation'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import logger from 'src/utils/logger'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
  marginBottom: theme.space(6),
}))

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))

const formSchema = yup.object({
  name: yup.string().required('Name is required'),
})

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onComplete: () => void
  dataCy?: string
  locationId: number
  userId: number
}

const AddTeamMemberToListModal: React.FC<Props> = ({
  onComplete,
  locationId,
  dataCy,
  userId,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { mutateAsync: createLocationList } = useCreateLocationList(locationId)
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async ({ name }) => {
    try {
      await createLocationList({
        name,
        type: 'USER',
        userId,
      })

      onComplete()
    } catch (error: unknown) {
      let errorMessage =
        'An error occurred while adding the list. Please try again later.'

      logger.error('Error while adding list', { error })
      toast.error(errorMessage)
    }
  })

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Form id={dataCy} onSubmit={onSubmit}>
      <Input label="List name:" {...register('name')} {...baseInputProps} />
      <StyledButtonsContainer>
        <Button
          action="secondary"
          type="button"
          label="Cancel"
          disabled={isSubmitting}
          onClick={closeModal}
        />

        <Button
          action="primary"
          type="submit"
          label="Add"
          disabled={isSubmitting}
        />
      </StyledButtonsContainer>
    </Form>
  )
}

export default AddTeamMemberToListModal
