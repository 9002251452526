import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div((props) => ({
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer',
}))

// NOTE: The arbitrary values used in this function were defined by analyzing the
// tooltips used and their positioning, while maintaining visual consistency.
const defineArrowDirection = (
  position?: string,
  whiteSpace?: string,
  widthUnits?: number
) => {
  if (position === 'bottom') {
    if (whiteSpace && widthUnits) {
      return '-5%'
    } else if (whiteSpace && !widthUnits) {
      return '-1.5%'
    } else if (!whiteSpace && widthUnits) {
      return '-20%'
    } else {
      return '-25%'
    }
  } else {
    return '100%'
  }
}

interface StyledTextProps {
  $widthUnits?: number
  $fontSize?: string
  $isActive?: boolean
  $bottom?: string
  $left?: string
  $color?: string
  $fontWeight?: number
  $position?: string
}

const StyledText = styled.span<StyledTextProps>(
  ({
    $color,
    $isActive,
    $fontSize,
    $fontWeight,
    $bottom,
    $left,
    $widthUnits,
    $position,
    style,
    theme,
  }) => ({
    visibility: `${$isActive ? 'visible' : 'hidden'}`,
    backgroundColor: theme.colors.base_0,
    // TODO investigate when this is the case and add the color to the styleguide
    // After, remove this conditional.
    color: $color ? $color : theme.colors.primary_1,
    textAlign: 'center',
    borderRadius: theme.constants.borderRadius,
    padding: theme.space(3),
    position: 'absolute',
    zIndex: theme.zIndexes.tooltip,
    bottom: $position === 'bottom' ? 'auto' : `${$bottom ? $bottom : '150%'}`,
    left: `${$left ? $left : '50%'}`,
    marginLeft: theme.space(-($widthUnits ? $widthUnits / 2 : 9)),
    fontWeight: $fontWeight ? $fontWeight : 500,
    fontSize: $fontSize ?? '1.5rem',
    filter: `drop-shadow(0px 2px 10px ${theme.colors.base_30})`,
    width: $widthUnits && theme.space($widthUnits),
    top: `${$position === 'bottom' ? '150%' : 'auto'}`,

    [`${StyledContainer}:hover ${$isActive === undefined ? '&' : ''}`]: {
      visibility: 'visible',
    },

    ':after': {
      // Content was being set up as an empty string; therefore omitted when compiling
      content: "''",
      position: 'absolute',
      top: defineArrowDirection($position, style?.whiteSpace, $widthUnits),
      left: '50%',
      marginLeft: theme.space(-1),
      borderWidth: theme.space(1.5),
      borderStyle: 'solid',
      borderColor:
        $position === 'bottom'
          ? `transparent transparent ${theme.colors.base_0} transparent`
          : `${theme.colors.base_0} transparent transparent transparent`,
    },
  })
)

export interface TooltipProps {
  text: string | JSX.Element
  widthUnits?: number
  fontSize?: string
  isActive?: boolean
  bottom?: string
  left?: string
  color?: string
  fontWeight?: number
  textStyle?: React.CSSProperties
  position?: string
}

const Tooltip = React.forwardRef<
  HTMLDivElement,
  TooltipProps & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      children,
      text,
      widthUnits,
      fontSize,
      isActive,
      bottom,
      left,
      color,
      fontWeight,
      textStyle,
      position,
      ...divProps
    },
    ref
  ) => {
    return (
      <StyledContainer ref={ref} {...divProps}>
        {children}
        <StyledText
          $widthUnits={widthUnits}
          $fontSize={fontSize}
          $isActive={isActive}
          $bottom={bottom}
          $left={left}
          $color={color}
          $fontWeight={fontWeight}
          $position={position}
          style={textStyle}
        >
          {text}
        </StyledText>
      </StyledContainer>
    )
  }
)

export default Tooltip
