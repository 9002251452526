import { useInfiniteQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Paginated, SpamAction } from 'src/api/types'

export const DEFAULT_SKIP = 0
export const DEFAULT_TAKE = 10
export interface BlockAllowListItem {
  phoneNumber: string
  action: SpamAction
}

export interface GetLocationBlockAllowListParams {
  locationId: number
  action: SpamAction
  pagination?: {
    skip: number
    take: number
  }
}

export const useGetLocationBlockAllowList = ({
  locationId,
  action,
  pagination,
}: GetLocationBlockAllowListParams) => {
  const { getClient } = useApiClient()

  return useInfiniteQuery({
    queryKey: [
      'location-block-or-allow-list',
      { locationId, action, pagination },
    ] as const,
    queryFn: async ({ queryKey: [, params], pageParam }) => {
      const client = await getClient()
      const searchParams = {
        action: params.action,
        skip: pageParam.skip,
        take: pageParam.take,
      }

      const { data } = await client.get<Paginated<BlockAllowListItem>>(
        `/locations/${params.locationId}/phone-numbers/spam-list`,
        {
          params: searchParams,
        }
      )

      return data
    },
    initialPageParam: {
      skip: pagination?.skip ?? 0,
      take: pagination?.take ?? 10,
    },
    getNextPageParam: (lastPage, pages, { take }) => {
      const currentLength = pages.reduce((a, c) => a + c.data.length, 0)

      if (currentLength < lastPage.total) {
        return {
          skip: currentLength,
          take,
        }
      }
    },
  })
}
