import { isBefore } from 'date-fns'

import { Location, AuthorizedUser } from 'src/api'
import { LocationInterface, UserInterface } from 'src/client/interfaces/Common'
import Constants from 'src/lib/Constants'
import {
  isAgencyLocation,
  isInternalUser,
  isLegacyInternalUser,
} from 'src/utils'

/**
 * Method to generate the Terms of Service Redirect URL
 * @param redirectUrl
 * @returns The string URL with the redirect search param
 */
export const getTOsRedirectUrl = (redirectUrl: string) => {
  const url = new URL('/terms', window.location.origin)

  url.searchParams.append('redirect', redirectUrl)

  return url.toString()
}

/**
 *
 * @param userLocations
 * @param user
 * @returns
 */
export const userRequiresTosAgreement = (
  userLocations: LocationInterface[],
  user: UserInterface
) => {
  const releaseDate =
    process.env.REACT_APP_TOS_RELEASE_DATE || Constants.TOS.releaseDate

  if (
    isLegacyInternalUser(user) ||
    !JSON.parse(process.env.REACT_APP_TOS_ENABLED || 'false')
  ) {
    return false
  }

  const isAuthorizedOrExcempt: boolean =
    // already signed terms of service
    !!user.policyAcks?.length ||
    // user doesn't have any active locations
    userLocations.length <= 0 ||
    // user was already in contract before the release of the in-product TOS feature
    userLocations.some((l) =>
      isBefore(new Date(l.firstActivatedAt), new Date(releaseDate))
    ) ||
    // user has agency or partnership locations
    userLocations.some(({ accountType }) => isAgencyLocation(accountType))

  if (isAuthorizedOrExcempt) {
    return false
  }

  return true
}

/**
 * Method to generate a new string URL with specific actionable search params
 * @param origin
 * @param url
 * @param search
 * @returns The string URL with the actionable params
 */
export const attachRedirectionableParams = (
  url: URL,
  search: URLSearchParams
) => {
  const actionableParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'trigger',
    'openConversationModal',
  ]

  actionableParams.forEach((param) => {
    if (search.has(param)) {
      url.searchParams.append(param, search.get(param)!)
    }
  })

  return url
}

/**
 * Method to get the default redirect for an specific user
 * @param user
 * @param locations
 * @param isAgencyRedirect
 * @returns The url to navigate and whether it is an
 */
export const generateUserDefaultRedirect = (
  user: AuthorizedUser,
  locations: Location[]
) => {
  if (isInternalUser(user) || locations.length > 1) {
    return {
      path: '/accounts',
    }
  }

  return {
    path: `/${locations[0]?.id}/messaging-hub`,
  }
}
