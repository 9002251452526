import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import {
  StyledColumnsContainer,
  StyledContent,
} from 'src/components/Settings/Business/styled'
import { StyledForm } from 'src/components/Settings/common/layout'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import Input from 'src/stories/Input'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { UnknownObject } from 'src/utils/interfaces'

const addressFormSchema = yup.object({
  street1: yup.string().required('Missing street'),
  street2: yup.string().default(''),
  city: yup.string().required('Missing city'),
  state: yup.string().required('Missing state'),
  zip: yup.string().zipCode('Invalid zip code').required('Missing zip code'),
})

type AddressFormSchema = yup.InferType<typeof addressFormSchema>

interface AddressFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const AddressForm: React.FC<AddressFormProps> = ({
  baseDataAttribute,
  isPending,
  update,
  onSubmitFinished,
}) => {
  const { isMediumScreen } = useScreenSizes()
  const { activeLocation } = useLocationContext()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors: formErrors },
    setError,
  } = useForm<AddressFormSchema>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: {
      street1: activeLocation.addressStreet1,
      street2: activeLocation.addressStreet2 ?? undefined,
      city: activeLocation.addressCity,
      state: activeLocation.addressState,
      zip: activeLocation.addressZipCode,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      await update({
        addressStreet1: data.street1,
        addressStreet2: data.street2,
        addressCity: data.city,
        addressState: data.state,
        addressZipCode: data.zip,
      })

      onSubmitFinished()
    } catch (err: unknown) {
      const propertyNames =
        (
          (err as UnknownObject | undefined)?.data as
            | UnknownObject<`instance.address.${keyof AddressFormSchema}`[]>
            | undefined
        )?.propertyName || []

      propertyNames.forEach((element) => {
        const propertyName = element.split('.').pop()

        if (propertyName) {
          setError(propertyName as keyof AddressFormSchema, {
            message: `Invalid ${propertyName}`,
            type: 'server',
          })
        }
      })
    }
  })

  const disableForm = isSubmitting || isPending

  const inputBaseProps = {
    verticallySpace: 4,
    disabled: disableForm,
  }

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-address`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <div>
          <Input
            {...inputBaseProps}
            label="Address line 1:"
            errors={formErrors}
            {...register('street1')}
          />
          <Input
            {...inputBaseProps}
            label="Address line 2 (optional):"
            errors={formErrors}
            {...register('street2')}
          />
          <StyledColumnsContainer columns={isMediumScreen ? 3 : 1}>
            <Input
              {...inputBaseProps}
              label="City:"
              errors={formErrors}
              {...register('city')}
            />
            <Input
              {...inputBaseProps}
              label="State:"
              errors={formErrors}
              {...register('state')}
            />
            <Input
              {...inputBaseProps}
              label="Zip code:"
              errors={formErrors}
              {...register('zip')}
            />
          </StyledColumnsContainer>
        </div>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-address`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default AddressForm
