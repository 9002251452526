import React from 'react'

import CallForwarding from './CallForwarding'
import PageLayout from 'src/components/WafLayout/PageLayout'

const LocationPhoneSettings: React.FC = () => {
  const baseDataAttribute = 'settings-location-mh'

  return (
    <PageLayout
      title="Phone Settings"
      subtitle="View and update your phone settings"
      baseDataAttribute={baseDataAttribute}
    >
      <CallForwarding baseDataAttribute={baseDataAttribute} />
    </PageLayout>
  )
}

export default LocationPhoneSettings
