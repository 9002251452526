import styled, { CSSObject } from 'styled-components'

import { ArrowDownIcon } from 'src/stories/assets'

interface GridContainerProps {
  $columns?: number
  $alignItems?: CSSObject['alignItems']
  $isSmallScreen: boolean
}
export const GridContainer = styled.div<GridContainerProps>(
  ({ theme, $columns = 1, $alignItems, $isSmallScreen }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${$columns}, 1fr)`,
    gap: `${$isSmallScreen ? theme.space(6) : theme.space(2)}`,
    alignItems: $alignItems,
  })
)

interface WidgetCardProps {
  $columnStart?: number
  $columnEnd?: number
  $rowStart?: number
  $rowEnd?: number
}

export const WidgetCard = styled.div<WidgetCardProps>(
  ({ theme, $columnStart, $columnEnd, $rowEnd, $rowStart }) => {
    const additionalCss: CSSObject = {}

    if ($columnStart) {
      additionalCss.gridColumnStart = $columnStart
    }
    if ($columnEnd) {
      additionalCss.gridColumnEnd = $columnEnd
    }
    if ($rowStart) {
      additionalCss.gridRowStart = $rowStart
    }
    if ($rowEnd) {
      additionalCss.gridRowEnd = $rowEnd
    }

    return {
      backgroundColor: theme.colors.base_0,
      border: `1px solid ${theme.colors.base_20}`,
      borderRadius: theme.constants.largeBorderRadius,
      minHeight: theme.space(44),
      padding: `${theme.space(5)} ${theme.space(3)}`,
      ...additionalCss,
    }
  }
)

export const StyledArrowIcon = styled(ArrowDownIcon)(({ theme }) => ({
  cursor: 'pointer',
  stroke: theme.colors.base_50,
}))

export const StyledInvertedArrowIcon = styled(ArrowDownIcon)(({ theme }) => ({
  cursor: 'pointer',
  stroke: theme.colors.base_50,
  transform: 'scaleY(-1)',
}))

export const InsightsContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(5),
  paddingBottom: theme.space(3),
}))

export const StyledSecondaryTotalFeedbackContainer = styled.div(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.space(4),
  })
)

export const StyledNameIconTextContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  gap: theme.space(2),
}))
