import BlockOrAllowTable from './BlockOrAllowTable'
import { SpamAction } from 'src/api'
import PageLayout from 'src/components/WafLayout/PageLayout'

const LocationBlockAllowListSettings: React.FC = () => {
  const baseDataAttribute = 'settings-location-block-allow-list'

  return (
    <PageLayout
      title="Block / Allow List"
      subtitle="Control who reaches your receptionist by easily adding or removing numbers."
      baseDataAttribute={baseDataAttribute}
    >
      <BlockOrAllowTable
        action={SpamAction.ALLOW}
        title="Always Allowed Contacts"
      />
      <BlockOrAllowTable action={SpamAction.BLOCK} title="Blocked Contacts" />
    </PageLayout>
  )
}

export default LocationBlockAllowListSettings
