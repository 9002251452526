import React from 'react'
import styled from 'styled-components'

import MessagesList from 'src/components/MessagingHub/MessagesPane/MessagesList'
import useMhContext from 'src/contexts/MhContext'
import { Col, Row } from 'src/stories/Layout'
import LoadingSpinner from 'src/stories/LoadingSpinner'

interface StyledContainerProps {
  $isLargeDesktop: boolean
  $isDesktop: boolean
  $isSmallDesktop: boolean
  $isMediumDesktop: boolean
}

const StyledContainer = styled(Row)<StyledContainerProps>(
  ({
    $isDesktop,
    $isLargeDesktop,
    $isMediumDesktop,
    $isSmallDesktop,
    theme,
  }) => ({
    flexGrow: 1,
    width: '100%',
    padding: `${$isMediumDesktop ? theme.space(3) : 0} ${theme.space(
      $isLargeDesktop ? 20 : $isDesktop ? 16 : $isSmallDesktop ? 5 : 6
    )} ${theme.space(4)} ${theme.space(
      $isLargeDesktop ? 21 : $isDesktop ? 17 : $isSmallDesktop ? 5 : 6
    )}`,
    transition: 'padding 150ms',
  })
)

const StyledContent = styled(Col)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: theme.space(175),
  height: '100%',
}))

const StyledBubbleOuterContainer = styled.div(() => ({
  height: '100%',
}))

interface ConversationProps {
  textboxHeight: string
}

const Conversation: React.FC<ConversationProps> = ({ textboxHeight }) => {
  const {
    isDesktop,
    isExtraLargeDesktop,
    isLargeDesktop,
    isMediumDesktop,
    loadingConversationMessages,
    isOpenConversationChangeInFlight,
  } = useMhContext()

  const loading =
    loadingConversationMessages || isOpenConversationChangeInFlight

  return (
    <StyledContainer
      data-cy="conversation"
      height={`calc(82% - ${textboxHeight})`}
      $isLargeDesktop={isExtraLargeDesktop}
      $isDesktop={isLargeDesktop}
      $isMediumDesktop={isMediumDesktop}
      $isSmallDesktop={isDesktop}
    >
      <StyledContent unspaced>
        <StyledBubbleOuterContainer>
          {loading ? <LoadingSpinner /> : <MessagesList />}
        </StyledBubbleOuterContainer>
      </StyledContent>
    </StyledContainer>
  )
}

export default Conversation
