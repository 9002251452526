import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'

const ActionsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

interface Props {
  baseDataAttribute: string
  onClick?: () => void
  isEditing: boolean
}

const AttributesAction: React.FC<Props> = ({
  baseDataAttribute,
  onClick,
  isEditing,
}) => {
  const theme = useTheme()

  return (
    <ActionsContainer>
      <Button
        baseDataAttribute={`${baseDataAttribute}-edit-toggle`}
        displayAsText
        label={isEditing ? 'Cancel' : 'Edit'}
        style={{ height: theme.space(8) }}
        onClick={onClick}
      />
      {isEditing && (
        <Button
          baseDataAttribute={`${baseDataAttribute}-save`}
          displayAsText
          label="Save"
          style={{ height: theme.space(8) }}
          type="submit"
          form={baseDataAttribute + '-form'}
        />
      )}
    </ActionsContainer>
  )
}

export default AttributesAction
