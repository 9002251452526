import { differenceInDays, addDays, format } from 'date-fns'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { BillingStatus } from 'src/api'
import Constants from 'src/lib/Constants'
import {
  HeadingStyled,
  SubtitleStyled,
  TextStyled,
} from 'src/stories/typography/Typography.styles'
import { formatPhoneNumber } from 'src/utils'

export type DisabledAccountStatus =
  | BillingStatus.SUSPENDED
  | BillingStatus.CANCELLED

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))

const Link = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  textDecoration: 'none',
}))

const calculateDeletionDate = (closingDate?: Date): Date => {
  const deletionDate = closingDate ? new Date(closingDate) : new Date()

  return addDays(deletionDate, 30)
}

const calculateDaysLeft = (closingDate?: Date): number => {
  const deletionDate = calculateDeletionDate(closingDate)

  return differenceInDays(deletionDate, new Date())
}

const TITLE_TEXT: Record<DisabledAccountStatus, string> = {
  [BillingStatus.SUSPENDED]: 'suspended',
  [BillingStatus.CANCELLED]: 'disabled',
}

const SUBTITLE_TEXT: Record<DisabledAccountStatus, string> = {
  [BillingStatus.SUSPENDED]:
    'Account suspensions occur due to outstanding invoice balances, policy violations, or fraudulent activity.',
  [BillingStatus.CANCELLED]:
    'Your data and rented phone number is safe and will be stored for {{daysLeft}} more days until we delete your account. ({{deletionDate}})',
}

const CONTACT_US_TEXT: Record<DisabledAccountStatus, string> = {
  [BillingStatus.SUSPENDED]:
    'Please contact our team to resolve at your earliest convenience:',
  [BillingStatus.CANCELLED]:
    'To resume your services or for any other assistance, please contact us:',
}

const generateSubtitleText = (
  template: string,
  daysLeft: number,
  deletionDate: string
) => {
  return template
    .replace('{{daysLeft}}', daysLeft.toString())
    .replace('{{deletionDate}}', deletionDate)
}

const DisabledAccountModal: React.FC<{
  status: DisabledAccountStatus
  closingDate?: string
}> = ({ status, closingDate }) => {
  const subtitle = useMemo(() => {
    if (status === BillingStatus.SUSPENDED) {
      return SUBTITLE_TEXT[status]
    }

    if (status === BillingStatus.CANCELLED && closingDate) {
      const daysLeft = calculateDaysLeft(new Date(closingDate))
      const deletionDate = calculateDeletionDate(new Date(closingDate))

      return generateSubtitleText(
        SUBTITLE_TEXT[status],
        daysLeft,
        format(deletionDate, 'MM/dd/yyyy')
      )
    }

    return ''
  }, [status, closingDate])

  return (
    <Container>
      <HeadingStyled size="large">
        Your Signpost account has been
        <br />
        {TITLE_TEXT[status]}.
      </HeadingStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
      <TextStyled color="light" fontSize="small">
        {CONTACT_US_TEXT[status]}
      </TextStyled>
      <TextStyled color="light" fontSize="small">
        Support:{' '}
        <Link href={`mailto:${Constants.Branding.companySupportEmail}`}>
          {Constants.Branding.companySupportEmail}
        </Link>{' '}
        |{' '}
        <Link href={`tel:${Constants.Branding.companyPhone}`}>
          {formatPhoneNumber(Constants.Branding.companyPhone)}
        </Link>
      </TextStyled>
    </Container>
  )
}

export default DisabledAccountModal
