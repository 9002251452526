import React from 'react'
import styled from 'styled-components'

import Constants from 'src/lib/Constants'

const StyledCenteredContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${theme.space(20)} auto 0 auto`,
  padding: `0 ${theme.space(5)}`,
  maxWidth: theme.space(120),
  form: {
    width: '100%',
    paddingBottom: theme.space(8),
  },
}))

const StyledTitleContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.space(10),
}))

const StyledSignupTitle = styled.h1(({ theme }) => ({
  marginBottom: theme.space(4),
  fontWeight: 'normal',
  fontSize: '2.9rem',
  alignText: 'center',
}))

const StyledSignupSubtitle = styled.h2(({ theme }) => ({
  fontWeight: 'normal',
  color: theme.colors.base_60,
}))

const ScrollContainer = styled.div((props) => ({
  overflow: 'auto',
  height: '100vh',
}))

const Signup: React.FC = () => {
  return (
    <ScrollContainer>
      <StyledCenteredContainer>
        <StyledTitleContainer>
          <StyledSignupTitle>
            No account exists for this login.
          </StyledSignupTitle>
          <StyledSignupSubtitle>
            {`Please contact ${Constants.Branding.companySupportEmail} for assistance.`}
          </StyledSignupSubtitle>
        </StyledTitleContainer>
      </StyledCenteredContainer>
    </ScrollContainer>
  )
}

export default Signup
