import styled from 'styled-components'

export const PageContainer = styled.div<{ isDesktop?: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.base_10,
    width: '100%',
    height: '100%',
    padding: isDesktop ? 0 : `0 ${theme.space(10)}`,
  })
)

export const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(5),
  width: theme.space(100),
  backgroundColor: theme.colors.base_0,
  padding: `${theme.space(15)} ${theme.space(8)}`,
  borderRadius: theme.constants.largeBorderRadius,
  boxShadow: `0 0 ${theme.space(3)} ${theme.colors.base_20};`,
}))
