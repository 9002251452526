import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { handleRedirection } from 'src/containers/Login'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'

const Home: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const [urlSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    handleRedirection({
      urlSearchParams,
      userLocations: locations,
      authorizedUser: user,
      navigate,
    })
  }, [locations, user, urlSearchParams, navigate])

  return null
}

export default Home
