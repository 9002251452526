import { useMutation } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateConversationEventAttachmentsParams {
  locationId: number
  conversationId: number
  attachments: {
    filename: string
    mimeType: string
  }[]
}

interface CreateConversationEventAttachmentsResponse {
  total: number
  attachments: {
    signedUrl: string
    path: string
  }[]
}

export const useCreateConversationEventAttachments = () => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: CreateConversationEventAttachmentsParams) => {
      const client = await getClient()

      const response =
        await client.post<CreateConversationEventAttachmentsResponse>(
          `/locations/${params.locationId}/conversations/${params.conversationId}/attachments`,
          {
            attachments: params.attachments,
          }
        )

      return response.data
    },
  })
}
