import styled from 'styled-components'

export const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.space(6),
  gap: theme.space(4),
}))

export const HeaderContentContainer = styled.div<{ clickable: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
  })
)

export const StyledZeroStateTextContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))
