import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { getIdFromLocationHeader } from 'src/utils/helpers/locationHeader'

export interface CreateLocationForwardingPhoneNumberParams {
  phoneNumber: string
}

export const useCreateLocationForwardingPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateLocationForwardingPhoneNumberParams) => {
      const { headers } = await getClient().then((client) =>
        client.post<void>(`/locations/${locationId}/forwarding-phone-numbers`, {
          phoneNumber: params.phoneNumber,
        })
      )

      const id = getIdFromLocationHeader(headers)!

      return parseInt(id)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-forwarding-phone-number', { locationId }],
      })
    },
  })
}
