import { format } from 'date-fns'
import { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'

import { MediaResource } from 'src/client'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { MissingFileIcon, PaperClipIcon } from 'src/stories/assets'
import { isFirefox } from 'src/utils'
import logger from 'src/utils/logger'

const StyledModalContainer = styled.div<{ showPointer?: boolean }>(
  ({ theme, showPointer }) => ({
    ':hover': {
      cursor: showPointer ? 'pointer' : undefined,
    },
  })
)

const IconContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const StyledLink = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
}))

interface DisplayMediaProps {
  url: string
  contentType: string
  alt?: string
  width?: string
  replaceForIcons?: boolean
  dataCy?: string
  includesText?: boolean
  preventOpenPreview?: boolean
}

const DisplayMedia: React.FC<DisplayMediaProps> = ({
  url: mediaUrl,
  contentType,
  alt,
  width: _width,
  dataCy,
  replaceForIcons = false,
  includesText,
  preventOpenPreview,
}) => {
  const theme = useTheme()
  const width = _width || theme.space(50)
  const { showModal } = useModalNotificationsContext()

  const [mediaFailedLoading, setMediaFailedLoading] = useState(false)
  const [isVideoNotDisplayed, setIsVideoNotDisplayed] = useState(false)
  const [retrieveVideoFile, setRetrieveVideoFile] = useState(false)
  const [filename, setFilename] = useState('')
  const [file, setFile] = useState<Blob | undefined>(undefined)
  const [isLoadingLink, setIsLoadingLink] = useState(false)
  const [url, setUrl] = useState(mediaUrl)
  const [retryCount, setRetryCount] = useState(0)

  const onError: React.ReactEventHandler<
    HTMLImageElement | HTMLVideoElement
  > = (event) => {
    if (contentType.includes('video')) {
      if (isFirefox(window.navigator)) {
        setIsVideoNotDisplayed(true)
      } else {
        setMediaFailedLoading(true)

        const timeout = setTimeout(() => {
          if (retryCount < 3) {
            setRetryCount((c) => c + 1)
            setMediaFailedLoading(false)
            setUrl(mediaUrl)
            ;(event.target as HTMLVideoElement).load()
          } else {
            clearTimeout(timeout)
          }
        }, 2000)
      }
    } else {
      event.currentTarget.onerror = null
      setMediaFailedLoading(true)
    }
  }

  const onLoadedMetadata: React.ReactEventHandler<
    HTMLImageElement | HTMLVideoElement
  > = (event) => {
    const target = event.target as HTMLVideoElement

    if (target.videoHeight === 0 && target.videoWidth === 0) {
      setIsVideoNotDisplayed(true)
    }
  }

  useEffect(() => {
    const getVideoFile = async () => {
      try {
        setIsLoadingLink(true)

        const { data }: { data: Blob } = await MediaResource.getVideoFile(url)

        const timestamp = format(Date.now(), 'yyyyMMdd_HHmmss')

        setFilename(`video_file_${timestamp}`)
        setFile(data)
      } catch (err) {
        setMediaFailedLoading(true)
        logger.error('Error retrieving video', { err })
      } finally {
        setIsLoadingLink(false)
      }
    }

    if (isVideoNotDisplayed) {
      void getVideoFile()
    }
  }, [isVideoNotDisplayed, url])

  useEffect(() => {
    const createLinkToDownload = () => {
      const videoUrl = URL.createObjectURL(file!)

      const link = document.createElement('a')

      link.href = videoUrl
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.remove()

      setRetrieveVideoFile(false)
    }

    if (retrieveVideoFile && file) {
      void createLinkToDownload()
    }
  }, [retrieveVideoFile, file, filename])

  if (mediaFailedLoading) {
    return <MissingFileIcon width={width} />
  }

  let mediaComponent

  if (contentType.includes('image')) {
    mediaComponent = (
      <StyledModalContainer
        onClick={() =>
          !preventOpenPreview &&
          showModal({
            dataCy: `model-${dataCy ?? 'display-media'}`,
            hideActionButtons: true,
            customBody: (
              <div>
                <img
                  src={url}
                  data-cy={dataCy}
                  alt={alt || 'Message'}
                  style={{
                    maxWidth: '100%',
                  }}
                  onError={onError}
                />
              </div>
            ),
            maxSpacedModal: true,
            height: 'auto',
            width: 'max-content',
          })
        }
        showPointer={!preventOpenPreview}
      >
        <img
          src={url}
          data-cy={dataCy}
          alt={alt || 'Message'}
          style={{
            maxWidth: width,
            marginTop: includesText ? theme.space(3) : 'unset',
          }}
          onError={onError}
        />
      </StyledModalContainer>
    )
  } else if (contentType.includes('application')) {
    mediaComponent = replaceForIcons ? (
      <IconContainer>
        <PaperClipIcon width={30} height={30} fill={theme.colors.primary_2} />
      </IconContainer>
    ) : (
      <>
        <PaperClipIcon
          style={{
            marginRight: theme.space(1),
            marginTop: includesText ? theme.space(3) : 'unset',
            fill: theme.colors.primary_2,
          }}
        />
        <StyledLink href={url} target="_blank" rel="noreferrer">
          Open file in new tab
        </StyledLink>
      </>
    )
  } else {
    if (isLoadingLink) {
      mediaComponent = <LoadingSpinner logoHeight={theme.space(10)} />
    } else {
      mediaComponent = (
        <>
          {!isVideoNotDisplayed ? (
            <video
              controls
              src={url}
              style={{
                maxWidth: width,
                marginTop: includesText ? theme.space(3) : 'unset',
              }}
              onError={onError}
              onLoadedMetadata={onLoadedMetadata}
            />
          ) : (
            <Button
              label={'Download attached media'}
              asLink
              onClick={() => setRetrieveVideoFile(true)}
            />
          )}
        </>
      )
    }
  }

  return mediaComponent
}

export default DisplayMedia
