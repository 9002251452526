import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { OperatingHours } from 'src/api/types'

export interface UpdateLocationMutationParams {
  locationId: number
  addressStreet1?: string
  addressStreet2?: string
  addressCity?: string
  addressState?: string
  addressZipCode?: string
  phoneNumber?: string
  description?: string
  website?: string
  operatingHours?: OperatingHours
}

export const useUpdateLocation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateLocationMutationParams) => {
      return getClient()
        .then((client) =>
          client.patch<void>(`/locations/${params.locationId}`, {
            addressStreet1: params.addressStreet1,
            addressStreet2: params.addressStreet2,
            addressCity: params.addressCity,
            addressState: params.addressState,
            addressZipCode: params.addressZipCode,
            phoneNumber: params.phoneNumber,
            description: params.description,
            website: params.website,
            operatingHours: params.operatingHours,
          })
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['locations', { locationId }],
      })
    },
  })
}
