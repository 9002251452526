/**
 * Method to build a URL to render the Legacy pages iframe
 * @param pathname
 * @param sessionId
 * @param rememberMeSessionId
 * @param search
 * @param hash Some Glaze pages use hash navigation, so we need to define it so the iframe renders the right screen. (e.g. /settings page)
 * @returns The Legacy pages iframe string URL
 */
export const getIframeUrl = (
  pathname: string,
  search: string,
  hash: string,
  bearer?: string
): string => {
  const url = new URL(pathname, process.env.REACT_APP_LEGACY_URL)

  url.search = search
  if (bearer) {
    url.searchParams.set('bearer', bearer)
  }
  url.searchParams.set('loadingFromWebAppFrontend', 'true')
  url.hash = hash

  return url.toString()
}

/**
 * Method to return the cleaned up version of the navigation URL from Legacy Pages
 * @param path
 * @returns The URL href if the path has oauth otherwise the URL pathname and search
 */
export const getLocalUrl = (path: string): string => {
  const url = new URL(path, process.env.REACT_APP_LEGACY_URL)

  url.searchParams.delete('sessionId')
  url.searchParams.delete('rememberMeSessionId')
  url.searchParams.delete('loadingFromWebAppFrontend')
  url.searchParams.delete('_csrf')

  if (path.includes('oauth')) {
    return `${url.href}`
  }

  return `${url.pathname}${url.search}`
}

/**
 * Method to generate a location url
 * @param locationId
 * @param path Optional, defaults to `''`. If a '/' is at the beginning of the route, it will be removed
 */
export const generateLocationUrl = (locationId: string | number, path = '') =>
  `/${locationId}/${path.replace(/^\//, '')}`

/**
 * Method to generate a location url
 * @param merchantId
 * @param locationId
 * @param path Optional, defaults to `''`. If a '/' is at the beginning of the route, it will be removed
 * @returns The location url pathname
 * @deprecated
 */
export const generateLegacyLocationUrl = (
  merchantId: string | number,
  locationId: string | number,
  path = ''
) => `/${merchantId}/locations/${locationId}/${path.replace(/^\//, '')}`

/**
 * Method to generate a multiple location urls
 * @param merchantId
 * @param locationId
 * @param path Optional, defaults to `[]`. If a '/' is at the beginning of the route, it will be removed
 * @returns Several location urls pathname
 */
export const generateMultipleLocationUrl = (
  merchantId: string | number,
  locationId: string | number,
  paths: string[] = []
) => paths.map((p) => generateLegacyLocationUrl(merchantId, locationId, p))

/**
 * Method to generate a user url
 * @param userId
 * @param path Optional, defaults to `''`. If a '/' is at the beginning of the route, it will be removed
 * @returns The user url pathname
 */
export const generateUserUrl = (userId: string | number, path = '') =>
  `/user/${userId}/${path.replace(/^\//, '')}`
