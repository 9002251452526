import Cookies, { CookieSetOptions } from 'universal-cookie'

const cookies = new Cookies()

type ConditionalReturnType<T = string> = T extends true ? boolean : string

/**
 * Method to get an specific cookie value.
 * @param key
 * @param validateBooleanString
 * @returns the value of the cookie, a boolean value if `validateBooleanString` is true
 */
export const getCookie = <B extends boolean = false>(
  key: string,
  validateBooleanString = false as B
): ConditionalReturnType<B> => {
  if (validateBooleanString) {
    return (cookies.get<string>(key) === 'true') as ConditionalReturnType<B>
  }

  return cookies.get(key) as ConditionalReturnType<B>
}

/**
 * Method to get an specific cookie value.
 * @param key
 * @param validateBooleanString
 * @returns the value of the cookie, a boolean value if `validateBooleanString` is true
 */
export const setCookie = (
  name: string,
  value: unknown,
  options?: CookieSetOptions | undefined
) => {
  cookies.set(name, value, options)
}

// Keeping this conditional since it's not working on Web
// Opened a issue on Capacitor repo: https://github.com/ionic-team/capacitor/issues/6754
// regarding cookie deletion on Web
/**
 * Method to delete an specific cookie value.
 * @param key
 * @param options CookieSetOptions for cookie configuration
 * @returns
 */
export const removeCookie = (
  key: string,
  options?: CookieSetOptions | undefined
) => {
  cookies.remove(key, options)
}
