import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Account } from 'src/api/types'

export const useGetAccounts = (enabled = false) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['accounts'],
    queryFn: async () =>
      getClient()
        .then((client) => client.get<Account[]>('/accounts'))
        .then((r) => r.data),
    enabled,
  })
}
