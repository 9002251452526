import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  LocationBusinessType,
  SmsRegistrationSoleProprietorshipBusinessIndustry,
  SmsRegistrationRepresentativePosition,
  SmsRegistrationBusinessIndustry,
  SmsRegistrationStatus,
} from 'src/api'
import { useGetLatestSmsRegistration } from 'src/api/hooks/queries/useGetLatestSmsRegistrationQuery'
import { useLocationContext } from 'src/contexts/LocationContext'

const isNotSoleProprietorship = (businessType: string) =>
  businessType !== LocationBusinessType.SOLE_PROPRIETORSHIP

const smsRegistrationFormSchema = yup.object({
  businessName: yup.string().required('Missing business name'),
  businessType: yup
    .mixed<LocationBusinessType>()
    .required('Please select a business type'),
  ein: yup
    .string()
    .typeError('Please enter a valid 9-digit EIN')
    .when('$isEINRetryRegistration', {
      is: true,
      then: (schema) =>
        schema.notOneOf(
          [yup.ref('$currentEIN')],
          'EIN cannot be the same as the previous registration'
        ),
      otherwise: (schema) => schema,
    })
    .when('businessType', {
      is: isNotSoleProprietorship,
      then: (schema) =>
        schema
          .matches(/^\d{9}$/, 'Please enter a valid 9-digit EIN') // matches exactly nine digits
          .required('Missing EIN'),
      otherwise: (schema) => schema.notRequired(),
    }),
  websiteUrl: yup.string().when('businessType', {
    is: isNotSoleProprietorship,
    then: (schema) =>
      schema.required('Website URL is required for non-sole proprietors'),
    otherwise: (schema) => schema.optional(),
  }),
  addressLine1: yup.string().required('Missing street'),
  addressLine2: yup.string(),
  city: yup.string().required('Missing city'),
  state: yup
    .string()
    .usState('Invalid state abbreviation')
    .required('Missing state abbreviation'),
  postalCode: yup
    .string()
    .zipCode('Invalid ZIP code')
    .required('Missing ZIP code'),
  representativeFirstName: yup.string().required('First name is required'),
  representativeLastName: yup.string().required('Last name is required'),
  representativeTitle: yup.string().when(['businessType'], {
    is: isNotSoleProprietorship,
    then: (schema) => schema.required('Job title is required'),
    otherwise: (schema) => schema.optional(),
  }),
  representativePosition: yup
    .mixed<SmsRegistrationRepresentativePosition>()
    .when(['businessType'], {
      is: isNotSoleProprietorship,
      then: (schema) => schema.required('Please select a job position'),
      otherwise: (schema) => schema.nullable(),
    }),
  representativePhoneNumber: yup
    .string()
    .phoneNumber()
    .typeError('Please enter a valid phone number')
    .required('Phone number is required'),
  representativeEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  businessIndustry: yup
    .mixed<
      | SmsRegistrationSoleProprietorshipBusinessIndustry
      | SmsRegistrationBusinessIndustry
    >()
    .required('Please select a business industry')
    .when('businessType', {
      is: isNotSoleProprietorship,
      then: (schema) =>
        schema.oneOf(
          Object.values(SmsRegistrationBusinessIndustry),
          'Please select a business industry'
        ),
      otherwise: (schema) =>
        schema.oneOf(
          Object.values(SmsRegistrationSoleProprietorshipBusinessIndustry),
          'Please select a business industry'
        ),
    }),
})

export type SmsRegistrationFormSchema = yup.InferType<
  typeof smsRegistrationFormSchema
>

type SmsRegistrationFormContextType = {
  isEINRetryRegistration: boolean
  previousRegistrationId?: number
}

export const SmsRegistrationFormContext = React.createContext<
  SmsRegistrationFormContextType | undefined
>(undefined)

export const SmsRegistrationFormProvider: React.FCWithChildren = ({
  children,
}) => {
  const { locationId, activeLocation } = useLocationContext()
  const { data: previousRegistrationValues } = useGetLatestSmsRegistration({
    locationId,
  })

  const isEINRetryRegistration =
    activeLocation.registrationStatus === SmsRegistrationStatus.FAILED &&
    !!activeLocation.canRetryA2PRegistration

  const previousRegistrationId = previousRegistrationValues?.id ?? undefined

  const formMethods = useForm<SmsRegistrationFormSchema>({
    resolver: yupResolver(smsRegistrationFormSchema),

    mode: 'onChange',
    ...(previousRegistrationValues
      ? {
          values: {
            businessType: previousRegistrationValues.businessType,
            representativePosition:
              previousRegistrationValues.representativePosition,
            businessIndustry: previousRegistrationValues.businessIndustry,
            businessName: previousRegistrationValues.businessName ?? '',
            ein: previousRegistrationValues.businessIdentifier,
            websiteUrl: previousRegistrationValues.websiteUrl,
            addressLine1: previousRegistrationValues.addressStreet1,
            addressLine2: previousRegistrationValues.addressStreet2,
            city: previousRegistrationValues.addressCity,
            state: previousRegistrationValues.addressState,
            postalCode: previousRegistrationValues.addressPostalCode,
            representativeFirstName:
              previousRegistrationValues.representativeFirstName,
            representativeLastName:
              previousRegistrationValues.representativeLastName,
            representativeTitle: previousRegistrationValues.representativeTitle,
            representativePhoneNumber:
              previousRegistrationValues.representativePhoneNumber,
            representativeEmail:
              previousRegistrationValues.representativeEmailAddress,
          },
        }
      : {}),
    context: {
      isEINRetryRegistration,
      currentEIN: previousRegistrationValues?.businessIdentifier,
    },
  })

  return (
    <SmsRegistrationFormContext.Provider
      value={{ isEINRetryRegistration, previousRegistrationId }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </SmsRegistrationFormContext.Provider>
  )
}

const useSmsRegistrationFormContext = () => {
  const context = React.useContext(SmsRegistrationFormContext)
  const isEINRetryRegistration = context?.isEINRetryRegistration ?? false
  const previousRegistrationId = context?.previousRegistrationId ?? undefined

  return {
    ...useFormContext<SmsRegistrationFormSchema>(),
    isEINRetryRegistration,
    previousRegistrationId,
  }
}

export default useSmsRegistrationFormContext
