import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { PhoneNumberForwardingStatus } from 'src/api/types'

export interface GetPhoneNumberForwardingStatusParams {
  locationId: number
}

export const useGetPhoneNumberForwardingStatus = ({
  locationId,
}: GetPhoneNumberForwardingStatusParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'location-phone-number-forwarding-status',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<PhoneNumberForwardingStatus>(
            `/locations/${params.locationId}/phone-numbers/forwarding-status`
          )
        )
        .then((r) => r.data)
    },
  })
}
