import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { UserPhoneNumberType } from 'src/api/types'

interface CreateUserPhoneNumberParams {
  locationId: number
  userId: number
  phoneNumber: string
  extension?: number
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
  type: UserPhoneNumberType
  carrier?: string
}

export const useCreateLocationUserPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateUserPhoneNumberParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${params.locationId}/users/${params.userId}/phone-numbers`,
        {
          phoneNumber: params.phoneNumber,
          type: params.type,
          carrier: params.carrier,
          shouldReceiveLrSummaries: params.shouldReceiveLrSummaries,
          shouldReceiveUpdates: params.shouldReceiveUpdates,
          extension: params.extension,
        }
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
