import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface MarkReadConversationMutationParams {
  locationId: number
  conversationId: number
  conversationEventId: number
  isRead?: boolean
}

export const useMarkReadConversation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: MarkReadConversationMutationParams) => {
      return getClient()
        .then((client) =>
          client.patch<void>(
            `/locations/${params.locationId}/conversations/${params.conversationId}/events/${params.conversationEventId}`,
            {
              isRead: params.isRead,
            }
          )
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['conversations', { locationId }],
      })
    },
  })
}
