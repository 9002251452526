import React from 'react'
import styled from 'styled-components'

import { Body } from 'src/stories/typography'

const TagContainer = styled.div<{ $selected: boolean }>(
  ({ theme, $selected, onClick }) => ({
    backgroundColor: $selected ? theme.colors.primary_2 : theme.colors.base_10,
    borderRadius: theme.constants.borderRadius,
    padding: `${theme.space(1)} ${theme.space(2)}`,
    cursor: onClick ? 'pointer' : 'auto',
  })
)

interface Props {
  onClick?: () => void
  isSelected: boolean
  label: string
}

const ContactTag: React.FC<Props> = ({ isSelected, label, onClick }) => {
  return (
    <TagContainer $selected={isSelected} onClick={onClick}>
      <Body size="small" customColor={isSelected ? 'base_0' : undefined}>
        {label}
      </Body>
    </TagContainer>
  )
}

export default ContactTag
