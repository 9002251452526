import React, { memo } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { IntegrationMappingStatus } from 'src/api'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  gap: theme.space(2),
}))

const StyledDot = styled.div<{
  color: keyof DefaultTheme['colors']
  shade: keyof DefaultTheme['colors']
}>(({ theme, color, shade }) => ({
  width: theme.space(2),
  height: theme.space(2),
  backgroundColor: theme.colors[color],
  borderRadius: '50%',
  boxShadow: `0 0 0 2px ${theme.colors[shade]}`,
}))

interface Props {
  status?: IntegrationMappingStatus
}

const labels: Record<IntegrationMappingStatus, string | undefined> = {
  [IntegrationMappingStatus.PENDING]: 'Pending',
  [IntegrationMappingStatus.READY]: 'Connected',
}

const colors: Record<
  IntegrationMappingStatus,
  keyof DefaultTheme['colors'] | undefined
> = {
  [IntegrationMappingStatus.READY]: 'accent_3',
  [IntegrationMappingStatus.PENDING]: 'accent_1',
}

const shades: Record<
  IntegrationMappingStatus,
  keyof DefaultTheme['colors'] | undefined
> = {
  [IntegrationMappingStatus.READY]: 'accent_3_30',
  [IntegrationMappingStatus.PENDING]: 'accent_1_30',
}

const ConnectionIndicator: React.FC<Props> = memo(({ status }) => {
  const color = (status && colors[status]) ?? 'base_40'
  const shade = (status && shades[status]) ?? 'base_10'

  return (
    <Container>
      <StyledDot color={color} shade={shade} />
      <Body size="small" customColor={color}>
        {(status && labels[status]) ?? 'Not connected'}
      </Body>
    </Container>
  )
})

export default ConnectionIndicator
