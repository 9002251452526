import { AxiosInstance } from 'axios'

import { ConversationRequests } from 'src/client/interfaces/Conversations'
import Resource from 'src/client/resource'
import Constants from 'src/lib/Constants'
import { reduceAllSettledResponse } from 'src/utils'
import logger from 'src/utils/logger'

const actions = (client: AxiosInstance): ConversationRequests => {
  // TODO: Fix this URL, it's stale / broken.
  // https://github.com/signpost/gravy/blob/master/apps/lambda/Gravy.js#L1629
  const sendMessage: ConversationRequests['sendMessage'] = async (
    body,
    medias,
    channelId,
    customerId,
    // TODO: Rename this parameter to something more clear, since the actual value passed
    // to it is sometimes just e.g. "AgentzInteraction", not an id like "AgentzInteraction.123".
    interactionId,
    documentType,
    asReplyTo
  ) => {
    logger.debug('Called sendMessage', {
      channelId,
      customerId,
      interactionId,
      documentType,
      asReplyTo,
    })
    let method: string

    if (Constants.COMMS.smsDocumentTypes.includes(documentType)) {
      method = 'sms'
    } else if (Constants.COMMS.agentzDocumentTypes.includes(documentType)) {
      method = 'agentz'
    } else if (Constants.COMMS.facebookDocumentTypes.includes(documentType)) {
      method = 'facebook'
    } else if (
      Constants.COMMS.googleMessagesDocumentTypes.includes(documentType)
    ) {
      method = 'googleMessages'
    } else {
      throw new Error(`Unsupported document type: ${documentType}`)
    }

    const transformedMedias = medias.map((media) => ({
      contentType: media.contentType,
      url: `${media.url.match(new RegExp(/(.*)(\?.*)/))![1]}`,
    }))

    logger.debug('Sending message via method', {
      channelId,
      customerId,
      interactionId,
      documentType,
      asReplyTo,
      method,
    })

    const requestToSend = (
      sendingMedia: {
        contentType: string
        url: string
      }[]
    ) => {
      return client.post<{ fingerprint: string }, { fingerprint: string }>(
        Constants.Backend.Endpoints.CORE_CLIENT_PROXY,
        {
          module: 'Customers',
          method: 'sendIndividualComm',
          params: {
            channelId,
            customerId,
            method,
            asReplyTo,
            content: {
              body,
              media: sendingMedia.length ? sendingMedia : undefined,
              endLiveSupport: false,
            },
            overrideFingerprint: true,
          },
        }
      )
    }

    let requests: Promise<{ fingerprint: string }>[] = [
      requestToSend(transformedMedias),
    ]

    if (method === 'facebook' && transformedMedias.length) {
      requests = transformedMedias.map((sendingMedia) =>
        requestToSend([sendingMedia])
      )
    }

    const responses = await Promise.allSettled(requests)

    const [fulfilled, rejected] = reduceAllSettledResponse(responses)

    if (rejected.length && !fulfilled.length) {
      throw new Error('Images failed to send')
    }

    return fulfilled[0].value
  }

  const getConversationsPreviews: ConversationRequests['getConversationsPreviews'] =
    (conversationsIds) => {
      return client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
        module: 'Documents',
        method: 'mgetByKey',
        params: {
          scope: 'ChannelEvents',
          key: 'docId',
          values: conversationsIds,
        },
      })
    }

  return {
    sendMessage,
    markConversationRead: async (contactId: number | number[], read = true) => {
      const contactIds = Array.isArray(contactId) ? contactId : [contactId]

      // TODO: Batches the requests, and waits for one batch to resolve before triggering the next.
      // Similar to https://github.com/signpost/essentials/blob/1e6f60cfb136248b2783c61fbcc4423a5f244c22/Sequencer.js#L165
      const results = await Promise.allSettled(
        contactIds.map(async (_contactId) =>
          client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
            module: 'Customers',
            method: 'updateConversation',
            params: {
              customerId: _contactId,
              read,
            },
          })
        )
      )

      return reduceAllSettledResponse(results)
    },
    updateArchiveStatus: async (
      contactId: number | number[],
      archived = true
    ) => {
      const contactIds = Array.isArray(contactId) ? contactId : [contactId]

      // TODO: Batches the requests, and waits for one batch to resolve before triggering the next.
      // Similar to https://github.com/signpost/essentials/blob/1e6f60cfb136248b2783c61fbcc4423a5f244c22/Sequencer.js#L165
      const results = await Promise.allSettled(
        contactIds.map(async (_contactId) =>
          client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
            module: 'Customers',
            method: 'updateConversation',
            params: {
              customerId: _contactId,
              archived,
            },
          })
        )
      )

      return reduceAllSettledResponse(results)
    },
    updateUnconfirmedReviewArchiveStatus: async (
      reviewIds,
      locationId = 0,
      archived = true
    ) => {
      // TODO: Batches the requests, and waits for one batch to resolve before triggering the next.
      // Similar to https://github.com/signpost/essentials/blob/1e6f60cfb136248b2783c61fbcc4423a5f244c22/Sequencer.js#L165
      const results = await Promise.allSettled(
        reviewIds.map(async (reviewId) =>
          client.post(Constants.Backend.Endpoints.CORE_CLIENT_PROXY, {
            module: 'LocationReviewSites',
            method: 'toggleHidePublicReview',
            params: {
              locationId,
              publicReviewId: reviewId,
              hiddenFromUi: archived,
            },
          })
        )
      )

      return reduceAllSettledResponse(results)
    },
    getConversationsPreviews,
  }
}

export default Resource(actions)
