import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import { Container, FormContainer } from './styled'
import { Button } from 'src/stories/Button'
import Select from 'src/stories/Select'
import { Body } from 'src/stories/typography'

interface ColumnsIterator {
  key: keyof FormSchema
  label: string
}

const columns: ColumnsIterator[] = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'emailAddress', label: 'Email address' },
  { key: 'phoneNumber', label: 'Phone number' },
]

const formSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  emailAddress: yup.string().required(),
  phoneNumber: yup.string().required(),
})

export type FormSchema = yup.InferType<typeof formSchema>

export interface FileDetails {
  columns: string[]
  name: string
  type: string
  size: number
}

interface Props {
  fileDetails: FileDetails
  onSubmit: (data: FormSchema) => unknown
}

const ColumnMapper: React.FC<Props> = ({ fileDetails, onSubmit }) => {
  const theme = useTheme()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: fileDetails.columns[0],
      lastName: fileDetails.columns[1],
      emailAddress: fileDetails.columns[2],
      phoneNumber: fileDetails.columns[3],
    },
  })

  const disableForm = isSubmitting

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {columns.map((column) => (
        <Container key={column.key}>
          <Body as="p">
            The contact&apos;s{' '}
            <Body as="span" fontWeight="bold">
              {' '}
              {column.label}
            </Body>{' '}
            data is in the column:
          </Body>
          <Controller
            name={column.key}
            control={control}
            render={({
              field,
              fieldState: { isTouched, isDirty, error },
              formState: { errors },
            }) => {
              return (
                <Select
                  initialValue={field.value}
                  disabled={disableForm}
                  errorText={errors[column.key]?.message}
                  options={fileDetails.columns.map((value) => ({
                    value,
                    label: value,
                  }))}
                  onChange={(value) => {
                    field.onChange(value)
                  }}
                  containerStyle={{
                    width: theme.space(50),
                  }}
                />
              )
            }}
          />
        </Container>
      ))}
      <Button
        label="Upload contact list"
        type="submit"
        disabled={disableForm}
        style={{ width: '50%' }}
      />
    </FormContainer>
  )
}

export default ColumnMapper
