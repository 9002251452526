import React from 'react'
import styled from 'styled-components'

import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  margin: `${theme.space(1)} 0 ${theme.space(4)} 0`,
  alignSelf: 'start',
}))

interface Props {
  message: string
}

const ConfirmationModal: React.FC<Props> = ({ message }) => {
  return (
    <Container>
      <Body color="light">{message}</Body>
    </Container>
  )
}

export default ConfirmationModal
