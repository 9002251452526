import { useMutation } from '@tanstack/react-query'
import { AxiosHeaders } from 'axios'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Contact, ContactEntitySource } from 'src/api/types'

interface CreateContactParams {
  locationId: number
  firstName: string
  lastName: string
  phoneNumber?: string
  emailAddress?: string
}

export const useCreateContact = (fetchOnCreate = false) => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: CreateContactParams) => {
      const client = await getClient()

      const { headers } = await client.post(
        `/locations/${params.locationId}/contacts`,
        {
          firstName: params.firstName,
          lastName: params.lastName,
          phoneNumber: params.phoneNumber,
          emailAddress: params.emailAddress,
          source: ContactEntitySource.MANUALLY_CREATED,
        }
      )

      if (headers instanceof AxiosHeaders && headers.has('location')) {
        const location = headers['location'] as string

        const id = location.split('/').pop()

        if (id) {
          if (fetchOnCreate) {
            const { data } = await client.get<Contact>(
              `/locations/${params.locationId}/contacts/${id}`
            )

            return data
          } else if (id && isNaN(+id) === false) {
            const contactId = +id

            return contactId
          }
        }
      }
    },
  })
}
