import styled from 'styled-components'

import { Body } from 'src/stories/typography'

export interface RestoreDefaultMessageModalProps {
  defaultMessage: string
}

const StyledDefaultMessage = styled(Body)(({ theme }) => ({
  marginTop: theme.space(5),
  marginBottom: theme.space(12),
}))

const RestoreDefaultMessageModal: React.FC<RestoreDefaultMessageModalProps> = ({
  defaultMessage,
}) => {
  return (
    <Body size="large" as="span">
      Resetting your text message will restore this default message:
      <StyledDefaultMessage color="darker" size="large" fontStyle="italic">
        "{defaultMessage}"
      </StyledDefaultMessage>
    </Body>
  )
}

export default RestoreDefaultMessageModal
