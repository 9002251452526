import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { UserPosition, useCreateLocationUser } from 'src/api'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import Select from 'src/stories/Select'
import logger from 'src/utils/logger'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
  marginBottom: theme.space(6),
}))

const formSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  position: yup
    .mixed<UserPosition>()
    .oneOf(Object.values(UserPosition), 'Invalid position')
    .required('Position is required'),
})

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onComplete: () => void
  dataCy?: string
  locationId: number
}

const AddTeamMemberModal: React.FC<Props> = ({
  onComplete,
  dataCy,
  locationId,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { mutateAsync: createTeamMember } = useCreateLocationUser(locationId)

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async ({ firstName, lastName, position }) => {
    try {
      await createTeamMember({
        locationId,
        firstName,
        lastName,
        position,
        isReadonly: false,
      })

      onComplete()
    } catch (error) {
      logger.error('Failed to create team member', { error })
      toast.error('There was an error trying to create this team member.')
    }
  })

  const positionOptions = [
    {
      label: 'Employee',
      value: UserPosition.EMPLOYEE,
    },
    {
      label: 'Owner',
      value: UserPosition.OWNER,
    },
  ]

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Form id={dataCy} onSubmit={onSubmit}>
      <Input
        label="First name"
        {...baseInputProps}
        {...register('firstName')}
      />
      <Input label="Last name" {...baseInputProps} {...register('lastName')} />
      <Controller
        control={control}
        name="position"
        render={({ field }) => {
          return (
            <Select
              label="Position"
              options={positionOptions}
              placeholder="Select"
              errorText={errors.position?.message}
              onChange={(value) => {
                field.onChange(value)
                field.onBlur()
              }}
              initialValue={field.value}
            />
          )
        }}
      />

      <StyledButtonsContainer>
        <Button
          action="secondary"
          type="button"
          label="Cancel"
          disabled={isSubmitting}
          onClick={closeModal}
        />

        <Button
          action="primary"
          type="submit"
          label="Add"
          disabled={isSubmitting}
        />
      </StyledButtonsContainer>
    </Form>
  )
}

export default AddTeamMemberModal
