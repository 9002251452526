import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface ArchiveOrMarkSpamConversationMutationParams {
  locationId: number
  conversationId: number
  isArchived?: boolean
  isSpam?: boolean
}

export const useArchiveOrMarkSpamConversation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: ArchiveOrMarkSpamConversationMutationParams) => {
      return getClient()
        .then((client) =>
          client.patch<void>(
            `/locations/${params.locationId}/conversations/${params.conversationId}`,
            {
              isArchived: params.isArchived,
              isSpam: params.isSpam,
            }
          )
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['conversations', { locationId }],
      })
    },
  })
}
