import React from 'react'
import styled, { CSSObject } from 'styled-components'

export interface BackdropProps {
  show: boolean
  onClick?: React.ComponentProps<'div'>['onClick']
}

const BackdropContainer = styled.div<BackdropProps>(({ show, theme }) => {
  const displayParameters: CSSObject = show
    ? {
        opacity: 0.8,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }
    : {
        opacity: 0,
        visibility: 'hidden',
      }

  return {
    position: 'fixed',
    display: 'flex',
    '-webkit-box-align': 'center',
    alignItems: 'center',
    '-webkit-box-pack': 'center',
    justifyContent: 'center',
    inset: 0,
    backgroundColor: theme.colors.base_50,
    ' -webkit-tap-highlight-color': 'transparent',
    zIndex: theme.zIndexes.backdrop,
    ...displayParameters,
  }
})

const Backdrop: React.FC<BackdropProps> = ({ show, onClick }) => {
  return <BackdropContainer show={show} onClick={onClick}></BackdropContainer>
}

export default Backdrop
