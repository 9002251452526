import React, { memo, useMemo } from 'react'
import styled from 'styled-components'

import AngiLeadsMessageContentSection, {
  Section,
} from './AngiLeadsMessageContentSection'
import { ConversationEventTypeEnum, TypedConversationEvent } from 'src/api'
import { formatPhoneNumber } from 'src/utils'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(4),
  width: '17vw',
}))

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: theme.space(0.5),
  backgroundColor: theme.colors.base_20,
}))

interface Props {
  message: TypedConversationEvent<ConversationEventTypeEnum.ANGI_LEAD_EVENT>
}

const AngiLeadsMessageContent: React.FC<Props> = memo(({ message }) => {
  const { details } = message

  const sections = useMemo<Section[]>(
    () => [
      {
        label: 'Lead Info',
        items: [
          { label: 'Source:', value: details?.leadSource, alwaysShow: true },
          { label: 'Type:', value: details?.matchType, alwaysShow: true },
          { label: 'Fee:', value: details?.fee, alwaysShow: true },
          {
            label: 'Lead Description:',
            value: details?.leadDescription,
            alwaysShow: false,
          },
          {
            label: 'Contact Status:',
            value: details?.contactStatus,
            alwaysShow: false,
          },
          { label: 'CRM Key:', value: details?.crmKey, alwaysShow: false },
          { label: 'Lead Id:', value: details?.leadId, alwaysShow: false },
        ],
      },
      {
        label: 'Contact Info',
        items: [
          {
            label: 'Name:',
            value: [details?.firstName, details?.lastName]
              .filter(Boolean)
              .join(' '),
            alwaysShow: true,
          },
          { label: 'Address:', value: details?.address, alwaysShow: false },
          { label: 'City:', value: details?.city, alwaysShow: false },
          { label: 'State:', value: details?.stateProvince, alwaysShow: false },
          { label: 'Zip:', value: details?.postalCode, alwaysShow: false },
          {
            label: 'Primary Phone:',
            value: [
              details?.primaryPhone
                ? formatPhoneNumber(details.primaryPhone)
                : undefined,
              details?.phoneExt,
            ]
              .filter(Boolean)
              .join(' '),
            alwaysShow: true,
          },
          {
            label: 'Secondary Phone:',
            value: [
              details?.secondaryPhone
                ? formatPhoneNumber(details.secondaryPhone)
                : undefined,
              details?.secondaryPhoneExt,
            ]
              .filter(Boolean)
              .join(' '),
            alwaysShow: false,
          },
          { label: 'Email:', value: details?.email, alwaysShow: true },
        ],
      },
      {
        label: 'Job Info',
        items: [
          { label: 'Task:', value: details?.taskName, alwaysShow: true },
          { label: 'Comments:', value: details?.comments, alwaysShow: true },
          ...(details?.interview ?? []).map((interview) => ({
            label: interview.question,
            value: interview.answer,
            alwaysShow: false,
          })),
        ],
      },
    ],
    [details]
  )

  return (
    <Container>
      {sections.map((section, idx) => (
        <React.Fragment key={section.label}>
          {idx > 0 && <Divider />}
          <AngiLeadsMessageContentSection section={section} />
        </React.Fragment>
      ))}
    </Container>
  )
})

export default AngiLeadsMessageContent
