import { ErrorMessage } from '@hookform/error-message'
import React, { HTMLInputTypeAttribute } from 'react'
import styled from 'styled-components'

interface StyledInputContainerProps {
  verticallySpaced: boolean
  verticallySpace: number
  type?: HTMLInputTypeAttribute
}

const StyledInputContainer = styled.div<StyledInputContainerProps>(
  ({ theme, type, verticallySpace, verticallySpaced }) => ({
    display: 'flex',
    flexDirection: type === 'checkbox' ? 'row-reverse' : 'column',
    alignItems: type === 'checkbox' ? 'center' : '',
    justifyContent: 'start',
    margin: `0 0 ${verticallySpaced ? theme.space(verticallySpace) : '0'} 0`,
    gap: type === 'checkbox' ? theme.space(3) : theme.space(1),
    position: 'relative',
  })
)

export const StyledLabel = styled.label<{ $type?: HTMLInputTypeAttribute }>(
  ({ $type }) => ({
    fontSize: '1.4rem',
    fontWeight: $type === 'checkbox' ? undefined : 'bold',
    display: 'flex',
  })
)

const StyledHelpLabel = styled.label(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.base_40,
  fontWight: '400',
}))

interface StyledInputProps {
  error?: boolean
  type?: string
  disabled?: boolean
  hasIcon?: boolean
}

const StyledInput = styled.input<StyledInputProps>(
  ({ error, theme, type, disabled, hasIcon = false }) => ({
    width: type === 'checkbox' ? theme.space(6) : '100%',
    height: type === 'checkbox' ? theme.space(6) : theme.space(11),
    padding: `0 ${hasIcon ? theme.space(8) : theme.space(3)}`,
    backgroundColor: disabled ? theme.colors.base_5 : theme.colors.base_0,
    color: error ? theme.colors.accent_2 : theme.colors.base_100,
    border: `1px solid ${error ? theme.colors.accent_2 : theme.colors.base_20}`,
    borderRadius: theme.constants.borderRadius,
    fontSize: '1.5rem',
    cursor: type === 'checkbox' ? 'pointer' : undefined,

    '&:not([disabled]):hover': {
      borderColor: error ? theme.colors.accent_2 : theme.colors.base_50,
    },

    '&:not([disabled]):focus': {
      color: theme.colors.base_100,
      outline: 'none',
      border: `2px solid ${
        error ? theme.colors.accent_2 : theme.colors.primary_1
      }`,
    },
  })
)

const StyledValidationErrorText = styled.label(({ theme }) => ({
  marginTop: theme.space(1),
  color: theme.colors.accent_2,
  fontSize: '1.3rem',
}))

const IconContainer = styled.span(({ theme }) => ({
  position: 'absolute',
  left: theme.space(2.5),
  top: '50%',
  transform: 'translateY(-50%)',
}))

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  placeholder?: string
  helpLabel?: string | JSX.Element
  type?: string
  errors?: Record<string, unknown>
  focusOnLoad?: boolean
  verticallySpaced?: boolean
  verticallySpace?: number
  containerStyle?: React.CSSProperties
  name: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      Icon,
      placeholder,
      helpLabel,
      type,
      focusOnLoad,
      errors,
      verticallySpaced = true,
      verticallySpace = 8,
      containerStyle,
      ...props
    },
    ref
  ) => {
    return (
      <StyledInputContainer
        verticallySpaced={verticallySpaced}
        verticallySpace={verticallySpace}
        style={containerStyle}
        type={type}
      >
        {label && <StyledLabel $type={type}>{label}</StyledLabel>}
        {helpLabel && <StyledHelpLabel>{helpLabel}</StyledHelpLabel>}
        <StyledInput
          ref={ref}
          type={type || 'text'}
          autoFocus={focusOnLoad}
          placeholder={placeholder}
          hasIcon={!!Icon}
          error={!!(errors && errors[props.name])}
          {...props}
        />
        {Icon && (
          <IconContainer data-cy="input-icon-container">
            <Icon />
          </IconContainer>
        )}
        {errors && (
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => (
              <StyledValidationErrorText>{message}</StyledValidationErrorText>
            )}
          />
        )}
      </StyledInputContainer>
    )
  }
)

export default Input
