import React, { useState } from 'react'

import ContentSection, { ContentSectionData } from './ContentSection'
import {
  ContentBodyContainer,
  ContentFooterContainer,
  ContentFooterLabel,
  ContentFooterToggle,
  ContentHeaderContainer,
  ContentHeaderTitle,
} from './styled'
import {
  StyledArrowIcon,
  StyledInvertedArrowIcon,
} from 'src/components/Insights/styled'
import { MoreIcon, LessIcon } from 'src/stories/assets'

interface InsightsContentProps {
  title: string
  hasMore?: boolean
  banner?: JSX.Element
  content: ContentSectionData
  additionalContent?: ContentSectionData
}

const buildDataAttribute = (section: string) => (uniqueName: string) =>
  ['insights', section, uniqueName].join('-')

const InsightsContent: React.FC<InsightsContentProps> = ({
  title,
  hasMore = false,
  banner,
  content,
  additionalContent,
}) => {
  const [displayMore, setDisplayMore] = useState(false)
  const [showAll, setShowAll] = useState(true)

  const getDataAttribute = buildDataAttribute(
    title.split(' ').join('-').toLocaleLowerCase()
  )

  const toggleShowAll = () => setShowAll((v) => !v)

  const arrowProps = {
    onClick: toggleShowAll,
  }

  return (
    <div data-cy={getDataAttribute('container')}>
      <ContentHeaderContainer data-cy={getDataAttribute('header-container')}>
        <ContentHeaderTitle data-cy={getDataAttribute('header')}>
          {title}
        </ContentHeaderTitle>
        {showAll ? (
          <StyledArrowIcon
            {...arrowProps}
            data-cy={getDataAttribute('hide-all')}
          />
        ) : (
          <StyledInvertedArrowIcon
            {...arrowProps}
            data-cy={getDataAttribute('show-all')}
          />
        )}
      </ContentHeaderContainer>
      {showAll && (
        <>
          <ContentBodyContainer data-cy={getDataAttribute('body-container')}>
            {banner ? banner : null}

            {!banner && (
              <>
                <ContentSection
                  baseDataCy={getDataAttribute('main-section')}
                  data={content}
                />

                {displayMore && additionalContent && (
                  <ContentSection
                    baseDataCy={getDataAttribute('additional-section')}
                    data={additionalContent}
                  />
                )}
              </>
            )}
          </ContentBodyContainer>
          {hasMore && (
            <ContentFooterContainer
              data-cy={getDataAttribute('footer-container')}
            >
              <ContentFooterToggle
                onClick={() => setDisplayMore((v) => !v)}
                data-cy={getDataAttribute(
                  displayMore ? 'hide-container' : 'show-container'
                )}
              >
                {displayMore ? (
                  <LessIcon data-cy={getDataAttribute('less-icon')} />
                ) : (
                  <MoreIcon data-cy={getDataAttribute('more-icon')} />
                )}
                <ContentFooterLabel
                  data-cy={getDataAttribute(
                    displayMore ? 'hide-label' : 'show-label'
                  )}
                >
                  {displayMore ? 'Hide' : 'Show'} breakdown
                </ContentFooterLabel>
              </ContentFooterToggle>
            </ContentFooterContainer>
          )}
        </>
      )}
    </div>
  )
}

export default InsightsContent
