import { useState, useEffect, useCallback } from 'react'
import { useTheme } from 'styled-components'

const useMediaQuery = (query: string): [boolean] => {
  const getMatches = (newQuery: string): boolean => {
    // Prevents SSR issues (Glaze iframes)
    if (typeof window !== 'undefined') {
      return window.matchMedia(newQuery).matches
    }

    return false
  }

  const [matches, setMatches] = useState<boolean>(getMatches(query))

  const handleChange = useCallback(() => {
    setMatches(getMatches(query))
  }, [query])

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    matchMedia.addEventListener('change', handleChange)

    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [handleChange, query])

  return [matches]
}

const useScreenSizes = () => {
  const theme = useTheme()

  const [is2ExtraLargeScreen] = useMediaQuery(theme.getBreakpoint('xxl'))
  const [isExtraLargeScreen] = useMediaQuery(theme.getBreakpoint('xl'))
  const [isLargeScreen] = useMediaQuery(theme.getBreakpoint('lg'))
  const [isMediumScreen] = useMediaQuery(theme.getBreakpoint('md'))
  const [isSmallScreen] = useMediaQuery(theme.getBreakpoint('sm'))
  const [isExtraSmallScreen] = useMediaQuery(theme.getBreakpoint('xs'))
  const [is2ExtraSmallScreen] = useMediaQuery(theme.getBreakpoint('xxs'))

  return {
    /** 1440x900 */
    is2ExtraLargeScreen,
    /** 1280x800 */
    isExtraLargeScreen,
    /** 1020x768 */
    isLargeScreen,
    /** 720x1080 */
    isMediumScreen,
    /** 640x960 */
    isSmallScreen,
    /** 480x800 */
    isExtraSmallScreen,
    /** 320x480 */
    is2ExtraSmallScreen,
  }
}

export default useScreenSizes
