import {
  differenceInMinutes,
  differenceInMonths,
  intlFormatDistance,
} from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import {
  ConversationEventTypeEnum,
  useCreateContact,
  useCreateConversation,
  useCreateConversationEvent,
  useGetContactConversations,
} from 'src/api'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useAttachmentsUpload } from 'src/contexts/MhContext/hooks'
import { HandleSendMessage } from 'src/contexts/MhContext/types'
import { unFormatPhoneNumber } from 'src/utils'
import logger from 'src/utils/logger'

interface UseHandleOnSendParams {
  newConversationContact?: number
  newConversationContactChannelId?: number
  newConversationInputValue: string
  locationId: number
  handleTabConversationChange: HandleTabConversationChangeFn
  closeModal: () => void
}

export const useHandleOnSend = ({
  newConversationContact,
  newConversationContactChannelId,
  newConversationInputValue,
  locationId,
  closeModal,
  handleTabConversationChange,
}: UseHandleOnSendParams) => {
  const { mutateAsync: createContact } = useCreateContact(true)
  const { mutateAsync: createConversation } = useCreateConversation()

  const { refetch: refetchConversationsList } = useConversationsListContext()
  const { refetch: getContactConversations } = useGetContactConversations(
    {
      locationId,
      contactId: newConversationContact,
    },
    false
  )
  const { mutateAsync: sendMessage } = useCreateConversationEvent()
  const { storeAttachmentsAndGetS3Paths } = useAttachmentsUpload()

  const handleOnSend = useCallback<HandleSendMessage>(
    async (inputText, medias, sendVCard) => {
      const shouldCreateContact = !(
        newConversationContact && newConversationContactChannelId
      )
      const phoneNumber = unFormatPhoneNumber(newConversationInputValue)
      let channelId = newConversationContactChannelId
      let contactId = newConversationContact
      let conversationId: number | undefined = undefined

      logger.debug('Handling onSend', { shouldCreateContact, phoneNumber })

      try {
        if (shouldCreateContact && phoneNumber) {
          const contactOrId = await createContact({
            firstName: phoneNumber,
            lastName: '',
            phoneNumber,
            locationId,
          })

          if (contactOrId) {
            if (typeof contactOrId === 'number') {
              contactId = contactOrId
            } else if ('id' in contactOrId) {
              contactId = contactOrId.id
              channelId = contactOrId.channels?.[0]?.id
            }
          }

          logger.debug('Created contact', {
            contactId,
            channelId,
          })

          if (!contactId || !channelId) {
            throw new Error(
              'Missing contactId or channelId. (New Conversation Modal)'
            )
          }

          conversationId = await createConversation({
            contactId,
            locationId,
          })
        }

        if ((inputText || medias.length) && channelId && contactId) {
          if (!conversationId) {
            const { data = [] } = await getContactConversations()

            conversationId = data[0]?.id
          }

          if (!conversationId) {
            throw new Error('Missing conversationId. (New Conversation Modal)')
          }

          let mediaUrls: string[] = await storeAttachmentsAndGetS3Paths(
            locationId,
            conversationId,
            medias
          )

          await sendMessage({
            channelId,
            message: inputText,
            type: ConversationEventTypeEnum.SMS_EVENT,
            conversationId,
            locationId,
            sendVCard,
            mediaUrls,
          })

          await refetchConversationsList()

          handleTabConversationChange({
            mode: 'conversation',
            conversationId,
          })

          closeModal()
        }
      } catch (error: unknown) {
        logger.error('Something happened. (New Conversation Modal)', {
          locationId,
          contactId: newConversationContact,
          message: (error as Error).message,
        })

        toast.error('Message not sent, Something went wrong. Please try again.')
      }
    },
    [
      newConversationContact,
      newConversationContactChannelId,
      newConversationInputValue,
      createContact,
      locationId,
      createConversation,
      sendMessage,
      storeAttachmentsAndGetS3Paths,
      refetchConversationsList,
      handleTabConversationChange,
      closeModal,
      getContactConversations,
    ]
  )

  return handleOnSend
}

export const useFormattedTimestamp = (date: Date) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, 60_000)

    return () => void clearInterval(intervalId)
  }, [])

  return useMemo(() => {
    if (differenceInMinutes(currentDate, new Date(date)) < 1) return 'now'

    const numMonths = differenceInMonths(currentDate, new Date(date))
    const overrideDateUnitQuarter = numMonths >= 3 && numMonths < 12

    return intlFormatDistance(new Date(date), currentDate, {
      numeric: 'always',
      style: 'narrow',
      unit: overrideDateUnitQuarter ? 'month' : undefined,
    })
  }, [currentDate, date])
}
