// https://github.com/import-js/eslint-plugin-import/issues/1810
import axios, { AxiosResponse } from 'axios'
// eslint-disable-next-line import/no-unresolved
import { parse as csvParse } from 'csv-parse/browser/esm'

import { Role, AuthorizedUser } from 'src/api'
import {
  AllSettledResponse,
  LocationInterface,
  LoopConfigurationsInterface,
  UserInterface,
} from 'src/client/interfaces/Common'
import Constants from 'src/lib/Constants'

/**
 * Method to determine if the user is an internal user
 * @param user
 * @returns whether the user has internal user roles to be considered internal user
 */
export const isInternalUser = (user: AuthorizedUser) =>
  (user.roles || []).some((role) =>
    [Role.INTERNAL_ADMIN, Role.INTERNAL_READONLY].includes(role)
  )

/**
 * Method to determine if the user is an internal user
 * @param user
 * @returns whether the user has internal user roles to be considered internal user
 */
export const isLegacyInternalUser = (user: UserInterface) =>
  (user.roles || []).some((role) =>
    ['ROLE_DEV', 'ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER'].includes(role)
  )

/**
 * Method to determine if the location account type corresponds to an agency or partnership
 * @param accountType
 * @returns whether the location is an agency or partnership
 */
export const isAgencyLocation = (accountType: string) => {
  return (
    accountType === Constants.ACCOUNT_TYPES.AGENCY ||
    accountType === Constants.ACCOUNT_TYPES.PARTNERSHIP
  )
}

/**
 * Method to get the active location based on current page locationId
 * @param userLocations
 * @param pageLocationId
 * @deprecated
 * @returns the active location
 *
 * On routes with a location ID, we'll use that one; on user routes,
 * (which might be multi-location), we'll use the first location in the array of user locations
 */
export const getLegacyActiveLocation = (
  userLocations?: LocationInterface[],
  pageLocationId?: string
): LocationInterface | undefined => {
  const found = userLocations?.find(
    (l) => l.locationId.toString() === pageLocationId
  )
  const first = userLocations?.[0]

  return found || first
}

export const getLocationInformation = (location?: LocationInterface) => {
  const hasTwilioNumber = !!location?.forwardingPhone
  // Currently the location object does not includes SCS Billing status
  // therefore we will not know when the location has an expired Securus subscription.
  // For now, we are using securusPhoneNumber as the indicator that the location
  // has a Securus subscription
  const hasSecurusSubscription = !!location?.securusPhoneNumber
  const hasActiveSignpostSubscription = [
    'Future',
    'Active',
    'Past Due',
  ].includes(location?.currentSubscriptionStatus ?? '')
  const isLiveReceptionistOnly =
    !hasActiveSignpostSubscription && hasSecurusSubscription
  const isDualClient = hasSecurusSubscription && hasActiveSignpostSubscription
  const isFreeTrialist = location?.currentServiceTier === 'Free Trial'
  const accountCreationDate = location?.createdAt

  return {
    /** True when the location has a forwarding phone */
    hasTwilioNumber,
    /**
     *   Currently the location object does not includes SCS Billing status
     * therefore we will not know when the location has an expired Securus subscription.
     *
     * For now, we are using securusPhoneNumber as the indicator that the location
     * has a Securus subscription
     */
    hasSecurusSubscription,
    /** True when `location.currentSubscriptionStatus` is any of the following:
     * - Future
     * - Active
     * - Past Due
     */
    hasActiveSignpostSubscription,
    /** True when `hasActiveSignpostSubscription` is false and `hasSecurusSubscription` is true */
    isLiveReceptionistOnly,
    /** True when `hasActiveSignpostSubscription` and `hasSecurusSubscription` are true */
    isDualClient,
    isFreeTrialist,
    accountCreationDate,
  }
}

export const isFeatureCodeEnabled = (
  featureCode: string,
  location?: LocationInterface
) => {
  return location?.features.some(
    (feature) => feature.featureCode === featureCode && feature.enabled
  )
}

/**
 * Method get if a phone number is valid
 * @param phoneNumber
 * @returns whether the phone number is valid
 *
 * This is replica of the same method in core.
 * https://github.com/signpost/core/blob/a5e79b057ea2ff1618e81babb37f607f3a198c4c/node_modules/essentials/Validation.js#L167
 */
export const isPhoneNumberValid = (phoneNumber?: string) => {
  return !!(
    phoneNumber &&
    /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phoneNumber)
  )
}

/**
 * Method get if a zip code is valid
 * @param zipCode
 * @returns whether the zip code is valid
 *
 * This is modification of the same method in core with an update to allow zipcodes with leading zeros
 * https://github.com/signpost/core/blob/a5e79b057ea2ff1618e81babb37f607f3a198c4c/node_modules/essentials/Validation.js#L147
 */
export const isZipCodeValid = (zipCode?: string) =>
  !!(zipCode && /^[0-9][0-9][0-9][0-9][0-9]$/.test(zipCode.trim()))

const validUSStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const isUsStateValid = (state?: string) =>
  !!state && validUSStates.includes(state)

/**
 * Method to get a location preferred terms
 * @param location
 * @returns the consumer terms of a location or the defaults
 */
export const getLocationPreferredTerms = (location?: {
  loopConfigurations: LoopConfigurationsInterface[]
}) => {
  const defaults = {
    consumerTerms: Constants.CONSUMER_TERMINOLOGY.consumer.CUSTOMER,
  }

  if (!location) {
    return defaults
  }

  const defaultLoopConfig = location.loopConfigurations.find(
    (lc) => lc.isLocationDefault
  )

  if (!defaultLoopConfig) {
    return defaults
  }

  const preferredConsumerTerm = defaultLoopConfig.preferredConsumerTerm

  return {
    consumerTerms:
      Constants.CONSUMER_TERMINOLOGY.consumer[preferredConsumerTerm],
  }
}

export const getLifecycleDisplayLabel = (
  location: {
    loopConfigurations: LoopConfigurationsInterface[]
  },
  value: (typeof Constants.CUSTOMERS.LifecycleStage)[keyof typeof Constants.CUSTOMERS.LifecycleStage]['value']
) => {
  const {
    consumerTerms: { singularCaps: preferredTerm },
  } = getLocationPreferredTerms(location)

  switch (value) {
    case Constants.CUSTOMERS.LifecycleStage.ACTIVE_CUSTOMER.value:
      return `Active ${preferredTerm}`

    case Constants.CUSTOMERS.LifecycleStage.INACTIVE_CUSTOMER.value:
      return `Inactive ${preferredTerm}`

    case Constants.CUSTOMERS.LifecycleStage.LEAD.value:
      return `Prospective ${preferredTerm}`

    case Constants.CUSTOMERS.LifecycleStage.UNHAPPY_CONTACT.value:
      return `Unhappy ${preferredTerm}`

    case Constants.CUSTOMERS.LifecycleStage.UNKNOWN.value:
    default:
      return `Unknown ${preferredTerm}`
  }
}

/**
 * Method to generate a random string
 * @param length
 * @returns a random string with length provided. Ths string contains alphanumeric values
 * that can be either lower or upper case
 */
export const getRandomString = (length: number) => {
  let result = ''
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const getRandomPhoneNumber = () =>
  `7705${Math.floor(100000 + Math.random() * 900000)}`

/**
 * Converts the provided string to kebab-case (by default).
 * @param str
 * @param separator defaults to `-`
 * @returns slugyfied string
 */
export const slugify = (str: string, separator = '-') =>
  str.split(' ').filter(Boolean).join(separator).toLowerCase()

/**
 * Method to reduce a response from a Promise.allSettled
 * @param results
 * @returns an array with the fulfilled and rejected promises. See example on how to use.
 * @example
 * ```ts
 * const [fulfilled, rejected] = reduceAllSettledResponse(responses)

    if (rejected.length && !fulfilled.length) {
      throw new Error('There are some issues')
    }
 * ```
 */
export const reduceAllSettledResponse = <T = unknown>(
  results: PromiseSettledResult<T>[]
): AllSettledResponse<T> => {
  return results.reduce(
    (a, c) => {
      if (c.status === 'fulfilled') {
        a[0].push(c)
      } else if (c.status === 'rejected') {
        a[1].push(c)
      }

      return a
    },
    [[], []] as AllSettledResponse<T>
  )
}

/**
 * Method to know if current browser is Safari
 * @param browserNavigator
 * @returns whether the current browser is safari
 */
export const isSafari = (browserNavigator: {
  vendor: string
  userAgent: string
}) => {
  const check =
    browserNavigator.vendor &&
    browserNavigator.vendor.indexOf('Apple') > -1 &&
    browserNavigator.userAgent &&
    browserNavigator.userAgent.indexOf('CriOS') === -1 &&
    browserNavigator.userAgent.indexOf('FxiOS') === -1

  return !!check
}

/**
 * Method to know if current browser is Firefox
 * @param browserNavigator
 * @returns whether the current browser is Firefox
 */
export const isFirefox = (browserNavigator: { userAgent: string }) =>
  browserNavigator.userAgent.toLowerCase().indexOf('firefox') !== -1

/**
 * Method to detect if user is using an iOs or Android based device
 * @param browserNavigator
 * @returns the name of the OS the user is using
 */
export const detectOS = (browserNavigator: { userAgent: string }) => {
  const userAgent = browserNavigator.userAgent

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios'
  }

  return 'unknown'
}

/**
 * Method to create trigger downloading a string as a file
 * @param filename
 * @param data
 * @param type
 */
export const downloadStringAsFile = (
  filename: string,
  data: string,
  type = 'text/plain'
) => {
  const element = document.createElement('a')
  const file = new Blob([data], { type })

  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element)
  element.click()
  element.remove()
}

export const delay = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms))

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

type ParseCsvReturnType<T extends boolean = false> = T extends true
  ? Record<string, string>
  : string[][]

export const parseCsv = <T extends boolean = false>(
  str: string,
  asObject?: T
): Promise<ParseCsvReturnType<T>> => {
  return new Promise((resolve, reject) => {
    csvParse(
      str,
      {
        columns: asObject,
      },
      (err, data) => {
        if (err) {
          reject(err)

          return
        }

        resolve(data as ParseCsvReturnType<T>)
      }
    )
  })
}

export const getFileText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (event.target) {
        const fileContent = event.target.result

        if (typeof fileContent === 'string') {
          resolve(fileContent)

          return
        }
      }

      reject(new Error('File content is not string'))

      return
    }

    reader.readAsText(file)
  })

export const getBlob = (url: string): Promise<AxiosResponse<Blob>> =>
  axios.get<Blob>(url, {
    responseType: 'blob',
  })

export * from './helpers/urlGenerators'
export * from './helpers/formatters'
export * from './helpers/cookies'
