import React from 'react'
import styled, { useTheme } from 'styled-components'

import SectionContainer, { SectionAction } from 'src/stories/SectionContainer'

const StyledContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(1),
}))

const StyledContentLabel = styled.p(({ theme }) => ({
  color: theme.colors.base_100,
  fontSize: '1.6rem',
  lineHeight: '2rem',
  fontWeight: 500,
  margin: 0,
}))

const StyledContentValue = styled.p<{ unspacedLongText?: boolean }>(
  ({ theme, unspacedLongText }) => ({
    color: theme.colors.base_60,
    fontSize: '1.4rem',
    lineHeight: '2rem',
    fontWeight: 400,
    margin: 0,
    overflowWrap: unspacedLongText ? 'anywhere' : undefined,
  })
)

interface Props {
  title: string
  items: {
    label: string
    value: string
    unspacedLongText?: boolean
  }[]
  onClick: () => void
}

const ReviewContent: React.FC<Props> = ({ onClick, items, title }) => {
  const theme = useTheme()

  return (
    <SectionContainer
      title={title}
      action={
        <SectionAction
          label="Edit"
          baseDataAttribute={`sms-registration-edit-${title}`}
          onClick={onClick}
        />
      }
    >
      <SectionContainer
        variant="primary"
        childrenStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.space(4),
        }}
      >
        {items.map((item) => (
          <StyledContent key={item.label}>
            <StyledContentLabel>{item.label}</StyledContentLabel>
            <StyledContentValue unspacedLongText={item.unspacedLongText}>
              {item.value}
            </StyledContentValue>
          </StyledContent>
        ))}
      </SectionContainer>
    </SectionContainer>
  )
}

export default ReviewContent
