import { yupResolver } from '@hookform/resolvers/yup'
import {
  addMinutes,
  startOfDay,
  isSameDay,
  format,
  parse,
  isValid,
} from 'date-fns'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { OperatingHour, OperatingHours } from 'src/api'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import { StyledContent } from 'src/components/Settings/Business/styled'
import { StyledForm } from 'src/components/Settings/common/layout'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import Select from 'src/stories/Select'

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.space(4),
  maxWidth: theme.space(90),
}))

const StyledSelectContainer = styled.div({
  display: 'flex',
  alignItems: 'start',
})

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const

type DaysOfWeek = (typeof DAYS_OF_WEEK)[number]

const PERIOD_HOUR = {
  CLOSED: 'Closed',
  OPEN_24_HOURS: 'Open 24 Hours',
  OPEN_24_HOURS_FROM_TIME: '12:00',
  OPEN_24_HOURS_TO_TIME: '23:59',
}

const isOpen24Hours = (day: OperatingHour) =>
  day.from === PERIOD_HOUR.OPEN_24_HOURS_FROM_TIME &&
  day.to === PERIOD_HOUR.OPEN_24_HOURS_TO_TIME

const isPeriodHour = (value: string) =>
  [PERIOD_HOUR.CLOSED, PERIOD_HOUR.OPEN_24_HOURS].includes(value)

const UI_DATE_FORMAT = 'h:mm aa'
const BACKEND_DATE_FORMAT = 'HH:mm'

export const displayHoursFormatter = (businessHours: OperatingHours) => {
  return businessHours.map((businessHour, index) => {
    const formattedDay = {
      dayOfWeek: DAYS_OF_WEEK[businessHour.dayOfWeek],
      label: PERIOD_HOUR.CLOSED,
    }

    if (isOpen24Hours(businessHour)) {
      formattedDay.label = PERIOD_HOUR.OPEN_24_HOURS

      return formattedDay
    }

    if (!businessHour.isInvalid) {
      const from = parse(businessHour.from, BACKEND_DATE_FORMAT, new Date())
      const to = parse(businessHour.to, BACKEND_DATE_FORMAT, new Date())

      if (isValid(from) && isValid(to)) {
        const fromFormatted = format(from, UI_DATE_FORMAT)
        const toFormatted = format(to, UI_DATE_FORMAT)

        formattedDay.label = `${fromFormatted} - ${toFormatted}`
      }
    }

    return formattedDay
  })
}

const validHoursIntervalOptions = () => {
  const validHours = [
    {
      label: PERIOD_HOUR.CLOSED,
      value: PERIOD_HOUR.CLOSED,
    },
    {
      label: PERIOD_HOUR.OPEN_24_HOURS,
      value: PERIOD_HOUR.OPEN_24_HOURS,
    },
  ]
  const today = new Date()
  let date = startOfDay(today)

  while (isSameDay(today, date)) {
    validHours.push({
      label: format(date, UI_DATE_FORMAT),
      value: format(date, BACKEND_DATE_FORMAT),
    })
    date = addMinutes(date, 30)
  }

  return validHours
}

const hoursFormSchema = yup.object({
  daysOfWeek: yup
    .array()
    .length(7)
    .of(
      yup.object({
        from: yup
          .string()
          .default('')
          .test(
            'isSameTime',
            'Open time cannot match close time.',
            (value, context) =>
              isPeriodHour(value)
                ? true
                : value !== (context.parent as DayHour).to
          ),
        to: yup.string().default(''),
      })
    ),
})

type DayHour = Pick<OperatingHour, 'from' | 'to' | 'dayOfWeek'>
interface HoursFormSchema {
  daysOfWeek: [DayHour, DayHour, DayHour, DayHour, DayHour, DayHour, DayHour]
}

interface HoursFormProps extends MutationBusinessInfoProps {
  baseDataAttribute: string
}

const HoursForm: React.FC<HoursFormProps> = ({
  baseDataAttribute,
  isPending,
  update,
  onSubmitFinished,
}) => {
  const { activeLocation } = useLocationContext()
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<HoursFormSchema>({
    resolver: yupResolver(hoursFormSchema),
    defaultValues: {
      daysOfWeek: activeLocation.operatingHours.map<DayHour>((hour) => {
        if (isOpen24Hours(hour)) {
          return {
            from: PERIOD_HOUR.OPEN_24_HOURS,
            to: PERIOD_HOUR.OPEN_24_HOURS,
            dayOfWeek: hour.dayOfWeek,
          }
        }

        if (hour.isClosed || hour.isInvalid) {
          return {
            from: PERIOD_HOUR.CLOSED,
            to: PERIOD_HOUR.CLOSED,
            dayOfWeek: hour.dayOfWeek,
          }
        }

        return {
          from: hour.from,
          to: hour.to,
          dayOfWeek: hour.dayOfWeek,
        }
      }) as HoursFormSchema['daysOfWeek'],
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      const operatingHours = data.daysOfWeek.map<OperatingHour>((day) => ({
        from:
          day.from === PERIOD_HOUR.OPEN_24_HOURS
            ? PERIOD_HOUR.OPEN_24_HOURS_FROM_TIME
            : day.from === PERIOD_HOUR.CLOSED
            ? ''
            : day.from,
        to:
          day.to === PERIOD_HOUR.OPEN_24_HOURS
            ? PERIOD_HOUR.OPEN_24_HOURS_TO_TIME
            : day.to === PERIOD_HOUR.CLOSED
            ? ''
            : day.to,
        dayOfWeek: day.dayOfWeek,
        isClosed:
          day.from === PERIOD_HOUR.CLOSED && day.to === PERIOD_HOUR.CLOSED,
      })) as OperatingHours

      await update({
        operatingHours,
      })

      onSubmitFinished()
    } catch (error: unknown) {
      // TODO: Figure any server validation errors
      // const response = (error as UnknownObject | undefined)?.data as
      //   | ({ [path: string]: string } & { propertyName: string[] })
      //   | undefined

      // if (response?.propertyName && response.propertyName.length > 0) {
      //   response?.propertyName?.forEach((propertyPath) => {
      //     const inputIndex = +propertyPath.match(/[0-6]/)![0]
      //     const target = propertyPath.match(/from|to/)![0] as 'from' | 'to'
      //     const fieldName = `daysOfWeek.${inputIndex}.${target}`

      //     if (response[propertyPath])
      //       setError(fieldName as 'daysOfWeek.0', {
      //         type: 'server',
      //         message: response[propertyPath],
      //       })
      //   })
      // } else {
      toast.error(
        'There was an error trying to save, please review the information and try again'
      )
      // }
    }
  })

  const disableForm = isSubmitting || isPending

  const selectProps = {
    verticallySpace: 4,
    disabled: disableForm,
    options: validHoursIntervalOptions(),
  }

  return (
    <StyledForm data-cy={`${baseDataAttribute}-form-hours`} onSubmit={onSubmit}>
      <StyledContent>
        <StyledContainer data-cy={`${baseDataAttribute}-form-hours`}>
          {DAYS_OF_WEEK.map((day: DaysOfWeek, idx: number) => {
            return (
              <StyledSelectContainer key={day}>
                <Controller
                  name={`daysOfWeek.${idx}` as 'daysOfWeek.0'}
                  control={control}
                  render={({
                    field,
                    fieldState: { isTouched, isDirty, error },
                    formState: { errors },
                  }) => {
                    return (
                      <>
                        <Select
                          {...selectProps}
                          label={`${day}:`}
                          initialValue={field.value.from}
                          errorText={errors.daysOfWeek?.[idx]?.from?.message}
                          onChange={(value) => {
                            field.onChange({
                              from: value,
                              to:
                                isPeriodHour(value) ||
                                isPeriodHour(field.value.to)
                                  ? value
                                  : field.value.to,
                              dayOfWeek: field.value.dayOfWeek,
                            })
                          }}
                          containerStyle={{
                            width: '50%',
                          }}
                          inputStyle={
                            isPeriodHour(field.value.from)
                              ? undefined
                              : {
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  marginRight: 0,
                                }
                          }
                        />
                        <Select
                          {...selectProps}
                          label={`${day}:`}
                          initialValue={field.value.to}
                          errorText={errors.daysOfWeek?.[idx]?.to?.message}
                          onChange={(value) => {
                            field.onChange({
                              from: isPeriodHour(value)
                                ? value
                                : field.value.from,
                              to: value,
                              dayOfWeek: field.value.dayOfWeek,
                            })
                          }}
                          hideLabel
                          inputStyle={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            marginLeft: 0,
                          }}
                          containerStyle={{
                            width: '50%',
                            visibility: isPeriodHour(field.value.from)
                              ? 'hidden'
                              : undefined,
                          }}
                        />
                      </>
                    )
                  }}
                />
              </StyledSelectContainer>
            )
          })}
        </StyledContainer>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-hours`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default HoursForm
