import {
  createColumnHelper,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Invoice, Pagination, Sort, useGetInvoice } from 'src/api'
import { BillingTotalCell } from 'src/containers/Settings/Billing/styled'
import { useLocationContext } from 'src/contexts/LocationContext'
import Pill from 'src/stories/Pill'
import { DownloadIcon } from 'src/stories/assets'
import logger from 'src/utils/logger'

const StyledDownloadContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.space(1),
  marginTop: theme.space(2),
}))

const Link = styled.a(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary_2,
  marginLeft: theme.space(1),
  display: 'inline',
  textDecorationLine: 'underline',
  cursor: 'pointer',
  textTransform: 'capitalize',
}))

const InvoiceDownload: React.FC<{ invoiceId: number }> = ({ invoiceId }) => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const { data: invoice, refetch: getInvoice } = useGetInvoice({
    locationId,
    invoiceId,
    enabled: false,
  })

  useEffect(() => {
    const openInvoice = () => {
      if (invoice) {
        if (invoice.signedUrl) {
          window.open(invoice.signedUrl)

          return
        }

        logger.error('Invoice not found')
      }
    }

    openInvoice()
  }, [invoice])

  const onClick = async () => {
    await getInvoice()
  }

  return (
    <StyledDownloadContainer>
      <DownloadIcon fill={theme.colors.primary_2} height={16} />
      <Link onClick={onClick}>{`INV${invoiceId}`}</Link>
    </StyledDownloadContainer>
  )
}

export const useBillingTable = (
  data: Invoice[],
  setSearchParams: ReturnType<typeof useSearchParams>[1],
  pagination: Required<Pagination>,
  totalItems: number,
  sorting?: Sort
) => {
  const theme = useTheme()
  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip / pagination.take,
      pageSize: pagination.take,
    }),
    [pagination.skip, pagination.take]
  )

  const tableSorting = useMemo<SortingState>(
    () =>
      sorting
        ? [
            {
              id: sorting.field ?? '',
              desc: sorting.direction === 'DESC',
            },
          ]
        : [],
    [sorting]
  )

  const take = pagination.take ?? 1
  const pageCount = Math.ceil(totalItems / take)

  const columnHelper = createColumnHelper<Invoice>()

  //---------------------------------------------------------------
  // Action handlers - Searching, sorting, and filtering functions
  // that update state on a user action
  //---------------------------------------------------------------

  const handleSort = (sort: Updater<SortingState>) => {
    const sortUpdate = functionalUpdate(sort, tableSorting)

    table.resetPagination(true)

    if (sortUpdate.length) {
      const { id, desc } = sortUpdate[0]

      setSearchParams((current) => {
        current.set('direction', desc ? 'DESC' : 'ASC')
        current.set('field', id)

        return current
      })
    } else {
      setSearchParams((current) => {
        current.delete('direction')
        current.delete('field')

        return current
      })
    }
  }

  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set('take', _pageSize.toString())
      current.set('skip', (_pageSize * _pageIndex).toString())

      return current
    })
  }

  const columns = [
    columnHelper.accessor('endDate', {
      header: 'date',
      cell: (info) => `${format(new Date(info.getValue()), 'MM/dd/yyyy')}`,
    }),
    columnHelper.accessor(
      (row) =>
        `${format(new Date(row.startDate), 'MM/dd/yyyy')} - ${format(
          new Date(row.endDate),
          'MM/dd/yyyy'
        )}`,
      {
        header: 'billing period',
        enableSorting: false,
        cell: (info) => info.getValue(),
      }
    ),
    columnHelper.accessor('included', {
      header: 'Plan',
      enableSorting: false,
      cell: (info) => `${info.getValue() || 0} min plan`,
    }),
    columnHelper.accessor('payAmount', {
      header: 'Total',
      cell: (info) => (
        <BillingTotalCell>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(info.getValue() || 0)}
          {info.row.original.paid === 'Paid' && (
            <Pill
              text="PAID"
              color="accent_3"
              backgroundColor="accent_3_15"
              hideDot
              paddingXUnits={1}
              fontSize="1rem"
              width={theme.space(8)}
            />
          )}
        </BillingTotalCell>
      ),
    }),
    columnHelper.display({
      header: 'Invoice',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!original.s3Path) {
          return (
            <StyledDownloadContainer>
              Contact support for invoice
            </StyledDownloadContainer>
          )
        }

        return <InvoiceDownload invoiceId={original.id} />
      },
    }),
  ]

  const table = useReactTable<Invoice>({
    columns,
    data,
    debugTable: process.env.REACT_APP_ENVIRONMENT !== 'prod',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    onSortingChange: handleSort,
    pageCount,
    state: {
      pagination: tablePagination,
      sorting: tableSorting,
    },
  })

  return { table, columnsCount: columns.length }
}
