import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Button } from 'src/stories/Button'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

interface Props {
  baseDataAttribute: string
  onClickDelete?: () => void
  onClick?: () => void
}

const LocationUserItemActions: React.FC<Props> = ({
  baseDataAttribute,
  onClickDelete,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <Container>
      <Button
        baseDataAttribute={`${baseDataAttribute}-delete`}
        displayAsText
        label="Delete"
        style={{ height: theme.space(8) }}
        onClick={onClickDelete}
        action="danger"
      />
      <Button
        baseDataAttribute={`${baseDataAttribute}-edit`}
        displayAsText
        label="Edit"
        style={{ height: theme.space(8) }}
        onClick={onClick}
      />
    </Container>
  )
}

export default LocationUserItemActions
