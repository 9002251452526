import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useGetLocationByContactCenterId } from 'src/api'
import LoadingSpinner from 'src/stories/LoadingSpinner'

const ContactCenterRedirect = () => {
  const { contactCenterId } = useParams()

  const { data, isLoading } = useGetLocationByContactCenterId({
    contactCenterId,
  })

  if (isLoading) return <LoadingSpinner />

  if (!data) return <Navigate to="/login" />

  return <Navigate to={`/${data.id}/messaging-hub`} />
}

export default ContactCenterRedirect
