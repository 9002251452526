import React from 'react'
import { Routes as ReactRoutes, Route } from 'react-router-dom'

import Logout from 'src/components/Logout'
import Signup from 'src/containers/Signup'

const UserlessRoutes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/logout" element={<Logout />} />
    </ReactRoutes>
  )
}

export default UserlessRoutes
