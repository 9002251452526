import styled from 'styled-components'

import { StyledItem } from 'src/stories/Dropdown'
import { ExpandableContentCustomTrigger } from 'src/stories/ExpandableContent'
import { ArrowDownIcon } from 'src/stories/assets'
import { Body } from 'src/stories/typography'

const StyledContainer = styled(StyledItem)(({ theme }) => ({
  width: theme.space(58),
  alignItems: 'center',
}))

const ArrowIconRight = styled(ArrowDownIcon)(() => ({
  transform: `rotate(270deg)`,
}))

const StyledArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
  stroke: theme.colors.base_50,
}))

const StyledArrowRight = styled(ArrowIconRight)(({ theme }) => ({
  stroke: theme.colors.base_50,
}))

const CustomTrigger: (
  name: string,
  dataCy: string,
  isSelected: boolean
) => ExpandableContentCustomTrigger =
  (name, dataCy, isSelected: boolean) => (toggle, expanded) => {
    return (
      <StyledContainer data-cy={dataCy} onClick={toggle}>
        <Body
          fontWeight={isSelected ? 'bolder' : 'medium'}
          color={isSelected ? 'dark' : 'medium'}
        >
          {name}
        </Body>
        {!!expanded ? <StyledArrowDown /> : <StyledArrowRight />}
      </StyledContainer>
    )
  }

export default CustomTrigger
