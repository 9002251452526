import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateContactTagParams {
  tagId: number
}

export const useUpdateContactTag = (locationId: number, contactId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateContactTagParams) => {
      const client = await getClient()

      await client.patch(
        `/locations/${locationId}/contacts/${contactId}/tags/${params.tagId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'contact',
          {
            locationId,
            contactId,
          },
        ],
      })
    },
  })
}
