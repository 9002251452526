import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import EditButton from './EditButton'
import { ContactChannelRow, ContactSection } from './styled'
import { ContactFormType } from './useContactForm'
import { Channel, Contact, useGetContact } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { Body } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(1),
}))

const BodyWrapper = styled(Container)(({ theme }) => ({
  padding: theme.space(5),
  gap: theme.space(4),
}))

interface Props {
  contactId: number
  onContactName: (name: string) => void
}

const splitContacts = (contact?: Contact) => {
  const primaryPhoneNumber = contact?.phoneNumber
  const primaryEmailAddress = contact?.emailAddress

  return (contact?.channels ?? []).reduce<{
    emails: Channel[]
    phones: Channel[]
  }>(
    (a, c) => {
      if (c.emailAddress) {
        if (c.emailAddress === primaryEmailAddress) {
          a.emails.unshift(c)
        } else {
          a.emails.push(c)
        }
      }
      if (c.phoneNumber) {
        if (c.phoneNumber === primaryPhoneNumber) {
          a.phones.unshift(c)
        } else {
          a.phones.push(c)
        }
      }

      return a
    },
    { emails: [], phones: [] }
  )
}

const ViewContact: React.FC<Props> = ({ contactId, onContactName }) => {
  const { locationId } = useLocationContext()

  const { data: contact, isFetching } = useGetContact({ locationId, contactId })

  const { emails, phones } = useMemo(() => splitContacts(contact), [contact])

  const sections = [
    {
      label: 'Name:',
      columns: [
        {
          id: 0,
          formType: 'name' as ContactFormType,
          el: (
            <>
              {[contact?.firstName, contact?.lastName]
                .filter(Boolean)
                .join(' ')}
            </>
          ),
        },
      ],
    },

    {
      label: 'Email:',
      columns: emails.map((channel) => ({
        id: channel.id,
        formType: 'email' as ContactFormType,
        el: (
          <a href={`mailto:${channel.emailAddress!}`}>
            {channel.emailAddress!}
          </a>
        ),
      })),
    },
    {
      label: 'Phone:',
      columns: phones.map((channel) => ({
        id: channel.id,
        formType: 'phone' as ContactFormType,
        el: (
          <a href={`tel:${channel.phoneNumber!}`}>
            {formatPhoneNumber(channel.phoneNumber!)}
          </a>
        ),
      })),
    },
  ]

  useEffect(() => {
    if (contact) {
      onContactName(
        [contact.firstName, contact.lastName].filter(Boolean).join(' ')
      )
    }
  }, [contact, onContactName])

  if (isFetching) {
    return <LoadingSpinner />
  }

  if (!contact) {
    return null
  }

  return (
    <BodyWrapper>
      {sections.map((row) => (
        <Container key={row.label}>
          <Body as="p" size="medium" fontWeight="bolder" color="darker">
            {row.label}
          </Body>
          <ContactSection key={row.label} data-cy="contact-section">
            {row.columns.map((column) => (
              <ContactChannelRow key={column.id} data-cy="con-cha-row">
                <Body as="p" size="medium" ellipsis>
                  {column.el}
                </Body>

                <EditButton
                  contact={contact}
                  channelId={column.id}
                  formType={column.formType}
                />
              </ContactChannelRow>
            ))}
          </ContactSection>
        </Container>
      ))}
    </BodyWrapper>
  )
}

export default ViewContact
