import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { LocationList, LocationUserList } from 'src/api'
import { useCreateUserList } from 'src/api/hooks/mutations/useCreateUserListMutation'
import { useDeleteUserList } from 'src/api/hooks/mutations/useDeleteUserListMutation'
import Toggle from 'src/stories/Toggle'
import { Body } from 'src/stories/typography'
import logger from 'src/utils/logger'

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.space(2),
}))

interface Props {
  locationId: number
  userId: number
  listsUserBelongsTo: LocationUserList[]
  listsOfLocation: Record<number, LocationList>
}

const LocationUserDetailPanelListsItem: React.FC<Props> = ({
  locationId,
  userId,
  listsOfLocation,
  listsUserBelongsTo,
}) => {
  const { isPending: isDeleting, mutateAsync: deleteUserFromList } =
    useDeleteUserList(locationId)
  const { isPending: isAdding, mutateAsync: addUserToList } =
    useCreateUserList(locationId)
  const lists = useMemo(() => {
    return listsUserBelongsTo.reduce<Record<number, LocationUserList>>(
      (acc, list) => {
        acc[list.listId] = list

        return acc
      },
      {}
    )
  }, [listsUserBelongsTo])

  const toggle = async (listId: number, isInList: boolean) => {
    try {
      if (isInList) {
        await deleteUserFromList({ listId, userId })
      }

      if (!isInList) {
        await addUserToList({ listId, userId })
      }
    } catch (error) {
      logger.error('MH - Failed to toggle user list', {
        error,
        listId,
        userId,
        isInList,
        locationId,
      })
      toast.error('Failed to update user list')
    }
  }

  return (
    <Wrapper>
      {Object.values(listsOfLocation).map((list) => {
        const isInList = !!lists[list.id]

        return (
          <Container key={`list-${list.id}`}>
            <Body>{list.name}</Body>
            <Toggle
              dataCy="location-user-lists-item-toggle"
              checked={isInList}
              onChange={() => toggle(list.id, isInList)}
              disabled={isAdding || isDeleting}
            />
          </Container>
        )
      })}
    </Wrapper>
  )
}

export default LocationUserDetailPanelListsItem
