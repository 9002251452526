import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.p<{ hasSubtitle: boolean }>(
  ({ theme, hasSubtitle }) => ({
    color: theme.colors.base_100,
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
    fontWeight: 500,
    marginBottom: theme.space(hasSubtitle ? 2 : 8),
  })
)

const StyledSubtitle = styled.p(({ theme }) => ({
  color: theme.colors.base_50,
  fontSize: '1.4rem',
  lineHeight: '2.4rem',
  marginBottom: theme.space(6),
}))

interface Props {
  title: string
  subtitle?: string | JSX.Element
  baseDataAttribute: string
}

const PageLayout: React.FCWithChildren<Props> = ({
  title,
  subtitle,
  baseDataAttribute,
  children,
}) => {
  return (
    <>
      <StyledTitle
        data-cy={`${baseDataAttribute}-title`}
        hasSubtitle={!!subtitle}
      >
        {title}
      </StyledTitle>
      {subtitle && (
        <StyledSubtitle data-cy={`${baseDataAttribute}-subtitle`}>
          {subtitle}
        </StyledSubtitle>
      )}
      {children}
    </>
  )
}

export default PageLayout
