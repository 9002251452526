import React from 'react'
import styled, { useTheme } from 'styled-components'

import ConnectionIndicator from './ConnectionIndicator'
import { IntegrationMappingStatus } from 'src/api'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import { Body, Heading } from 'src/stories/typography'

const TitleContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
}))

interface SharedIntegrationSettingsProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
  description: string | JSX.Element
  status?: IntegrationMappingStatus
}

const SharedIntegrationSettings: React.FCWithChildren<
  SharedIntegrationSettingsProps
> = ({ icon: Icon, title, description, children, status }) => {
  const theme = useTheme()
  const iconWidth = theme.space(10)

  return (
    <PageSectionContainer
      action={false}
      variant="inverted"
      isRounded
      title={
        <TitleContainer>
          <Icon width={iconWidth} height={iconWidth} />
          <Heading as="h3" size="medium">
            {title}
          </Heading>
          <ConnectionIndicator status={status} />
        </TitleContainer>
      }
      childrenStyle={{
        gap: theme.space(4),
      }}
    >
      {typeof description === 'string' ? (
        <Body>{description}</Body>
      ) : (
        description
      )}

      {children}
    </PageSectionContainer>
  )
}

export default SharedIntegrationSettings
