import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { IntegrationFailure, Paginated } from 'src/api/types'

export interface GetIntegrationFailuresParams {
  locationId: number
}

export const useGetIntegrationFailures = ({
  locationId,
}: GetIntegrationFailuresParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'integration-failures',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<Paginated<IntegrationFailure>>(
            `/locations/${params.locationId}/integrations/failures`
          )
        )
        .then((r) => r.data)
    },
  })
}
