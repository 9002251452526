import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { ContentWrapper, PageContainer } from './styled'
import {
  useGetAccounts,
  useGetAccountLocations,
  useGetUserAccounts,
} from 'src/api'
import useAuthContext from 'src/contexts/AuthContext'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import Select from 'src/stories/Select'
import { CompanyLogo } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatAddress, isInternalUser } from 'src/utils'

const Accounts = () => {
  const { user } = useAuthContext()
  const theme = useTheme()
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const navigate = useNavigate()
  const [selectedAccountId, setSelectedAccountId] = useState<number>()
  const [selectedLocationId, setSelectedLocationId] = useState<number>()
  const internalUser = isInternalUser(user)

  const { data: allAccounts = [], isLoading: isLoadingAllAccounts } =
    useGetAccounts(internalUser)
  const { data: userAccounts = [], isLoading: isLoadingUserAccounts } =
    useGetUserAccounts(!internalUser)
  const { data: locations = [], isLoading: isLoadingLocations } =
    useGetAccountLocations(selectedAccountId)

  const isLoading = isLoadingAllAccounts || isLoadingUserAccounts

  if (isLoading) return <LoadingSpinner />

  const accounts = internalUser ? allAccounts : userAccounts

  const defaultLocationId = selectedLocationId ?? locations[0]?.id

  return (
    <PageContainer isDesktop={isDesktop}>
      <ContentWrapper>
        <CompanyLogo
          fill={theme.colors.base_0}
          style={{ alignSelf: 'center' }}
        />
        <Select
          height={theme.space(8)}
          dataCy="accounts-selector-account"
          disabled={isLoading}
          placeholder="Select an account"
          selectAllOnFocus
          label="Account"
          onChange={(id) => {
            setSelectedAccountId(id)
            setSelectedLocationId(undefined)
          }}
          searchable
          options={accounts.map((account) => ({
            label: account.name,
            value: account.id,
          }))}
        />
        {!isLoadingLocations && selectedAccountId && !!locations.length && (
          <Select
            key={`${selectedAccountId}-location-selector`}
            height={theme.space(8)}
            dataCy="accounts-selector-location"
            disabled={isLoading}
            initialValue={locations[0].id}
            label="Location"
            placeholder="Select a location"
            onChange={setSelectedLocationId}
            options={locations.map((location) => ({
              label: `${location.name} - ${formatAddress(
                location.addressStreet1,
                location.addressStreet2 ?? '',
                location.addressCity,
                location.addressState,
                location.addressZipCode
              )}`,
              value: location.id,
            }))}
          />
        )}
        {
          <Button
            action="primary"
            type="button"
            label="Navigate to location"
            baseDataAttribute="accounts-navigate-to-location"
            style={{ marginTop: theme.space(4) }}
            disabled={!(selectedAccountId && defaultLocationId)}
            onClick={() => {
              navigate(`/${defaultLocationId}/messaging-hub`)
            }}
          />
        }
      </ContentWrapper>
    </PageContainer>
  )
}

export default Accounts
