import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateContactParams {
  firstName: string
  lastName: string
}

export const useUpdateContact = (locationId: number, contactId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateContactParams) => {
      const client = await getClient()

      await client.patch(`/locations/${locationId}/contacts/${contactId}`, {
        firstName: params.firstName,
        lastName: params.lastName,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['contact', { locationId, contactId }],
      })
    },
  })
}
