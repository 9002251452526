import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'

import SharedIntegrationSettings from './SharedIntegrationSettings'
import {
  IntegrationMappingStatus,
  IntegrationMappingType,
  useCreateAngiLeadsIntegration,
  useGetIntegrationStatus,
} from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import { AngiLeadsIcon } from 'src/stories/assets'
import { Body } from 'src/stories/typography'

const formSchema = yup
  .object({
    leadId: yup.string().required('Angi Leads ID is required'),
  })
  .required()

type FormSchema = yup.InferType<typeof formSchema>

const AngiLeadsIntegrationSettings: React.FC = () => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const { data: angiLeadsIntegration } = useGetIntegrationStatus({
    locationId,
    type: IntegrationMappingType.ANGI_LEADS,
  })
  const { mutateAsync: createAngiLeadsIntegration } =
    useCreateAngiLeadsIntegration(locationId)

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async (data) => {
    await createAngiLeadsIntegration({
      leadId: data.leadId,
    })
  })

  return (
    <SharedIntegrationSettings
      icon={AngiLeadsIcon}
      title="Angi Leads"
      status={angiLeadsIntegration?.status}
      description={
        <>
          <Body size="large" fontWeight="bolder" color="darker">
            Get More Out of Your Angi Leads
          </Body>
          <Body color="dark" fontWeight="bold">
            When it comes to Angi's leads, speed matters. By using our Angi
            Leads Instant Response feature, you can engage every Angi's lead
            with a text instead of playing phone tag.
          </Body>
          <Body color="dark" fontWeight="bold">
            This feature will kickstart the conversation so they are less likely
            to move onto the competition, resulting in more booked business.
          </Body>

          {!angiLeadsIntegration?.status && (
            <Body>
              Please enter your Angi Leads ID below and submit it. Our Support
              team will make the connection within 1-2 business days and follow
              up via email.
            </Body>
          )}
          {angiLeadsIntegration?.status ===
            IntegrationMappingStatus.PENDING && (
            <Body>Currently awaiting approval</Body>
          )}
        </>
      }
    >
      {!angiLeadsIntegration?.status && (
        <form onSubmit={onSubmit} style={{ width: '33%' }}>
          <Input
            label="Your Angi Leads ID"
            disabled={isSubmitting}
            errors={formErrors}
            {...register('leadId')}
          />

          <Button
            action="primary"
            type="submit"
            maxWidth={theme.space(40)}
            label="Request"
            disabled={isSubmitting}
          />
        </form>
      )}
    </SharedIntegrationSettings>
  )
}

export default AngiLeadsIntegrationSettings
