import { RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'

import { ContactsResource } from 'src/client'
import { Segment } from 'src/client/interfaces/Segments'
import AddContactListForm from 'src/components/Contacts/AddContactListForm'
import useAccountContext from 'src/contexts/AccountContext'
import useMhContext from 'src/contexts/MhContext'
import {
  SegmentInterfaceWithIsPrimary,
  sortSegments,
} from 'src/contexts/MhContext/utils'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import Dropdown from 'src/stories/Dropdown'
import Input from 'src/stories/Input'
import Pill from 'src/stories/Pill'
import Tooltip from 'src/stories/Tooltip'
import { UseLocationRouteParams } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.space(2),
}))

const StyledDropdownContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.space(3)}`,
}))

const StyledDropdownTitle = styled.h2(({ theme }) => ({
  color: theme.colors.base_40,
  fontSize: '1.1rem',
  fontWeight: 500,
  margin: `${theme.space(4)} 0`,
}))

type Size = 'small' | 'large'

const StyledDropdownItemContainer = styled.div<{ size?: Size }>(
  ({ theme, size = 'small' }) => ({
    display: 'flex',
    alignSelf: 'start',
    margin: `${theme.space(size === 'small' ? 2 : 3)} 0`,
  })
)

const StyledDropdownItem = styled.p<{ size?: Size }>(
  ({ theme, size = 'small' }) => ({
    color: theme.colors.base_50,
    fontSize: size === 'small' ? '1.2rem' : '1.4rem',
    fontWeight: 400,
    margin: 0,
    marginLeft: theme.space(2),
  })
)

const StyledDropdownFooter = styled.div(({ theme }) => ({
  display: 'flex',
  marginTop: theme.space(2),
  padding: `${theme.space(3)} 0`,
  borderTop: `1px solid ${theme.colors.base_5}`,
}))

const generateDataCy = (label: string) => `mh-contact-details-list-${label}`

interface SegmentsListProps {
  segments: Segment[]
  contactId: number
  contactSegments: number[]
  primarySegmentId: number
  smallList: boolean
  reloadCustomer: () => Promise<void>
}

const SegmentsList: React.FC<SegmentsListProps> = ({
  segments,
  contactId,
  contactSegments,
  primarySegmentId,
  smallList,
  reloadCustomer,
}) => {
  const theme = useTheme()

  const [editSegments, setEditSegments] = useState<EditSegments[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleCheckSegment = async (checked: boolean, segmentId: number) => {
    setIsLoading(true)

    let idx: number

    setEditSegments((prev) => {
      const newState = [...prev]

      idx = prev.findIndex((p) => p.id === segmentId)
      newState[idx].checked = checked

      return newState
    })

    try {
      await ContactsResource.editCustomerFromSegment({
        segmentId,
        customerId: contactId,
        action: checked ? 'add' : 'remove',
      })

      await reloadCustomer()
    } catch (error) {
      setEditSegments((prev) => {
        const newState = [...prev]

        newState[idx].checked = !checked

        return newState
      })

      logger.error('MH - Error editing customer segment', { error })
      toast.error('There was an error trying to update the customer.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const sortedSegments = sortSegments(
      segments,
      contactSegments,
      primarySegmentId
    )

    const segmentsToEdit = sortedSegments.map((ss) => ({
      ...ss,
      checked: true,
    }))

    if (segmentsToEdit.length <= 4) {
      segments.forEach((s) => {
        if (!segmentsToEdit.some((se) => se.id === s.id)) {
          segmentsToEdit.push({ ...s, isPrimary: false, checked: false })
        }
      })
    }

    setEditSegments(segmentsToEdit)
  }, [contactSegments, primarySegmentId, segments])

  return (
    <>
      {editSegments.map((s, idx) => {
        return (
          ((smallList && idx <= 3) || !smallList) && (
            <StyledDropdownItemContainer
              size={smallList ? 'small' : 'large'}
              key={`contact-list-section-segments-full-${s.id}`}
            >
              <Input
                type="checkbox"
                name="segment-full-list-checkbox"
                style={{
                  width: theme.space(smallList ? 3 : 4),
                  height: theme.space(smallList ? 3 : 4),
                  margin: 0,
                }}
                containerStyle={{ margin: 0 }}
                checked={s.checked}
                data-cy={generateDataCy(`segment-full-list-checkbox-${idx}`)}
                disabled={isLoading}
                onChange={(e) => handleCheckSegment(e.target.checked, s.id)}
              />
              <StyledDropdownItem size={smallList ? 'small' : 'large'}>
                {s.name}
              </StyledDropdownItem>
            </StyledDropdownItemContainer>
          )
        )
      })}
    </>
  )
}

interface EditSegments extends SegmentInterfaceWithIsPrimary {
  checked: boolean
}

interface Props {
  contactId: number
  segments: Segment[]
  contactSegments: number[]
  primarySegmentId: number
  editRef: RefObject<HTMLButtonElement>
}

const ContactLists: React.FC<Props> = ({
  contactId,
  segments,
  contactSegments,
  primarySegmentId,
  editRef,
}) => {
  const { locationId } = useParams<UseLocationRouteParams>()
  const theme = useTheme()

  const { showModal } = useModalNotificationsContext()
  const { refetchOpenConversationContactDetails } = useMhContext()
  const { handleRefetchUserLocations } = useAccountContext()

  const [displaySegments, setDisplaySegments] = useState<
    SegmentInterfaceWithIsPrimary[]
  >([])

  useEffect(() => {
    const sortedSegments = sortSegments(
      segments,
      contactSegments,
      primarySegmentId
    )

    setDisplaySegments(sortedSegments)
  }, [contactSegments, primarySegmentId, segments])

  return (
    <Container>
      {displaySegments.map((item, idx) => {
        let Wrapper: React.FCWithChildren = ({ children }) => <>{children}</>

        if (item.isPrimary) {
          Wrapper = ({ children }) => (
            <Tooltip
              widthUnits={40}
              fontSize="1.2rem"
              text="This is the list the contact was added to last. Automatic campaigns will be sent to this contact based on the configuration of this list."
            >
              {children}
            </Tooltip>
          )
        }

        return (
          <Wrapper key={item.id}>
            <Pill
              text={item.name}
              hideDot={!item.isPrimary}
              dataCy={generateDataCy(idx.toString()) + '-pill'}
            />
          </Wrapper>
        )
      })}

      <Dropdown
        anchor={editRef}
        style={{ width: '85%', maxWidth: theme.space(75) }}
      >
        <StyledDropdownContainer>
          <StyledDropdownTitle>LISTS</StyledDropdownTitle>

          <SegmentsList
            segments={segments}
            contactId={contactId}
            contactSegments={contactSegments}
            primarySegmentId={primarySegmentId}
            smallList
            reloadCustomer={refetchOpenConversationContactDetails}
          />

          {segments.length > 4 && (
            <Button
              key={`contact-list-section-segments-view-all`}
              label="View all..."
              style={{ height: theme.space(7) }}
              displayAsText
              baseDataAttribute={generateDataCy('segments-view-all')}
              onClick={() =>
                showModal({
                  title: 'Contact Lists',
                  height: 'auto',
                  dataCy: generateDataCy('segments-view-all-modal'),
                  hideActionButtons: true,
                  width: theme.space(75),
                  customBody: (
                    <SegmentsList
                      segments={segments}
                      contactId={contactId}
                      contactSegments={contactSegments}
                      primarySegmentId={primarySegmentId}
                      smallList={false}
                      reloadCustomer={refetchOpenConversationContactDetails}
                    />
                  ),
                })
              }
            />
          )}

          <StyledDropdownFooter>
            <Button
              key={`contact-list-section-segments-view-all`}
              label="Create new list"
              style={{ height: theme.space(5) }}
              displayAsText
              baseDataAttribute={generateDataCy('segments-create-list')}
              onClick={() =>
                showModal({
                  dataCy: generateDataCy('segments-create-list-modal'),
                  title: 'Create a new list',
                  height: 'auto',
                  hideActionButtons: true,
                  width: theme.space(110),
                  customBody: (
                    <AddContactListForm
                      baseDataAttribute="mh-contact-details-list"
                      locationId={+locationId!}
                      onSubmitCallback={async (listId) => {
                        await ContactsResource.editCustomerFromSegment({
                          segmentId: listId,
                          customerId: contactId,
                          action: 'add',
                        })

                        await refetchOpenConversationContactDetails()
                        await handleRefetchUserLocations()
                      }}
                    />
                  ),
                })
              }
            />
          </StyledDropdownFooter>
        </StyledDropdownContainer>
      </Dropdown>
    </Container>
  )
}

export default ContactLists
