import styled from 'styled-components'

import { Row } from 'src/stories/Layout'
import { PaperClipIcon } from 'src/stories/assets'

interface StyledContainerProps {
  $isLargeDesktop: boolean
  $isDesktop: boolean
  withPadding: boolean
  $isSmallDesktop?: boolean
  $removeMargin?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>(
  ({
    $isLargeDesktop,
    $isDesktop,
    $isSmallDesktop,
    $removeMargin,
    theme,
    withPadding,
  }) => ({
    flexGrow: 0,
    padding: `0 ${theme.space(
      withPadding
        ? $isLargeDesktop
          ? 20
          : $isDesktop
          ? 16
          : $isSmallDesktop
          ? 5
          : 6
        : 0
    )}`,
    margin: $removeMargin ? 0 : `0 0 ${theme.space(6)}`,
    transition: 'padding 100ms',
    width: '100%',
  })
)

export const HelperButtonsSection = styled(Row)(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.space(2),
  height: theme.space(10),
  maxWidth: theme.space(169),
}))

interface MessageSendContainerProps {
  error: boolean
}
export const MessageSendContainer = styled.div<MessageSendContainerProps>(
  ({ theme, error }) => ({
    margin: `0 auto`,
    padding: `${theme.space(4)} ${theme.space(4)} ${theme.space(2)}`,
    width: '100%',
    maxWidth: theme.space(169),
    border: `2px solid ${
      error ? theme.colors.accent_2 : theme.colors.primary_2
    }`,
    borderRadius: theme.constants.borderRadius,
    minHeight: theme.space(21),
  })
)

interface AttachmentsContainerProps {
  disabled?: boolean
}
export const AttachmentsContainer = styled.div<AttachmentsContainerProps>(
  ({ disabled }) => ({
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      cursor: disabled ? 'default' : 'pointer',
    },
  })
)

export const MessageSendFooter = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: theme.space(8),
}))

interface StyledTextboxProps {
  height?: number
}
export const StyledTextbox = styled.textarea<StyledTextboxProps>(
  ({ theme, height }) => ({
    ':disabled': {
      cursor: 'not-allowed',
    },
    backgroundColor: theme.colors.base_0,
    width: '100%',
    minHeight: theme.space(height ? height : 5),
    marginBottom: theme.space(2),
    cursor: 'text',
    overflow: 'auto',
    border: 'none',
    resize: 'none',
    '&:focus': {
      outline: 'none',
    },
  })
)

export const StyledErrorMessage = styled.h4(({ theme }) => ({
  margin: `${theme.space(1)} auto`,
  color: theme.colors.accent_2,
  maxWidth: theme.space(169),
}))

interface StyledPaperClipIconProps {
  disabled?: boolean
}
export const StyledPaperClipIcon = styled(
  PaperClipIcon
)<StyledPaperClipIconProps>(({ theme, disabled }) => ({
  fill: disabled ? theme.colors.base_10 : theme.colors.primary_2,
}))
