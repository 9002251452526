import React, { ChangeEventHandler, useCallback, useState } from 'react'
import styled from 'styled-components'

// import { ArrowIcon } from 'src/stories/assets'
import Filter from 'src/components/Contacts/Filter'
import { useDebouncer } from 'src/hooks/useDebounce'
import Input from 'src/stories/Input'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const ToolbarContainer = styled.div(({ theme }) => ({
  display: 'grid',
  padding: theme.space(4),
  gap: theme.space(5),
  background: theme.colors.base_0,
  borderBottom: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
}))

const ToolbarOptions = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: isDesktop ? 'repeat(5, 1fr)' : '1fr',
    gap: theme.space(5),
  })
)

// const SelectWrapper = styled.div<{ isDesktop: boolean }>(
//   ({ theme, isDesktop }) => ({
//     gridColumn: isDesktop ? 5 : '1 / -1',
//     // NOTE this is a temporary solution until responsive
//     // design is applied to the toolbar
//     width: isDesktop ? theme.space(75) : theme.space(83),
//   })
// )

// const ArrowIconRight = styled(ArrowIcon)(() => ({
//   transform: `rotate(270deg)`,
// }))

export interface ToolbarProps {
  search?: string
  onSearch: (searchValue: string) => void
}

const Toolbar: React.FC<ToolbarProps> = ({ search, onSearch }) => {
  const baseDataAttribute = 'contacts-toolbar'
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const [showFilters] = useState(false)

  const handleContactSearch = useDebouncer<
    ChangeEventHandler<HTMLInputElement>
  >(
    useCallback(
      (e) => {
        onSearch(e.target.value.trim())
      },
      [onSearch]
    ),
    400
  )

  return (
    <ToolbarContainer data-cy={baseDataAttribute}>
      <ToolbarOptions isDesktop={isDesktop}>
        <Input
          placeholder="Search..."
          defaultValue={search || undefined}
          type="search"
          name="search"
          verticallySpaced={false}
          onChange={handleContactSearch}
        />
        {/* <Button
          label={showFilters ? 'Hide filters' : 'Show filters'}
          onClick={() => setShowFilters(!showFilters)}
          endIcon={ArrowIconRight}
          displayAsText
          action="primary"
          size="large"
          style={{ width: 'fit-content' }}
          baseDataAttribute={`${baseDataAttribute}-show-filters`}
        /> */}
        {showFilters && !isDesktop && <Filter />}
        {/* <SelectWrapper isDesktop={isDesktop}>
          <Select
            onChange={onSegmentSelect}
            initialValue={selectedSegment || -1}
            options={segments}
          >
            <Button
              label="Create new list"
              style={{ height: theme.space(8), width: '100%' }}
              displayAsText
              baseDataAttribute={`${baseDataAttribute}-create-list`}
              onClick={() =>
                showModal({
                  dataCy: `${baseDataAttribute}-create-list`,
                  title: 'Create a new list',
                  height: 'auto',
                  hideActionButtons: true,
                  width: theme.space(110),
                  headerTitleFontSize: '2rem',
                  customBody: (
                    <AddContactListForm
                      baseDataAttribute={`${baseDataAttribute}-create-list`}
                      locationId={locationId}
                      onSubmitCallback={(listId) => {
                        const newContactListUrl = generateLocationUrl(
                          merchantId,
                          locationId,
                          `customer-lists/${listId}`
                        )

                        navigate(newContactListUrl)
                      }}
                    />
                  ),
                })
              }
            />
          </Select>
        </SelectWrapper> */}
      </ToolbarOptions>
      {showFilters && isDesktop && <Filter />}
    </ToolbarContainer>
  )
}

export default Toolbar
