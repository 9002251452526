import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { ConversationEvent } from 'src/api/types'

export interface GetConversationEventParams {
  locationId: number
  conversationId: number | string
  conversationEventId: number
  enabled?: boolean
}

export const useGetConversationEvent = ({
  locationId,
  conversationId,
  conversationEventId,
  enabled,
}: GetConversationEventParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'conversation-event',
      {
        locationId,
        conversationId,
        conversationEventId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<ConversationEvent>(
            `/locations/${params.locationId}/conversations/${params.conversationId}/events/${params.conversationEventId}`
          )
        )
        .then((r) => r.data)
    },
    enabled:
      !!(
        locationId &&
        conversationId &&
        !isNaN(+conversationId) &&
        +conversationId > 0
      ) && enabled,
  })
}
