import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import {
  usePatchLocationUserEmailOrPhone,
  useResendVerificationEmail,
} from 'src/api'
import { Button } from 'src/stories/Button'
import Toggle from 'src/stories/Toggle'
import { Body } from 'src/stories/typography'
import logger from 'src/utils/logger'

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.space(2),
}))

const Link = styled.a(({ theme }) => ({
  color: theme.colors.primary_2,
  textDecoration: 'none',
}))

interface SharedProps {
  locationId: number
  userId: number
  id: number
  shouldReceiveLrSummaries: boolean
  shouldReceiveUpdates: boolean
  value: string
}

interface EmailProps extends SharedProps {
  itemType: 'email'
  canLogin: boolean
  isVerified?: boolean
}

interface PhoneProps extends SharedProps {
  itemType: 'phone'
  type: string
  carrier?: string
}

type Props = EmailProps | PhoneProps

const LocationUserDetailPanelItem: React.FC<Props> = ({
  locationId,
  userId,
  shouldReceiveLrSummaries,
  shouldReceiveUpdates,
  value,
  ...props
}) => {
  const { isPending, mutateAsync: patchLocationUserEmailOrPhone } =
    usePatchLocationUserEmailOrPhone(locationId, userId, props.itemType)
  const { isPending: isResendPending, mutateAsync: resendVerificationEmail } =
    useResendVerificationEmail(locationId, userId)

  const toggle = async (key: string, newValue: boolean) => {
    try {
      await patchLocationUserEmailOrPhone({
        id: props.id,
        [key]: newValue,
      })
    } catch (error) {
      logger.error('MH - Failed to update', {
        error,
        locationId,
        userId,
        type: props.itemType,
        itemId: props.id,
      })
      toast.error('Failed to update')
    }
  }

  return (
    <Wrapper>
      <Body as="span">
        <Link
          href={props.itemType === 'email' ? `mailto:${value}` : `tel:${value}`}
        >
          {value}
        </Link>
      </Body>
      {props.itemType === 'email' && props.canLogin && (
        <>
          {props.isVerified && (
            <Body color="dark" ellipsis>
              Email verified
            </Body>
          )}
          {!props.isVerified && (
            <Body as="span">
              <Button
                displayAsText
                action="primary"
                label="Resend verification email"
                disabled={isResendPending}
                onClick={() => resendVerificationEmail({ emailId: props.id })}
              />
            </Body>
          )}
        </>
      )}
      {props.itemType === 'email' && (
        <Container>
          <Body>Email can log in</Body>
          <Toggle
            dataCy="toggle"
            onChange={() => toggle('canLogin', !props.canLogin)}
            checked={props.canLogin}
            disabled={isPending}
          />
        </Container>
      )}
      {props.itemType === 'phone' && (
        <Body color="lighter" ellipsis>
          {props.type}
          {props.carrier && ` (${props.carrier})`}
        </Body>
      )}
      <Container>
        <Body>Signpost Updates</Body>
        <Toggle
          dataCy="toggle"
          onChange={() => toggle('shouldReceiveUpdates', !shouldReceiveUpdates)}
          checked={shouldReceiveUpdates}
          disabled={isPending}
        />
      </Container>
      <Container>
        <Body>Call Center Updates</Body>
        <Toggle
          dataCy="toggle"
          onChange={() =>
            toggle('shouldReceiveLrSummaries', !shouldReceiveLrSummaries)
          }
          checked={shouldReceiveLrSummaries}
          disabled={isPending}
        />
      </Container>
    </Wrapper>
  )
}

export default LocationUserDetailPanelItem
