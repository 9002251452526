import React from 'react'

import { ConversationEvent, isConversationEvent } from 'src/api'
import { Body } from 'src/stories/typography'

interface Props {
  conversationEvent: ConversationEvent
}

const PhoneCallModalMessageBody: React.FC<Props> = ({ conversationEvent }) => {
  if (isConversationEvent(conversationEvent, 'LR_SUMMARY_EVENT')) {
    return (
      <>
        {conversationEvent.details?.displayFieldsJson?.map((field, id) => {
          const strValue = (field.value as string | undefined) ?? ''

          return (
            <React.Fragment key={id}>
              <Body as="p" color="darker" fontWeight="bold">
                {field.label as string}:{' '}
              </Body>
              <Body as="p" color="dark">
                {strValue}
              </Body>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const message = isConversationEvent(conversationEvent, 'PHONE_CALL_EVENT')
    ? (conversationEvent.details?.status ?? '').replaceAll('-', ' ')
    : isConversationEvent(conversationEvent, 'LR_VOICEMAIL_EVENT')
    ? 'Live Receptionist Voicemail'
    : undefined

  const body = message
    ? message.charAt(0).toUpperCase() + message.slice(1)
    : conversationEvent.message

  return <Body>{body}</Body>
}

export default PhoneCallModalMessageBody
