import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { InsightsWidget } from './InsightsWidget'
import { StyledWidgetContentContainer, SummaryRowItem } from './styled'
import { WidgetProps } from './types'
import useInsightsContext from 'src/contexts/InsightsContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'

const StyledChartContainer = styled.div(({ theme }) => ({
  display: 'inline-grid',
}))

const LRCallsByWeekdayWidget: React.FC<WidgetProps> = ({
  baseDataAttribute,
}) => {
  const theme = useTheme()
  const { liveReceptionistReportingData, isLiveReceptionistLoading } =
    useInsightsContext()
  const { numOfCalls, callsByWeekday } = liveReceptionistReportingData

  if (isLiveReceptionistLoading) {
    return <LoadingSpinner logoHeight={theme.space(15)} />
  }

  const data = Object.entries(callsByWeekday).map(
    ([key, value]: [string, number]) => {
      return { name: key, y: value }
    }
  )

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    plotOptions: {
      bar: {
        pointWidth: 20,
        dataLabels: {
          enabled: true,
        },
      },
    },
    chart: {
      type: 'column',
      height: '50%',
    },
    xAxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    yAxis: {
      visible: false,
      title: {
        text: 'Num of Calls',
      },
    },
    series: [
      { type: 'column', name: 'Calls', data, color: theme.colors.primary_2 },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  }

  return (
    <InsightsWidget
      baseDataAttribute={baseDataAttribute}
      title="Calls by Weekday"
    >
      <StyledWidgetContentContainer
        data-cy={baseDataAttribute + '-weekday-chart'}
      >
        <StyledChartContainer>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <SummaryRowItem
            label="Incoming calls"
            baseDataAttribute={baseDataAttribute}
            value={numOfCalls}
            color={'primary_2'}
            isZeroState={false}
            decreaseMargin
          />
        </StyledChartContainer>
      </StyledWidgetContentContainer>
    </InsightsWidget>
  )
}

export default LRCallsByWeekdayWidget
