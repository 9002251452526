declare global {
  interface Window {
    /**
     * Facebook SDK
     * - Further reading: https://developers.facebook.com/docs/javascript/reference/v20.0
     */
    FB: {
      /**
       * https://developers.facebook.com/docs/javascript/reference/FB.init/v20.0
       */
      init: (params: {
        appId: string
        cookie: boolean
        status?: boolean
        xfbml: boolean
        version: string
      }) => void
      /**
       * https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus
       */
      getLoginStatus: (
        cb: (response: FacebookLoginStatus, force?: boolean) => void
      ) => void
      /**
       * https://developers.facebook.com/docs/reference/javascript/FB.login/v20.0
       */
      login: (
        cb: (response: FacebookLoginStatus) => void,
        opts?: FacebookLoginOptions
      ) => void
      /**
       * https://developers.facebook.com/docs/reference/javascript/FB.logout
       */
      logout: (cb: () => void) => void
      /**
       * https://developers.facebook.com/docs/javascript/reference/FB.api
       */
      api: (
        path: string,
        method: 'get' | 'post' | 'delete',
        params: Record<string, unknown>,
        cb: (response: unknown) => void
      ) => void
    }
  }
}

interface FacebookLoginOptions {
  scope: string
  return_scopes?: boolean
  enable_profile_selector?: boolean
  profile_selector_ids?: string
}

interface FacebookLoginStatus {
  /**
   * - connected - The user is logged into Facebook and has authenticated the application
   * - not_authorized - The user is logged into Facebook but has not authenticated the application
   * - unknown - The user is not logged into Facebook
   */
  status: 'connected' | 'not_authorized' | 'unknown'
  authResponse?: {
    accessToken: string
    expiresIn: number
    signedRequest: string
    userID: string
  }
}

export const initFacebookSdk = () => {
  window.FB.init({
    appId: process.env.REACT_APP_FACEBOOK_APP_ID as string,
    cookie: true,
    xfbml: true,
    version: 'v20.0',
  })
}

export const getFacebookLoginStatus = () => {
  return new Promise<FacebookLoginStatus>((resolve) => {
    window.FB.getLoginStatus((response) => {
      resolve(response)
    })
  })
}

export const fbLogin = (opts?: FacebookLoginOptions) => {
  return new Promise<FacebookLoginStatus>((resolve) => {
    window.FB.login((response) => {
      resolve(response)
    }, opts)
  })
}

export const fbLogout = () => {
  return new Promise<void>((resolve) => {
    window.FB.logout(() => {
      resolve()
    })
  })
}

export const fbApi = (
  path: string,
  method: 'get' | 'post' | 'delete' = 'get',
  params: Record<string, unknown> = {}
) => {
  return new Promise((res) => {
    window.FB.api(path, method, params, (response) => {
      res(response)
    })
  })
}
