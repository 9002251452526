import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { IntegrationMappingType } from 'src/api/types'

interface CreateAngiLeadsIntegrationParams {
  leadId: string
}

export const useCreateAngiLeadsIntegration = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateAngiLeadsIntegrationParams) => {
      const client = await getClient()

      await client.post(`locations/${locationId}/integrations/angi-leads`, {
        externalId: params.leadId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'integration-status',
          {
            locationId,
            type: IntegrationMappingType.ANGI_LEADS,
          },
        ],
      })
    },
  })
}
