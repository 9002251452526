import React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledHelperWidgetContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.primary_2_15,
  border: `2px solid ${theme.colors.primary_1}`,
  borderRadius: theme.constants.borderRadius,
  padding: `${theme.space(3)} ${theme.space(4)}`,
}))

const StyledIconContainer = styled.div(({ theme }) => ({
  marginBottom: theme.space(2),
}))

const StyledTitle = styled.h4(({ theme }) => ({
  color: theme.colors.primary_1,
  fontSize: '1.6rem',
  marginBottom: theme.space(2),
  marginTop: 0,
}))

const StyledCopy = styled.span(({ theme }) => ({
  color: theme.colors.primary_1,
  fontSize: '1.4rem',
  lineHeight: '2rem',
  margin: 0,

  ul: {
    paddingLeft: theme.space(6),
  },
}))

export interface HelperWidgetProps {
  copy?: string | React.ReactNode
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title?: string
}

/**
 * Helper widget component
 */
export const HelperWidget: React.FCWithChildren<HelperWidgetProps> = ({
  children,
  copy,
  icon: Icon,
  title,
}) => {
  const theme = useTheme()

  return (
    <StyledHelperWidgetContainer>
      {!!Icon && (
        <StyledIconContainer>
          <Icon
            data-cy="helperwidget-icon"
            fill={theme.colors.primary_1}
            height={32}
            width={32}
          />
        </StyledIconContainer>
      )}
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <StyledCopy>{copy}</StyledCopy>
      {children}
    </StyledHelperWidgetContainer>
  )
}
