import React, { ErrorInfo } from 'react'

import { awsRum } from 'src/containers/Vendors/rum'
import logger from 'src/utils/logger'

type Props = React.PropsWithChildren
interface State {
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
      let errInfo = 'Not set'

      try {
        errInfo = JSON.stringify(errorInfo)
      } catch {}
      logger.error('Unhandled error', { error, info: errInfo })
      awsRum?.recordError(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
