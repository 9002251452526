import { AxiosInstance } from 'axios'
import { format, subMonths } from 'date-fns'

import { InsightsV3Requests } from 'src/client/interfaces/InsightsV3'
import Resource from 'src/client/resource'
import { delay, getRandomInt } from 'src/utils'

const actions = (client: AxiosInstance): InsightsV3Requests => {
  return {
    getInstantResponsesTotalAndReplied: async (locationId) => {
      if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
        await delay(1000)

        return dummyData.instantResponsesTotalAndReplied
      }

      return client.get(
        `/v3/locations/${locationId}/insights/instant-responder-total-replied`
      )
    },
    getContactsFromLeadSources: async (locationId) => {
      if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
        await delay(1000)

        return dummyData.contactsFromLeadSources
      }

      return client.get(
        `/v3/locations/${locationId}/insights/contacts-from-lead-sources`
      )
    },
    getInboundLeadsPerMonth: async (locationId) => {
      if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
        await delay(1000)

        return dummyData.inboundLeadsPerMonth
      }

      return client.get(`/v3/locations/${locationId}/insights/leads/monthly`)
    },
  }
}

// Initializing dummy data on page load to avoid inconsistent
// regeneration upon invalidation
const dummyData: {
  instantResponsesTotalAndReplied: Awaited<
    ReturnType<InsightsV3Requests['getInstantResponsesTotalAndReplied']>
  >
  contactsFromLeadSources: Awaited<
    ReturnType<InsightsV3Requests['getContactsFromLeadSources']>
  >
  inboundLeadsPerMonth: Awaited<
    ReturnType<InsightsV3Requests['getInboundLeadsPerMonth']>
  >
} = (() => {
  const totalInstantResponsesSent = getRandomInt(0, 100)
  const totalInstantResponsesRepliedByUser = getRandomInt(
    0,
    totalInstantResponsesSent
  )

  const leadSources = ['Facebook', 'Google', 'Angi Leads', 'Thumbtack']

  const currentDate = new Date()

  // see InboundLeadsPerMonthWidget
  const amountOfMonthsBack = 5

  return {
    instantResponsesTotalAndReplied: {
      totalInstantResponsesSent,
      totalInstantResponsesRepliedByUser,
    },
    contactsFromLeadSources: {
      data: leadSources.map((source) => ({
        amount: getRandomInt(0, 10),
        source,
      })),
      total: leadSources.length,
    },
    inboundLeadsPerMonth: {
      data: Array.from({ length: amountOfMonthsBack }, (_, index) => ({
        totalContacts: getRandomInt(0, 20),
        addedPeriod: format(
          subMonths(currentDate, amountOfMonthsBack - 1 - index),
          'y-M'
        ),
      })),
    },
  }
})()

export default Resource(actions)
