import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UseResendVerificationEmailMutationParams {
  emailId: number
}

export const useResendVerificationEmail = (
  locationId: number,
  userId: number
) => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: UseResendVerificationEmailMutationParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${locationId}/users/${userId}/email-addresses/${params.emailId}/verification-email`
      )
    },
    onSuccess: () => {
      toast.success('Email verification sent')
    },
    onError: (error) => {
      toast.error(
        'There was an error sending email verification. Please try again.'
      )
    },
  })
}
