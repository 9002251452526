import { AxiosHeaders } from 'axios'

export const getIdFromLocationHeader = (headers: object) => {
  if (headers instanceof AxiosHeaders && headers.has('location')) {
    const location = headers['location'] as string

    const id = location.split('/').pop()

    return id
  }
}
