import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosHeaders } from 'axios'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateLocationListParams {
  name: string
  type: 'USER' | 'CONTACT'
  userId?: number
}

export const useCreateLocationList = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateLocationListParams) => {
      const client = await getClient()

      const { headers } = await client.post(`/locations/${locationId}/lists`, {
        name: params.name,
        type: params.type,
      })

      if (headers instanceof AxiosHeaders && headers.has('location')) {
        const location = headers['location'] as string

        const listId = location.split('/').pop()

        if (listId && params.userId) {
          await client.put(
            `/locations/${locationId}/lists/${listId}/users/${params.userId}`,
            {}
          )
        }
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-lists', { locationId }],
      })
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
