import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { UserPosition } from 'src/api/types'

interface PatchLocationUserMutationParams {
  position?: UserPosition
  notes?: string
}

export const usePatchLocationUser = (locationId: number, userId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: PatchLocationUserMutationParams) => {
      const client = await getClient()

      await client.patch(`/locations/${locationId}/users/${userId}`, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
