import styled from 'styled-components'

import { Contact } from 'src/api'
import { Body, Heading } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

interface ModalNewContactRecordProps {
  phoneNumber: string
  newContactClickHandler: (contactNumber: string) => Promise<void>
}

interface ModalContactRecordProps {
  contact: Contact
  contactClickHandler: (contact: Contact) => Promise<void>
}

const ContactBox = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_3,
  border: `1px solid ${theme.colors.base_20}`,
  padding: `${theme.space(3)} ${theme.space(5)}`,
  borderRadius: theme.space(1),
  marginBottom: theme.space(2),
  cursor: 'pointer',
}))

const Row = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'normal',
  gap: theme.space(1),
}))

const Value = styled(Body)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.colors.primary_2,
}))

const Col = styled.div({
  width: '100%',
})

export const ModalNewContactRecord = ({
  phoneNumber,
  newContactClickHandler,
}: ModalNewContactRecordProps) => {
  const formattedNumber = formatPhoneNumber(phoneNumber)

  return (
    <ContactBox onClick={() => newContactClickHandler(formattedNumber)}>
      <Col>
        <Heading>{formattedNumber}</Heading>
      </Col>
    </ContactBox>
  )
}

const ModalContactRecord = ({
  contact,
  contactClickHandler,
}: ModalContactRecordProps) => {
  const { firstName, lastName, phoneNumber, emailAddress } = contact

  return (
    <ContactBox onClick={(e) => contactClickHandler(contact)}>
      <Col>
        <Heading>{`${firstName} ${lastName}`}</Heading>
        {phoneNumber && (
          <Row>
            <Body fontWeight="bold" customColor="base_100">
              Phone:
            </Body>
            <Value>{phoneNumber}</Value>
          </Row>
        )}
        {emailAddress && (
          <Row>
            <Body fontWeight="bold" customColor="base_100">
              Email:
            </Body>
            {<Value>{emailAddress}</Value>}
          </Row>
        )}
      </Col>
    </ContactBox>
  )
}

export default ModalContactRecord
