import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

import LoadingSpinner from 'src/stories/LoadingSpinner'

const Logout: React.FC = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    void logout({ logoutParams: { returnTo: window.location.origin } })
  }, [logout])

  return <LoadingSpinner />
}

export default Logout
