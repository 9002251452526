import { AxiosInstance } from 'axios'

import {
  BetaFlagsRequests,
  BetaFlagResponse,
} from 'src/client/interfaces/BetaFlags'
import Resource from 'src/client/resource'
import Constants from 'src/lib/Constants'
import logger from 'src/utils/logger'

const actions = (client: AxiosInstance): BetaFlagsRequests => ({
  getBetaFlags: async (params) => {
    try {
      return client.post(Constants.Backend.Endpoints.BETA_FLAGS, {
        requestedFlags: params,
      })
    } catch (error) {
      logger.error('Error fetching BetaFlags. Returning default values.', {
        params,
        error,
      })

      return params.map<BetaFlagResponse>((param) => ({
        ...param,
        enabled: false,
      }))
    }
  },
})

export default Resource(actions)
