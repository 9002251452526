import React from 'react'
import styled from 'styled-components'

import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const FooterContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

const Spacer = styled.hr(({ theme }) => ({
  marginTop: theme.space(8),
  width: '100%',
  opacity: '20%',
}))

const StyledButtonLink = styled(Button)(({ theme }) => ({
  display: 'flex',
  fontWeight: 600,
  fontSize: '1.2rem',
  color: theme.colors.primary_2,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
}))

const FooterList = styled.ul<{
  $row?: boolean
}>(({ $row }) => ({
  display: 'flex',
  flexDirection: $row ? 'row' : 'column',
  listStyle: 'none',
  paddingLeft: '0rem',
  textAlign: 'left',
}))

const Link = styled.a(({ theme }) => ({
  fontWeight: 500,
  fontSize: '1.2rem',
  lineHeight: '2.6rem',
  color: theme.colors.primary_2,
  display: 'inline',
  textDecoration: 'none',
}))

const FooterText = styled.li(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: '400',
  lineHeight: '3.2rem',
  textDecoration: 'none',
  color: theme.colors.base_50,
}))

const CopyrightText = styled.p(({ theme }) => ({
  height: theme.space(8),
  fontSize: '1rem',
  fontWeight: '500',
  color: theme.colors.base_50,
  marginLeft: theme.space(2),
  marginBottom: theme.space(1),
  width: '90%',
}))

const Footer: React.FC = () => {
  const links = Constants.FooterLinks

  const { isSmallScreen } = useScreenSizes()

  const currentYear = new Date().getFullYear()

  return (
    <FooterContainer>
      <Spacer />
      <FooterList $row={isSmallScreen}>
        {links.map(({ text, url, dataAttribute, openInNewTab }) => (
          <StyledButtonLink
            key={text}
            baseDataAttribute={dataAttribute}
            label={text}
            href={url}
            asLink
            shouldOpenInNewTab={openInNewTab}
          />
        ))}

        <FooterText>
          For help, email{' '}
          <Link
            href="mailto: ccsupport@signpost.com"
            data-cy="footer-email-link"
          >
            ccsupport@signpost.com
          </Link>{' '}
          or call{' '}
          <Link href="tel: (844) 202-2015" data-cy="footer-phone-link">
            (844) 202-2015
          </Link>
        </FooterText>
      </FooterList>
      <CopyrightText>
        &copy; {currentYear} {Constants.Branding.copyRightText}
      </CopyrightText>
    </FooterContainer>
  )
}

export default Footer
