import { formatRelative } from 'date-fns'
import React, { memo } from 'react'
import styled from 'styled-components'

import { CompanyIcon } from 'src/stories/assets'

const StyledMessageHeader = styled.div(({ theme }) => ({
  marginTop: theme.space(6),
  display: 'flex',
  justifyContent: 'space-between',
}))

const StyledMessageHeaderTitle = styled.span(({ theme }) => ({
  marginLeft: theme.space(1),
  fontSize: '1.4rem',
  fontWeight: 500,
  color: theme.colors.primary_1,
  verticalAlign: 'top',
}))

const StyledMessageHeaderTimestamp = styled.span(({ theme }) => ({
  alignSelf: 'center',
  fontSize: '1.0rem',
  fontWeight: 500,
  color: theme.colors.base_40,
}))

const MessageHeader = memo(() => {
  return (
    <StyledMessageHeader>
      <div>
        <CompanyIcon width={18} height={18} />
        <StyledMessageHeaderTitle>Signpost</StyledMessageHeaderTitle>
      </div>
      <StyledMessageHeaderTimestamp>
        {formatRelative(new Date(), new Date())}
      </StyledMessageHeaderTimestamp>
    </StyledMessageHeader>
  )
})

export default MessageHeader
