import styled from 'styled-components'

import { StatusIcon } from './assets'

export interface StatusIconProps {
  /** The Hex color. #e11e3eff */
  $hexColor: string
  /**
   * The percentage of the color that will be lighter.
   * Positive values will make the color lighter.
   * Negative values will make the color darker.
   *
   * @default 0.6
   */
  lighterPercentage?: number
}

const StatusIconComponent = styled(StatusIcon)<StatusIconProps>(
  ({ theme, $hexColor, lighterPercentage = 0.6 }) => {
    const { r, g, b, ...rgba } = theme.colorMethods.hexToRgba($hexColor)
    const { h, s, l, a } = theme.colorMethods.rgbaToHsla(r, g, b, rgba.a)
    const hsla = theme.colorMethods.formatHsla(
      h,
      s,
      l * (1 + lighterPercentage),
      a
    )

    return {
      stroke: hsla,
      fill: $hexColor,
    }
  }
)

export default StatusIconComponent
