import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Contact, ListResourceParams, Paginated } from 'src/api/types'

export type GetContactsParams = ListResourceParams

export const useGetContacts = (
  { locationId, pagination, search, sort }: GetContactsParams,
  enabled = true
) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'contacts',
      {
        locationId,
        pagination,
        search,
        sort,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      const searchParams: Record<string, unknown> = {}

      if (
        typeof params.pagination.skip !== 'undefined' &&
        typeof params.pagination.take !== 'undefined'
      ) {
        searchParams.skip = params.pagination.skip
        searchParams.take = params.pagination.take
      }
      if (params.sort?.direction && params.sort.field) {
        searchParams.direction = params.sort?.direction
        searchParams.field = params.sort.field
      }
      if (params.search) {
        searchParams.search = params.search
      }

      return getClient()
        .then((client) =>
          client.get<Paginated<Contact>>(
            `/locations/${params.locationId}/contacts`,
            {
              params: searchParams,
            }
          )
        )
        .then((r) => r.data)
    },
    enabled,
  })
}
