import styled from 'styled-components'

export const ContactSection = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

export const ContactChannelRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
  justifyContent: 'space-between',
}))
