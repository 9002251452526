import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import CustomTrigger from './CustomTrigger'
import LocationNavigationItem from './LocationNavigationItem'
import ReturnToMultiLocationView from './ReturnToMultiLocationView'
import { useMultiLocationHeaderHooks } from './hooks'
import { useLocationContext } from 'src/contexts/LocationContext'
import { hiddenNavbarPagesRegex } from 'src/lib/Constants'
import Dropdown from 'src/stories/Dropdown'
import ExpandableContent from 'src/stories/ExpandableContent'
import { StyledHeader as NavbarHeader } from 'src/stories/Navbar'
import { ArrowDownIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatAddress } from 'src/utils'

const StyledHeader = styled(NavbarHeader)(({ theme }) => ({
  height: theme.heights.multiLocationHeader,
  borderBottom: `1px solid ${theme.colors.base_100}`,
}))

const StyledLabel = styled.span<{ $isButton?: boolean }>(
  ({ $isButton, theme }) => ({
    color: theme.colors.base_10,
    fontSize: '1.2rem',
    cursor: $isButton ? 'pointer' : 'text',
  })
)

const StyledDimmedLabel = styled.span(({ theme }) => ({
  color: theme.colors.base_30,
  fontSize: '1.2rem',
}))

const StyledArrow = styled.button(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  paddingRight: 0,
  marginRight: 0,
  marginLeft: theme.space(2),
  marginBottom: theme.space(0.5),
  cursor: 'pointer',
  color: theme.colors.base_10,
}))

const StyledDropdownInnerContainer = styled.div<{
  $size: number
}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space(2)} 0`,
  overflowY: 'scroll',
  maxHeight: 'calc(100vh / 3 * 2)',
}))

const StyledNavigationContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

interface MultiLocationHeaderProps {
  dark?: boolean
  isInternalUser?: boolean
}

const MultiLocationHeader: React.FC<MultiLocationHeaderProps> = ({
  dark,
  isInternalUser,
}) => {
  const { dimmedLabel, label, locations, title } =
    useMultiLocationHeaderHooks(isInternalUser)
  const theme = useTheme()
  const { pathname } = useLocation()
  const { locationId } = useLocationContext()
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const currentPageIsLocations = /^\/\d+\//.test(pathname)

  const navRef = useRef<HTMLButtonElement>(null)

  if (hiddenNavbarPagesRegex.some((r) => r.test(pathname))) {
    return null
  }

  return (
    <StyledHeader data-cy="ml-header-container" $dark={dark}>
      <StyledLabel>{isDesktop ? title : ''}</StyledLabel>
      {
        <StyledNavigationContainer>
          {dimmedLabel && isDesktop && (
            <StyledDimmedLabel>{dimmedLabel}&nbsp;</StyledDimmedLabel>
          )}
          <StyledLabel $isButton onClick={() => navRef.current?.click()}>
            {label}
          </StyledLabel>
          <StyledArrow ref={navRef} data-cy="multi-location-dropdown-arrow">
            <ArrowDownIcon stroke={theme.colors.base_0} />
          </StyledArrow>
        </StyledNavigationContainer>
      }
      <Dropdown
        anchor={navRef}
        style={{
          overflow: 'hidden',
        }}
      >
        <StyledDropdownInnerContainer $size={locations.length}>
          {currentPageIsLocations && <ReturnToMultiLocationView />}
          {locations.map((location) => (
            <ExpandableContent
              key={location.name}
              organizeAs="column"
              removeScroll
              customTrigger={CustomTrigger(
                location.name,
                'location-navigation-parent',
                location.locationAddresses.some((l) => l.id === locationId)
              )}
            >
              {location.locationAddresses.map((l, idx) => (
                <LocationNavigationItem
                  key={idx}
                  dataCy={`location-navigation-item-${idx}`}
                  address={formatAddress(
                    l.addressStreet1,
                    l.addressStreet2 ?? '',
                    l.addressCity,
                    l.addressState,
                    l.addressZipCode
                  )}
                  displayName={l.name}
                  locationId={l.id}
                  isSelected={locationId === l.id}
                />
              ))}
            </ExpandableContent>
          ))}
        </StyledDropdownInnerContainer>
      </Dropdown>
    </StyledHeader>
  )
}

export default MultiLocationHeader
