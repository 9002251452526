import Constants from './Constants'

export class FileTooBigError extends Error {
  readonly errorMessageToDisplay: string

  constructor(
    mediaType: string,
    methodSizeLimits: { photo: number; video: number }
  ) {
    super(Constants.ERRORS.fileTooBig)

    this.errorMessageToDisplay = Constants.ERRORS.fileSizeExceeded(
      `${
        (mediaType.startsWith('image')
          ? methodSizeLimits.photo
          : methodSizeLimits.video) * Math.pow(2, -20)
      }MB`
    )
  }
}
