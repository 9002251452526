import { useInfiniteQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import {
  ConversationEvent,
  Pagination,
  Paginated,
  ConversationEventDirection,
} from 'src/api/types'

export interface GetConversationEventsParams {
  locationId: number
  conversationId?: number | string
  pagination?: Required<Pagination>
  query?: {
    direction?: ConversationEventDirection
  }
}

export const useGetConversationEvents = ({
  locationId,
  conversationId,
  pagination,
  query,
}: GetConversationEventsParams) => {
  const { getClient } = useApiClient()

  return useInfiniteQuery({
    queryKey: [
      'conversation-events',
      {
        locationId,
        conversationId,
        pagination,
        query,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params], pageParam }) => {
      const searchParams: Record<string, unknown> = {
        skip: pageParam.skip,
        take: pageParam.take,
        direction: query?.direction,
      }

      return getClient()
        .then((client) =>
          client.get<Paginated<ConversationEvent>>(
            `/locations/${
              params.locationId
            }/conversations/${params.conversationId!}/events`,
            {
              params: searchParams,
            }
          )
        )
        .then((r) => r.data)
    },
    initialPageParam: {
      skip: pagination?.skip ?? 0,
      take: pagination?.take ?? 20,
    },
    getNextPageParam: (lastPage, pages, { take }) => {
      const currentLength = pages.reduce((a, c) => a + c.data.length, 0)

      if (currentLength < lastPage.total) {
        return {
          skip: currentLength,
          take,
        }
      }
    },
    enabled: !!(
      locationId &&
      conversationId &&
      !isNaN(+conversationId) &&
      +conversationId > 0
    ),
  })
}
