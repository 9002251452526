import { toast } from 'react-toastify'

import { SelectableConversationsList } from './ConversationsPane/ConversationList'
import { UserInterface } from 'src/client/interfaces/Common'
import { OpenConversationContactDetails } from 'src/containers/MessagingHub/types'
import { isEditableConversationItem } from 'src/contexts/MhContext/utils'
import { getCookie, isLegacyInternalUser } from 'src/utils'

/**
 * Method to determine if user has accepted TCPA policy
 * @param user
 * @returns whether the user has a policy acknowledgement for TCPA policy
 * or if it is internal user or there is a `disableTCPA` cookie for e2e tests.
 */
export const hasAcceptedTCPA = (user: UserInterface) =>
  isLegacyInternalUser(user) ||
  (getCookie('e2eTesting') === 'e2eTestsRunning' &&
    getCookie('disableTCPA', true)) ||
  !!user.policyAcks?.some((policy) => policy.policyType === 'TCPA')

export const getContactSubscriptionState = (
  contact: OpenConversationContactDetails
) => {
  const canBeToggled =
    !!(contact.email && contact.emailOptIn) ||
    !!(contact.name && (!!contact.smsOptIn || contact.smsOptIn === null))

  return {
    canBeToggled,
    toggleState: canBeToggled && !!contact.merchantSubscribed,
  }
}

interface ReducedConversationListToEdit {
  conversationIds: number[]
  conversationEventIds: number[]
}

export const reduceEditableConversations = (
  conversationsList: SelectableConversationsList[]
): ReducedConversationListToEdit =>
  conversationsList.reduce<ReducedConversationListToEdit>(
    (a, c) => {
      if (c.selected && isEditableConversationItem(c)) {
        a.conversationIds.push(c.id)
        a.conversationEventIds.push(c.mostRecentEvent.id)
      }

      return a
    },
    {
      conversationIds: [],
      conversationEventIds: [],
    }
  )

export const showNotificationOnMhActions = (
  action: string,
  requestLength: number,
  successCount: number
) => {
  let toastMethod: keyof typeof toast = 'success'
  let message = `${successCount} conversation${
    successCount > 1 ? 's' : ''
  } ${action}.`

  if (!successCount && requestLength) {
    toastMethod = 'warning'
    message = 'No updates were made, please try again or contact us for help.'
  }

  toast[toastMethod](message)
}

export const downloadFile = ({
  file,
  filename,
}: {
  file: Blob
  filename: string
}) => {
  const audioUrl = URL.createObjectURL(file)
  const link = document.createElement('a')

  link.href = audioUrl
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
