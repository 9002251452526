import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface ToggleLocationForwardingPhoneNumberParams {
  phoneNumberId: number
  forward: boolean
  forwardingPhoneNumberId?: number
}

export const useToggleLocationForwardingPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: ToggleLocationForwardingPhoneNumberParams) => {
      await getClient().then((client) =>
        client.patch<void>(
          `/locations/${locationId}/phone-numbers/${params.phoneNumberId}/call-forwarding`,
          {
            forward: params.forward,
            forwardingPhoneNumberId: params.forwardingPhoneNumberId,
          }
        )
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-phone-number-forwarding-status', { locationId }],
      })
    },
  })
}
