import React from 'react'
import styled from 'styled-components'

import { Body } from 'src/stories/typography'

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

interface LocationUserItemNotesProps {
  notes: string
  title: string
}

const LocationUserItemNotes: React.FC<LocationUserItemNotesProps> = ({
  notes,
  title,
}) => {
  return (
    <Wrapper>
      <Body size="small" textTransform="uppercase">
        {title}
      </Body>
      <Body color="dark">{notes}</Body>
    </Wrapper>
  )
}

export default LocationUserItemNotes
