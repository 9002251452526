import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'

import LocationUserDetailPanelItem from './LocationUserDetailPanelItem'
import LocationUserDetailPanelItemNotes from './LocationUserDetailPanelItemNotes'
import LocationUserDetailPanelListsItem from './LocationUserDetailPanelListsItem'
import {
  LocationList,
  LocationUser,
  UserPosition,
  useDeleteLocationUserEmailAddress,
  useDeleteLocationUserPhoneNumber,
  usePatchLocationUser,
} from 'src/api'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useDebouncer } from 'src/hooks/useDebounce'
import { Button, ButtonProps } from 'src/stories/Button'
import Select from 'src/stories/Select'
import { formatPhoneNumber, formatPhoneType, slugify } from 'src/utils'
import logger from 'src/utils/logger'

const Container = styled.div(({ theme }) => ({
  padding: theme.space(6),
  paddingTop: theme.space(3),
  overflowY: 'auto',
  height: 'auto',
}))

const Divider = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_10}`,
  marginTop: theme.space(4),
  marginBottom: theme.space(4),
}))

// Sections

const Section = styled.div(() => ({}))

const SectionHeader = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const SectionHeaderLabel = styled.p(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.base_40,
  fontWeight: 500,
  fontSize: '1rem',
}))

const SectionContent = styled.div(() => ({}))

interface SectionInterface {
  id: string | number
  label: string
  link?:
    | {
        onClick?: () => void
        href?: string
        label?: string
        action?: ButtonProps['action']
      }
    | false
  content?: ReactElement
}

interface Props {
  activeLocationUser: LocationUser
  locationLists: Record<number, LocationList>
  onEmailAdd?: () => void
  onPhoneAdd?: () => void
  onListAdd?: () => void
}

const positionOptions = [
  {
    label: 'Employee',
    value: UserPosition.EMPLOYEE,
  },
  {
    label: 'Owner',
    value: UserPosition.OWNER,
  },
]

const generateDataCy = (name: string) => `settings-location-user-pane-${name}`

const LocationUserDetailsPane: React.FC<Props> = ({
  activeLocationUser,
  locationLists,
  onEmailAdd,
  onPhoneAdd,
  onListAdd,
}) => {
  const theme = useTheme()
  const { showModal, closeModal } = useModalNotificationsContext()
  const { locationId } = useLocationContext()
  const { mutateAsync: deleteUserEmailAddress } =
    useDeleteLocationUserEmailAddress(locationId)
  const { mutateAsync: deleteUserPhoneNumber } =
    useDeleteLocationUserPhoneNumber(locationId)
  const { isPending: isUserUpdatePending, mutateAsync: updateLocationUser } =
    usePatchLocationUser(locationId, activeLocationUser.id)
  const [isEditingNotes, setIsEditingNotes] = useState(false)

  const onPositionChange = useCallback(
    async (position: UserPosition) => {
      try {
        await updateLocationUser({ position })
      } catch (error) {
        logger.error('Error updating user position', { error })
        toast.error('Error updating user position')
      }
    },
    [updateLocationUser]
  )

  const handleEmailAdreesDeletion = useDebouncer(
    async (userId: number, emailId: number) => {
      try {
        await deleteUserEmailAddress({ userId, emailId })
        closeModal()
      } catch (error) {
        logger.error('Error deleting email', { error })
        toast.error('Error deleting email')
      }
    },
    400
  )

  const handlePhoneNumberDeletion = useDebouncer(
    async (userId: number, phoneNumberId: number) => {
      try {
        await deleteUserPhoneNumber({ userId, phoneNumberId })
        closeModal()
      } catch (error) {
        logger.error('Error deleting phone number', { error })
        toast.error('Error deleting phone number')
      }
    },
    400
  )

  const sections = useMemo<SectionInterface[]>(
    () => [
      {
        id: 'contact-type',
        label: 'Contact Type',
        content: (
          <>
            <Select
              darkBackground={isUserUpdatePending}
              height={theme.space(8)}
              dataCy={generateDataCy('contact-type-select')}
              initialValue={activeLocationUser.position}
              disabled={isUserUpdatePending}
              onChange={(selected) => onPositionChange(selected)}
              options={positionOptions}
            />
          </>
        ),
      },
      ...activeLocationUser.emailAddresses.map<SectionInterface>((email) => ({
        id: `Email-${email.id}`,
        label: 'Email',
        content: (
          <LocationUserDetailPanelItem
            locationId={locationId}
            userId={activeLocationUser.id}
            id={email.id}
            itemType="email"
            canLogin={email.canLogin}
            shouldReceiveLrSummaries={email.shouldReceiveLrSummaries}
            shouldReceiveUpdates={email.shouldReceiveUpdates}
            value={email.emailAddress}
            isVerified={email.isVerified}
          />
        ),
        link: {
          label: 'Delete',
          onClick: () => {
            showModal({
              title: 'Delete Email Address',
              dataCy: `delete-email-modal-${email.id}`,
              headerColor: 'accent_2',
              height: 'auto',
              modalActionsOptions: {
                callToAction: {
                  label: 'Delete',
                  action: 'danger',
                  onClick: () =>
                    handleEmailAdreesDeletion(activeLocationUser.id, email.id),
                },
              },
              customBody: (
                <ConfirmationModal message="Are you sure you want to delete this email address?" />
              ),
            })
          },
          action: 'danger',
        },
      })),
      {
        id: 'add-email',
        label: 'Email',
        link: {
          label: 'Add',
          onClick: onEmailAdd,
        },
      },
      ...activeLocationUser.phoneNumbers.map<SectionInterface>((phone) => ({
        id: `Phone-${phone.id}`,
        label: 'Phone',
        content: (
          <LocationUserDetailPanelItem
            locationId={locationId}
            userId={activeLocationUser.id}
            id={phone.id}
            itemType="phone"
            type={formatPhoneType(phone.type)}
            carrier={phone.carrier}
            shouldReceiveLrSummaries={phone.shouldReceiveLrSummaries}
            shouldReceiveUpdates={phone.shouldReceiveUpdates}
            value={formatPhoneNumber(phone.phoneNumber)}
          />
        ),
        link: {
          label: 'Delete',
          onClick: () => {
            showModal({
              title: 'Delete Phone Number',
              dataCy: `delete-phone-modal-${phone.id}`,
              headerColor: 'accent_2',
              height: 'auto',
              modalActionsOptions: {
                callToAction: {
                  label: 'Delete',
                  action: 'danger',
                  onClick: () =>
                    handlePhoneNumberDeletion(activeLocationUser.id, phone.id),
                },
              },
              customBody: (
                <ConfirmationModal message="Are you sure you want to delete this phone number?" />
              ),
            })
          },
          action: 'danger',
        },
      })),
      {
        id: 'add-phone',
        label: 'Phone',
        link: {
          label: 'Add',
          onClick: onPhoneAdd,
        },
      },
      {
        id: 'add-list',
        label: 'Lists',
        link: {
          label: 'Add',
          onClick: onListAdd,
        },
        content: (
          <LocationUserDetailPanelListsItem
            locationId={locationId}
            userId={activeLocationUser.id}
            listsOfLocation={locationLists}
            listsUserBelongsTo={activeLocationUser.lists ?? []}
          />
        ),
      },
      {
        id: 'notes',
        label: 'Notes',
        content: (
          <LocationUserDetailPanelItemNotes
            locationId={locationId}
            userId={activeLocationUser.id}
            notes={activeLocationUser.notes}
            isEditing={isEditingNotes}
            onCompleted={() => setIsEditingNotes(false)}
          />
        ),
        link: {
          label: isEditingNotes
            ? 'Cancel'
            : Boolean(activeLocationUser.notes)
            ? 'Edit'
            : 'Add',
          onClick: () => setIsEditingNotes((current) => !current),
        },
      },
    ],
    [
      theme,
      onEmailAdd,
      onPhoneAdd,
      onListAdd,
      showModal,
      activeLocationUser,
      locationId,
      handleEmailAdreesDeletion,
      handlePhoneNumberDeletion,
      onPositionChange,
      isUserUpdatePending,
      isEditingNotes,
      setIsEditingNotes,
      locationLists,
    ]
  )

  return (
    <Container>
      {sections.map((section, idx) => {
        const sectionName = slugify(section.label)

        return (
          <React.Fragment key={section.id}>
            {idx !== 0 && (
              <Divider data-cy={generateDataCy(`divider-${idx}`)} />
            )}
            <Section>
              <SectionHeader data-cy={generateDataCy(`${sectionName}-header`)}>
                <SectionHeaderLabel
                  data-cy={generateDataCy(`${sectionName}-label`)}
                >
                  {section.label}
                </SectionHeaderLabel>
                {section.link && (
                  <Button
                    baseDataAttribute={generateDataCy(
                      `${
                        section.link.label?.toLocaleLowerCase() ?? 'item'
                      }-${sectionName}-link`
                    )}
                    displayAsText
                    label={section.link.label || ''}
                    style={{ height: theme.space(8) }}
                    href={section.link.href}
                    onClick={section.link.onClick}
                    action={section.link.action}
                  />
                )}
              </SectionHeader>
              {section.content && (
                <SectionContent
                  data-cy={generateDataCy(`${sectionName}-content`)}
                >
                  {section.content}
                </SectionContent>
              )}
            </Section>
          </React.Fragment>
        )
      })}
    </Container>
  )
}

export default LocationUserDetailsPane
