import Constants from 'src/lib/Constants'
import { formatPhoneNumber } from 'src/utils/helpers/formatters'

/**
 * Method to get a display name from a Contact
 * @returns the contact display name; if `firstName` and `lastName` are empty, fallback to phone number
 */
export const getContactDisplayName = (
  firstName: string,
  lastName: string,
  phoneNumber?: string
): string => {
  const upperCase = (t: string) =>
    t.charAt(0).toUpperCase() + t.slice(1, t.length)

  const name = [firstName, lastName]
    .reduce<string[]>((a, c) => {
      if (c) {
        a.push(upperCase(c))
      }

      return a
    }, [])
    .join(' ')

  return (name || (phoneNumber && formatPhoneNumber(phoneNumber))) ?? ''
}

/**
 * Computes the ownership of a message. This method is provisional while we
 * figure out all edges of the system.
 * @param interactionType
 * @param documentType
 * @returns
 *
 */
export const computeMessageOwnership = (
  interactionType: string,
  documentType: string
) => {
  if (
    [
      Constants.COMMS.documentTypes.agentzInteraction,
      Constants.COMMS.documentTypes.homeAdvisorInteraction,
      Constants.COMMS.documentTypes.thumbtackInteraction,
    ].includes(documentType) ||
    interactionType?.includes('incoming')
  ) {
    return false
  }

  return true
}
