import axios from 'axios'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ColumnMapper, {
  FormSchema as ColumnMapperData,
  FileDetails,
} from './components/ColumnMapper'
import { Container, FileInput, StyledUploadedListIcon } from './styled'
import { useStartContactUpload } from 'src/api'
import WafLayout from 'src/components/WafLayout'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { Body, Heading } from 'src/stories/typography'
import { getFileText, parseCsv } from 'src/utils'

interface Props {
  isMultiLocation: boolean
}

const getFileDetails = async (file: File): Promise<FileDetails | undefined> => {
  const fileText = await getFileText(file)
  const content = await parseCsv(fileText)

  if (content[0]) {
    return {
      columns: content[0],
      name: file.name,
      type: file.type,
      size: file.size,
    }
  }
}

const UploadContacts: React.FC<Props> = ({ isMultiLocation }) => {
  const { locationId } = useLocationContext()
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [fileDetails, setFileDetails] = useState<FileDetails>()

  const { mutateAsync: startContactUpload } = useStartContactUpload()

  const handleFocusBack = () => {
    setIsLoading(false)

    window.removeEventListener('focus', handleFocusBack)
  }

  const onFileSelected: React.ChangeEventHandler<HTMLInputElement> = async ({
    target: { files },
  }) => {
    if (files && files[0]) {
      const _file = files[0]
      const _fileDetails = await getFileDetails(_file)

      setFile(_file)
      setFileDetails(_fileDetails)
    }

    window.removeEventListener('focus', handleFocusBack)
  }

  const onColumnMapperSubmit = async (data: ColumnMapperData) => {
    if (file && fileDetails) {
      const { signedUrl } = await startContactUpload({
        locationId,
        fileName: fileDetails.name,
        firstNameField: data.firstName,
        lastNameField: data.lastName,
        emailAddressField: data.emailAddress,
        phoneNumberField: data.phoneNumber,
      })

      const fileData = await fetch(URL.createObjectURL(file))
      const imageBlob = await fileData.blob()

      await axios.put(signedUrl, imageBlob, {
        headers: { 'Content-Type': fileDetails.type },
      })

      navigate('..')
    }
  }

  return (
    <WafLayout
      layoutTitle={'Upload Contacts'}
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        showDropShadow: true,
      }}
    >
      <Container>
        <StyledUploadedListIcon />
        <Heading as="h2">Before you Upload</Heading>
        <Body as="p">We currently accept CSV files</Body>

        <FileInput
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={onFileSelected}
        />

        <Button
          label={file ? 'Choose another file' : 'Choose a file'}
          action={file ? 'accent' : 'primary'}
          outline={!!file}
          disabled={isLoading}
          style={{ width: '50%' }}
          onClick={() => {
            setIsLoading(true)
            setFile(undefined)
            setFileDetails(undefined)
            if (fileInputRef.current) {
              fileInputRef.current.value = ''

              window.addEventListener('focus', handleFocusBack)
              fileInputRef.current.click()
            }
          }}
        />

        {isLoading && <LoadingSpinner />}
        {file && fileDetails && (
          <>
            <Body as="p" size="large" fontWeight="bold">
              Selected file: {fileDetails.name}
            </Body>
            <ColumnMapper
              fileDetails={fileDetails}
              onSubmit={onColumnMapperSubmit}
            />
          </>
        )}
      </Container>
    </WafLayout>
  )
}

export default UploadContacts
