import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Location } from 'src/api/types'

export const useGetLocationByContactCenterId = ({
  contactCenterId,
}: {
  contactCenterId?: string
}) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'locations',
      {
        contactCenterId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, { contactCenterId: ccId }] }) =>
      getClient()
        .then((client) =>
          client.get<Location>(`/locations/contact-center/${ccId!}`)
        )
        .then((r) => r.data),
    enabled: !!contactCenterId,
  })
}
