import React from 'react'
import { useTheme } from 'styled-components'

import useContactForm, { ContactFormType } from './useContactForm'
import { Contact } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'

interface Props {
  contact: Contact
  channelId: number
  formType: ContactFormType
}

const EditButton: React.FC<Props> = ({ contact, formType, channelId }) => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const channel = contact.channels.find((c) => c.id === channelId)
  const { openContactForm } = useContactForm({
    contactId: contact.id,
    locationId,
    baseDataAttribute: 'edit-contact',
    email: channel?.emailAddress ?? '',
    firstName: contact.firstName,
    lastName: contact.lastName,
    phone: channel?.phoneNumber ?? '',
    hasWrittenName: !!contact.firstName || !!contact.lastName,
    channelId,
  })

  return (
    <Button
      style={{ width: theme.space(6) }}
      displayAsText
      label="Edit"
      onClick={() => openContactForm(formType)}
    />
  )
}

export default EditButton
