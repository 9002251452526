import React from 'react'
import styled from 'styled-components'

import ContactTag from './ContactTag'
import {
  useDeleteContactTag,
  useGetContactTagAttributes,
  useUpdateContactTag,
} from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'

interface Props {
  contactId: number
  contactTagIds: number[]
}

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
  flexWrap: 'wrap',
}))

const ContactsTagsSection: React.FC<Props> = ({ contactId, contactTagIds }) => {
  const { locationId } = useLocationContext()
  const { data: contactTagAttributes = [] } = useGetContactTagAttributes({
    locationId,
  })
  const { mutateAsync: onUpdateContactTag } = useUpdateContactTag(
    locationId,
    contactId
  )
  const { mutateAsync: onDeleteContactTag } = useDeleteContactTag(
    locationId,
    contactId
  )

  return (
    <Container>
      {contactTagAttributes.map((tag) => {
        const isSelected = contactTagIds.includes(tag.id)

        return (
          <ContactTag
            key={tag.id}
            label={tag.label}
            isSelected={isSelected}
            onClick={() => {
              if (isSelected) {
                void onDeleteContactTag({
                  tagId: tag.id,
                }).catch(() => null)

                return
              }

              void onUpdateContactTag({
                tagId: tag.id,
              }).catch(() => null)
            }}
          />
        )
      })}
    </Container>
  )
}

export default ContactsTagsSection
