import styled from 'styled-components'

export const StyledContent = styled.div(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.space(5),
  },
}))

export const StyledTitleContainer = styled.div(({ theme }) => ({
  margin: '0',
  marginBottom: theme.space(1),
  display: 'flex',
  alignItems: 'center',
}))

interface StyledColumnsContainerProps {
  columns: number
}

export const StyledColumnsContainer = styled.div<StyledColumnsContainerProps>(
  ({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    columnGap: theme.space(4),
  })
)

export const StyledBoldItem = styled.span<{ capitalize?: boolean }>(
  ({ theme, capitalize = false }) => ({
    color: theme.colors.base_100,
    textTransform: capitalize ? 'capitalize' : undefined,
  })
)
