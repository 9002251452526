import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { CreateLocationVCardMutationParams } from 'src/api'
import ButtonForm from 'src/components/Settings/Business/ButtonForm'
import {
  StyledColumnsContainer,
  StyledContent,
} from 'src/components/Settings/Business/styled'
import { StyledForm } from 'src/components/Settings/common/layout'
import { MutationBusinessInfoProps } from 'src/containers/Settings/Business'
import { useLocationContext } from 'src/contexts/LocationContext'
import Input from 'src/stories/Input'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { formatPhoneNumber } from 'src/utils'
import { UnknownObject } from 'src/utils/interfaces'

const formSchema = yup.object({
  firstName: yup.string().required('Missing First Name'),
  lastName: yup.string().required('Missing Last Name'),
  email: yup.string().email().default(''),
  street1: yup.string().required('Missing street'),
  street2: yup.string().default(''),
  city: yup.string().required('Missing city'),
  state: yup.string().required('Missing state'),
  zip: yup.string().zipCode('Invalid zip code').required('Missing zip code'),
})

type VCardFormSchema = yup.InferType<typeof formSchema>

interface VCardFormProps
  extends MutationBusinessInfoProps<
    Omit<CreateLocationVCardMutationParams, 'locationId'>,
    Omit<CreateLocationVCardMutationParams, 'locationId'>
  > {
  baseDataAttribute: string
}

const VCardForm: React.FC<VCardFormProps> = ({
  baseDataAttribute,
  isPending,
  update,
  onSubmitFinished,
}) => {
  const { isMediumScreen } = useScreenSizes()
  const { activeLocation } = useLocationContext()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors: formErrors },
    setError,
  } = useForm<VCardFormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: activeLocation.primaryVCard?.firstName,
      lastName: activeLocation.primaryVCard?.lastName,
      email: activeLocation.primaryVCard?.emailAddress ?? undefined,
      street1:
        activeLocation.primaryVCard?.addressStreet1 ??
        activeLocation.addressStreet1,
      street2:
        activeLocation.primaryVCard?.addressStreet2 ??
        activeLocation.addressStreet2 ??
        undefined,
      city:
        activeLocation.primaryVCard?.addressCity ?? activeLocation.addressCity,
      state:
        activeLocation.primaryVCard?.addressState ??
        activeLocation.addressState,
      zip:
        activeLocation.primaryVCard?.addressZipCode ??
        activeLocation.addressZipCode,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      await update({
        addressStreet1: data.street1,
        addressStreet2: data.street2,
        addressCity: data.city,
        addressState: data.state,
        addressZipCode: data.zip,
        emailAddress: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      })

      onSubmitFinished()
    } catch (err: unknown) {
      const propertyNames =
        (
          (err as UnknownObject | undefined)?.data as
            | UnknownObject<`instance.address.${keyof VCardFormSchema}`[]>
            | undefined
        )?.propertyName || []

      propertyNames.forEach((element) => {
        const propertyName = element.split('.').pop()

        if (propertyName) {
          setError(propertyName as keyof VCardFormSchema, {
            message: `Invalid ${propertyName}`,
            type: 'server',
          })
        }
      })
    }
  })

  const disableForm = isSubmitting || isPending

  const inputBaseProps = {
    verticallySpace: 4,
    disabled: disableForm,
  }

  return (
    <StyledForm
      data-cy={`${baseDataAttribute}-form-address`}
      onSubmit={onSubmit}
    >
      <StyledContent>
        <div>
          <StyledColumnsContainer columns={isMediumScreen ? 2 : 1}>
            <Input
              {...inputBaseProps}
              label="Main Contact First Name:"
              errors={formErrors}
              {...register('firstName')}
            />
            <Input
              {...inputBaseProps}
              label="Main Contact Last Name:"
              errors={formErrors}
              {...register('lastName')}
            />
          </StyledColumnsContainer>
          <Input
            {...inputBaseProps}
            label="Email:"
            errors={formErrors}
            type="email"
            {...register('email')}
          />
          <Input
            {...inputBaseProps}
            label="Phone Number:"
            disabled
            helpLabel="This has to be your Signpost Number and can't be changed."
            name="phoneNumber"
            value={
              activeLocation.primaryRentedPhoneNumber &&
              formatPhoneNumber(activeLocation.primaryRentedPhoneNumber)
            }
          />
          <Input
            {...inputBaseProps}
            label="Address line 1:"
            errors={formErrors}
            {...register('street1')}
          />
          <Input
            {...inputBaseProps}
            label="Address line 2 (optional):"
            errors={formErrors}
            {...register('street2')}
          />
          <StyledColumnsContainer columns={isMediumScreen ? 3 : 1}>
            <Input
              {...inputBaseProps}
              label="City:"
              errors={formErrors}
              {...register('city')}
            />
            <Input
              {...inputBaseProps}
              label="State:"
              errors={formErrors}
              {...register('state')}
            />
            <Input
              {...inputBaseProps}
              label="Zip code:"
              errors={formErrors}
              {...register('zip')}
            />
          </StyledColumnsContainer>
        </div>
        <ButtonForm
          baseDataAttribute={`${baseDataAttribute}-form-address`}
          disabled={disableForm}
        />
      </StyledContent>
    </StyledForm>
  )
}

export default VCardForm
