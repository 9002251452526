import { format, isValid, parseISO } from 'date-fns'
import { useMemo } from 'react'
import styled from 'styled-components'

import { AngiLeadsConversationEventDetailAppointment } from 'src/api'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
  justifyContent: 'flex-start',
}))

const Item = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(1),
}))

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: theme.space(0.5),
  backgroundColor: theme.colors.base_20,
}))

const Tag = styled.div(({ theme, onClick }) => ({
  backgroundColor: theme.colors.accent_5,
  borderRadius: theme.constants.borderRadius,
  width: 'fit-content',
  padding: `${theme.space(1)} ${theme.space(2)}`,
  cursor: onClick ? 'pointer' : 'auto',
}))

interface Props {
  appointment: AngiLeadsConversationEventDetailAppointment
}

const getDateValue = (date: string) => {
  const dateObj = parseISO(date)

  if (!isValid(dateObj)) {
    return
  }

  return format(dateObj, 'MMM dd, yyyy - hh:mm a')
}

const AngiLeadsMessageAppointment: React.FC<Props> = ({ appointment }) => {
  const fields = useMemo(
    () => [
      { label: 'Type:', value: appointment.type },
      { label: 'Status:', value: appointment.status },
      { label: 'Start:', value: getDateValue(appointment.start) },
      { label: 'End:', value: getDateValue(appointment.end) },
    ],
    [appointment]
  )

  return (
    <Container>
      <Tag>
        <Body size="small" customColor="base_0">
          Appointment Scheduled
        </Body>
      </Tag>
      <Divider />
      {fields.map((field) => (
        <Item key={field.label}>
          <Body as="span" color="darker" fontWeight="bold">
            {field.label}
          </Body>
          <Body as="p">{field.value}</Body>
        </Item>
      ))}
    </Container>
  )
}

export default AngiLeadsMessageAppointment
