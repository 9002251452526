import { DefaultTheme } from 'styled-components'

const hexToRgba = (
  hex: string
): { r: number; g: number; b: number; a: number; rgba: string } => {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)
  const a = parseInt(hexValue.substring(6, 8) || 'ff', 16) / 255

  return {
    r,
    g,
    b,
    a: parseFloat(a.toFixed(1)),
    rgba: formatRgba(r, g, b, a),
  }
}

const rgbaToHex = (r: number, g: number, b: number, a: number): string => {
  let newR = r.toString(16)
  let newG = g.toString(16)
  let newB = b.toString(16)
  let newA = Math.round(a * 255).toString(16)

  return (
    '#' +
    newR.padStart(2, '0') +
    newG.padStart(2, '0') +
    newB.padStart(2, '0') +
    newA.padStart(2, '0')
  )
}

const rgbaToHsla = (
  r: number,
  g: number,
  b: number,
  a: number
): { h: number; s: number; l: number; a: number; hsla: string } => {
  let normalizedR = r / 255
  let normalizedG = g / 255
  let normalizedB = b / 255
  const cmax = Math.max(normalizedR, normalizedG, normalizedB)
  const cmin = Math.min(normalizedR, normalizedG, normalizedB)
  const delta = cmax - cmin
  let h = 0

  // Calculate hue

  if (delta === 0) {
    h = 0
  } else if (cmax === normalizedR) {
    h = ((normalizedG - normalizedB) / delta) % 6
  } else if (cmax === normalizedG) {
    h = (normalizedB - normalizedR) / delta + 2
  } else if (cmax === normalizedB) {
    h = (normalizedR - normalizedG) / delta + 4
  }

  // Make negative hues positive behind 360°
  h = Math.round(h * 60)

  // Calculate lightness
  let l = (cmax + cmin) / 2

  // Calculate saturation
  let s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return {
    hsla: formatHsla(h, s, l, a),
    h,
    s,
    l,
    a,
  }
}

const formatRgba = (
  r: number | string,
  g: number | string,
  b: number | string,
  a: number | string
): string => `rgba(${r}, ${g}, ${b}, ${a})`

const formatHsla = (
  h: number | string,
  s: number | string,
  l: number | string,
  a: number | string
): string => `hsla(${h}, ${s}%, ${l}%, ${a})`

const colorMethods: DefaultTheme['colorMethods'] = {
  hexToRgba,
  rgbaToHex,
  rgbaToHsla,
  formatRgba,
  formatHsla,
}

// Colors

const baseHue = 0
const baseSaturation = 0

const base_100 = `hsl(${baseHue}, ${baseSaturation}%, 0%)`
const base_90 = `hsl(${baseHue}, ${baseSaturation}%, 10%)`
const base_80 = `hsl(${baseHue}, ${baseSaturation}%, 20%)`
const base_70 = `hsl(${baseHue}, ${baseSaturation}%, 30%)`
const base_60 = `hsl(${baseHue}, ${baseSaturation}%, 40%)`
const base_50_60 = `hsla(${baseHue}, ${baseSaturation}%, 50%, 0.6)`
const base_50 = `hsl(${baseHue}, ${baseSaturation}%, 50%)`
const base_40 = `hsl(${baseHue}, ${baseSaturation}%, 60%)`
const base_30 = `hsl(${baseHue}, ${baseSaturation}%, 70%)`
const base_20 = `hsl(${baseHue}, ${baseSaturation}%, 80%)`
const base_10 = `hsl(${baseHue}, ${baseSaturation}%, 90%)`
const base_5 = `hsl(${baseHue}, ${baseSaturation}%, 95%)`
const base_3 = `hsl(${baseHue}, ${baseSaturation}%, 97%)`
const base_0 = `hsl(${baseHue}, ${baseSaturation}%, 100%)`
const base_0_20 = `hsla(${baseHue}, ${baseSaturation}%, 100%, 0.2)`

const colors: DefaultTheme['colors'] = {
  primary_1_hex: '#005BB5',
  primary_1: 'rgba(0, 91, 181, 1)',
  primary_1_10: 'rgba(0, 91, 181, 0.1)',
  primary_1_25: 'rgba(0, 91, 181, 0.25)',
  primary_1_45: 'rgba(0, 91, 181, 0.45)',
  primary_1_70: 'rgba(0, 91, 181, 0.7)',
  primary_1_dark: 'rgba(0, 54, 107, 1)',
  primary_2: 'rgba(49, 140, 230, 1)',
  primary_2_10: 'rgba(49, 140, 230, 0.1)',
  primary_2_15: 'rgba(49, 140, 230, 0.15)',
  primary_2_70: 'rgba(49, 140, 230, 0.7)',
  accent_1: 'rgba(255, 121, 11, 1)',
  accent_1_15: 'rgba(255, 121, 11, 0.15)',
  accent_1_30: 'rgba(255, 121, 11, 0.3)',
  accent_1_70: 'rgba(255, 121, 11, 0.7)',
  accent_2: 'rgba(225, 30, 62, 1)',
  accent_2_15: 'rgba(225, 30, 62, .15)',
  accent_2_30: 'rgba(225, 30, 62, .3)',
  accent_2_70: 'rgba(225, 30, 62, 0.7)',
  accent_3: 'rgba(104, 190, 0, 1)',
  accent_3_70: 'rgba(104, 190, 0, 0.7)',
  accent_3_30: 'rgba(104, 190, 0, 0.3)',
  accent_3_15: 'rgba(104, 190, 0, 0.15)',
  accent_4: 'rgba(255, 191, 11, 1)',
  accent_5: 'rgba(172, 106, 255, 1)',
  base_100,
  base_90,
  base_80,
  base_70,
  base_60,
  base_50,
  base_50_60,
  base_40,
  base_30,
  base_20,
  base_10,
  base_5,
  base_3,
  base_0_20,
  base_0,
}

// Spacing

const spacingBaseValue = 4
const space = (value: number) => `${value * spacingBaseValue}px`

// Z-Indexing

const baseZIndex = 100
const onTopOf = 10

const layoutZIndex = baseZIndex
const tooltipZIndex = onTopOf + layoutZIndex
const selectZIndex = onTopOf + tooltipZIndex
const dropdownZIndex = onTopOf + selectZIndex
const backdropZIndex = onTopOf + dropdownZIndex
const sideDrawerZIndex = onTopOf + backdropZIndex
const modalZIndex = onTopOf + sideDrawerZIndex

// Breakpoints

const breakpoints: DefaultTheme['breakpoints'] = {
  xxl: [1440, 900],
  xl: [1280, 800],
  lg: [1020, 768],
  md: [720, 1280],
  sm: [640, 960],
  xs: [480, 800],
  xxs: [320, 480],
}
const getBreakpoint: DefaultTheme['getBreakpoint'] = (width) =>
  `(min-width: ${breakpoints[width][0]}px)`

const navbarHeightUnits = 18
const multiLocationHeaverHeightUnits = 9
const sidebarWidthUnits = 65
const getHeadersHeights = (include?: {
  navbar?: boolean
  multiLocationHeader?: boolean
  additionalHeight?: number
}) =>
  space(
    (include?.navbar ? navbarHeightUnits : 0) +
      (include?.multiLocationHeader ? multiLocationHeaverHeightUnits : 0) +
      (include?.additionalHeight ?? 0)
  )

// Typography
const typographyWeights = {
  medium: 400,
  bold: 500,
  bolder: 600,
}

const typographyColors = {
  darker: colors.base_100,
  dark: colors.base_70,
  medium: colors.base_60,
  light: colors.base_50,
  lighter: colors.base_40,
}

const theme: DefaultTheme = {
  colors,
  colorMethods,
  space,
  zIndexes: {
    layout: layoutZIndex,
    tooltip: tooltipZIndex,
    select: selectZIndex,
    dropdown: dropdownZIndex,
    backdrop: backdropZIndex,
    sidedrawer: sideDrawerZIndex,
    modal: modalZIndex,
  },
  constants: {
    borderRadius: '4px',
    largeBorderRadius: '8px',
  },
  heights: {
    navbar: space(navbarHeightUnits),
    multiLocationHeader: space(multiLocationHeaverHeightUnits),
    navbarHeightUnits,
    multiLocationHeaverHeightUnits,
    layoutStatusBar: 15,
    getHeadersHeights,
  },
  widths: {
    sidebar: space(sidebarWidthUnits),
    sidebarWidthUnits,
  },
  breakpoints,
  getBreakpoint,
  typography: {
    weights: typographyWeights,
    colors: typographyColors,
    heading: {
      extraLarge: {
        fontSize: '2.4rem',
        lineHeight: '3.6rem',
        fontWeight: typographyWeights.bolder,
        color: typographyColors.darker,
        textTransform: 'none',
        fontStyle: 'unset',
      },
      large: {
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
        fontWeight: typographyWeights.bolder,
        color: typographyColors.darker,
        textTransform: 'none',
        fontStyle: 'unset',
      },
      medium: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.darker,
        textTransform: 'none',
        fontStyle: 'unset',
      },
      small: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.light,
        textTransform: 'uppercase',
        fontStyle: 'unset',
      },
    },
    subtitle: {
      medium: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.bold,
        color: typographyColors.dark,
        textTransform: 'none',
        fontStyle: 'unset',
      },
    },
    body: {
      large: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.dark,
        textTransform: 'none',
        fontStyle: 'unset',
      },
      medium: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.dark,
        textTransform: 'none',
        fontStyle: 'unset',
      },
      small: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        fontWeight: typographyWeights.medium,
        color: typographyColors.dark,
        textTransform: 'none',
        fontStyle: 'unset',
      },
    },
  },
}

export default theme
