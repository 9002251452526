import { useTheme } from 'styled-components'

import DisabledAccountModal, {
  DisabledAccountStatus,
} from 'src/components/MessagingHub/DisabledAccountModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'

export const useShowDisabledAccountModal = () => {
  const { showModal } = useModalNotificationsContext()
  const theme = useTheme()

  return ({
    status,
    closingDate,
  }: {
    status: DisabledAccountStatus
    closingDate?: string
  }) => {
    showModal({
      title: 'Account Disabled',
      dataCy: 'mh-account-disabled-modal',
      width: theme.space(120),
      hideActionButtons: true,
      canClose: false,
      customBody: (
        <DisabledAccountModal status={status} closingDate={closingDate} />
      ),
    })
  }
}
