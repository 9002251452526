import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface BulkUpdateConversationMutationParams {
  locationId: number
  conversationId: number[]
  conversationEventId?: number[]
  isRead?: boolean
  isArchived?: boolean
  isSpam?: boolean
}

export interface BulkUpdateConversationMutationResponse {
  conversations: number
  conversationsUpdated: number
  conversationsEvents: number
  conversationsEventsUpdated: number
}

export const useBulkUpdateConversation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: BulkUpdateConversationMutationParams) => {
      return getClient()
        .then((client) =>
          client.patch<BulkUpdateConversationMutationResponse>(
            `/locations/${params.locationId}/conversations/bulk`,
            {
              isRead: params.isRead,
              isArchived: params.isArchived,
              isSpam: params.isSpam,
              conversationId: params.conversationId,
              conversationEventId: params.conversationEventId,
            }
          )
        )
        .then((r) => r.data)
        .catch(() => ({
          conversations: params.conversationId.length,
          conversationsUpdated: 0,
          conversationsEvents: params.conversationEventId?.length || 0,
          conversationsEventsUpdated: 0,
        }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['conversations', { locationId }],
      })
    },
  })
}
