import { ReactElement } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { CloseIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body } from 'src/stories/typography'

const bannerNotificationType = {
  error: (theme: DefaultTheme) => ({
    borderColor: theme.colors.accent_2,
    backgroundColor: theme.colors.accent_2_15,
    color: theme.colors.accent_2,
  }),
}

interface Props {
  type: keyof typeof bannerNotificationType
  title: string | ReactElement
  message?: string | ReactElement
  icon?: ReactElement
}

const Container = styled.div<{
  isDesktop: boolean
  type: keyof typeof bannerNotificationType
}>(({ theme, isDesktop, type }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.space(3),
  width: isDesktop ? '90vw' : '100vw',
  maxWidth: isDesktop ? theme.space(220) : 'auto',
  borderStyle: 'solid',
  borderWidth: 2,
  padding: theme.space(4),
  borderRadius: theme.space(2),
  textWrap: 'pretty',
  ...bannerNotificationType[type](theme),
}))

const IconContainer = styled.span(({ theme }) => ({
  display: 'inline-block',
  width: theme.space(4),
  height: theme.space(4),
}))

const InlineContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(3),
  flexShrink: 0,
}))

const ResponsiveContainer = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    alignItems: isDesktop ? 'center' : 'start',
    gap: isDesktop ? theme.space(3) : theme.space(2),
  })
)

const StyledCloseXIcon = styled(CloseIcon)(({ theme }) => ({
  marginLeft: 'auto',
  fill: theme.colors.base_30,
  cursor: 'pointer',
  flexShrink: 0,
}))

const BannerNotification: React.FC<Props> = ({
  type,
  title,
  message,
  icon,
}) => {
  const { isMediumScreen: isDesktop } = useScreenSizes()

  return (
    <Container
      className="banner-notification"
      isDesktop={isDesktop}
      type={type}
    >
      <ResponsiveContainer isDesktop={isDesktop}>
        <InlineContainer>
          {icon && <IconContainer>{icon}</IconContainer>}
          <Body fontWeight="bolder" customColor="accent_2">
            {title}
          </Body>
        </InlineContainer>
        {message && <Body customColor="accent_2">{message}</Body>}
      </ResponsiveContainer>
      <StyledCloseXIcon />
    </Container>
  )
}

export default BannerNotification
