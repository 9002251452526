import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface UpdateSmsRegistrationParams {
  businessIdentifier?: string
  smsRegistrationId: number
}

export const useUpdateSmsRegistration = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      businessIdentifier,
      smsRegistrationId,
    }: UpdateSmsRegistrationParams) => {
      const client = await getClient()

      await client.patch(
        `/locations/${locationId}/sms-registrations/${smsRegistrationId}`,
        { businessIdentifier }
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['locations', { locationId }],
      })
    },
  })
}
