import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface DeleteLocationUserPhoneNumberMutationParams {
  userId: number
  phoneNumberId: number
}

export const useDeleteLocationUserPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: DeleteLocationUserPhoneNumberMutationParams) => {
      const client = await getClient()

      await client.delete(
        `/locations/${locationId}/users/${params.userId}/phone-numbers/${params.phoneNumberId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
