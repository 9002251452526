import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateConversationStatusAttributesParams {
  statusId: number
  label: string
  color: string
}

export const useUpdateConversationStatusAttributes = (
  locationId: number,
  preventQueryInvalidation = false
) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: UpdateConversationStatusAttributesParams) => {
      const client = await getClient()

      await client.put(
        `/locations/${locationId}/conversations/status-attributes/${params.statusId}`,
        {
          color: params.color,
          label: params.label,
        }
      )
    },
    onSuccess: async () => {
      if (!preventQueryInvalidation) {
        await queryClient.invalidateQueries({
          queryKey: ['conversation-status-attributes', { locationId }],
        })
      }
    },
  })
}
