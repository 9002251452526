import styled from 'styled-components'

import { UploadedListIcon } from 'src/stories/assets'

export const Container = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  background: theme.colors.base_0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.space(5),
  gap: theme.space(2),
}))

export const StyledUploadedListIcon = styled(UploadedListIcon)(({ theme }) => ({
  width: theme.space(20),
  height: theme.space(20),
}))

export const FileInput = styled.input(() => ({
  display: 'none',
}))
