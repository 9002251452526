import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Paginated, PaymentMethod } from 'src/api/types'

export const useGetLocationPaymentMethods = (locationId: number) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['location-payment-methods', { locationId }] as const,
    queryFn: async ({ queryKey: [, { locationId: lid }] }) => {
      return getClient()
        .then((client) =>
          client.get<Paginated<PaymentMethod>>(
            `/locations/${lid}/billing/payment-methods`
          )
        )
        .then((r) => r.data)
    },
  })
}
