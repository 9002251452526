import { useMutation } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateConversationStatusParams {
  statusId: number
  conversationId: number
}

export const useUpdateConversationStatus = (locationId: number) => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: UpdateConversationStatusParams) => {
      const client = await getClient()

      await client.patch(
        `/locations/${locationId}/conversations/${params.conversationId}/status/${params.statusId}`
      )
    },
  })
}
