import React from 'react'
import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import { Col, Row } from 'src/stories/Layout'
import Modal from 'src/stories/Modal'

interface StyledModalHeaderTextProps {
  bottomSpaced?: boolean
}
const StyledModalHeaderText = styled.p<StyledModalHeaderTextProps>(
  ({ theme, bottomSpaced }) => ({
    fontSize: '2.2rem',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: theme.space(bottomSpaced ? 8 : 4),
  })
)
const StyledModalContentText = styled.p<StyledModalHeaderTextProps>(
  ({ theme, bottomSpaced }) => ({
    fontSize: '1.8rem',
    fontWeight: 400,
    marginTop: 0,
    lineHeight: `1.6em`,
    marginBottom: theme.space(bottomSpaced ? 8 : 4),
  })
)
const StyledModalBulletText = styled.li<StyledModalHeaderTextProps>(
  ({ theme, bottomSpaced }) => ({
    fontSize: '1.6rem',
    color: theme.colors.base_40,
    marginBottom: theme.space(bottomSpaced ? 6 : 4),
    paddingLeft: theme.space(3),
    lineHeight: theme.space(5),
    '&::marker': {
      content: '"✓"',
    },
  })
)

export interface ModalProps {
  modalCanClose: boolean
  handleClose: () => void
}

const ModalTrialUpgrade: React.FC<ModalProps> = ({
  modalCanClose,
  handleClose,
}) => {
  const modalBody = (
    <Row>
      <Col>
        <StyledModalHeaderText bottomSpaced>
          Turn your leads into customers
        </StyledModalHeaderText>
        <StyledModalContentText>
          Schedule a call with our team to get matched with the perfect package
          for your business
        </StyledModalContentText>
        <ul>
          <StyledModalBulletText bottomSpaced>
            Reply to leads and customers with our team of US-based receptionists
            and our suite of Instant Response features.
          </StyledModalBulletText>
          <StyledModalBulletText bottomSpaced>
            Use one central Messaging Hub to view call summaries, send text
            messages, continue conversations on Facebook and Google, and more.
          </StyledModalBulletText>
          <StyledModalBulletText bottomSpaced>
            Get found and chosen online. We'll send review campaigns to help you
            grow your online reputation on the top review sites like Google and
            Yelp, getting you more calls and messages as a result.
          </StyledModalBulletText>
        </ul>
        <Button
          label="Schedule a call"
          href="https://meetings.hubspot.com/matt-packard/free-trial-demo"
          shouldOpenInNewTab={true}
          action="primary"
          maxWidth={250}
        />
      </Col>
      <Col>
        {/*
         * TODO: In this column, the missing modal components must be added,
         * to correspond to the proposed design for the trial upgrade functionality,
         * according to the existing prototype in Figma.
         *
         * See more on the PLT-457 card.
         */}
      </Col>
    </Row>
  )

  return (
    <Modal
      modalTitle="Upgrade your Signpost free trial"
      modalDataCy="trial-upgrade-modal"
      modalCanClose={modalCanClose}
      headerColor="primary_1"
      handleClose={handleClose}
      height="90"
      width="200"
      hideActions
    >
      {modalBody}
    </Modal>
  )
}

export default ModalTrialUpgrade
