import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { UserPosition } from 'src/api/types'

interface CreateContactParams {
  locationId: number
  firstName: string
  lastName: string
  position: UserPosition
  isReadonly: boolean
}

export const useCreateLocationUser = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateContactParams) => {
      const client = await getClient()

      await client.post(`/locations/${params.locationId}/users`, {
        firstName: params.firstName,
        lastName: params.lastName,
        position: params.position,
        isReadonly: params.isReadonly,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-users', { locationId }],
      })
    },
  })
}
