import { useInfiniteQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Conversation, Paginated, Pagination } from 'src/api/types'

export interface GetConversationsParams {
  locationId: number
  pagination?: Required<Pagination>
  search?: string
  isSpam?: boolean
  isArchived?: boolean
  statusId?: number
}

export const useGetConversations = ({
  locationId,
  pagination,
  search,
  isSpam,
  isArchived,
  statusId,
}: GetConversationsParams) => {
  const { getClient } = useApiClient()

  return useInfiniteQuery({
    queryKey: [
      'conversations',
      {
        locationId,
        pagination,
        search,
        isSpam,
        isArchived,
        statusId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params], pageParam }) => {
      const searchParams: Record<string, unknown> = {
        skip: pageParam.skip,
        take: pageParam.take,
        isSpam: params.isSpam ?? false,
        isArchived: params.isArchived ?? false,
        statusId: params.statusId ?? undefined,
      }

      if (params.search) {
        searchParams.search = params.search
      }

      return getClient()
        .then((client) =>
          client.get<Paginated<Conversation>>(
            `/locations/${params.locationId}/conversations`,
            {
              params: searchParams,
            }
          )
        )
        .then((r) => r.data)
    },
    initialPageParam: {
      skip: pagination?.skip ?? 0,
      take: pagination?.take ?? 15,
    },
    getNextPageParam: (lastPage, pages, { take }) => {
      const currentLength = pages.reduce((a, c) => a + c.data.length, 0)

      if (currentLength < lastPage.total) {
        return {
          skip: currentLength,
          take,
        }
      }
    },
  })
}
