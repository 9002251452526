import { useMutation } from '@tanstack/react-query'
import { AxiosHeaders } from 'axios'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateConversationParams {
  locationId: number
  contactId: number
}

export const useCreateConversation = () => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: CreateConversationParams) => {
      const client = await getClient()

      const { headers } = await client.post(
        `/locations/${params.locationId}/conversations`,
        {
          locationId: params.locationId,
          contactId: params.contactId,
        }
      )

      if (headers instanceof AxiosHeaders && headers.has('location')) {
        const location = headers['location'] as string

        const id = location.split('/').pop()

        return id ? +id : undefined
      }
    },
  })
}
