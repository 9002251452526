import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateContactChannelParams {
  contactId: number
  phoneNumber?: string
  emailAddress?: string
}

export const useCreateContactChannel = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateContactChannelParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${locationId}/contacts/${params.contactId}/channels`,
        {
          phoneNumber: params.phoneNumber,
          emailAddress: params.emailAddress,
        }
      )
    },
    onSuccess: async (_, params) => {
      const { contactId } = params

      await queryClient.invalidateQueries({
        queryKey: ['contact', { locationId, contactId }],
      })
    },
  })
}
