import React from 'react'

import Footer from 'src/components/WafLayout/Footer'
import Layout, { LayoutProps } from 'src/stories/Layout'

export interface WafLayoutProps extends LayoutProps {
  displayFooter?: boolean
}

const WafLayout: React.FC<WafLayoutProps> = ({
  children,
  displayFooter,
  ...props
}) => {
  return (
    <Layout {...props} footer={displayFooter ? <Footer /> : undefined}>
      {children}
    </Layout>
  )
}

export default WafLayout
