import { useState } from 'react'
import styled from 'styled-components'

import { renderFilter } from './FilterComponents'
import { Button } from 'src/stories/Button'
import Select from 'src/stories/Select'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const FiltersContainer = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    backgroundColor: theme.colors.base_5,
    display: 'flex',
    alignItems: isDesktop ? 'center' : 'unset',
    flexDirection: isDesktop ? 'row' : 'column',
    gap: theme.space(2),
    padding: theme.space(2),
    width: '100%',
  })
)

const FilterSelectContainer = styled.div(() => ({
  flex: 2,
}))

const RenderedFilterContainer = styled.div(({ theme }) => ({
  flex: 8,
  marginLeft: theme.space(3),
}))

const FilterButtonContainer = styled.div(() => ({
  flex: 2,
}))

const Filter: React.FC = () => {
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const [selectedFilter, setSelectedFilter] = useState('status')

  return (
    <FiltersContainer isDesktop={isDesktop}>
      <FilterSelectContainer>
        <Select
          options={[
            { label: 'Status', value: 'status' },
            { label: 'Lifecycle', value: 'lifecycle' },
            { label: 'Estimates', value: 'estimates' },
            { label: 'Jobs', value: 'jobs' },
          ]}
          onChange={(selected: string) => setSelectedFilter(selected)}
        ></Select>
      </FilterSelectContainer>
      <RenderedFilterContainer>
        {renderFilter(selectedFilter)}
      </RenderedFilterContainer>
      <FilterButtonContainer>
        <Button label="Filter" />
      </FilterButtonContainer>
    </FiltersContainer>
  )
}

export default Filter
