import { AxiosInstance } from 'axios'

import { ConversationsV3Requests } from 'src/client/interfaces/ConversationsV3'
import Resource from 'src/client/resource'

const actions = (client: AxiosInstance): ConversationsV3Requests => {
  const findConversations: ConversationsV3Requests['findConversations'] = ({
    locationId,
    pagination,
    search,
  }) => {
    return client.get(`/v3/locations/${locationId}/conversations`, {
      params: {
        ...pagination,
        ...search,
        take: 200,
        skip: 0,
      },
    })
  }

  const findPublicReviews: ConversationsV3Requests['findPublicReviews'] = ({
    locationId,
    pagination,
    reviewSites,
  }) => {
    return client.get(`/v3/locations/${locationId}/public-reviews`, {
      params: {
        ...pagination,
        reviewSites,
      },
    })
  }

  const findConversationEvents: ConversationsV3Requests['findConversationEvents'] =
    ({ locationId, contactId }) => {
      return client.get(
        `/v3/locations/${locationId}/conversations/${contactId}/events`
      )
    }

  const findConversationEvent: ConversationsV3Requests['findConversationEvent'] =
    ({ locationId, conversationId, eventFingerprint }) => {
      return client.get(
        `/v3/locations/${locationId}/conversations/${conversationId}/events/${eventFingerprint}`
      )
    }

  return {
    findConversations,
    findPublicReviews,
    findConversationEvents,
    findConversationEvent,
  }
}

export default Resource(actions)
