import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Location } from 'src/api/types'

export const useGetAccountLocations = (accountId?: number) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['account-locations', { accountId }] as const,
    queryFn: async ({ queryKey: [, params] }) =>
      getClient()
        .then((client) =>
          client.get<Location[]>(`/accounts/${params.accountId!}/locations`)
        )
        .then((r) => r.data),
    enabled: !!accountId,
  })
}
