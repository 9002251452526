import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import * as yup from 'yup'

import {
  useCreateLocationForwardingPhoneNumber,
  useGetLocationForwardingPhoneNumber,
  useUpdateLocationForwardingPhoneNumber,
} from 'src/api'
import ToggleAction from 'src/components/ToggleAction'
import { useLocationContext } from 'src/contexts/LocationContext'
import { useLocationForwardingPhoneNumber } from 'src/hooks/useLocationForwardingPhoneNumber'
import { Button } from 'src/stories/Button'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import PhoneNumberInput from 'src/stories/PhoneNumberInput'
import { ReceptionistIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body, Heading } from 'src/stories/typography'

const TitleContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(1),
}))

const Form = styled.form(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
}))

const formSchema = yup
  .object({
    phoneNumber: yup
      .string()
      .phoneNumber()
      .required('Phone number is required'),
  })

  .required()

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  baseDataAttribute: string
}

const CallForwarding: React.FC<Props> = ({ baseDataAttribute }) => {
  const { locationId } = useLocationContext()
  const formRef = useRef<HTMLFormElement>(null)
  const { isSmallScreen: isDesktop } = useScreenSizes()
  const theme = useTheme()

  const { data: forwardingPhoneNumber } = useGetLocationForwardingPhoneNumber({
    locationId,
  })
  const { mutateAsync: createLocationForwardingPhoneNumber } =
    useCreateLocationForwardingPhoneNumber(locationId)
  const { mutateAsync: updateLocationForwardingPhoneNumber } =
    useUpdateLocationForwardingPhoneNumber(locationId)

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    control,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    values: {
      phoneNumber: forwardingPhoneNumber?.phoneNumber ?? '',
    },
    mode: 'onChange',
    resetOptions: { keepDefaultValues: true },
  })

  const { isTogglingDisabled, isLrEnabled, onToggle } =
    useLocationForwardingPhoneNumber({
      forwardingPhoneNumber,
      canDoToggle: trigger,
    })

  const onSubmit = handleSubmit(async ({ phoneNumber }) => {
    if (isTogglingDisabled) {
      toast.error('Cannot toggle Live Receptionist, contact support')

      return
    }

    try {
      if (forwardingPhoneNumber?.id) {
        await updateLocationForwardingPhoneNumber({
          forwardingPhoneNumberId: forwardingPhoneNumber.id,
          phoneNumber,
        })

        toast.success('Fallback number updated successfully')

        return
      }

      await createLocationForwardingPhoneNumber({
        phoneNumber,
      })

      toast.success('Fallback number created successfully')
    } catch (error) {
      toast.error(
        'There was an error trying to set up your Fallback number. Please try again'
      )
    }
  })

  const isDisabled = isTogglingDisabled || isSubmitting

  return (
    <PageSectionContainer
      title="Inbound Call Setup"
      titleProps={{
        as: 'h3',
        size: 'medium',
      }}
      action={false}
      isRounded
      childrenStyle={{
        gap: theme.space(3),
      }}
    >
      <PageSectionContainer
        title={
          <TitleContainer>
            <ReceptionistIcon
              fill={theme.colors.base_100}
              style={{ marginRight: theme.space(2) }}
            />
            <Heading as="h3">Live Receptionist</Heading>
          </TitleContainer>
        }
        subtitle="Send all my calls to my Live Receptionist"
        variant="inverted"
        isRounded
        action={ToggleAction}
        actionProps={{
          label: isLrEnabled
            ? 'Live Receptionist Active'
            : 'Live Receptionist Not Active',
          dataCy: '',
          disabled: isDisabled,
          checked: isLrEnabled,
          onClick: () => null,
          onEnablementChange: onToggle,
        }}
        preventHiding
        childrenStyle={{
          gap: theme.space(3),
        }}
      >
        <Body>
          Please enter a fallback number. This is usually your business phone
          number. All incoming calls will be instantly directed to this number
          when the Live Receptionist is not active.
        </Body>

        <Form
          onSubmit={onSubmit}
          id={baseDataAttribute + '-form'}
          ref={formRef}
        >
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneNumberInput
                label="Fallback number:"
                errors={errors}
                verticallySpaced={false}
                style={{ marginTop: 0, maxWidth: isDesktop ? '40%' : '100%' }}
                {...field}
                disabled={field.disabled || isDisabled}
              />
            )}
          />

          <Button
            label="Save"
            type="submit"
            style={{ width: isDesktop ? '10%' : '100%' }}
            disabled={Object.values(errors).some(Boolean) || isDisabled}
          />
        </Form>
      </PageSectionContainer>
    </PageSectionContainer>
  )
}

export default CallForwarding
