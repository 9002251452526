import { AxiosInstance } from 'axios'

import customClient from './client'

const createResource =
  (client: AxiosInstance) =>
  <T>(actions: (baseClient: AxiosInstance) => T) => {
    return { ...actions(client) }
  }

export default createResource(customClient)
